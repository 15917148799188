import { makeAutoObservable } from 'mobx';
import isEqual from 'lodash/isEqual';
import { FieldIdType, FormDefinition, FormLayout, FormLayoutType } from '@/Annotator/data/forms';
import { RootStore } from '@/stores/rootStore';
import { accountsService } from '@/Accounts/accountsServices';
import { deepClone, keyPropGroup } from '@/Annotator/data/utils';
import { OntologyTemplate, TemplateAssignment, TemplateGroup } from '@/Annotator/data/templates';
import { FieldDefinition } from '@/FieldDefinitions/types';
import { EditFormUtils } from '../utils/editFormUtils';
import { EditFormDragStore } from './editFormDragStore';
import ProtocolAnnotator from '@/Annotator/Templates/ProtocolAnnotator';
import axios from 'axios';
import { VaultRunFieldDefinitions } from '@/Protocols/types';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { FieldDefinitionsMap, FormDefinitionForEditing, FormLayoutForEditing } from '../types';
import { blankFormTemplate } from '../components/editor/constants';
import { term } from '@/shared/utils/stringUtils';

/**
 * A store for persisting form definition and associated editor dependency data.
 *
 * Access this through getRootStore().formDefinitionStore.editFormDefinitionStore.
 */
export class EditFormDefinitionStore {
  dragStore: EditFormDragStore;

  isOpen = false;
  previewMode = false;
  formNameError = false;

  currentFormName: ('protocol_form' | 'readout_form' | 'run_form') = 'protocol_form';

  valueBeforeEdits: FormDefinition | null = null;
  value: FormDefinitionForEditing | null = null;

  currentEditItem: FormLayoutForEditing | null = null;
  currentEditItemBeforeEdits: FormLayoutForEditing | null = null;

  templates: Array<OntologyTemplate> = [];
  selectedTemplate: OntologyTemplate | null = null;

  bumpState = 0;

  readonly fieldDefinitionsMap: FieldDefinitionsMap = {
    protocol_form: [],
    readout_form: [],
    run_form: [],
  };

  readonly labelSidebarItem: FormLayoutForEditing = {
    layoutType: FormLayoutType.Cell,
    label: 'Text',
    span: 1,
    layoutID: EditFormUtils.dragLabelFromSidebarId,
  };

  formContentWidth = 0;

  constructor(public readonly root: RootStore) {
    this.dragStore = new EditFormDragStore(root);
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  isRequired(layout: FormLayout) {
    const field = this.getFieldDefinition(layout);
    return field?.required_group_number ?? layout.isRequired ?? false;
  }

  setup(data: {
    vault_id: number;
    ontology_templates: Array<OntologyTemplate>;
    protocol_field_definitions: Array<FieldDefinition>;
  }) {
    const { vault_id, protocol_field_definitions, ontology_templates } = data;
    this.fieldDefinitionsMap.protocol_form = protocol_field_definitions;
    this.templates = ontology_templates.sort((a, b) => a.root.name.localeCompare(b.root.name));

    this.selectedTemplate = null;

    if (vault_id) {
      const url = `/vaults/${vault_id}/vault_run_field_definitions.json`;

      axios.get<VaultRunFieldDefinitions[]>(url).then(result => {
        if (result && result.data) {
          this.fieldDefinitionsMap.run_form = result.data.filter(item => item.id === vault_id)[0]?.run_field_definitions ?? [];
        }
      });
    }
  }

  /**
   * Get the currently active form within the form definition.
   */
  get currentForm() {
    return this.value?.[this.currentFormName] ?? null;
  }

  selectCurrentForm(form: ('protocol_form' | 'readout_form' | 'run_form')) {
    this.currentFormName = form;
  }

  setPreviewMode(value: boolean) {
    this.previewMode = value;
  }

  setFormNameError(value: boolean) {
    this.formNameError = value;
  }

  triggerReload() {
    this.bumpState++;
  }

  get availableForms() {
    return [
      {
        name: `${term('protocol', true)} Definition`,
        id: 'protocol_form',
      },
      {
        name: `${term('run', true)} Definition`,
        id: 'run_form',
      },
    ];
  }

  /**
   * Get the field definitions for the currently active form (protocol fields, run fields, etc)
   */
  get currentFieldDefinitions() {
    return this.fieldDefinitionsMap[this.currentFormName];
  }

  /**
   * Set the width of the form, used to calculate the width of the form preview.
   * @param value
   */
  setFormContentWidth(value: number) {
    this.formContentWidth = value;
  }

  /**
   * When previewing a field with a label in the editor, we need to restrict the width of the label
   * to a calculated value based on the width of the form content and the column span.
   */
  get fieldDisplayWidth() {
    // determine the maximum number of items in all rows
    const maxItems = Math.max(...this.currentFormRows.map(row => row.contents.length));
    // divide the form content width by the max number of items
    return (this.formContentWidth - (1 + maxItems) * 20) / 6;
  }

  get formPreviewProps(): React.ComponentProps<typeof ProtocolAnnotator> {
    if (this.value) {
      const preview = EditFormUtils.processFormForSerialization(this.value);
      EditFormUtils.updateSchemaPrefixes(preview, this.assignmentLookup);

      return {
        cancelURL: '',
        formURL: '',
        form: preview[this.currentFormName],
        isEditable: true,
        isLocked: false,
        navigateOnCreation: false,
        protocolFields: this.currentFieldDefinitions.map(item => ({
          label: item.name,
          definition: item,
          value: {
            id: item.id as number,
            type: '',
            value: '',
            protocol_field_definition_id: item.id as number,
            protocol_id: 0,
          },
        })),
        schemaPrefix: preview[this.currentFormName].schemaPrefix,
        ontologyAnnotations: [],
        protocolId: 0,
        resourceType: 'protocol',
        runGrouping: null,
        templateList: this.templates,
        sharedProjectIDs: [],
        isEditing: true,
        isNew: false,
        projectOptions: [],
      };
    }
  }

  get assignmentLookup() {
    return EditFormUtils.buildFieldLookupMapFromTemplates(this.templates);
  }

  get formItemLookup() {
    const result = {} as {[key:string] : FormLayoutForEditing};
    if (this.value) {
      EditFormUtils.iterateThroughFormFields(this.value, item => {
        result[item.layoutID] = item;
      });
    }
    return result;
  }

  formItemFromId(id: string) {
    return this.formItemLookup[id] ?? null;
  }

  updateSchemaPrefixes() {
    EditFormUtils.updateSchemaPrefixes(this.value, this.assignmentLookup);
  }

  sidebarItemFromId(id: string) {
    return this.sidebarItems.find(item => id === item.layoutID);
  }

  assignmentFromLayout(layout: FormLayout) {
    const { currentTemplate } = this;
    if (layout.layoutType === FormLayoutType.Cell && layout.ontologyAssn && currentTemplate) {
      const assignmentKey = EditFormUtils.getTemplateAssignmentKey(layout);
      if (assignmentKey) {
        return this.assignmentLookup[assignmentKey]?.assignment ?? null;
      }
    }
    return null;
  }

  // get the rows for the current section
  get currentFormRows(): Array<FormLayoutForEditing> {
    return this.currentForm?.sections[0]?.contents?.[0]?.contents ?? [];
  }

  get inUseFormIds() {
    const result = new Set<string>(
      this.currentFormRows.flatMap(row => row.contents).map(item => item.layoutID),
    );
    return result;
  }

  setSelectedTemplate(template: OntologyTemplate | null) {
    this.selectedTemplate = template;
  }

  // set the rows for the current section
  setCurrentFormRows(rows: Array<FormLayout>) {
    const formContents = this.currentForm?.sections[0]?.contents?.[0];
    if (formContents) {
      formContents.contents = rows;
    }
  }

  // get the rows for the current section, with spacers added to make them all the same length
  get displayedRows(): Array<FormLayoutForEditing> {
    const { isDragging } = this.dragStore.dragState;

    const result = this.currentFormRows.filter(row => row.contents?.length > 0).map(row => {
      const result = {
        ...row,
      };
      return result;
    });

    if (isDragging) {
      return this.dragStore.adjustDisplayedRowsForDrag(result);
    }
    return result;
  }

  get sidebarFields(): Array<FormLayoutForEditing> {
    return this.currentFieldDefinitions.map(field => ({
      layoutType: FormLayoutType.Cell,
      layoutID: '' + field.id,
      label: field.name,
      fieldID: field.id,
    }));
  }

  get currentTemplateSchemaPrefix() {
    return this.currentForm?.schemaPrefix;
  }

  get currentTemplate() {
    if (this.selectedTemplate) {
      return this.selectedTemplate;
    }
    const prefix = this.currentTemplateSchemaPrefix;
    if (prefix) {
      return this.templates.find(template => template.schemaPrefix === prefix);
    }
    return null;
  }

  get availableTemplates() {
    const { currentTemplateSchemaPrefix } = this;
    if (currentTemplateSchemaPrefix) {
      return this.templates.filter(template => template.schemaPrefix === currentTemplateSchemaPrefix);
    }
    return this.templates;
  }

  get templateLookupMap() {
    const result: {[key:string]: TemplateAssignment } = {};
    this.templates.forEach(template => {
      const addAssignments = (group: TemplateGroup, groups:Array<string> = []) => {
        group.assignments.forEach(assignment => {
          result[keyPropGroup(assignment.propURI, groups)] = assignment;
        });
        (group.subGroups ?? []).forEach(group => addAssignments(group, [group.groupURI, ...groups]));
      };
      template.root && addAssignments(template.root);
    });
    return result;
  }

  get sidebarTemplateFieldsInGroups(): Array<FormLayoutForEditing> {
    const result: Array<FormLayoutForEditing> = [];

    const addAssignments = (group: TemplateGroup, contents: Array<FormLayoutForEditing>, groups:Array<string> = []) => {
      group.assignments.forEach(assignment => {
        contents.push({
          layoutType: FormLayoutType.Cell,
          layoutID: keyPropGroup(assignment.propURI, groups),
          label: assignment.name,
          ontologyAssn: [assignment.propURI, ...groups],
        });
      });
      (group.subGroups ?? []).forEach(group => {
        const groupLayout = {
          layoutType: FormLayoutType.Cell,
          layoutID: EditFormUtils.getUniqueLayoutId(),
          label: group.name,
          contents: [],
        };
        contents.push(groupLayout);

        addAssignments(group, groupLayout.contents, [group.groupURI, ...groups]);
      });
    };

    this.currentTemplate?.root && addAssignments(this.currentTemplate.root, result);
    return result;
  }

  get sidebarTemplateFields() {
    const result: Array<FormLayoutForEditing> = [];
    const addAssignments = (group: TemplateGroup, groups:Array<string> = []) => {
      group.assignments.forEach(assignment => {
        result.push({
          layoutType: FormLayoutType.Cell,
          layoutID: keyPropGroup(assignment.propURI, groups),
          label: assignment.name,
          ontologyAssn: [assignment.propURI, ...groups],
        });
      });
      (group.subGroups ?? []).forEach(group => addAssignments(group, [group.groupURI, ...groups]));
    };

    this.currentTemplate?.root && addAssignments(this.currentTemplate.root);
    return result;
  }

  get sidebarDefinitionFields(): Array<FormLayoutForEditing> {
    return this.currentFieldDefinitions.map(field => ({
      layoutType: FormLayoutType.Cell,
      layoutID: '' + field.id,
      label: field.name,
      fieldID: field.id as FieldIdType,
    }));
  }

  get sidebarItems() {
    return [
      this.labelSidebarItem,
      ...this.sidebarFields,
      ...this.sidebarTemplateFields,
    ];
  }

  get currentFieldDefinitionsRequiredGroups() {
    const groupMap: { [id: number]: Array<FieldDefinition> } = {};
    this.currentFieldDefinitions.forEach(field => {
      const group = field.required_group_number;
      if (group) {
        if (!groupMap[group]) {
          groupMap[group] = [];
        }
        groupMap[group].push(field);
      }
    });

    // return a map of protocol field ids to either null if the field is not in a group, "is required" if the
    // field is in a group of one, or the concatenated names of the other fields in the group each prefixed with "or".
    const result: { [id: number]: { requiredLabel: string | null, canDelete: boolean } } = {};

    const formElementIds = new Set<FieldIdType>(this.currentFormRows.flatMap(row => row.contents).map(item => item.fieldID));
    this.currentFieldDefinitions.forEach(field => {
      if (!field.required_group_number) {
        result[field.id] = { requiredLabel: null, canDelete: true };
      } else {
        const groupFields = groupMap[field.required_group_number];
        if (groupFields.length === 1) {
          result[field.id] = { requiredLabel: 'This field is required', canDelete: false };
        } else {
          const otherFieldsInGroup = groupFields.filter(groupField => field.id !== groupField.id);
          const canDelete = otherFieldsInGroup.some(otherField => formElementIds.has(otherField.id));
          const requiredLabel = 'This field ' + otherFieldsInGroup.map(groupField => `or ${groupField.name}`).join(' ') + ' is required';
          result[field.id] = { requiredLabel, canDelete };
        }
      }
    });
    return result;
  }

  getFieldDefinition(val: number | FormLayout): FieldDefinition | undefined {
    const id = typeof val === 'number' ? val : val?.fieldID;
    return id ? this.currentFieldDefinitions.find(def => def.id === id) : undefined;
  }

  setItemLabel(id: string, newLabel: string) {
    const { currentFormRows: currentSectionRows } = this;
    const row = currentSectionRows.find(row => row.contents?.some(item => item.layoutID === id));
    if (row) {
      const item = row.contents?.find(item => item.layoutID === id);
      if (item) {
        item.label = newLabel;
      }
    }
  }

  deleteField(id: string) {
    const { currentFormRows } = this;
    const row = currentFormRows.find(row => row.contents?.some(item => item.layoutID === id));
    if (row) {
      row.contents = row.contents?.filter(item => item.layoutID !== id);
      this.setCurrentFormRows(currentFormRows.filter(row => row.contents?.length > 0));
    }
    this.updateSchemaPrefixes();
  }

  // Alex sez not to store the ids, so we'll strip them out
  get valueForSerialization() {
    const result = EditFormUtils.processFormForSerialization(this.value);
    result.data_set_id = this.root.formDefinitionsStore.vaultId;
    return result;
  }

  updateFormName(name: string) {
    this.value.name = name;
  }

  get isDirty() {
    return !isEqual(this.valueBeforeEdits, this.value);
  }

  get isValid() {
    return true;
  }

  replaceDefaultValue(defaultValue: string) {
    this.currentEditItem.defaultValue = defaultValue;
  }

  replaceAllowedValues(allowedValues: string[]) {
    this.currentEditItem.allowedValues = allowedValues;
  }

  handleEditForm(form: FormDefinition) {
    this.valueBeforeEdits = deepClone(form);
    this.value = EditFormUtils.processFormForEditing(form, this.fieldDefinitionsMap, this.assignmentLookup);
    this.isOpen = true;
  }

  handleCreateForm() {
    this.valueBeforeEdits = null;
    const form = { ...blankFormTemplate };
    this.value = EditFormUtils.processFormForEditing(form, this.fieldDefinitionsMap, this.assignmentLookup);
    this.isOpen = true;
  }

  async handleDelete() {
    const { formDefinitionsStore, formDefinitionsStore: { formDefinitions } } = this.root;
    if (this.valueBeforeEdits?.id) {
      if (await ModalUtils.confirmDelete(`protocol form "${this.valueBeforeEdits.name}"`)) {
        await accountsService.deleteFormDefinition(this.valueBeforeEdits.data_set_id, this.valueBeforeEdits);
        formDefinitionsStore.setFormDefinitions(
          formDefinitions.filter(form => form.id !== this.valueBeforeEdits.id),
        );
        this.handleCancelEdit();
      }
    }
  }

  handleCancelEdit() {
    this.value = this.valueBeforeEdits = null;
    this.isOpen = false;
    this.currentFormName = 'protocol_form';
  }

  async handleSubmit() {
    const { valueForSerialization } = this;
    if (this.valueBeforeEdits) {
      await accountsService.updateFormDefinition(valueForSerialization.data_set_id, valueForSerialization);
    } else {
      await accountsService.createFormDefinition(valueForSerialization.data_set_id, valueForSerialization);
    }
    this.root.formDefinitionsStore.loadFormDefinitions();
    this.handleCancelEdit();
  }

  // field editing
  handleEditField(item: FormLayout) {
    this.currentEditItem = item;
    this.currentEditItemBeforeEdits = deepClone(item);
  }

  handleCancelEditField() {
    // revert
    Object.assign(this.currentEditItem, this.currentEditItemBeforeEdits);
    this.currentEditItem = this.currentEditItemBeforeEdits = null;
  }

  handleSubmitEditField() {
    this.setCurrentFormRows([...this.currentFormRows]);
    this.currentEditItem = this.currentEditItemBeforeEdits = null;
  }
}
