import React from 'react'
import flatMap from 'lodash/flatMap'

import { Input } from '@/shared/components/sanitizedTags.js'

// Used like:
// <RailsHiddenFields
// name="search[molecule_criteria_attributes]"
// value={MoleculeCriterion.toAttributes(fields, molecule_criteria)}
// />

const collectValues = ({ value, name }) => {
  if (!value) { return null }

  switch (typeof value) {
    case 'object':
      return flatMap(value, (v, key) => {
        return collectValues({
          value: v,
          name: `${name}[${key}]`,
        })
      }).filter(v => v !== null)
    default:
      return { name, value }
  }
}

const renderInput = (props, key = 0) => (
  <Input data-testid='rails-hidden-fields' type="hidden" key={key} {...props} />
)

export default function RailsHiddenFields(props) {
  let { value, name } = props
  value = value && value.toJS ? value.toJS() : value

  const values = collectValues({ value, name })

  if (values === null) {
    return null
  } else if (Array.isArray(values)) {
    return (
      <React.Fragment>
        {values.map(renderInput)}
      </React.Fragment>
    )
  } else {
    return renderInput(values)
  }
}
