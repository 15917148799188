import {
  ColumnDef,
} from '@/components';
import EditFieldDefinitionView from '@/FieldDefinitions/shared/EditFieldDefinitionView';
import { EditFieldDefinitionViewProps, FieldDefinitionUtils } from '@/FieldDefinitions/shared/fieldDefinitionUtils';
import { term } from '@/shared/utils/stringUtils';
import React from 'react';
import { FieldDefinition } from '../types';
import { deepClone } from '@/Annotator/data/utils';

type Props = EditFieldDefinitionViewProps & {
  allows_multiple_molecules_per_structure: boolean;
};

type State = Props;

export class MoleculeFieldDefinitions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = FieldDefinitionUtils.stripEntitiesFromProps(deepClone(props), 'molecule') as Props;
  }

  get columns(): Array<ColumnDef> {
    const columns: Array<ColumnDef & { id: keyof FieldDefinition; }> = [
      {
        label: 'Name',
        id: 'name',
        width: 140,
      },
      {
        label: 'Data Type',
        id: 'data_type_name',
        type: 'pickList',
        width: 120,
      },
      {
        label: 'Must be Unique',
        id: 'unique_value',
        type: 'checkmark',
        width: 50,
        style: { textAlign: 'center' },
      },
      {
        label: 'Overwritable',
        id: 'overwritable',
        type: 'checkmark',
        width: 50,
        style: { textAlign: 'center' },
      },
      {
        label: 'This Field',
        id: 'required_group_number',
        name: 'requirement',
        className: 'requirement',
        width: 230,
      },
    ];
    return columns as Array<ColumnDef>;
  }

  get fixedRows(): Array<FieldDefinition> {
    return [
      {
        id: 'molecule-name',
        name: `${term('molecule', true)} Name`,
        unique_value: true,
      },
      {
        id: 'molecule-synonyms',
        name: term('synonyms', true),
        unique_value: true,
      },
      {
        id: 'molecule-structure',
        name: 'Structure/Sequence',
        unique_value: !this.props.allows_multiple_molecules_per_structure,
      },
    ];
  }

  render() {
    return <EditFieldDefinitionView
      {...this.state}
      columns={this.columns}
      fixedRows={this.fixedRows}
      dataType={'molecule'}
    />;
  }
}
