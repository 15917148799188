/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import { accountsService } from '@/Accounts/accountsServices';
import {
  EditTeamSchema, ProjectSchema,
  TeamSchema,
  UserSchema, UserWithPermissionsSchema,
  VaultWithProjectsSchema,
  WithLabel,
} from '@/Accounts/types';
import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { diff } from 'deep-object-diff';
import { keysAsNumbers } from '@/shared/utils/objectKeys';
import { AccountsUtils } from '@/Accounts/accountsUtils';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { RoleName } from '../../types';
import flatten from 'lodash/flatten';

export class EditTeamsStore {
  inited = false;
  disposers: Array<() => void> = [];
  isOpen = false;
  value: EditTeamSchema = null; // the value currently being edited
  valueBeforeEdits: EditTeamSchema = null; // a copy of the value as loaded
  cacheVaultUserRoleNames: { [vaultId: number]: { [userId: number]: string } } = {};

  formState: {
    data: {
      step: number;
    };
    errors: { [key: string]: string; } | null;
  } = {
      data: { step: 0 },
      errors: null,
    };

  constructor(public readonly root: RootStore) {
    this.root = root;
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get usersMap() {
    return this.root.accountsStore.usersMap;
  }

  get vaultsMap() {
    return this.root.accountsStore.vaultsMap;
  }

  get prohibitedNames() {
    // get the list of existing team names
    const { teams } = this.root.accountsStore.teamsStore;
    return teams
      .map((team) => team.name)
      .filter((name) => name !== this.valueBeforeEdits?.name); // if editing an existing team, remove the current name from the prohibited list
  }

  // get the current value's list of vaults and projects, filtering out any projects the value isn't in and any vaults without
  // relevant projects
  get currentVaultsAndProjects(): Array<VaultWithProjectsSchema> {
    const projectSet = new Set<number>(this.value?.project_ids ?? []);
    const vaults = Object.values(
      this.root.accountsStore.vaultsAndProjectsStore.vaultsMap ?? {},
    );
    return vaults
      .map((vault) => ({
        vault,
        projects: vault.projects.filter((project) =>
          projectSet.has(project.id),
        ),
      }))
      .filter((val) => val.projects.length > 0)
      .sort((a, b) => (a.vault.name + '').localeCompare(b.vault.name))
      .map(item => ({
        ...item.vault,
        projects: item.projects,
      }));
  }

  get availableUsersToAdd(): Array<WithLabel<UserSchema>> {
    const { usersMap = {} } = this.root.accountsStore.usersStore;
    return Object.values(usersMap)
      .filter((user) => !this.value?.user_membership[user.id])
      .map((user) => ({
        ...user,
        label: `${user.first_name} ${user.last_name} (${user.email})`,
      }))
      .sort(AccountsUtils.compareNames);
  }

  /**
   * all projects in all vaults (with label)
   */
  get projects(): Array<WithLabel<ProjectSchema>> {
    const { vaultsMap } = this.root.accountsStore.vaultsAndProjectsStore ?? {};
    const vaults = Object.values(vaultsMap);
    return flatten(
      vaults.map(vault => vault.projects),
    ).map(project => ({
      label: `${vaultsMap[project.vault_id].name}: ${project.name}`,
      ...project,
    }));
  }

  /**
   * only projects currently in team
   */
  get projectsInTeam() {
    const projectSet = new Set<number>(this.value?.project_ids ?? []);
    return this.projects.filter(project => projectSet.has(project.id));
  }

  /**
   * projects that can be added
   */
  get availableProjectsToAdd() {
    const projectSet = new Set<number>(this.value?.project_ids ?? []);
    return this.projects.filter(project => !projectSet.has(project.id));
  }

  get vaultsWithUsersLackingAccess() {
    // users that need to be added to vaults
    const { add_users_to_vaults } = this.value;
    const { usersMap, vaultsMap } = this;
    const allVaultIds = new Set<number>();

    const vaultIdToUsers = {};

    Object.values(add_users_to_vaults).forEach(vaultMembership => {
      keysAsNumbers(vaultMembership).forEach(vaultId => {
        allVaultIds.add(vaultId);
      });
    });

    const vaults = [...allVaultIds]
      .map(vaultId => vaultsMap[vaultId])
      .sort((v1, v2) => v1.name.localeCompare(v2.name));

    return vaults.map(vault => ({
      vault,
      users:
        keysAsNumbers(add_users_to_vaults)
          .filter(userId => add_users_to_vaults[userId][vault.id] !== undefined)
          .map(userId => usersMap[userId])
          .sort(AccountsUtils.compareNames),
    }));
  }

  init() {
    if (!this.inited) {
      this.inited = true;
    }
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    --this.root.accountsStore.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.root.accountsStore.loading;
    this.root.decrementLoading();
  }

  async handleEditTeam(value: TeamSchema) {
    const { teamsMap, projectsMap, vaultsMap, usersMap } = this.root.accountsStore;
    this.cacheVaultUserRoleNames = {};
    try {
      this.incrementLoading();
      const response = await accountsService.getTeamDetails(value?.id);
      runInAction(() => {
        this.formState = {
          data: { step: 1 }, // skip the details page when editing new
          errors: null,
        };
        this.value = AccountsUtils.convertToEditTeam(response.data, usersMap, projectsMap);
        this.valueBeforeEdits = JSON.parse(JSON.stringify(this.value)); // clone the original value

        this.isOpen = true;
      });
    } finally {
      this.decrementLoading();
    }
  }

  handleNewTeam() {
    this.cacheVaultUserRoleNames = {};
    this.value = {
      id: null,
      name: '',
      user_membership: {},
      project_ids: [],
      add_users_to_vaults: {},
    };
    this.formState = {
      data: { step: 0 },
      errors: null,
    };
    this.valueBeforeEdits = null;
    this.isOpen = true;
  }

  handleCancelEdit() {
    this.value = null;
    this.valueBeforeEdits = null;
    this.isOpen = false;
  }

  async handleSubmit() {
    const {
      value,
      valueBeforeEdits,
      root: {
        accountsStore: { usersMap, vaultsMap, projectsMap },
      },
    } = this;
    const { formatUserName, convertPermissionsToString } = AccountsUtils;
    const updates = diff(
      valueBeforeEdits ?? {},
      value,
    ) as Partial<EditTeamSchema>;

    const reloadOptions = {
      reloadUsers: false,
      reloadProjects: false,
      reloadTeams: true,
      reloadCustomNucleotides: false,
      reloadCustomAminoAcids: false,
    };

    let teamId = value.id;
    const changes: Array<{ label: string; action: () => Promise<any>; }> = [];

    if (!value.id) {
      const response = await accountsService.createTeam(value);
      teamId = response.data.id;
    } else {
      if (updates.name !== undefined) {
        changes.push({
          label: `Change name from "${valueBeforeEdits?.name}" to "${value.name}".`,
          action: () => {
            return accountsService.updateTeam(value);
          },
        });
      }
    }

    // add users to vaults
    if (updates.add_users_to_vaults) {
      const updatedUsersInVaults = this.value.add_users_to_vaults;
      const oldUsersInVaults = this.valueBeforeEdits?.add_users_to_vaults;

      keysAsNumbers(updatedUsersInVaults).forEach((userId) => {
        const newUsersInVaults = updatedUsersInVaults[userId];
        const user = usersMap[userId];
        if (!user) {
          // this is a deviant case in which we have a user id but no user record, just ignore this
          // because there's nothing we can do.
          return;
        }
        keysAsNumbers(newUsersInVaults).forEach((vaultId) => {
          const vault = vaultsMap[vaultId];
          if (!oldUsersInVaults?.[userId]?.[vaultId]) {
            reloadOptions.reloadUsers = true;
            const role_name = newUsersInVaults[vaultId] as RoleName;
            changes.push({
              label: `Add ${AccountsUtils.formatUserName(user)} to vault "${vault.name
                }" with role "${role_name}".`,
              action: () => {
                return accountsService.addUserToVault(vaultId, {
                  ...user,
                  role_name,
                });
              },
            });
          }
        });
      });
    }

    // update user membership in team
    if (updates.user_membership) {
      reloadOptions.reloadUsers = true;
      reloadOptions.reloadProjects = true;
      keysAsNumbers(updates.user_membership).forEach((userId) => {
        const userName = formatUserName(
          this.root.accountsStore.usersMap[userId],
        );

        if (updates.user_membership[userId] === undefined) {
          changes.push({
            label: `Remove ${userName} from team.`,
            action: () => {
              return accountsService.removeUserFromTeam(value, userId);
            },
          });
        } else {
          const membership = value.user_membership[userId];
          const permissions = convertPermissionsToString(membership);
          if (!valueBeforeEdits?.user_membership[userId]) {
            changes.push({
              label: `Add ${userName} with ${permissions} permissions.`,
              action: () => {
                return accountsService.addUserToTeam(teamId, membership);
              },
            });
          } else {
            const old = convertPermissionsToString(
              valueBeforeEdits[userId] ?? {},
            );
            changes.push({
              label: `Change permissions on ${userName} from ${old} to ${permissions} permissions.`,
              action: () => {
                return accountsService.changeUserMembership(teamId, membership);
              },
            });
          }
        }
      });
    }

    // update projects in team
    if (updates.project_ids) {
      value.project_ids.forEach((projectId) => {
        reloadOptions.reloadProjects = true;
        if (!valueBeforeEdits?.project_ids?.includes(projectId)) {
          changes.push({
            label: `Add project "${projectsMap[projectId].name}" to team.`,
            action: () => {
              return accountsService.addProjectToTeam(projectId, teamId);
            },
          });
        }
      });
      valueBeforeEdits?.project_ids?.forEach((oldId) => {
        if (!value.project_ids?.includes(oldId)) {
          changes.push({
            label: `Remove project "${projectsMap[oldId].name}" from team.`,
            action: () => {
              return accountsService.removeProjectFromTeam(oldId, teamId);
            },
          });
        }
      });
    }

    if (changes.length || (teamId && !this.value.id)) {
      const { cancelled, failures } = await AccountsUtils.performChanges(changes, !!value.id);
      if (cancelled || failures) {
        return;
      }
    }

    this.root.accountsStore.reloadData(reloadOptions);
    this.handleCancelEdit();
  }

  async handleDelete() {
    if (await ModalUtils.confirmDelete('this team')) {
      await accountsService.deleteTeam(this.value);

      // If it's not expensive, we can keep loading the teams from the API
      // if not we could do it locally.
      this.root.accountsStore.reloadData();
      this.handleCancelEdit();
    }
  }

  handleAddUser(user: UserSchema) {
    this.value.user_membership[user.id] = {
      user_id: user.id,
      can_edit_data: true,
      can_manage_project: false,
    } as any;
    this.refreshRequiredUserVaultRoles();
  }

  handleRemoveUser(user: UserSchema) {
    delete this.value.user_membership[user.id];
    this.refreshRequiredUserVaultRoles();
  }

  handleAddProject(project: ProjectSchema) {
    this.value.project_ids.push(project.id);
    this.refreshRequiredUserVaultRoles();
  }

  handleRemoveProject(project: ProjectSchema) {
    this.value.project_ids = this.value.project_ids.filter(id => id !== project.id);
    this.refreshRequiredUserVaultRoles();
  }

  // load a vault's user roles into the cache, if not loaded, and return it
  async loadVaultUserRoles(vaultId: number) {
    if (!this.cacheVaultUserRoleNames[vaultId]) {
      const response = await accountsService.getVaultUsersWithPermissions(
        vaultId,
      );
      response.data.forEach((user) => {
        const perm = user.vault_permissions.find(
          (perm) => perm.vault_id === vaultId,
        );
        if (perm) {
          if (!this.cacheVaultUserRoleNames[vaultId]) {
            this.cacheVaultUserRoleNames[vaultId] = {};
          }
          this.cacheVaultUserRoleNames[vaultId][user.id] = perm.role_name;
        }
      });
    }
    return this.cacheVaultUserRoleNames[vaultId];
  }

  // Find all the users in all the selected teams that are not in this project's vault and add them to the field
  // add_users_to_vault, so that the UI will prompt for their vault roles
  async refreshRequiredUserVaultRoles() {
    const new_add_users_to_vaults = {};
    const add_users_to_vaults = this.value.add_users_to_vaults;

    const userIds = keysAsNumbers(this.value.user_membership);

    for (let i = 0; i < userIds.length; i++) {
      const userId = userIds[i];
      for (let j = 0; j < this.currentVaultsAndProjects.length; j++) {
        const vault = this.currentVaultsAndProjects[j];
        const userRoles = await this.loadVaultUserRoles(vault.id);
        if (!userRoles[userId]) {
          if (!new_add_users_to_vaults[userId]) {
            new_add_users_to_vaults[userId] = {};
          }
          new_add_users_to_vaults[userId][vault.id] =
            add_users_to_vaults?.[userId]?.[vault.id] ?? '';
        }
      }
    }
    runInAction(() => {
      this.value.add_users_to_vaults = new_add_users_to_vaults;
    });
  }
}
