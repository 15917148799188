import { ColumnsEditor } from '@/components';
import { Column } from '@/components/ColumnsEditor/ColumnsEditor';
import { ElnFieldDefinitions } from '@/FieldDefinitions';
import { FieldDefinition } from '@/FieldDefinitions/types';
import React from 'react';

interface ResultColumnsEditorProps {
  selectedValues: string[];
  elnFieldDefinitions: {
    byVault: Map<string, (FieldDefinition & { selectValue: string })[]>;
  };
  handleFieldClick: (args: { fieldDefinition: any; currentlySelected: boolean }) => void;
  handleReplaceColumns: (columns: string[]) => void;
}

interface ResultColumnsEditorState {
  open: boolean;
  anchorEl: null | HTMLElement;
}

export default class ResultColumnsEditor extends React.Component<ResultColumnsEditorProps, ResultColumnsEditorState> {
  state: ResultColumnsEditorState = {
    open: false,
    anchorEl: null,
  };

  getAvailableColumns = () => {
    const { elnFieldDefinitions } = this.props;
    const result: Array<Column> = Array.from(elnFieldDefinitions.byVault).map(([vaultName, fieldDefinitions]) =>
      ({
        id: vaultName,
        label: vaultName,
        children: fieldDefinitions
          .sort((a: FieldDefinition, b: FieldDefinition) => { return (a.display_order > b.display_order ? 1 : -1); })
          .map(fieldDefinition => ({
            id: fieldDefinition.selectValue,
            label: fieldDefinition.name,
          })),
      }),
    );
    return result;
  };

  handleChangeValue = (value: string[]) => {
    this.props.handleReplaceColumns(value);
  };

  render() {
    const selectedValues = [...this.props.selectedValues];
    return <ColumnsEditor
      availableColumns={this.getAvailableColumns()}
      value={selectedValues}
      onChangeValue={this.handleChangeValue}
    />;
  }
}
