import 'regenerator-runtime/runtime'
import axios from 'axios'
import { Mixture } from 'chemical-mixtures/Mixture'
import vaultResultIcon from 'ASSETS/images/mixture_vault_result.svg'

function searchByName(searchText) {
  return new Promise((resolve) => {
    const url = `/${CDD.ActiveDataContext.toContextParam}/link_suggestions`
    axios.get(url, {
      params: {
        text: searchText,
        molecule_details: true,
        batch_details: true,
        vault_details: true,
      },
    }).then((response) => {
      if (!response.data) {
        /* eslint-disable no-console */
        console.error(`Molecule search on ${url} failed with error ${response.status}/${response.statusText}`)
        resolve([])
        return
      }
      resolve(response.data.map(suggestion => {
        const details = suggestion.molecule_details || {}
        const batches = suggestion.batch_details || []
        const vault = suggestion.vault_details || {}
        return {
          id: suggestion.id,
          url: suggestion.url,
          vault_name: vault.name,
          ...details,
          batches,
        }
      }))
    })
  })
}

function convertResult(result) {
  const { url, name, synonyms, vault_name, molfile } = result
  let mixfile = null
  if (result.mixfile) {
    mixfile = JSON.parse(result.mixfile)
    if (mixfile) {
      mixfile.links = mixfile.links || {}
      mixfile.links.vaultMolecule = url
    }
  } else {
    mixfile = {
      mixfileVersion: 1.0,
      name: name,
      molfile: molfile,
      links: { vaultMolecule: url },
    }
  }

  const auxiliary = []
  let batches = []
  if (result.batches && result.batches.length > 0) {
    auxiliary.push({
      category: 'Batches',
      content: result.batches.map((batch) => { return { label: batch.name, identifier: { key: 'vaultBatch', val: batch.name } } }).filter((batch) => !!batch.label),
    })
    batches = result.batches.map((batch) => batch.name).filter((batch) => !!batch)
  }
  auxiliary.push({
    category: 'Vault',
    content: [{ label: vault_name }],
  })

  return {
    mixture: new Mixture(mixfile),
    svgIcon: vaultResultIcon,
    nameURL: url,
    molName: name, // name of the "molecule" in Vault (may or may not be present in the mixfile)
    synonyms: synonyms.filter((s) => s != name),
    auxiliary,
    batches,
  }
}

export function searchTheVault(searchText, responseFunc) {
  const ctx = CDD.ActiveDataContext.toContextParam
  const searchLC = searchText.toLowerCase()
  const rankScore = (result) => {
    const { url, vault_name, name, synonyms } = result
    let score = 0
    if (url.includes(ctx)) score += 1 // it's in the same Vault
    if (synonyms.some((syn) => syn.toLowerCase() == searchLC)) score += 2
    if (synonyms.some((syn) => syn == searchText)) score += 4
    if (name.toLowerCase() == searchLC) score += 8
    if (name == searchText) score += 16
    return score // higher is better
  }

  searchByName(searchText).then((resultList) => {
    resultList = resultList.filter((result) => result.name || result.molfile || result.nucleotide_sequence || result.amino_acid_sequence || result.mixfile)
    resultList = resultList.sort((r1, r2) => rankScore(r2) - rankScore(r1)) // decreasing order (highest scores first)
    responseFunc(resultList.map(result => convertResult(result)))
  })
}

export function searchTheVaultAsync(searchText) {
  return new Promise(resolve => {
    searchTheVault(searchText, (resultList) => resolve(resultList))
  })
}

export function convertNameToStructure(molName) {
  return new Promise((resolve) => {
    const url = `/${CDD.ActiveDataContext.toContextParam}/name_to_structure`
    axios.get(url, {
      params: {
        text: molName,
      },
    }).then((response) => {
      if (!response.data) {
        /* eslint-disable no-console */
        console.error(`Molecule search on ${url} failed with error ${response.status}/${response.statusText}`)
        resolve(null)
        return
      }
      resolve(response.data.structure)
    })
  })
}
