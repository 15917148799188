import { FieldDefinition } from '@/FieldDefinitions/types';
import { FieldDefinitionFormUtils } from '@/Samples/components/fieldDefinitionFormUtils';
import { EditInventoryStore } from '@/Samples/stores/editInventoryStore';
import {
  currentAmountForSample,
  setEventCreditDebit,
} from '@/Samples/stores/sampleDataStore';
import {
  Batch,
} from '@/Samples/types';
import { CDDModalForm } from '@/shared/components/CDDForm/CDDForm';
import { deleteButtonLayout } from '@/shared/components/CDDForm/cddElements';
import { layoutBuilder } from '@/shared/components/DDForm/layoutBuilder';
import { ElementType } from '@/shared/components/DDForm/types';
import { SelectMultipleDef } from '@/shared/components/DDForm/types/selectMultipleDef';
import { SelectOption, SelectSingleDef } from '@/shared/components/DDForm/types/selectSingleDef';
import { TypographyDef } from '@/shared/components/DDForm/types/typographyDef';
import MuiTheme from '@/shared/components/MuiTheme';
import { term } from '@/shared/utils/stringUtils';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

export const INVENTORY_SAMPLE_FIELDS = 'inventory_sample_fields';
export const INVENTORY_EVENT_FIELDS = 'inventory_event_fields';

const { row, column, select, numberInput, typography, radioGroup, checkbox } = layoutBuilder;

type Props = {
  store: EditInventoryStore;
  units: Array<string>;
  batches: Array<Pick<Batch, 'id' | 'name'>>;
  createSampleDisabled?: boolean;
  alwaysShowEditEventFields?: boolean;
  sampleFieldDefinitions?: Array<FieldDefinition>;
  eventFieldDefinitions?: Array<FieldDefinition>;
  onDeleteSample?: () => void;
  onDeleteEvent?: () => void;
  onSubmit?: () => void;
};

@observer
export default class EditInventoryDialog extends React.Component<Props> {
  get value() {
    return this.props.store.currentlyEditingValue;
  }

  get editType() {
    return this.value?.type;
  }

  get formState() {
    return this.props.store.formState;
  }

  get event() {
    return this.props.store.currentlyEditingValue?.event;
  }

  get sampleFieldDefinitions() {
    if (this.props.sampleFieldDefinitions) {
      return this.props.sampleFieldDefinitions;
    }
    return this.props.store.inventory_sample_field_definitions;
  }

  get eventFieldDefinitions() {
    if (this.props.eventFieldDefinitions) {
      return this.props.eventFieldDefinitions;
    }
    return this.props.store.inventory_event_field_definitions;
  }

  get isEdit() {
    return ['EDIT_EVENT', 'EDIT_SAMPLE', 'EDIT_SINGLE_USE_SAMPLE', 'EDIT_SAMPLE_WITH_FIRST_EVENT'].includes(this.editType);
  }

  get dialogTitle(): string {
    return this.props.store.currentEditText;
  }

  get excludedNames() {
    return ['Current Amount', 'Credit', 'Debit'];
  }

  get unitSelect(): SelectSingleDef | SelectMultipleDef | TypographyDef {
    const { units, store: { sample } } = this.props;
    const eventsCanBeEdited =
      sample?.inventory_events?.length > 0 && this.isEdit;

    const unitSelectOptions = units.map((unit) => ({
      label: unit,
      id: unit,
    }));

    const disabled = (!sample?.is_single_use && eventsCanBeEdited) || this.formState.data.createSampleFromDebit;

    return select({
      key: 'sample.units',
      label: 'Units',
      valueType: 'string',
      required: true,
      typeahead: true,
      passIdOnSelect: true,
      selectOptions: unitSelectOptions,
      disabled,
    });
  }

  get filteredSampleFieldDefinitions(): FieldDefinition[] {
    return this.sampleFieldDefinitions.filter(
      (fieldDefinition) =>
        !fieldDefinition.is_hidden && !this.excludedNames.includes(fieldDefinition.name),
    );
  }

  get filteredEventFieldDefinitions(): FieldDefinition[] {
    return this.eventFieldDefinitions.filter(
      (fieldDefinition) => !this.excludedNames.includes(fieldDefinition.name),
    );
  }

  get showEventFields() {
    return this.props.store.showEventFields || this.props.alwaysShowEditEventFields;
  }

  get layoutSampleColumn() {
    const {
      showEventFields,
      props: { batches, store: { sample, disableSampleIdField } },
      formState: { data: { initialValue } },
    } = this;

    const batchOptions: Array<SelectOption> = [
      { id: null, label: '' },
      ...batches.map((batch) => ({ id: batch.id, label: batch.name })),
    ];

    const rows: Array<ElementType> = [
      select({
        key: 'sample.batch_id',
        label: term('batch', true),
        selectOptions: batchOptions,
        valueType: 'number',
        required: true,
        disabled: sample?.id !== undefined,
      })];

    if (showEventFields) {
      rows.push(
        row([
          numberInput({
            label: sample.is_single_use ? 'Credit' : 'Initial Amount',
            key: '.initialValue',
            required: true,
            error: initialValue > 0 ? null : 'Amount must be greater than 0',
            disabled: this.formState.data.createSampleFromDebit,
          }),
          this.unitSelect,
        ]),
      );
    } else {
      rows.push(this.unitSelect);
    }

    return column([
      ...rows,
      ...FieldDefinitionFormUtils.layoutForFieldDefinitions(
        this.filteredSampleFieldDefinitions,
        this.value,
        'sample.inventory_sample_fields',
        disableSampleIdField,
        undefined,
        this.editType === 'NEW_SAMPLE' || this.formState.data.createSampleFromDebit,
      ),
    ]);
  }

  get layoutEventColumn() {
    const { sample, needsUpdatedLocation } = this.props.store;
    const eventRows: Array<ElementType> = [];
    const currentAmount = currentAmountForSample(sample);
    const { creditOrDebitValue, creditOrDebit } = this.formState.data;
    const debitExceedsCurrent = creditOrDebit === 'debit' && currentAmount - creditOrDebitValue < 0;

    if (needsUpdatedLocation) {
      eventRows.push(row([
        typography({
          className: 'warningMessage',
          label:
            "This sample's location is in use. Before it can be restored, you must select a new location.",
        }),
      ]));
    }

    if (!this.showSampleFields) {
      const unitString = sample?.units ? `(${sample.units})` : '';
      eventRows.push(
        row({ className: 'top-row' }, [
          numberInput({
            className: 'creditOrDebitValue',
            key: '.creditOrDebitValue',
            label: 'Amount',
            valueType: 'number',
            minValue: 0,
            error: (() => {
              if (!creditOrDebitValue && creditOrDebitValue !== 0) {
                return 'Required field';
              }
              if (debitExceedsCurrent) {
                return 'Debit exceeds current amount';
              }
            })(),
            width: 100,
          }),
          typography({ label: unitString, width: 50 }),
          radioGroup({
            key: '.creditOrDebit',
            horizontal: true,
            selectOptions: [
              { id: 'debit', label: 'Debit' },
              { id: 'credit', label: 'Credit' },
            ],
          }),
          typography({ label: `Current amount: ${currentAmount} ${unitString}` }),
        ]),
      );
    }

    eventRows.push(
      ...FieldDefinitionFormUtils.layoutForFieldDefinitions(
        this.filteredEventFieldDefinitions,
        this.value,
        'event.inventory_event_fields',
      ),
    );

    return column(eventRows);
  }

  get showSampleFields() {
    if (this.formState.data.createSampleFromDebit) {
      return true;
    }
    return this.props.store.showSampleFields;
  }

  get layout() {
    const columns: Array<ElementType> = [];
    const { showEventFields, showSampleFields } = this;

    if (showSampleFields) {
      columns.push(this.layoutSampleColumn);
    }

    if (showEventFields) {
      columns.push(this.layoutEventColumn);
    }

    return row({ spacing: 4 }, columns);
  }

  get isEditingSample() {
    return ['EDIT_SAMPLE', 'EDIT_SINGLE_USE_SAMPLE', 'EDIT_SAMPLE_WITH_FIRST_EVENT'].includes(this.editType);
  }

  handleSubmit = () => {
    const { store: { currentlyEditingValue: value } } = this.props;
    const handleSubmit = this.props.onSubmit ?? this.props.store.handleSubmit;
    const { creditOrDebit, creditOrDebitValue } = this.formState.data;

    switch (this.editType) {
      case 'NEW_SAMPLE':
      case 'EDIT_SINGLE_USE_SAMPLE':
      case 'EDIT_SAMPLE_WITH_FIRST_EVENT':
        setEventCreditDebit(value.event, this.formState.data.initialValue);
        break;

      case 'NEW_EVENT':
      case 'EDIT_EVENT':
        if (creditOrDebitValue) {
          setEventCreditDebit(value.event, creditOrDebit === 'credit' ? creditOrDebitValue : -creditOrDebitValue);
        }
        break;
    }

    handleSubmit();
  };

  handleCancelEdit = () => {
    this.props.store.handleCancelEdit();
  };

  handleDelete = () => {
    if (this.isEditingSample) {
      if (this.props.onDeleteSample) {
        this.props.onDeleteSample();
        return;
      }
    } else {
      if (this.props.onDeleteEvent) {
        this.props.onDeleteEvent();
        return;
      }
    }
    this.props.store.handleDelete();
  };

  get bottomLeftElements() {
    const result = [];

    if (this.formState.data.creditOrDebit === 'debit' && this.editType === 'NEW_EVENT') {
      let disabled = false;
      let tooltip: string | undefined;
      if (!this.formState.data.createSampleFromDebit) {
        if (!this.formState.data.creditOrDebitValue) {
          disabled = true;
          tooltip = 'Debit amount must be greater than 0';
        } else if (this.formState.data.creditOrDebitValue > currentAmountForSample(this.props.store.sample)) {
          disabled = true;
          tooltip = `Debit amount exceeds current ${term('sample')} amount`;
        }
      }
      result.push(
        checkbox({
          key: '.createSampleFromDebit',
          label: `Create ${term('sample')} from debit`,
          visible: (this.formState.data.creditOrDebit === 'debit') && !this.isEdit,
          disabled,
          tooltip,
        }),
      );
    }

    if (this.isEdit) {
      const { sample, event } = this.props.store.currentlyEditingValue;
      let disabled = false;
      let tooltip: string | undefined;

      if (this.editType === 'EDIT_EVENT' && event?.child_sample_name !== null) {
        disabled = true;
        tooltip = 'Cannot delete event with child sample';
      } else if (this.editType === 'EDIT_SAMPLE' && sample?.should_disable_delete) {
        disabled = true;
        tooltip = 'Cannot delete sample with child sample';
      }
      result.push(
        deleteButtonLayout({
          disabled,
          tooltip,
          onClickButton: this.handleDelete,
        }),
      );
    }
    return result;
  }

  get renderValueIfPresent() {
    const { sample, needsUpdatedLocation } = this.props.store;
    if (sample) {
      return (
        <>
          <DialogTitle className="muiDialog-title">{this.dialogTitle}</DialogTitle>
          <DialogContent>
            <CDDModalForm
              data={this.value ?? {}}
              onCancel={this.handleCancelEdit}
              onOK={this.handleSubmit}
              bottomLeftElements={this.bottomLeftElements}
              terminology={{ OK: 'Save' }}
              layout={this.layout}
              okDisabled={needsUpdatedLocation}
              formState={this.formState}
            />

          </DialogContent>
        </>
      );
    }
  }

  render() {
    const { sample } = this.props.store;
    if (!sample) {
      return <></>;
    }
    return (
      <MuiTheme>
        <Dialog
          open={!!sample || !!event}
          onClose={this.props.store.handleCancelEdit}
          className="EditInventoryDialog"
          maxWidth={false} // Disable default max width
          fullWidth={false} // Dialog will dynamically size to content
          PaperProps={{
            style: {
              minWidth: '300px', // Set a minimum width if needed
              maxWidth: 'none', // Ensure dialog is not restricted by width
            },
          }}
        >
          {this.renderValueIfPresent}
        </Dialog>
      </MuiTheme>
    );
  }
}
