/**
 * This will be the single point of integration between the frontend and the Accounts API
 */

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { CustomCalculationAutoComplete, CustomCalculationObject, DoseResponseCalculationObject, DoseResponseCalculationEdit, EditValueType, ReadoutDefinitionEdit, ReadoutDefinitionObject, CustomCalculationEdit } from './types';
import { getRootStore } from '@/stores/rootStore';
import { ReadoutDefinitionUtils } from './readoutDefinitionUtils';

class http {
  static withPrefix(url: string) {
    const { routerStore } = getRootStore();
    const { vaultId = null, protocolId = null } = routerStore.extractFromPattern(
      '/vaults/:vaultId/protocols/:protocolId',
    ) ?? {};
    if (!vaultId || !protocolId || protocolId === 'new') {
      return null;
    }

    if (url.startsWith('/')) {
      return url.replace('{vault_id}', '' + vaultId);
    }
    return `/vaults/${vaultId}/protocols/${protocolId}/${url}`;
  }

  static async get<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ) {
    url = this.withPrefix(url);
    if (!url) {
      return null;
    }
    return axios.get<T, R>(this.withPrefix(url), config ?? { 'headers': { 'Accept': 'application/json' } });
  }

  static put<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.put<T, R>(this.withPrefix(url), data, config);
  }

  static post<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.post<T, R>(this.withPrefix(url), data, config);
  }

  static patch<T = unknown, R = AxiosResponse<T>>(
    url: string,
    data: unknown,
    config?: AxiosRequestConfig,
  ) {
    return axios.patch<T, R>(this.withPrefix(url), data, config);
  }

  static delete<T = unknown, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig,
  ) {
    return axios.delete<T, R>(this.withPrefix(url), config);
  }
}

class ReadoutDefinitionService {
  get isOnProtocolPage() {
    return http.withPrefix('') !== null;
  }

  get vaultId() {
    return getRootStore().accountsStore.vaultId;
  }

  // readout definitions
  getReadoutDefinitions() {
    return http.get<Array<ReadoutDefinitionObject>>('readout_definitions?sortable_and_usable_as_dr_input_only=true');
  }

  getReadoutDefinitionForEditing(id: number) {
    return http.get<ReadoutDefinitionEdit>(`readout_definitions/${id}/edit`);
  }

  getNewTemplateForReadoutDefinition() {
    return http.get<ReadoutDefinitionEdit>('readout_definitions/new');
  }

  getNewTemplateForDoseResponse() {
    return http.get<DoseResponseCalculationEdit>('dose_response_calculations/new');
  }

  updateReadoutDefinition(editValue: EditValueType) {
    let url, method, value;
    const { vaultId = null, protocolId = null } = getRootStore().routerStore.extractFromPattern('/vaults/:vaultId/protocols/:protocolId') ?? {};
    if (!vaultId || !protocolId) {
      return null;
    }

    switch (ReadoutDefinitionUtils.getType(editValue)) {
      case 'dose-response':
        value = editValue.dose_response_calculation_object;

        [
          'maximum', 'minimum', 'slope',
          'slope1', 'center1', 'plateau1', 'peak', 'plateau2', 'center2', 'slope2',
          'y_intercept',
        ].forEach((type) => {
          if (!value.constraints[`${type}_fit_type`]) {
            delete value.constraints[`${type}_lower_value`];
            delete value.constraints[`${type}_upper_value`];
            delete value.constraints[`${type}_fit_type`];
          }
        });

        for (const v of editValue.dose_response_calculation_object.intercept_readout_definitions_attributes) {
          if (!v.intercept && v.name) {
            // if no intercept, use name with leading non-digits removed
            v.intercept = v.name.replace(/^[^\d]*/, '');
          }
        }

        value.protocol_id = protocolId;
        method = value.id ? 'PUT' : 'POST';
        url = `dose_response_calculations${value.id ? `/${value.id}` : ''}`;

        if (value.has_custom_minimum_activity && !value.minimum_inactivity_modifier) {
          value.has_custom_minimum_activity = false;
        }
        break;

      case 'calculated':
        value = editValue.custom_calculation_object;
        value.aggregate_readouts_by = value.aggregate_readouts_by ?? 'batch and run';
        if (value.id) {
          method = 'PUT';
          url = `/vaults/{vault_id}/custom_calculations/${value.id}`;
        } else {
          method = 'POST';
          url = 'custom_calculations';
        }
        break;

      default: // readout
        value = editValue.readout_definition_object;
        value.name = value.name.trim();
        if (value.id) {
          method = 'PUT';
          url = `/vaults/{vault_id}/readout_definitions/${value.id}`;
        } else {
          method = 'POST';
          url = 'readout_definitions';
        }
        (value.pick_list_values ?? []).forEach((v, i) => {
          v.display_order = i;
          if (v.id?.startsWith?.('FAKE-ID')) {
            delete v.id;
          }
        });
        break;
    }

    if (method === 'PUT') {
      return http.put(url, value);
    } else {
      return http.post(url, value);
    }
  }

  // dose response
  getDoseResponseCalculations() {
    return http.get<Array<DoseResponseCalculationObject>>('dose_response_calculations');
  }

  getDoseResponseForEditing(id: number) {
    return http.get<DoseResponseCalculationEdit>(`dose_response_calculations/${id}/edit`);
  }

  // custom calculations
  getCustomCalculation(id: number) {
    return http.get<CustomCalculationObject>(`custom_calculations/${id}`);
  }

  getCustomCalculationForEditing(id: number) {
    return http.get<CustomCalculationEdit>(`custom_calculations/${id}/edit`);
  }

  getCustomCalculations() {
    return http.get<Array<CustomCalculationObject>>('custom_calculations');
  }

  getCustomCalculationAutoComplete() {
    return http.get<CustomCalculationAutoComplete>('custom_calculations/new');
  }

  reorderReadoutDefinitions(readoutDefinitions: Array<number>) {
    return http.put<Array<ReadoutDefinitionObject>>('readout_definition_order',
      { sortable_readout_definitions: readoutDefinitions });
  }

  deleteReadoutDefinition(editValue: EditValueType) {
    switch (ReadoutDefinitionUtils.getType(editValue)) {
      case 'dose-response':
        return http.delete(`/vaults/{vault_id}/dose_response_calculations/${editValue.dose_response_calculation_object.id}`);

      case 'calculated':
        return http.delete(`custom_calculations/${editValue.custom_calculation_object.id}`);

      default: // readout
        return http.delete(`/vaults/{vault_id}/readout_definitions/${editValue.readout_definition_object.id}`);
    }
  }

  getHelpTopic(topic: string) {
    return http.get<string>(`/vaults/{vault_id}/help?topic_name=${topic}`, { 'headers': { 'Accept': 'text/html' } });
  }
}

export const readoutDefinitionService = new ReadoutDefinitionService();
