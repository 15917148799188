/* eslint-disable no-jquery/no-jquery-constructor, no-jquery/no-find-collection, no-jquery/no-other-methods, no-jquery/no-attr, no-jquery/no-html */
import { StringOrNumber } from '@/types';
import React, { ReactNode } from 'react';
import { createRoot, Root } from 'react-dom/client';

/**
 * Exports a factory function to create a store that:
 * - Renders and re-renders react components
 * - Provides a assignProperties method via properties passed to the rendered components, which can be used to override
 *    properties. When properties are changed via this method, the changes will persist even when DOM elements are
 *    overwritten via RJS
 *
 * The rendering is abstracted out so that we can have a version that is dependent on MUI and MobX and one that is not.
 */

const logging = false; // flag to enable debugging messages

// a function that renders a react component with the given data and contentHtml
export type RenderComponentFunction = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  componentClass: React.ComponentType<any>,
  data: object,
  contentHtml: string,
  id: StringOrNumber,
  logging: boolean,
  useMuiTheme: boolean,
) => ReactNode

export class EmbeddedComponentStore {
  private inited = false;
  data = {};

  constructor(private renderFunc: RenderComponentFunction) {
  }

  renderedComponents: Array<{
    id: string,
    element: HTMLElement,
    lastReceivedProperties: string,
    lastRenderedHtml: string,
    root: Root,
  }> = [];

  init() {
    if (!this.inited) {
      addEventListener('htmlPrefilter', this.handleHtmlPrefilter);
      addEventListener('load', () => {
        this.handleChanges({ initialLoad: true });
      });
      addEventListener('renderReactComponents', () => {
        this.handleChanges();
      });
    }
    this.inited = true;
  }

  handleHtmlPrefilter = () => {
    // take a snapshot of the HTML for all rendered components
    this.renderedComponents.forEach(item => {
      item.lastRenderedHtml = item.element.outerHTML;
    });
    // now handle changes to the DOM
    setTimeout(this.handleChanges);
  };

  handleChanges = ({ root = 'body', initialLoad = false, ignoreUnchangedElements = true } = {}) => {
    const body = document.getElementsByTagName('BODY')[0];
    const reactComponentsOnPage = $(root).find('.react_component').toArray();

    // handle any changes to the DOM, potentially rendering or unmounting components as necessary
    logging && console.log('handleChanges');

    // survey all the elements on the page
    const mapIdToElement = new Map<string, HTMLElement>();
    const elementsAndIds = new Map<string, HTMLElement>();

    reactComponentsOnPage.forEach((element: HTMLElement) => {
      const componentClass = element.getAttribute('component_class');
      const componentProperties = element.getAttribute('react_props') || '{}';
      const siblings = reactComponentsOnPage.filter(testElem =>
        (testElem === element || testElem.getAttribute('component_class') === componentClass));

      let id = `${componentClass}_${siblings.indexOf(element)}`;
      id += `_${componentProperties}`;
      elementsAndIds.set(id, element);
    });

    const skipRenderIds = new Set<string>();

    // iterate through previously rendered items
    this.renderedComponents.forEach(({ id, element, lastReceivedProperties, lastRenderedHtml, root }
      , index) => {
      if (!body.contains(element)) {
        // element had been previously rendered but was removed from the DOM
        try {
          root && root.unmount();
        } catch (e) {
          console.warn(e); // not a critical error
        }

        // if the previously rendered element exists in the DOM with the same id, then we'll update it
        if (mapIdToElement.has(id)) {
          // if we've received new properties from the backend, then update the store
          const componentProperties = element.getAttribute('react_props') || '{}';
          if (componentProperties !== lastReceivedProperties) {
            this.setData(id, componentProperties);
            logging && console.log(`react_component ${id} received new properties, updating`);
          } else {
            logging && console.log(`react_component ${id} didn't receive new properties but was overwritten, so refresh`);
          }
        }
        this.renderedComponents.splice(index, 1);
      } else if (lastRenderedHtml === element.outerHTML && ignoreUnchangedElements) {
        // This is an optimization to prevent re-rendering of unchanged react components, but it breaks some things
        // and may not be necessary. So for now, just render all components.

        logging && console.log(`react_component ${id} exists and wasn't updated, so don't refresh`);
        skipRenderIds.add(id);
      }
    });

    let didRender = false;
    elementsAndIds.forEach((element, id) => {
      if (!this.data[id]) {
        this.setData(id, element.getAttribute('react_props') || '{}');
      }
      if (!skipRenderIds.has(id)) {
        this.renderReactComponent(element, id);
        didRender = true;
      }
    });

    if (didRender && !initialLoad) {
      window.dispatchEvent(new CustomEvent('rjsPostProcessing'));
    }
  };

  setData(id: string, inData: string | object) {
    if (typeof inData === 'string') {
      try {
        JSON.parse(inData);
      } catch (e) {
        inData = {};
      }
    }

    const inDataObj = (typeof inData === 'string') ? JSON.parse(inData) : inData;
    this.data[id] = inDataObj;
  }

  private renderReactComponent(element: HTMLElement, id: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const CDD = (window as any).CDD as { Component: { Class: { [key: string]: React.ComponentType<any> } } };

    logging && console.log(`react_component ${id}: render`);

    const componentClass = element.getAttribute('component_class');
    const componentProperties = element.getAttribute('react_props') || '{}';
    const ReactComponentClass = CDD.Component.Class[componentClass];

    let root = this.renderedComponents.find(item => item.element === element)?.root;
    if (!root) {
      root = createRoot(element);
    }

    this.renderedComponents.push({
      id,
      element,
      lastReceivedProperties: componentProperties,
      lastRenderedHtml: '',
      root,
    });

    const $element = $(element);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const useMuiTheme = !(ReactComponentClass as any).noTheme;

    // this is tested in the Snapgene Selenium specs found in manually_register_or_update_molecules_spec.rb
    // prior to this, when a component needed to be rerendered, it would grab the DOM of the old version and insert it as a child
    if (!$element.attr('rendered-react-innerhtml')) {
      $element.attr('rendered-react-innerhtml', JSON.stringify({ __html: $element.html() }));
    }
    const contentHtmlHash = JSON.parse($element.attr('rendered-react-innerhtml'));

    root.render(
      this.renderFunc(ReactComponentClass, this.data[id], contentHtmlHash.__html, id, logging, useMuiTheme),
    );
  }
}

export type EmbeddedComponentStoreFactory = (renderFunc: RenderComponentFunction,
  initFunc?: (store: EmbeddedComponentStore) => void) => EmbeddedComponentStore;

export const createEmbeddedComponentStore: EmbeddedComponentStoreFactory = (renderFunc: RenderComponentFunction, initFunc?: (store: EmbeddedComponentStore) => void) => {
  const store = new EmbeddedComponentStore(renderFunc);
  initFunc?.(store);
  return store;
};
