import React from 'react'
import autobind from 'class-autobind'
import { TableBody, TableRow, TableCell } from '@mui/material'
import upperFirst from 'lodash/upperFirst'

import SearchContext from './SearchContext'
import handleLeftClickLikeLink from '@/shared/utils/handleLeftClickLikeLink.js'
import RenderSimpleHTML from '@/shared/components/RenderSimpleHTML.tsx'
import { A } from '@/shared/components/sanitizedTags.js'

const renderStatus = ({ status }) => {
  if (status == 'finalized') { status = 'final' }

  return upperFirst(status)
}

export default class Row extends React.Component {
  constructor(props) {
    super(props)
    autobind(this)

    this.url = `${this.props.elnEntryUrl}/${this.props.elnEntry.id}`
    this.onClick = (e) => {
      if (e.target.tagName !== 'A') {
        // handle pseudo-link navigation only if not clicking on a link
        handleLeftClickLikeLink(this.url)(e)
      }
    }
  }

  highlightFor(attribute) {
    const { result } = this.props
    const highlight = result.highlights.find(highlight => highlight.name === attribute)

    if (highlight) {
      return highlight.fragments.first()
    }
  }

  renderIdentifierCell = () => {
    const { elnEntry } = this.props
    const content = this.highlightFor('identifier') || elnEntry.identifier
    return (
      <TableCell name='ID' key='identifier' className='search_results__item search_results__item__identifier'>
        <A href={this.url}><RenderSimpleHTML text={content} /></A>
      </TableCell>
    )
  }

  renderTitleCell = () => {
    const { elnEntry } = this.props
    const highlightContent = this.highlightFor('title')
    const title = elnEntry.title
    if (highlightContent) {
      return (
        <TableCell name='Title' key='title' className='search_results__item search_results__item__title' dangerouslySetInnerHTML={{ __html: highlightContent }}>
        </TableCell>
      )
    } else {
      return (
        <TableCell name='Title' key='title' className='search_results__item search_results__item__title'>
          <RenderSimpleHTML text={title} />
        </TableCell>
      )
    }
  }

  renderCellFor = (selectValue, params) => {
    const { elnEntry } = this.props
    const { fieldsByDefId } = elnEntry
    const field = fieldsByDefId.get(parseInt(selectValue))
    const content = field ? (field.text_value || field.value) : ''
    return (<TableCell {...params}>{content}</TableCell>)
  }

  renderCells = () => {
    const { resultColumns, elnEntry } = this.props
    const { user_full_name, eln_witness_name, updated_at, created_at, submitted_date, finalized_date, status } = elnEntry

    return resultColumns.map((column) => {
      const selectValue = column.selectValue
      const params = {
        className: 'search_results__item',
        name: selectValue,
        key: selectValue,
      }

      switch (selectValue) {
        case 'ID':
          return this.renderIdentifierCell()
        case 'Title':
          return this.renderTitleCell()
        case 'Author':
          return (<TableCell {...params}>{user_full_name}</TableCell>)
        case 'Witness':
          return (<TableCell {...params}>{eln_witness_name}</TableCell>)
        case 'Modified':
          return (<TableCell {...params}>{updated_at.split('T')[0]}</TableCell>)
        case 'Created':
          return (<TableCell {...params}>{created_at.split('T')[0]}</TableCell>)
        case 'Submitted':
          return (<TableCell {...params}>{submitted_date ? submitted_date.split('T')[0] : ''}</TableCell>)
        case 'Finalized':
          return (<TableCell {...params}>{finalized_date ? finalized_date.split('T')[0] : ''}</TableCell>)
        case 'Status':
          return (<TableCell {...params}>{renderStatus({ status })}</TableCell>)
        default:
          return this.renderCellFor(selectValue, params)
      }
    })
  }

  render() {
    const { elnEntry, resultColumns, result, displayStructureRow } = this.props

    return (
      <TableBody className="search_results__body" onClick={this.onClick}>
        <TableRow
          id={'eln_entry-' + elnEntry.id}
          className='search_results__row'
        >
          {this.renderCells()}
          <TableCell className='search_results__item search_results__item__edit'>
          </TableCell>
        </TableRow>
        {!result.isEmpty() && (
          <TableRow className='search_results__row search_results__row__context'>
            <TableCell className='search_results__item' colSpan={resultColumns.size + 1}>
              <SearchContext result={result} displayStructureRow={displayStructureRow} />
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    )
  }
}
