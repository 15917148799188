import { OntologyTree } from '@/Annotator/data/OntologyTree';
import { Schema } from '@/Annotator/data/Schema';
import { OntologyTemplate } from '@/Annotator/data/templates';
import {
  initializeOntologies,
  keyPropGroup,
  setOntologiesInitialized,
  unpackKeyGroupValue,
} from '@/Annotator/data/utils';
import { FieldDataType, FieldDefinition } from '@/FieldDefinitions/types';
import { ColumnSortDef } from '@/components';
import { term } from '@/shared/utils/stringUtils';
import { RootStore } from '@/stores/rootStore';
import axios from 'axios';
import { makeAutoObservable, reaction } from 'mobx';
import { PropertyGridFocusPos } from './PropertyGridRender';
import {
  FilterLayer,
  FilterLayerFieldDataType,
  FilterLayerOntologyTerm,
  FilterLayerProtocolField,
  FilterLayerType,
  filteredProtocolsByLayer,
  validateFilterLayer,
} from './advancedSearchHelper';
import {
  ColumnForDisplay,
  FieldDefinitionsForVault,
  ID,
  Protocol,
} from './types';

const uncacheAlreadyTriedBranchURI = new Set<string>(); // private list of URIs to not re-lookup

export const PROTOCOL_ID_NAME = 'name';
export const PROTOCOL_ID_EXPLORE = 'explore';
export const PROTOCOL_ID_ONTOLOGY = 'ontology_terms';

export class ProtocolsStore {
  protocols: Protocol[] = [];
  fieldDefinitions: FieldDefinitionsForVault[] = [];
  query = { text: '' };
  lastLoadedUrl = '';
  dictionary = {};
  loading = 0;
  inited = false;
  originalTemplateList: OntologyTemplate[] = [];
  templateList: OntologyTemplate[] = [];
  ontologySchemaPrefix = '';
  filterLayers: FilterLayer[] = [];
  eligibleProtocols: Protocol[] = null; // subset of protocols that has been run through the filterLayers
  propertyGridFocusPos: PropertyGridFocusPos = null;
  loadingBranches: string[] = [];

  disposers: Array<() => void> = [];

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    if (!this.inited) {
      this.inited = true;

      // react to url changes by updating model data and loading protocols
      this.disposers.push(
        reaction(
          () => this.getProtocolUrlParams(),
          (protocolUrlParams) => {
            if (!protocolUrlParams) {
              return;
            }

            (async () => {
              try {
                const { query, ontologySchemaPrefix, filterLayers } =
                  protocolUrlParams;
                if (query?.text) {
                  this.setQuery({ text: query.text });
                }
                if (ontologySchemaPrefix) {
                  this.setOntologySchemaPrefix(ontologySchemaPrefix);
                }
                if (filterLayers) {
                  this.filterLayers = filterLayers;
                }

                await initializeOntologies();
                await this.loadProtocols();
                this.loadFieldDefinitions();
                this.updateFilterResults();
              } catch (e) {
                console.error('Error in ProtocolsStore init', e);
              }
            })();
          },
          { fireImmediately: true },
        ),
      );

      window.addEventListener(
        'rjsPostProcessing',
        this.handleRjsPostProcessing,
      );
      this.disposers.push(() => {
        window.removeEventListener(
          'rjsPostProcessing',
          this.handleRjsPostProcessing,
        );
      });
    }
  }

  /**
   * null if not on Protocols page, otherwise data extracted from url
   */
  getProtocolUrlParams() {
    const url = this.root.routerStore.url;
    const match = /^\/vaults\/(\d+)\/protocols/.exec(url.pathname);
    if (!match) return null;
    const vaultId = match[1];

    const strPfx = url.searchParams.get('pfx');
    const strLayers = url.searchParams.get('layers');
    let withAdvancedSearch = false,
      ontologySchemaPrefix = '',
      filterLayers: FilterLayer[] = [];
    if (strPfx || strLayers) {
      withAdvancedSearch = true;
      ontologySchemaPrefix = strPfx;
      try {
        filterLayers = JSON.parse(strLayers);
      } catch {
        // (leave it blank)
      }
      if (!Array.isArray(filterLayers)) filterLayers = [];
      filterLayers = filterLayers.filter((layer) => validateFilterLayer(layer));
    }

    return {
      vaultId,
      query: { text: url.searchParams.get('query') || '' },
      withAdvancedSearch,
      ontologySchemaPrefix,
      filterLayers,
    };
  }

  get vaultId() {
    const urlParams =
      this.root.routerStore.extractFromPattern('/vaults/:vaultId');
    return urlParams?.vaultId;
  }

  get queryText() {
    return this.query?.text ?? '';
  }

  cleanup() {
    this.inited = false;
    setOntologiesInitialized(false);
    this.disposers.forEach((disposer) => disposer());
  }

  handleRjsPostProcessing() {
    if (this.getProtocolUrlParams()) {
      try {
        this.incrementLoading();
        this.loadFieldDefinitions();
        this.loadProtocols();
      } finally {
        this.decrementLoading();
      }
    }
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  get searchUrl() {
    if (!this.vaultId) {
      return null;
    }
    const bits = [
      'utf8=%E2%9C%93',
      `query=${encodeURIComponent(this.queryText)}`,
    ];
    if (this.ontologySchemaPrefix) {
      bits.push(`pfx=${encodeURIComponent(this.ontologySchemaPrefix)}`);
    }
    if (this.filterLayers.length > 0) {
      bits.push(
        `layers=${encodeURIComponent(JSON.stringify(this.filterLayers))}`,
      );
    }

    return `/vaults/${this.vaultId}/protocols?${bits.join('&')}`;
  }

  get fieldDefinitionsUrl() {
    return `/vaults/${this.vaultId}/vault_protocol_field_definitions`;
  }

  get newUrl() {
    return `/vaults/${this.vaultId}/protocols/new`;
  }

  get availableColumns(): FieldDefinitionsForVault[] {
    const hardCodedDefinition: FieldDefinitionsForVault = {
      id: 'protocol',
      name: term('protocol', true),
      protocol_field_definitions: [
        {
          id: PROTOCOL_ID_NAME,
          name: 'Name',
          display_order: 1,
          data_type_name: FieldDataType.Text,
          required_group_number: 0,
          overwritable: false,
          protocol_fields_count: 1,
          disabled: false,
          pick_list_values: [],
          unique_value: false,
        },
        {
          id: PROTOCOL_ID_EXPLORE,
          name: `Search ${term('molecule.other')}`,
          display_order: 2,
          data_type_name: FieldDataType.Text,
          required_group_number: 0,
          overwritable: false,
          protocol_fields_count: 1,
          disabled: false,
          pick_list_values: [],
          unique_value: false,
        },
      ],
    };

    return [
      hardCodedDefinition,
      ...this.fieldDefinitions,
      this.ontologyDefinitionsForColumns(),
    ].filter((def) => !!def);
  }

  private ontologyDefinitionsForColumns(): FieldDefinitionsForVault {
    const mapTemplate = new Map<string, OntologyTemplate>();
    for (const template of this.templateList) {
      mapTemplate.set(template.schemaPrefix, template);
    }

    const results: FieldDefinition[] = [];
    const alreadyGot = new Set<string>();

    for (const protocol of this.protocols) {
      if (!protocol.ontology_schema_prefix) continue;
      const template = mapTemplate.get(protocol.ontology_schema_prefix);
      if (!template) continue;

      const schema = new Schema(template);

      for (const annot of protocol.ontology_annotations) {
        const assn = schema.findAssignment(annot.prop_uri, annot.group_nest);
        if (!assn) continue;
        const hash = keyPropGroup(annot.prop_uri, annot.group_nest);
        if (alreadyGot.has(hash)) continue;
        alreadyGot.add(hash);
        results.push({
          id: hash,
          name: assn.name,
          display_order: results.length + 1,
          data_type_name: FieldDataType.Text,
        });
      }
    }

    if (results.length == 0) {
      return null;
    }

    return {
      id: PROTOCOL_ID_ONTOLOGY,
      name: 'Ontology Terms',
      protocol_field_definitions: results,
    };
  }

  get perProtocolPreferences() {
    return this.root.vaultPreferencesStore.preferences
      .perProtocolRunFieldsColumnPrefs;
  }

  get preferences() {
    return this.root.vaultPreferencesStore.protocolColumnPrefs;
  }

  get displayedColumns(): ColumnForDisplay[] {
    const availableColumnsFlat: ColumnForDisplay[] = [];
    for (const group of this.availableColumns) {
      for (const column of group.protocol_field_definitions) {
        availableColumnsFlat.push({ ...column, parentID: group.id });
      }
    }

    let result = this.visibleColumnIds
      .map((id) => availableColumnsFlat.find((def) => def?.id === id))
      .filter((def) => !!def);

    const sortedColumn = this.sortedColumn;
    result = result.map((column) => {
      if (`${column?.id}` == `${sortedColumn.id}`) {
        return { ...column, direction: sortedColumn.direction };
      }
      return column;
    });
    return result;
  }

  // get sorted row data
  get rows(): Protocol[] {
    const srcProtocols = this.eligibleProtocols || this.protocols;

    const result = srcProtocols.map((protocol) => {
      const obj = {
        ...protocol,
        id: protocol.id,
        name: protocol.name,
      };
      const fields = protocol.protocol_fields || [];
      fields.forEach((field) => {
        obj[field.protocol_field_definition_id] =
          field.display_string || field.value;
      });
      return obj;
    });

    const { id, direction } = this.sortedColumn;
    const parentID = this.displayedColumns.find(
      (look) => `${look.id}` == `${id}`,
    )?.parentID;
    const getField = (row: Protocol): string => {
      if (parentID == PROTOCOL_ID_ONTOLOGY) {
        return this.findOntologyLabel(row, id as string).join(', ');
      } else if (id === PROTOCOL_ID_EXPLORE) {
        // when sorting by this column in ascending order, put rows with columns first
        return row.has_compounds ? 'a' : 'b';
      }
      return '' + (row[id] ?? '');
    };
    result.sort((row1, row2) => {
      const compare = getField(row1).localeCompare(getField(row2), undefined, {
        sensitivity: 'base',
      });
      return direction === 'asc' ? compare : -compare;
    });
    return result;
  }

  get visibleColumnIds() {
    const fieldDefinitions = this.fieldDefinitions.flatMap(
      (item) => item.protocol_field_definitions,
    );

    let result: Array<ID> = this.preferences.visibleColumnIds;
    if (!result.length) {
      result = ['name'];
      const categoryDef = fieldDefinitions.find(
        (def) => def.name === 'Category',
      );
      if (categoryDef) {
        result.push(categoryDef.id);
      }
      const descriptionDef = fieldDefinitions.find(
        (def) => def.name === 'Description',
      );
      if (descriptionDef) {
        result.push(descriptionDef.id);
      }
      result.push('explore');
    }
    return result;
  }

  get sortedColumn() {
    return (
      this.preferences.sortedColumn || {
        id: 'name',
        direction: 'asc',
      }
    );
  }

  get enableSearch() {
    return this.searchUrl !== this.lastLoadedUrl;
  }

  setProtocols(value: Array<Protocol>) {
    this.protocols = value;

    // keep only templates that are represented in the available protocols; set the default one to the most well represented entry
    const validTemplates = new Set(
      this.templateList.map((template) => template.schemaPrefix),
    );
    const schemaCount = new Map<string, number>();
    for (const protocol of this.protocols) {
      const pfx = protocol.ontology_schema_prefix;
      if (pfx && validTemplates.has(pfx)) {
        const count = schemaCount.get(pfx) ?? 0;
        schemaCount.set(pfx, count + 1);
      }
    }
    this.templateList = this.originalTemplateList.filter((template) =>
      schemaCount.get(template.schemaPrefix),
    );
    let mostPopulousPrefix = '';
    let highCount = 0;
    let gotCurrentPrefix = false;
    for (const [pfx, count] of schemaCount.entries()) {
      if (pfx == this.ontologySchemaPrefix) {
        gotCurrentPrefix = true;
        break;
      }
      if (count > highCount) {
        highCount = count;
        mostPopulousPrefix = pfx;
      }
    }
    if (!gotCurrentPrefix) {
      this.ontologySchemaPrefix = mostPopulousPrefix;
    }

    this.updateFilterResults();
  }

  setFieldDefinitions(value: FieldDefinitionsForVault[]) {
    this.fieldDefinitions = value;
  }

  setOriginalTemplateList(templateList: OntologyTemplate[]) {
    this.originalTemplateList = templateList;
    this.templateList = [...templateList];
  }

  setQuery(value: { text: string }) {
    this.query = value;
  }

  setVisibleColumnIds(value: Array<ID>) {
    this.root.vaultPreferencesStore.setPreference('protocolColumnPrefs', {
      visibleColumnIds: value,
      sortedColumn: this.sortedColumn,
    });
  }

  setVisibleColumnIdsForProtocolRunFields(
    protocolId: number,
    value: Array<ID>,
  ) {
    const { vaultPreferencesStore } = this.root;
    const perProtocolRunFieldsColumnPrefs = {
      ...vaultPreferencesStore.preferences.perProtocolRunFieldsColumnPrefs,
      [protocolId]: {
        visibleColumnIds: value,
      },
    };

    vaultPreferencesStore.setPreference(
      'perProtocolRunFieldsColumnPrefs',
      perProtocolRunFieldsColumnPrefs,
    );
  }

  setSortColumnProtocolRunFields(
    protocolId: number,
    sortedColumn: ColumnSortDef,
  ) {
    this.root.vaultPreferencesStore.updatePreferences({
      perProtocolRunFieldsColumnPrefs: {
        ...{
          [protocolId]: {
            sortedColumn,
          },
        },
      },
    });
  }

  setOntologySchemaPrefix(pfx: string) {
    this.ontologySchemaPrefix = pfx;
    this.filterLayers = this.filterLayers.filter(
      (layer) => layer.type != FilterLayerType.OntologyTerm,
    );
    this.updateFilterResults();
    this.updatePageUrl();
  }

  createFilterLayer(layer: FilterLayer) {
    this.filterLayers.push(layer);
    this.updatePageUrl();
  }

  setFilterLayerProtocolField(
    idx: number,
    dataType: FilterLayerFieldDataType,
    defn: FieldDefinition,
    observedValues: string[],
    unionOfValues: string[],
  ) {
    this.filterLayers[idx] = {
      type: FilterLayerType.ProtocolField,
      dataType,
      defn: { name: defn.name },
      observedValues,
      unionOfValues,
    } as FilterLayerProtocolField;
    this.updatePageUrl();
  }

  setFilterLayerProtocolFieldValue(
    idx: number,
    valueProps: Record<string, unknown>,
  ) {
    for (const [k, v] of Object.entries(valueProps)) {
      this.filterLayers[idx][k] = v;
    }
    this.updatePageUrl();
  }

  setFilterLayerOntologyTerm(
    idx: number,
    propURI: string,
    groupNest: string[],
  ) {
    this.filterLayers[idx] = {
      type: FilterLayerType.OntologyTerm,
      propURI,
      groupNest,
      valueURIList: [],
    } as FilterLayerOntologyTerm;
    this.updatePageUrl();
  }

  setFilterLayerOntologyValues(idx: number, valueURIList: string[]) {
    const layer = this.filterLayers[idx] as FilterLayerOntologyTerm;
    layer.valueURIList = valueURIList;
    this.updatePageUrl();
  }

  setPropertyGridFocusPos(focus: { blk: number; col: number; row: number }) {
    this.propertyGridFocusPos = focus;
  }

  deleteFilterLayer(idx: number) {
    this.filterLayers.splice(idx, 1);
    this.updatePageUrl();
  }

  deleteFilterLayerValue(idx: number, pos: number) {
    const layer = this.filterLayers[idx];
    if (layer.type == FilterLayerType.ProtocolField) {
      const lfield = layer as FilterLayerProtocolField;
      if (Array.isArray(lfield.value)) {
        lfield.value.splice(pos, 1);
      }
    } else if (layer.type == FilterLayerType.OntologyTerm) {
      const lterm = layer as FilterLayerOntologyTerm;
      lterm.valueURIList.splice(pos, 1);
    }
    this.updatePageUrl();
  }

  setEligibleProtocols(eligibleProtocols: Protocol[]) {
    this.eligibleProtocols = eligibleProtocols;
  }

  currentTemplateSchema(): Schema {
    const { ontologySchemaPrefix, templateList } = this;
    if (!ontologySchemaPrefix || !templateList) return null;
    const template = templateList.find(
      (look) => look.schemaPrefix == ontologySchemaPrefix,
    );
    return template ? new Schema(template) : null;
  }

  public findOntologyLabel(protocol: Protocol, key: string): string[] {
    if (!protocol.ontology_schema_prefix) return [];

    const valuesURI: string[] = [],
      valuesLabel: string[] = [];
    for (const annot of protocol.ontology_annotations ?? []) {
      if (keyPropGroup(annot.prop_uri, annot.group_nest) != key) continue;
      if (annot.value_uri) {
        valuesURI.push(annot.value_uri);
      } else if (annot.value_label) {
        valuesLabel.push(annot.value_label);
      }
    }

    if (valuesURI.length == 0) return valuesLabel; // quick out; may be blank

    const { propURI, groupNest } = unpackKeyGroupValue(key);

    const template = this.templateList.find(
      (look) => look.schemaPrefix == protocol.ontology_schema_prefix,
    );
    if (!template) return [];

    const schema = new Schema(template);
    const assn = schema.findAssignment(propURI, groupNest);
    if (!assn) return [];

    const mapInline = new Map<string, string>();
    for (const value of assn.values) {
      mapInline.set(value.uri, value.name);
    }

    for (const uri of valuesURI) {
      let label = mapInline.get(uri);
      if (!label) {
        label = OntologyTree.values.cachedLabel(uri);
        if (!label) {
          label = '...';
          if (
            !this.loadingBranches.includes(uri) &&
            !uncacheAlreadyTriedBranchURI.has(uri)
          ) {
            uncacheAlreadyTriedBranchURI.add(uri);
            setTimeout(() => {
              (async () => {
                this.setLoadingBranches([...this.loadingBranches, uri]);
                await initializeOntologies();
                await OntologyTree.values.init();
                await OntologyTree.values.getBranch(uri);
                this.setLoadingBranches(
                  this.loadingBranches.filter((look) => look != uri),
                );
              })();
            }, 0);
          }
        }
      }
      valuesLabel.push(label);
    }
    return valuesLabel;
  }

  public convertVisibleTableToCSV(): string {
    const { displayedColumns, rows } = this;

    const columns = displayedColumns.filter((column) => column.id != 'explore');

    const escapeCell = (cell) => {
      if (!cell.includes('"') && !cell.includes(',') && !cell.includes('\n')) {
        return cell;
      }
      return '"' + cell.replace(/"/g, '""') + '"';
    };

    const textLines: string[] = [];
    textLines.push(columns.map((column) => escapeCell(column.name)).join(','));

    for (const row of rows) {
      const cells: string[] = [];

      for (const column of columns) {
        if (column.parentID == 'ontology_terms') {
          const labelList = this.findOntologyLabel(row, column.id as string);
          cells.push(labelList.join(', '));
        } else if (column.id == 'name') {
          cells.push(row.name);
        } else {
          cells.push(row[column.id] ?? '');
        }
      }

      textLines.push(cells.map((cell) => escapeCell(cell)).join(','));
    }

    return textLines.join('\n');
  }

  setLoadingBranches(uriList: string[]): void {
    this.loadingBranches = uriList;
  }

  updatePageUrl() {
    const { searchUrl } = this;
    if (this.vaultId && searchUrl !== this.lastLoadedUrl) {
      window.history.pushState({ path: searchUrl }, '', searchUrl);
      this.lastLoadedUrl = searchUrl;
    }
  }

  // something may have changed, so re-do the filters
  updateFilterResults(): void {
    (async () => {
      const schema = this.currentTemplateSchema();
      if (!schema) {
        this.filterLayers = this.filterLayers.filter(
          (layer) => layer.type != FilterLayerType.OntologyTerm,
        );
      }

      let eligibleProtocols = this.protocols;
      for (let n = 0; n < this.filterLayers.length; n++) {
        eligibleProtocols = await filteredProtocolsByLayer(
          eligibleProtocols,
          this.fieldDefinitions,
          schema,
          this.filterLayers[n],
        );
      }
      this.setEligibleProtocols(eligibleProtocols);
    })();
  }

  handleClickColumn(columnId: ID) {
    let direction: ColumnSortDef['direction'] = 'asc';
    if (this.sortedColumn.id === columnId) {
      direction = this.sortedColumn.direction === 'asc' ? 'desc' : 'asc';
    }
    this.root.vaultPreferencesStore.updatePreferences({
      protocolColumnPrefs: {
        sortedColumn: {
          id: columnId,
          direction,
        },
      },
    });
  }

  handleClickSearch() {
    const { searchUrl } = this;

    if (this.vaultId && searchUrl !== this.lastLoadedUrl) {
      window.history.pushState({ path: searchUrl }, '', searchUrl);
      this.loadProtocols();
    }
  }

  async loadProtocols() {
    const url = this.searchUrl;
    if (url) {
      this.lastLoadedUrl = url;
      try {
        this.incrementLoading();
        const response = await axios.get(url, { params: { format: 'json' } });
        this.setProtocols(response.data);
      } finally {
        this.decrementLoading();
      }
    }
  }

  async loadFieldDefinitions() {
    if (!this.vaultId) {
      return;
    }
    try {
      this.incrementLoading();
      const response = await axios.get(this.fieldDefinitionsUrl, {
        params: { format: 'json' },
      });
      this.setFieldDefinitions(response.data);
    } finally {
      this.decrementLoading();
    }
  }
}
