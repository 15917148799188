import { getRootStore, RootStore } from '@/stores/rootStore';
import { makeAutoObservable } from 'mobx';
import { AnyObject, StringOrNumber } from '../types';

// a simple store that makes the URL observable AND allows for mocking
export class RouterStore {
  static initialUrl = window.location.href;
  url: URL = new URL(RouterStore.initialUrl);

  public static openInNewTab(url: string, focus = true) {
    const newTab = window.open(url, '_blank');
    if (focus) {
      newTab.focus();
    }
  }

  public static async openInNewTabWithPost(url: string, data: AnyObject) {
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = url;
    form.target = '_blank'; // Open in new tab

    // Add data to the form (incl. CSRF token)
    data = { ...data, authenticity_token: $.rails.csrfToken() };
    Object.entries(data).forEach(([key, value]) => {
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = key;
      input.value = String(value);
      form.appendChild(input);
    });

    document.body.appendChild(form);
    form.submit();
    document.body.removeChild(form);
  }

  constructor(public readonly root: RootStore) {
    window.onpopstate = () => {
      this.setUrl();
    };
    makeAutoObservable(this);
  }

  init() {
    /* */
  }

  cleanup() {
    window.onpopstate = null;
  }

  setUrl(url: string = this.url.href) {
    this.url = new URL(url);
  }

  go(url: string) {
    window.location.href = url;
  }

  goHash(hash: string) {
    window.location.hash = hash;
    this.url = new URL(window.location.href);
  }

  extractFromPattern(pattern: string): null | AnyObject {
    return extractParamsFromUrlPattern(this.url.pathname, pattern);
  }
}

// could build up a recursive string type that automatically infers potential returned keys just from pattern literal, for now KISS
export const extractParamsFromUrlPattern = <TReturnKeys extends string>(
  url: string,
  pattern: string,
): Record<TReturnKeys, StringOrNumber> | null => {
  const patternParts = pattern.split('/');
  const urlParts = url.split('/');
  let result: { [key: string]: number | string } | null = null;
  if (patternParts.length > urlParts.length) {
    return null;
  }
  for (let i = 0; i < patternParts.length; i++) {
    if (patternParts[i].startsWith(':')) {
      if (!result) {
        result = {};
      }
      let value: string | number = urlParts[i];
      // if this string part is a number, convert it to an actual number
      const valueAsNumber = parseFloat(value);
      if (urlParts[i] === '' + valueAsNumber) {
        value = valueAsNumber;
      }
      result[patternParts[i].substring(1)] = value;
    } else {
      if (patternParts[i] !== urlParts[i]) {
        return null;
      }
    }
  }
  return (result || {}) as Record<TReturnKeys, StringOrNumber>;
};

export const vaultIdFromUrl = (url: string = null) => {
  if (url === null) {
    url = getRootStore().routerStore.url.pathname;
  }
  const { vaultId } = (extractParamsFromUrlPattern(url, '/vaults/:vaultId') as {
    vaultId?: number;
  }) ?? {
    vaultId: null,
  };
  return vaultId;
};
