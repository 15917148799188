import React from 'react'

import { Terminology } from 'javascripts/cross_app_utilities.js'

import CriterionField from '../MoleculeSearchBuilder/KeywordCriterion/CriterionField/index.js'
import withStore from '../MoleculeSearchBuilder/store.jsx'

import RailsHiddenFields from '@/shared/components/RailsHiddenFields.jsx'
import { Input } from '@/shared/components/sanitizedTags.js'

import { MoleculeCriterion } from '../MoleculeSearchBuilder/models.js'

class MoleculeKeywordSearch extends React.PureComponent {
  onQueryTextAreaChange = (event) => {
    const { onQueryChange } = this.props
    const { value } = event.target
    return onQueryChange(0, value)
  }

  render() {
    const { fields, molecule_criteria, onFieldChange } = this.props
    const molecule_criterion = molecule_criteria.get(0)
    const { query, path, field, data_type_name } = molecule_criterion

    return (
      <React.Fragment>
        <RailsHiddenFields
          name='query'
          value={MoleculeCriterion.toAttributes(fields, molecule_criteria)}
        />
        <div className='item'>
          <Input
            type="text"
            className="input-text molecule_criteria__query molecule_criteria__query__single-row"
            size="20"
            onChange={this.onQueryTextAreaChange}
            value={query}
            placeholder={`Search ${Terminology.dictionary['molecule.other']}...`}
          />
        </div>

        <CriterionField
          className="molecule_criteria__select"
          onFieldChange={onFieldChange}
          fields={fields}
          path={path}
          field={field}
          data_type_name={data_type_name}
          criterionIndex={0}
        />
      </React.Fragment>
    )
  }
}

export default withStore(MoleculeKeywordSearch)
