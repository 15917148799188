/* eslint-disable dot-notation */

import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable } from 'mobx';
import { ProcessParsingText } from './ProcessParsingText';
import { convertNameToStructure } from '@/shared/utils/mixtureLookupUtils.js';
import { CDD } from '@/typedJS';
import { StructureFormat } from '@/components/StructureEditor/StructureEditorTypes';
import { deepClone } from '@/Annotator/data/utils';
import { Mixfile, MixfileComponent } from 'chemical-mixtures/Mixfile';
import { MetaVector } from 'webmolkit/gfx/MetaVector';
import { Mixture } from 'chemical-mixtures/Mixture';
import { MoleculeStream } from 'webmolkit/io/MoleculeStream';
import { Vec } from 'webmolkit/util/Vec';
import { FauxHost } from '@cdd/mixture-importer/FauxHost';

const UNDO_STACK_SIZE = 10;

export enum MixtureParsedResultType {
  Basic = 'basic', // just a name
  Predicted = 'predicted', // generated using machine learning
  Reference = 'reference', // looked up in a static reference database
  Vault = 'vault', // found as a Vault molecule
  Quantity = 'quantity', // just quantity information
}

export interface MixtureParsedResult {
  key: string,
  type: MixtureParsedResultType,
  comp: MixfileComponent,
  gfx?: MetaVector,
  vaultMolName?: string;
  vaultBatches?: string[];
  vaultSynonyms?: string[];
  sortPri?: number;
  selectedSynonym?: string;
  selectedBatch?: string;
}

export interface MixtureParsing {
  origin: number[];
  text: string;
  isRunning: boolean;
  isCancelled: boolean;
  watermarkExec: number;
  results: MixtureParsedResult[];
  selectedResultKey: string;
  nextKeyBounce: number;
  lastKeyBounce: number;
}

export enum ArrowKeyDirection {
  Up,
  Down,
  Left,
  Right,
}

export interface ZoomPanEvent {
  zoomDirection?: number;
  resizeToFit?: boolean;
  panDX?: number;
  panDY?: number;
}

export enum MixtureClipboardType {
  Blank,
  UnknownFormat,
  MixtureFormat,
  MoleculeFormat,
}

export class MixturesStore {
  root: RootStore;
  isDirty: boolean;
  mixture: Mixture;
  zoomPanEvents: ZoomPanEvent[] = [];
  watermarkMixture = 0;
  undoStack: Mixture[] = [];
  redoStack: Mixture[] = [];
  hoverKey: string = null;
  selectedOrigin: number[] = null;
  collapsedBranches: number[][] = [];
  menuOrigin: number[] = null;
  parsing: MixtureParsing = null;
  singletonFauxHost: FauxHost = null;
  isClipboardOpen = false;
  clipboardText = '';
  isDetailOpen = false;
  detailOrigin:number[] = null;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    // nop
  }

  public get fauxHost(): FauxHost {
    if (!this.singletonFauxHost) {
      this.singletonFauxHost = new FauxHost();
      this.singletonFauxHost.callbackNameToMol = convertNameToStructure;
      this.singletonFauxHost.urlBase = '/mixtureUI/deploy';
    }
    return this.singletonFauxHost;
  }

  public clearHistory(): void {
    this.isDirty = false;
    this.undoStack = [];
    this.redoStack = [];
  }

  public setMixture(mixture: Mixture, stashUndo = true): void {
    if (stashUndo) {
      this.undoStack.push(this.mixture);
      while (this.undoStack.length > UNDO_STACK_SIZE) this.undoStack.shift();
      this.redoStack = [];
    }
    this.mixture = mixture.clone();
    this.watermarkMixture++;
    if (this.selectedOrigin && !this.mixture.getComponent(this.selectedOrigin)) this.selectedOrigin = null;
  }

  public canUndo(): boolean {
    return this.undoStack.length > 0;
  }

  public canRedo(): boolean {
    return this.redoStack.length > 0;
  }

  public setHoverKey(hoverKey: string): void {
    this.hoverKey = hoverKey;
  }

  public setSelectedOrigin(origin: number[]): void {
    if (JSON.stringify(origin) != JSON.stringify(this.selectedOrigin)) {
      this.selectedOrigin = origin;
      this.watermarkMixture++;
      this.clearParsingText();
    }
  }

  public setCollapsedBranches(collapsed: number[][]): void {
    if (JSON.stringify(collapsed) != JSON.stringify(this.collapsedBranches)) {
      this.collapsedBranches = collapsed;
      this.watermarkMixture++;
      this.clearParsingText();
    }
  }

  public toggleCollapseBranch(origin: number[]): void {
    const hash = JSON.stringify(origin);
    const collapsedBranches = [...this.collapsedBranches];
    const idx = collapsedBranches.findIndex((look) => JSON.stringify(look) == hash);
    if (idx >= 0) {
      collapsedBranches.splice(idx, 1);
    } else {
      collapsedBranches.push(origin);
    }
    this.setCollapsedBranches(collapsedBranches);
  }

  public setMenuOrigin(origin: number[]): void {
    if (JSON.stringify(origin) != JSON.stringify(this.menuOrigin)) {
      this.menuOrigin = origin;
      this.clearParsingText();
    }
  }

  public mergeComponent(origin: number[], result: MixtureParsedResult): void {
    const mixture = this.mixture.clone();
    const comp = mixture.getComponent(origin);
    const merge = result.comp;
    const { selectedSynonym, selectedBatch } = result;

    if (merge.name) {
      if (comp.synonyms && !comp.synonyms.includes(merge.name)) {
        comp.synonyms = [...(comp.synonyms ?? []), merge.name];
      }
      comp.name = merge.name;
    }
    if (merge.synonyms?.length > 0) {
      comp.synonyms = comp.synonyms ?? [];
      for (const syn of merge.synonyms) {
        if (!comp.synonyms.includes(syn)) {
          comp.synonyms.push(syn);
        }
      }
    }
    if (merge.molfile) {
      comp.molfile = merge.molfile;
    }
    if (merge.quantity || merge.ratio) {
      comp.quantity = merge.quantity;
      comp.error = merge.error;
      comp.ratio = merge.ratio;
      comp.units = merge.units;
      comp.relation = merge.relation;
    }
    if (merge.identifiers || selectedBatch) {
      comp.identifiers = comp.identifiers ?? {};
      for (const [k, v] of Object.entries(merge.identifiers ?? {})) {
        comp.identifiers[k] = v;
      }
      if (selectedBatch) {
        comp.identifiers['vaultBatch'] = selectedBatch;
      }
    }
    if (merge.links) {
      comp.links = comp.links ?? {};
      for (const [k, v] of Object.entries(merge.links ?? {})) {
        comp.links[k] = v;
      }
    }
    if (merge.contents?.length > 0) {
      comp.contents = [...(comp.contents ?? []), ...merge.contents];
    }
    if (merge.metadata?.length > 0) {
      comp.metadata = [...(comp.metadata ?? []), ...merge.metadata];
    }

    if (selectedSynonym && selectedSynonym != comp.name) {
      comp.synonyms = (comp.synonyms ?? []).filter((look) => look != selectedSynonym);
      if (comp.name) comp.synonyms.push(comp.name);
      if (comp.synonyms.length == 0) delete comp.synonyms;
      comp.name = selectedSynonym;
    }

    this.setMixture(mixture);
  }

  public openPasteClipboard(text: string): void {
    if (!this.selectedOrigin) return;

    if (this.actionPasteText(this.selectedOrigin, text)) return;

    this.isClipboardOpen = true;
    this.clipboardText = text;
  }

  public closePasteClipboard(): void {
    this.isClipboardOpen = false;
    this.clipboardText = '';
  }

  public shouldBogartClipboard(): boolean {
    if (this.isClipboardOpen || this.parsing || this.isDetailOpen) return false;
    return true;
  }

  public investigateCurrentText(text: string): MixtureClipboardType {
    if (!text) return MixtureClipboardType.Blank;

    try {
      const mixture = Mixture.deserialise(text);
      if (mixture) return MixtureClipboardType.MixtureFormat;
    } catch {}

    try {
      const molfile = MoleculeStream.readMDLMOL(text);
      if (molfile) return MixtureClipboardType.MoleculeFormat;
    } catch {}

    return MixtureClipboardType.UnknownFormat;
  }

  public openComponentDetail(origin: number[]): void {
    this.isDetailOpen = true;
    this.detailOrigin = origin;
  }

  public closeComponentDetail(): void {
    this.isDetailOpen = false;
    this.detailOrigin = null;
  }

  public actionUndo(): void {
    if (this.undoStack.length == 0) return;
    this.redoStack.push(this.mixture.clone());
    this.mixture = this.undoStack.pop();
    this.watermarkMixture++;
  }

  public actionRedo(): void {
    if (this.redoStack.length == 0) return;
    this.undoStack.push(this.mixture.clone());
    this.mixture = this.redoStack.pop();
    this.watermarkMixture++;
  }

  public actionZoomFit(): void {
    this.setMenuOrigin(null);
    this.zoomPanEvents.push({ resizeToFit: true });
    this.watermarkMixture++;
  }

  public actionZoomMag(zoomDirection: number): void {
    this.setMenuOrigin(null);
    this.zoomPanEvents.push({ zoomDirection });
    this.watermarkMixture++;
  }

  public actionPanDisplay(dx: number, dy: number): void {
    this.setMenuOrigin(null);
    this.zoomPanEvents.push({ panDX: dx, panDY: dy });
    this.watermarkMixture++;
  }

  public clearZoomPanEvents(): void {
    this.zoomPanEvents = [];
  }

  public actionEditStructure(origin: number[]): void {
    this.setMenuOrigin(null);
    const modmix = this.mixture.clone();
    const comp = modmix.getComponent(origin);

    (async () => {
      const opt = { structureFormat: StructureFormat.MOL, skipInsert: false, moleculesOnly: true };
      let molfile: string = null;
      try {
        const result = await CDD.StructureEditor.openMarvin4JSWithPromise(comp.molfile, 'auto', opt);
        molfile = result.mrv;
      } catch (ex) {
        if (ex.message != 'empty') return;
        // otherwise: it's probably 'cancel', so do nothing
      }

      if (molfile) {
        comp.molfile = molfile;
      } else {
        delete comp.molfile;
      }
      this.setMixture(modmix);
    })();
  }

  public actionEditDetails(origin: number[]): void {
    this.setMenuOrigin(null);
    this.openComponentDetail(origin);
  }

  public actionInsertLeft(origin: number[]): void {
    this.setMenuOrigin(null);
    const modmix = this.mixture.clone();
    modmix.prependBefore(origin, {});
    this.setMixture(modmix);
    this.setSelectedOrigin(origin);
  }

  public actionInsertRight(origin: number[]): void {
    this.setMenuOrigin(null);
    const modmix = this.mixture.clone();
    const comp = modmix.getComponent(origin);
    if (!comp.contents) comp.contents = [];
    comp.contents.push({});
    this.setMixture(modmix);
    this.setSelectedOrigin([...origin, comp.contents.length - 1]);
  }

  public actionInsertAbove(origin: number[]): void {
    this.setMenuOrigin(null);
    if (origin.length == 0) return;
    const modmix = this.mixture.clone();
    origin = [...origin];
    const pos = origin.pop();
    const parent = modmix.getComponent(origin);
    parent.contents.splice(pos, 0, {});
    origin.push(pos);
    this.setMixture(modmix);
    this.setSelectedOrigin(origin);
  }

  public actionInsertBelow(origin: number[]): void {
    this.setMenuOrigin(null);
    if (origin.length == 0) return;
    const modmix = this.mixture.clone();
    origin = [...origin];
    const pos = origin.pop();
    const parent = modmix.getComponent(origin);
    parent.contents.splice(pos + 1, 0, {});
    origin.push(pos + 1);
    this.setMixture(modmix);
    this.setSelectedOrigin(origin);
  }

  public actionMove(origin: number[], direction: number): void {
    this.setMenuOrigin(null);
    if (origin.length == 0) return;
    const modmix = this.mixture.clone();
    const [parent, idx] = Mixture.splitOrigin(origin);
    const comp = modmix.getComponent(parent);
    if (!comp || !(comp.contents?.length > 1)) return;
    if (idx + direction < 0 || idx + direction >= comp.contents.length) return;
    Vec.swap(comp.contents, idx, idx + direction);
    this.setMixture(modmix);
    this.setSelectedOrigin([...parent, idx + direction]);
  }

  public actionDelete(origin: number[], wholeBranch: boolean): void {
    this.setMenuOrigin(null);
    if (origin.length == 0) return;
    const modmix = this.mixture.clone();
    const comp = modmix.getComponent(origin);
    if (!comp) return;
    if (wholeBranch) {
      comp.contents = [];
    }
    modmix.deleteComponent(origin);
    this.setMixture(modmix);
  }

  public actionCopy(origin: number[], wholeBranch: boolean): void {
    this.setMenuOrigin(null);
    const comp = deepClone(this.mixture.getComponent(origin));
    if (!comp) return;
    delete (comp as Mixfile).mixfileVersion;
    if (!wholeBranch) {
      comp.contents = [];
    }
    const str = Mixture.serialiseComponent(comp);
    this.copyToClipboard(str);
  }

  public actionCut(origin: number[]): void {
    this.setMenuOrigin(null);
    const modmix = this.mixture.clone();
    const comp = deepClone(modmix.getComponent(origin));
    if (!comp) return;
    delete (comp as Mixfile).mixfileVersion;
    const str = Mixture.serialiseComponent(comp);
    this.copyToClipboard(str);
    modmix.getComponent(origin).contents = [];
    modmix.deleteComponent(origin);
    this.setMixture(modmix);
  }

  public actionPaste(): void {
    this.setMenuOrigin(null);
    this.openPasteClipboard('');
  }

  public actionPasteText(origin: number[], text: string): boolean {
    if (!origin || !text) return false;

    try {
      const mixture = Mixture.deserialise(text);
      if (mixture) {
        const comp = mixture.mixfile;
        delete comp.mixfileVersion;
        this.actionPasteComponent(origin, comp);
        return true;
      }
    } catch {}

    try {
      const molecule = MoleculeStream.readMDLMOL(text);
      if (molecule) {
        this.actionPasteStructure(origin, text);
        return true;
      }
    } catch {
      // nop
    }

    return false;
  }

  public actionPasteComponent(origin: number[], comp: MixfileComponent): void {
    // special deal when pasting into nothing: just replace it
    if (origin.length == 0 && this.mixture.isEmpty()) {
      const modmix = new Mixture(comp as Mixfile);
      this.setMixture(modmix);
      return;
    }

    // append to or replace some piece, preferably selected
    const modmix = this.mixture.clone();
    const modcomp = modmix.getComponent(origin);
    if (Mixture.isComponentEmpty(modcomp)) {
      Object.keys(modcomp).forEach((key:string) => delete (modcomp as any)[key]); // eslint-disable-line @typescript-eslint/no-explicit-any
      Object.keys(comp).forEach((key:string) => (modcomp as any)[key] = comp[key]); // eslint-disable-line @typescript-eslint/no-explicit-any
    } else { // append
      if (!modcomp.contents) modcomp.contents = [];
      modcomp.contents.push(comp);
      this.setSelectedOrigin([...origin, modcomp.contents.length - 1]);
    }
    this.setMixture(modmix);
  }

  public actionPasteStructure(origin: number[], molfile: string): void {
    const modmix = this.mixture.clone();
    const comp = modmix.getComponent(origin);
    comp.molfile = molfile;
    this.setMixture(modmix);
  }

  public actionActivateTyping(key: string): void {
    if (this.selectedOrigin) {
      this.parsing = {
        origin: this.selectedOrigin,
        text: key,
        isRunning: true,
        isCancelled: false,
        watermarkExec: 0,
        results: [],
        selectedResultKey: null,
        nextKeyBounce: 0,
        lastKeyBounce: 0,
      };
      new ProcessParsingText(this, this.parsing).execute();
    }
  }

  public clearParsingText(): void {
    if (this.parsing) {
      this.parsing.isCancelled = true;
      this.parsing = null;
    }
  }

  public changeParsingText(text: string): void {
    if (this.parsing) {
      this.parsing.text = text;
      this.parsing.results = [];
      this.parsing.watermarkExec++;
      new ProcessParsingText(this, this.parsing).execute();
    }
  }

  public changeParsingResults(results: MixtureParsedResult[]): void {
    if (this.parsing) {
      this.parsing.results = results;
    }
  }

  public notifyParsingDone(): void {
    if (this.parsing) {
      this.parsing.isRunning = false;
    }
  }

  public adjustParsingSelection(dir: number): void {
    const { parsing } = this;
    if (!parsing) return;

    let idx = parsing.results.findIndex((result) => result.key == parsing.selectedResultKey);
    const sz = parsing.results.length;
    if (sz == 0) return;

    if (idx < 0) {
      idx = dir > 0 ? 0 : sz - 1;
    } else {
      idx = (idx + sz + dir) % sz;
    }
    parsing.selectedResultKey = parsing.results[idx].key;
  }

  public selectParsingSynonym(key: string, synonym: string): void {
    const { parsing } = this;
    if (!parsing) return;

    const idx = parsing.results.findIndex((result) => result.key == key);
    parsing.results[idx].selectedSynonym = synonym;
  }

  public selectParsingBatch(key: string, batch: string): void {
    const { parsing } = this;
    if (!parsing) return;

    const idx = parsing.results.findIndex((result) => result.key == key);
    parsing.results[idx].selectedBatch = batch;
  }

  public actionKeyArrow(dir: ArrowKeyDirection): void {
    let origin = this.selectedOrigin;

    if (origin == null) {
      this.setSelectedOrigin([]);
      return;
    }

    origin = [...origin];
    if (dir == ArrowKeyDirection.Up) {
      if (origin.length == 0 || origin[origin.length - 1] == 0) return;
      origin[origin.length - 1]--;
    } else if (dir == ArrowKeyDirection.Down) {
      if (origin.length == 0) return;
      origin[origin.length - 1]++;
    } else if (dir == ArrowKeyDirection.Left) {
      if (origin.length == 0) return;
      origin.pop();
    } else if (dir == ArrowKeyDirection.Right) {
      origin.push(0);
    }

    if (this.mixture.getComponent(origin)) {
      this.setSelectedOrigin(origin);
    }
  }

  public actionKeyTab(): void {
    const origin = this.selectedOrigin;
    if (origin == null) {
      this.setSelectedOrigin([]);
    } else if (origin.length == 0) {
      const root = this.mixture.mixfile;
      if (!root.contents?.length) {
        this.actionInsertRight(origin);
      } else {
        this.actionKeyArrow(ArrowKeyDirection.Right);
      }
    } else {
      const parent = this.mixture.getComponent(origin.slice(0, origin.length - 1)), pos = origin[origin.length - 1];
      if (pos == parent.contents?.length - 1) {
        this.actionInsertBelow(origin);
      } else {
        this.actionKeyArrow(ArrowKeyDirection.Down);
      }
    }
  }

  public copyToClipboard(text: string): void {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(text).then();
    } else {
      document.execCommand('copy', true, text);
    }
  }
}
