import React, { useCallback, useRef, useState } from 'react';
import { PropsOf } from '@emotion/react';
import { Alert, Fade, Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useResizeDetector } from 'react-resize-detector';
import merge from 'lodash/merge';

/**
 * AutoEllipsisTooltip
 *
 * A component that applies text-overflow: ellipsis to its children and automatically displays a tooltip with the full text when the text is
 * truncated.
 */

const defaultTooltipProps: Partial<PropsOf<typeof Tooltip>> = {
  disableInteractive: true, // necessary or weird behavior results, such as tooltips not dismissing
  componentsProps: {
    tooltip: {
      sx: {
        bgcolor: 'rgb(252, 252, 252)',
        color: 'rgb(89, 50, 22)',
        minWidth: '40rem',
        padding: '1rem',
        border: '1px solid rgba(89, 50, 22, .5)',
        fontSize: '1rem',
        boxShadow: '2px 2px 2px 2px rgba(0,0,0,0.2)',
      },
    },
  },
};

interface Props extends React.PropsWithChildren {
  tooltipContent?: React.ReactNode; // optionally pass content to display in the tooltip, defaults to use the component's children
  tooltipProps?: Partial<PropsOf<typeof Tooltip>>;
  maxWidth?: string | number;
  margin?: string | number;
  clickToCopy?: boolean,
  copyTextPreprocessFunc?: (text: string) => string;
  enabled?: boolean;
}

const COPY_MESSAGE_TIMEOUT = 1000;

const AutoEllipsisTooltipEnabled = (props: Props) => {
  const { width, height, ref } = useResizeDetector();
  const refTextContents = useRef<HTMLDivElement>(null);
  const showTooltip = width && height && ((width + 1) < refTextContents.current?.offsetWidth || (height + 1) < refTextContents.current?.offsetHeight);
  const children = props.children;
  const tooltipContent = props.tooltipContent ?? children;
  const [showCopyMessage, setShowCopyMessage] = useState(false);

  const handleClick = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
    setShowCopyMessage(true);
    setTimeout(() => { setShowCopyMessage(false); }, COPY_MESSAGE_TIMEOUT);

    const text = refTextContents.current?.textContent ?? '';
    const textToCopy = props.copyTextPreprocessFunc?.(text) ?? text;
    if (navigator.clipboard) {
      (async () => {
        await navigator.clipboard.writeText(textToCopy);
      })();
    } else {
      // backward compatibility
      // noinspection JSDeprecatedSymbols
      document.execCommand('copy', true, text);
    }
  }, []);

  const TooltipWithCustomWidth = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: props.maxWidth,
      margin: props.margin,
    },
  });

  const toolTipProps: TooltipProps = {
    onClick: props.clickToCopy ? handleClick : undefined,
    open: showCopyMessage ? false : undefined,
    ...(merge(defaultTooltipProps, props.tooltipProps)),
    title: showTooltip ? tooltipContent : null,
    children: undefined,
  };

  const tooltipContents = <div
    ref={refTextContents}
    className='text-contents'>{children}</div>;

  return <div className={`AutoEllipsisTooltip ${showTooltip ? 'AutoEllipsisTooltip-tooltip' : ''}`} ref={ref}>

    {showTooltip
      ? <TooltipWithCustomWidth title={showTooltip ? tooltipContent : null} {...toolTipProps}>
        {tooltipContents}
      </TooltipWithCustomWidth>
      : null}
    {showTooltip ? null : tooltipContents}

    <Fade in={showCopyMessage} timeout={COPY_MESSAGE_TIMEOUT}>
      <Alert
        className="CopyToClipboard-alert"
        severity="success"
      >
        {showCopyMessage ? 'Copied to clipboard.' : null}
      </Alert>
    </Fade>
  </div>;
};

export const AutoEllipsisTooltip = (props: Props) => {
  if (props.enabled === false) {
    return <>{props.children}</>;
  }

  return <AutoEllipsisTooltipEnabled {...props} />;
};
