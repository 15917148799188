import React from 'react';
import { MolImage } from '@cdd/ui-kit/lib/components/elements/molImage/v2';
import { convertKetToMrv } from '@cdd/ui-kit/lib/molRendering/v2/ketcherFormat';
import { MarvinCML } from '@cdd/ui-kit/lib/molRendering/v2/MarvinCML';
import {
  MolRenderOptions,
  Highlight,
  molfileIsValid,
  molfileIsValidAndHasAllZeroCoords,
} from '@cdd/ui-kit/lib/molRendering/v2/RenderMoleculeSVG';
import { ErrorBoundary } from 'react-error-boundary';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { ensureChemUtils } from '@/shared/utils/chemUtils';
import { obtainIndigo, StandaloneIndigo } from '@/shared/utils/indigo';
import { RenderIfVisible } from '@/components';
import { A } from '@/shared/components/sanitizedTags';
import { CDD } from '@/typedJS';

// Scott: this is the best way to import sass, but it doesn't work w/ webpacker 3. revisit after uggrade.
// See https://www.pivotaltracker.com/story/show/182980152
// import './ChemistryImage.sass';

/**
 * ChemistryImage
 *
 * Renders an image with optional highlights.
 * This is complex because it may have to perform transformations on the src and/or highlightedStructures passed in through
 * props before rendering can happen, and some of those transformations rely on the chemUtils and/or indigo library which is loaded asynchronously.
 * So we have a State that contains the data after transformations have been performed, which also forces rerenders when
 * asynchronously triggered operations complete.
 */
type Props = {
  // In the normal flow of events, src comes from Molecule#structure_for_display
  // Whenever these change, update ruby/lib/frontend_executor/methods/chemistryImage.tsx and rebuild frontend executor
  src: string; // molfile, CML or SMILES string
  imgUrl?: string;
  onClick?: () => void;
  id?: string;
  highlightedStructures?: string[];
  isRegistration?: boolean;
  alt?: string;
  className?: string;
  title?: string;
  width?: number;
  height?: number;
  minWidth?: number;
  minHeight?: number;
  renderPngForSize?: boolean;
  extractDimensionsFromUrl?: boolean;
  onlyRenderWhenVisible?: boolean;
  stripParentOfClassTags?: boolean;
  renderOptions?: Partial<MolRenderOptions>;
  csSmilesLimit?: number;
  molLimit?: number;
  mrvLimit?: number;
  finishedCallback?: () => void;
  erroredCallback?: (e: any) => void; // eslint-disable-line @typescript-eslint/no-explicit-any
  useNativeFontRendering?: boolean;
  skipHighlightAlignment?: boolean;
  highlightedAtomNumbers?: number[];
};

type State = {
  gettingChemUtils: boolean;
  molfile: string;
  highlighted: Highlight[];
  options: MolRenderOptions;
  isReady: boolean;
  errorState: boolean;
  inlineSvgStyle: React.CSSProperties;
  showingLargeStructureMsg?: boolean;
};

const MSG_TOO_LARGE = 'too big';

const isMolfile = (structure: string) => {
  return !!/^M\s+END\s*/m.exec(structure);
};

const isMarvinCML = (structure: string) => {
  return !!/<cml/m.exec(structure);
};

const isCDXML = (structure: string) => {
  return !!/<CDXML/m.exec(structure);
};

const isBase64CDX = (structure: string) => {
  return !!/^VmpDRDAxMD/m.exec(structure);
};

const requiresIndigo = (structure: string) => isBase64CDX(structure) || isCDXML(structure);

const removeBOM = (s: string) => {
  return s.replace(/^\uFEFF/, '').replace(/^\u00EF?\u00BB\u00BF/, '');
};

class ChemistryImageComponent extends React.Component<Props, State> {
  static whyDidYouRender = true; // set to true to debug excessive renders (https://github.com/welldone-software/why-did-you-render):

  readonly ref = React.createRef<HTMLDivElement>();
  private mounted = false;
  private updateTimeoutReference;
  private chemUtils: ChemUtils;
  private indigo: StandaloneIndigo;

  private setChemUtils() {
    if (!this.chemUtils && !this.state.gettingChemUtils) {
      this.setState({ gettingChemUtils: true });
      ensureChemUtils().then(chemUtils => {
        // putting chemUtils into state can cause issues with JSONifying state
        this.chemUtils = chemUtils;
        this.mounted && this.setState({ gettingChemUtils: false });
      });
    }
  }

  private setIndigo() {
    if (!this.indigo) {
      // putting indigo into state can cause issues with JSONifying state
      this.indigo = obtainIndigo();
    }
  }

  renderErrorFallback = () => {
    const dataProps = JSON.stringify(this.props);
    return <span className='error-boundary-msg' data-props={dataProps}>Structure cannot be displayed</span>;
  };

  get renderFormat() {
    return this.props.renderOptions?.format ?? 'svgInImg';
  }

  get imgClass() {
    return this.props.renderOptions?.imgAttributes?.class;
  }

  private processHighlights(): Highlight[] {
    if (!this.chemUtils) return;

    const highlighted: Highlight[] = [];
    for (const structure of (this.props.highlightedStructures ?? [])) {
      if (isMarvinCML(structure)) {
        const mrv = new MarvinCML(structure);
        try {
          mrv.parse();
        } catch (ex) {
          console.error('Unable to convert from MarvinCML:', ex);
        }
        const molfile = mrv.getMolfile();
        if (molfile) {
          highlighted.push({ molfile, smiles: null });
        }
      } else if (isMolfile(structure)) {
        const molfile = structure;
        const smiles = null; // chemUtils.molToSmiles(molfile); (actually we don't care)
        highlighted.push({ molfile, smiles });
      } else {
        const smiles = structure;
        const molfile = this.chemUtils.smilesToMol(smiles);
        highlighted.push({ molfile, smiles });
      }
    }
    return highlighted;
  }

  constructor(props: Props) {
    super(props);

    this.state = {
      gettingChemUtils: false,
      molfile: null,
      isReady: false,
      errorState: false,
      highlighted: [],
      options: this.initializeOptions(props),
      inlineSvgStyle: {},
    };
  }

  private initializeOptions(props: Props): MolRenderOptions {
    return {
      marvinCML: null,
      hideExplicitValence: false,
      isRegistration: props.isRegistration,
      useNativeFontRendering: props.useNativeFontRendering,
      skipHighlightAlignment: props.skipHighlightAlignment,
    };
  }

  private prepSmiles(structure: string, options: MolRenderOptions) {
    const molfile = this.chemUtils.smilesToMol(structure);
    options = { ...options, hideExplicitValence: true };
    return { molfile, options };
  }

  private prepMol(structure: string, options: MolRenderOptions) {
    let molfile = structure;

    if (molfileIsValidAndHasAllZeroCoords(molfile)) {
      const smiles = this.chemUtils.molToSmiles(molfile);
      ({ molfile, options } = this.prepSmiles(smiles, options));
    }

    return { molfile, options };
  }

  private prepMarvinCML(structure: string, options: MolRenderOptions) {
    const marvinCML = new MarvinCML(structure);
    marvinCML.parse();
    const molfile = marvinCML.getMolfile();
    options = { ...options, marvinCML };
    return this.prepMol(molfile, options);
  }

  private prepStructure(structure: string, options: MolRenderOptions) {
    const { csSmilesLimit = 1000, molLimit = 500000, mrvLimit = 500000 } = this.props;
    const { showingLargeStructureMsg } = this.state;

    const throwIfSizeExceedsLimit = (limit: number) => {
      if (showingLargeStructureMsg === undefined) {
        if (structure?.length > limit) {
          throw new Error(MSG_TOO_LARGE);
        }
      }
    };

    if (isMarvinCML(structure)) {
      throwIfSizeExceedsLimit(mrvLimit);

      return this.prepMarvinCML(structure, options);
    } else if (isMolfile(structure)) {
      throwIfSizeExceedsLimit(molLimit);

      return this.prepMol(structure, options);
    } else {
      throwIfSizeExceedsLimit(csSmilesLimit);

      return this.prepSmiles(structure, options);
    }
  }

  private cancelUpdateState() {
    if (this.updateTimeoutReference) {
      clearTimeout(this.updateTimeoutReference);
      this.updateTimeoutReference = null;
    }
  }

  /**
   * Called when properties change, or ChemUtils library becomes ready, to update the state with the rendering
   * information.
   */
  private scheduleUpdateState() {
    this.cancelUpdateState();
    // queue this behind other work and allow the Rendering... to show
    this.updateTimeoutReference = setTimeout(this.updateState);
  }

  /**
   * DO NOT USE DIRECTLY, use scheduleUpdateState
   * @returns
   */
  private updateState = async () => {
    // be sure to check this is still the same value after any await call
    const thisUpdateTimeoutReference = this.updateTimeoutReference;
    try {
      if (!this.chemUtils) return;

      let { molfile } = this.state;
      let { width, height, imgUrl } = this.props;
      const { src } = this.props;
      let styleWidth, styleHeight;
      let options = this.initializeOptions(this.props);

      // remove BOM from src
      const content = removeBOM(src);
      if (!molfile) {
        if (requiresIndigo(content)) {
          // double check that we asked for indigo in case somehow src originally didn't need it
          this.setIndigo();
          if (!this.indigo) return;

          const convertResults = await this.indigo.convert(content);
          if (thisUpdateTimeoutReference !== this.updateTimeoutReference) return; // we were cancelled

          const mrv = convertKetToMrv(convertResults.struct, true);
          ({ molfile, options } = this.prepStructure(mrv, options));
        } else {
          ({ molfile, options } = this.prepStructure(content, options));
        }
      }

      if (!molfile || !molfileIsValid(molfile)) {
        throw new Error();
      }

      if (imgUrl && this.props.extractDimensionsFromUrl) {
        const searchParams = new URL(imgUrl, document.baseURI).searchParams;
        width = searchParams.get('width') ? parseFloat(searchParams.get('width')) : undefined;
        height = searchParams.get('height') ? parseFloat(searchParams.get('height')) : undefined;
        const auto_scale = searchParams.get('auto_scale');

        if (auto_scale === '0') {
          // when auto_scale is set to 0, pass the width/height from the image url to the MolImage so it's used to generate
          // the svg, and render the svg to fill the height of the container.
          //
          // Not sure if this is still needed!
          styleWidth = undefined;
          styleHeight = 'calc(100% - 30px)';
        } else {
          styleWidth = (width !== undefined) ? (width + 'px') : undefined;
          styleHeight = (height !== undefined) ? (height + 'px') : undefined;
        }
      }

      const inlineSvgStyle = omitBy({
        width: styleWidth ?? this.props.width,
        height: styleHeight ?? this.props.height,
      }, isNil);

      this.props.renderOptions && Object.assign(options, this.props.renderOptions);
      options.imgAttributes = omitBy({
        width: styleWidth,
        height: styleHeight,
        id: this.props.id,
        alt: this.props.alt,
        ...this.props.renderOptions?.imgAttributes,
        'data-molimage-src': content,
      }, isNil);

      this.setState({
        isReady: true,
        molfile,
        options,
        errorState: false,
        highlighted: this.processHighlights(),
        inlineSvgStyle,
      });

      if (typeof this.props.finishedCallback == 'function') this.props.finishedCallback();
    } catch (e) {
      console.log(e);
      if (e.message === MSG_TOO_LARGE) {
        this.setState({
          showingLargeStructureMsg: true,
          isReady: false,
        });
      } else {
        this.setState({
          errorState: true,
          isReady: true,
        });
      }

      if (typeof this.props.erroredCallback == 'function') {
        this.props.erroredCallback(e);
      } else if (typeof this.props.finishedCallback == 'function') {
        this.props.finishedCallback();
      }
    }
  };

  shouldComponentUpdate(nextProps: Readonly<Props>, nextState: Readonly<State>) {
    const propsToString = (props: Readonly<Props>) => {
      return JSON.stringify(omit(props, ['assignProperties', 'children']));
    };
    return propsToString(nextProps) !== propsToString(this.props) ||
      JSON.stringify(nextState) !== JSON.stringify(this.state);
  }

  componentDidMount() {
    this.mounted = true;
    this.setChemUtils();
    if (requiresIndigo(this.props.src)) {
      this.setIndigo();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, _prevState: Readonly<State>, _snapshot?: unknown) { // eslint-disable-line @typescript-eslint/no-unused-vars
    if ((this.props.src !== prevProps.src && this.props.src) ||
      JSON.stringify(this.props.highlightedStructures) !== JSON.stringify(prevProps.highlightedStructures) ||
      this.props.renderOptions?.format !== prevProps.renderOptions?.format
    ) {
      if (this.state.molfile) {
        this.setState({ molfile: null, errorState: false, isReady: false, showingLargeStructureMsg: false });
      }
    } else if (!this.state.molfile && !this.state.errorState && !this.state.showingLargeStructureMsg) {
      this.scheduleUpdateState();
    }
  }

  componentWillUnmount(): void {
    this.mounted = false;
    this.cancelUpdateState();
  }

  handleClickToRenderLargeStructure = (e: React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    this.setState({ showingLargeStructureMsg: false });
  };

  render() {
    const { isReady, molfile, highlighted, options, errorState, inlineSvgStyle, showingLargeStructureMsg } = this.state;
    const { className = '', width, height, minWidth, minHeight, imgUrl, renderPngForSize, highlightedAtomNumbers } = this.props;

    if (imgUrl) {
      const searchParams = new URL(imgUrl, document.baseURI).searchParams;
      const invalid = searchParams.get('invalid') ? JSON.parse(searchParams.get('invalid')) : undefined;
      if (invalid) {
        return <div className={`ChemistryImage ${this.imgClass}`}>
          <p>
            Invalid Structure - click to view
          </p>
        </div>;
      }
    }
    if (showingLargeStructureMsg) {
      return <div className={`ChemistryImage ${this.imgClass}`} onClick={this.handleClickToRenderLargeStructure}>
        <A
          onClick={this.handleClickToRenderLargeStructure}
          href={'#'}
        >
          Click to render large structure
        </A>
      </div>;
    }
    if (errorState) {
      return this.renderErrorFallback();
    }

    if (showingLargeStructureMsg === false && !isReady) {
      return <div className={`ChemistryImage ${this.imgClass} rendering`}>
        <p>
          Rendering....
        </p>
      </div>;
    }

    const result = <>
      {!isReady && <div className={`ChemistryImage ${this.imgClass} rendering`} ref={this.ref} />}
      {isReady && <div className={`ChemistryImage ${className}`} ref={this.ref}>
        <ErrorBoundary FallbackComponent={this.renderErrorFallback}>
          <MolImage
            mol={molfile}
            highlighted={highlighted}
            highlightedAtomNumbers={highlightedAtomNumbers}
            inlineSvgStyle={inlineSvgStyle}
            width={width}
            height={height}
            minWidth={minWidth}
            minHeight={minHeight}
            renderPngForSize={renderPngForSize}
            structureRenderingMnemonics={CDD.debug?.structureRenderingMnemonics}
            options={options}
            {...(omit(this.props, 'src', 'highlightedStructures', 'width', 'height'))} />
        </ErrorBoundary>
      </div>}
    </>;

    if (this.renderFormat !== 'svg') {
      setTimeout(() => {
        if (this.ref.current && this.props.stripParentOfClassTags) {
          // This is some ugly code that removes attributes from the '.react_component' tag embedding which will be applied to
          // the <img> child. I'd like to not have them emitted as part of the tag in the first place, but too many tests break.
          // we also don't plan on removing that jquery just yet
          // eslint-disable-next-line no-jquery/no-closest,no-jquery/no-jquery-constructor
          const reactComponentTag = $(this.ref.current).closest('.react_component');
          const { imgAttributes = {} } = this.props.renderOptions ?? {};

          Object.keys(imgAttributes).forEach(attr => {
            switch (attr) {
              case 'alt':
                reactComponentTag.removeAttr('alt');
                break;
              case 'class':
                reactComponentTag.removeClass(imgAttributes[attr]);
                break;
            }
          });
        }
      });
    }

    return result;
  }
}

export class ChemistryImage extends React.Component<Props> {
  render() {
    const {
      extractDimensionsFromUrl = true,
      onlyRenderWhenVisible = true,
      ...restProps
    } = this.props;

    const component = <ChemistryImageComponent {...restProps} extractDimensionsFromUrl={extractDimensionsFromUrl} />;

    if (onlyRenderWhenVisible) {
      return (
        <RenderIfVisible>
          {component}
        </RenderIfVisible>
      );
    } else {
      return component;
    }
  }
}

export function convertHighlights(highlightedStructures: string[], chemUtils: ChemUtils): Highlight[] {
  const highlighted: Highlight[] = [];
  for (const structure of (highlightedStructures ?? [])) {
    if (isMarvinCML(structure)) {
      const mrv = new MarvinCML(structure);
      try {
        mrv.parse();
      } catch (ex) {
        console.error('Unable to convert from MarvinCML:', ex);
      }
      const molfile = mrv.getMolfile();
      if (molfile) {
        highlighted.push({ molfile, smiles: null });
      }
    } else if (isMolfile(structure)) {
      const molfile = structure;
      const smiles = null; // chemUtils.molToSmiles(molfile); (actually we don't care)
      highlighted.push({ molfile, smiles });
    } else {
      const smiles = structure;
      const molfile = chemUtils.smilesToMol(smiles);
      highlighted.push({ molfile, smiles });
    }
  }
  return highlighted;
}
