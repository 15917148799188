import React, { useState } from 'react';
import { Link, Menu, MenuItem } from '@mui/material';
import { BioisosterResultsDialog } from '../Bioisoster/BioisosterResultsDialog';
import { BioisostereText, fetchDLBioisoster } from '../Bioisoster/LaunchDLBioisoster';
import { DLError as DLBioisosterError, DLBioisosterResponse } from '../Bioisoster/BioisosterResultsTypes';
import { fetchDLSimilarity, SimilaritySearchText } from '../Similarity/LaunchDLSimilaritySearch';
import { SimilarityResultsDialog } from '../Similarity/SimilarityResultsDialog';
import { DLError as DLSimilarityError, DLSimilarityResponse } from '../Similarity/SimilarityResultsTypes';
import { CDD } from '@/typedJS';

type AILinkProps = {
  callbackSmiles: () => Promise<string>;
  linkText?: string;
  className?: string
  fetchDLBioisoster?: (
    smiles: string,
    count: number,
  ) => Promise<DLBioisosterResponse | DLBioisosterError>;
  fetchDLSimilarity?: (
    smiles: string,
    count: number,
  ) => Promise<DLSimilarityResponse | DLSimilarityError>;

};

export const AILink = (
  props: AILinkProps,
) => {
  if (!CDD.features.enableDL) {
    return null;
  }
  const [aismiles, setAismiles] = useState('');
  const [openBioisoster, setOpenBioisoster] = useState(false);
  const [openSimilarity, setOpenSimilarity] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | Element>(null);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget);
    event.preventDefault();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleBioisostereSelected = () => {
    handleClose();
    (async () => {
      const smiles = await props.callbackSmiles();
      setAismiles(smiles);
      setOpenBioisoster(true);
    })();
  };

  const handleSimilaritySelected = () => {
    handleClose();
    (async () => {
      const smiles = await props.callbackSmiles();
      setAismiles(smiles);
      setOpenSimilarity(true);
    })();
  };

  const open = Boolean(anchorEl);
  return (
    <>
      <Link className={props.className} href='#' onClick={handleClick} underline='none'>
        {props.linkText || 'Launch CDD AI Tools'}
      </Link >
      <Menu
        id='ai-select-menu'
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleBioisostereSelected}><BioisostereText /></MenuItem>
        <MenuItem onClick={handleSimilaritySelected}><SimilaritySearchText /></MenuItem>
      </Menu>
      {openBioisoster && <BioisosterResultsDialog
        open={openBioisoster}
        fetchDLBioisoster={props.fetchDLBioisoster || fetchDLBioisoster}
        smiles={aismiles}
        onClose={() => setOpenBioisoster(false)}
        banExternalLinks={CDD.features.banExternalLinks}
        skipExternalLinkWarning={CDD.features.skipExternalLinkWarning}
        canRegisterMolecules={CDD.features.canRegisterMolecules}
      />
      }
      {openSimilarity && <SimilarityResultsDialog
        open={openSimilarity}
        fetchDLSimilarity={props.fetchDLSimilarity || fetchDLSimilarity}
        smiles={aismiles}
        name={'AI'}
        onClose={() => setOpenSimilarity(false)}
        banExternalLinks={CDD.features.banExternalLinks}
        skipExternalLinkWarning={CDD.features.skipExternalLinkWarning}
        canRegisterMolecules={CDD.features.canRegisterMolecules}
      />}
    </>
  );
};
