/* eslint-disable multiline-ternary, no-nested-ternary, react/forbid-dom-props */

import React from 'react';
import './Mixture.sass';
import {
  Menu,
  MenuItem,
  PopoverPosition,
  Divider,
  Typography,
  Tooltip,
} from '@mui/material';
import { observer } from 'mobx-react';
import { MixturesStore } from './mixturesStore';
import { InputParsingText } from './InputParsingText';
import { ArrangeMixtureComponent } from 'chemical-mixtures/ArrangeMixture';

type Props = {
  store: MixturesStore,
  arrcomp: ArrangeMixtureComponent,
  offsetX: number,
  offsetY: number,
  width: number,
  height: number,
};
type State = {
  menuPos: PopoverPosition;
}

@observer
export class OverlayNode extends React.Component<Props, State> {
  private isMac = navigator.userAgent.includes('Macintosh');

  constructor(props: Props) {
    super(props);

    this.state = {
      menuPos: null,
    };
  }

  public render(): JSX.Element {
    const { store, arrcomp, offsetX, offsetY, width, height } = this.props;
    const { hoverKey, menuOrigin } = store;

    const box = arrcomp.boundary.withOffsetBy(offsetX, offsetY);
    const chkbox = arrcomp.collapseBox?.clone();
    if (chkbox) {
      box.w -= chkbox.w;

      chkbox.offsetBy(box.x, box.y);
      chkbox.y -= 3;
      chkbox.h += 6;
      chkbox.w += 3;
    }

    const keyComp = `comp-${arrcomp.origin}`, keyCheck = `check-${arrcomp.origin}`;
    const isContext = JSON.stringify(arrcomp.origin) == JSON.stringify(menuOrigin);
    const isParsing = JSON.stringify(arrcomp.origin) == JSON.stringify(store.parsing?.origin);
    const bgComp = isContext ? 'rgba(0,0,128,0.2)' : hoverKey == keyComp ? 'rgba(0,0,0,0.05)' : null;
    const bgCheck = hoverKey == keyCheck ? 'rgba(0,0,0,0.05)' : null;

    const contextMenu = isContext && this.buildContextMenu(arrcomp);

    const isEmpty = store.mixture.isEmpty();
    const tooltipHelp = isEmpty && (
      <Typography>
        TAB to create new components.<br/>
        ENTER to edit component details.<br/>
        Shift-ENTER or double click to edit structure.<br/>
        Right click for context menu.<br/>
        Just start typing to search.
      </Typography>
    );

    const divComp = (
      <Tooltip
        title={tooltipHelp}
        arrow
        disableFocusListener
        placement="right"
        >
        <div
          id={`mixtureNode-${[0, ...arrcomp.origin].join('_')}`}
          data-component={JSON.stringify(arrcomp.content)}
          key="overlay-component"
          style={{
            position: 'absolute',
            left: `${box.x}px`,
            top: `${box.y}px`,
            width: `${box.w}px`,
            height: `${box.h}px`,
            backgroundColor: bgComp,
          }}
          onClick={this.handleClick}
          onMouseDown={(event) => event.stopPropagation()}
          onMouseEnter={() => store.setHoverKey(keyComp)}
          onMouseLeave={() => store.setHoverKey(null)}
          onDoubleClick={this.handleDoubleClick}
          onContextMenu={this.handleContextMenu}
          >
          {isEmpty && this.renderStartHere(box.w, box.h)}
          {contextMenu}
        </div>
      </Tooltip>
    );
    const divCheck = chkbox && (
      <div
        key="overlay-hover"
        style={{
          position: 'absolute',
          left: `${chkbox.x}px`,
          top: `${chkbox.y}px`,
          width: `${chkbox.w}px`,
          height: `${chkbox.h}px`,
          backgroundColor: bgCheck,
          cursor: 'pointer',
        }}
        onClick={() => store.toggleCollapseBranch(arrcomp.origin)}
        onMouseEnter={() => store.setHoverKey(keyCheck)}
        onMouseLeave={() => store.setHoverKey(null)}
        >
      </div>
    );
    const divParsing = isParsing && (
      <InputParsingText
        store={store}
        parentBox={box}
        width={width}
        height={height}
        />
    );

    return (<>{divComp}{divCheck}{divParsing}</>);
  }

  private renderStartHere(width: number, height: number): JSX.Element {
    return (
      <div
        className="MixtureEditor-starthere"
        style={{ width: `${width}px`, height: `${height}px`, pointerEvents: 'none' }}
        >
        <div>
          <svg
            width="80"
            height="80"
            viewBox="0 0 21.166666 21.166667"
            >
            <circle
              style={{ fill: 'none', stroke: '#3399FF', strokeWidth: 0.22, strokeLinecap: 'round', strokeDasharray: '0.22,0.66' }}
              cx="10.583333"
              cy="10.583333"
              r="9.8299026" />
            <text
              style={{ fontSize: '5.82873px', lineHeight: 1.25, fontFamily: 'sans-serif', textAlign: 'center', textAnchor: 'middle', strokeWidth: 0.218577, fill: '#3399FF' }}
              x="10.486567"
              y="8.9496946"
              >
              <tspan x="10.486567" y="8.9496946">start</tspan>
              <tspan x="10.486567" y="16.235607">here</tspan>
            </text>
          </svg>
        </div>
      </div>
    );
  }

  handleClick = (event: React.MouseEvent<HTMLElement>): void => {
    const { store, arrcomp } = this.props;
    event.preventDefault();
    event.stopPropagation();
    store.setMenuOrigin(null);
    store.setSelectedOrigin(arrcomp.origin);
  };

  handleDoubleClick = (event: React.MouseEvent<HTMLElement>): void => {
    const { store, arrcomp } = this.props;
    store.openComponentDetail(arrcomp.origin);
    event.stopPropagation();
    event.preventDefault();
  };

  handleContextMenu = (event: React.MouseEvent<HTMLElement>): void => {
    const { store, arrcomp } = this.props;

    event.preventDefault();
    event.stopPropagation();
    this.setState({ menuPos: { left: event.clientX, top: event.clientY } });
    store.setMenuOrigin(arrcomp.origin);
    store.setSelectedOrigin(arrcomp.origin);
    store.setHoverKey(null);
  };

  private buildContextMenu(arrcomp: ArrangeMixtureComponent): JSX.Element {
    const items: JSX.Element[] = [];

    const { store } = this.props;
    const { mixture } = store;
    const { origin } = arrcomp;
    const comp = mixture.getComponent(origin);

    const makeItem = (text: string, shortcut:string, clickAction: () => void) => {
      if (shortcut) {
        shortcut = shortcut.replace(/CmdOrCtrl/g, this.isMac ? 'Cmd' : 'Ctrl');
      }

      const key = text.replace(/\s+/g, '-');
      const clickHandler = (event: React.MouseEvent<HTMLElement>) => {
        clickAction();
        event.stopPropagation();
      };
      items.push((
        <MenuItem key={key} onClick={clickHandler}>
          <div key={key} className="MixtureEditor-contextmenu-line">
            <div>{text}</div>
            {shortcut && <div className="MixtureEditor-contextmenu-shortcut">{shortcut}</div>}
          </div>
        </MenuItem>
      ));
    };

    makeItem('Edit Structure', 'Enter', () => store.actionEditStructure(origin));
    makeItem('Edit Details', 'Shift+Enter', () => store.actionEditDetails(origin));

    items.push((<Divider key="divider1"/>));

    if (origin.length > 0) {
      makeItem('Insert Left', 'CmdOrCtrl+\\', () => store.actionInsertLeft(origin));
    }
    makeItem('Insert Right', 'CmdOrCtrl+/', () => store.actionInsertRight(origin));
    if (origin.length > 0) {
      makeItem('Insert Above', 'CmdOrCtrl+;', () => store.actionInsertAbove(origin));
      makeItem('Insert Below', 'CmdOrCtrl+\'', () => store.actionInsertBelow(origin));
    }

    if (origin.length > 0) {
      items.push((<Divider key="divider2"/>));

      if (origin[origin.length - 1] > 0) {
        makeItem('Move Up', 'CmdOrCtrl+Up', () => store.actionMove(origin, -1));
      }
      if (origin[origin.length - 1] < mixture.getParentComponent(origin).contents?.length - 1) {
        makeItem('Move Down', 'CmdOrCtrl+Down', () => store.actionMove(origin, 1));
      }

      makeItem('Delete', 'Delete', () => store.actionDelete(origin, false));
      if (comp.contents?.length > 0) {
        makeItem('Delete Branch', 'CmdOrCtrl+Delete', () => store.actionDelete(origin, true));
      }
    }

    items.push((<Divider key="divider3"/>));

    makeItem('Copy', 'CmdOrCtrl+C', () => store.actionCopy(origin, false));
    if (comp.contents?.length > 0) {
      makeItem('Copy Branch', 'CmdOrCtrl+Shift+C', () => store.actionCopy(origin, true));
    }
    if (origin.length > 0) {
      const label = comp.contents?.length > 0 ? 'Cut Branch' : 'Cut';
      makeItem(label, 'CmdOrCtrl+X', () => store.actionCut(origin));
    }
    makeItem('Paste', 'CmdOrCtrl+V', () => store.actionPaste());

    return (
      <Menu open={true} anchorReference="anchorPosition" anchorPosition={this.state.menuPos}>
        {items}
      </Menu>
    );
  }
}
