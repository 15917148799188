/* eslint-disable @typescript-eslint/no-explicit-any */
import omit from 'lodash/omit';
import axios, { AxiosError } from 'axios';
import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, reaction, runInAction } from 'mobx';
import { CustomCalculationAutoComplete, CustomCalculationEdit, DoseResponseCalculationEdit, EditValueType, NormalizedCalculationCreate, ReadoutDefinitionEdit, ReadoutDefinitionObject, ReadoutDefinitionType } from '../types';
import { ProtocolReadoutDefinitionsStore } from './protocolReadoutDefinitionsStore';
import { readoutDefinitionService } from '../readoutDefinitionService';
import { ReadoutDefinitionUtils } from '../readoutDefinitionUtils';
import { ModalUtils } from '@/shared/utils/modalUtils';
import { term } from '@/shared/utils/stringUtils';
import { cancelBroadcastError } from '@/ErrorReporting/subscribe';
import { deepClone } from '@/Annotator/data/utils';
import { displayUnhandledErrorDialog } from '@/ErrorReporting/UnhandledErrorDialog';

export class EditProtocolReadoutDefinitionsStore {
  inited = false;

  value: EditValueType = null;
  valueBeforeEdits: EditValueType = null;

  newReadoutDefinitionTemplate: Partial<ReadoutDefinitionEdit> | null = null;
  newDoseResponseTemplate: Partial<DoseResponseCalculationEdit> | null = null;
  newCalculatedReadoutTemplate: Partial<ReadoutDefinitionEdit> | null = null;
  inCustomCalculationAutoComplete: Partial<CustomCalculationAutoComplete> | null = null;

  serverValidationErrors: Record<string, string[]> | null = null;

  mapHelpToContent: Record<string, string> = {};

  calculationOptions = ['IC50', 'IC90', 'IC99', 'EC50', 'EC90'];

  constructor(public readonly root: RootStore, public readonly protocolReadoutDefinitionsStore: ProtocolReadoutDefinitionsStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    if (!readoutDefinitionService.isOnProtocolPage || this.inited) {
      return;
    }

    reaction(() => this.value?.readout_definition_object?.data_type_name, (data_type_name) => {
      const readout_definition_object = this.value?.readout_definition_object;
      if (readout_definition_object) {
        runInAction(() => {
          this.serverValidationErrors = null;
        });

        if (data_type_name === 'Number') {
          // when switching type to Number, init values
          if (!readout_definition_object.display_format) {
            runInAction(() => {
              readout_definition_object.display_format = 'None';
            });
          }
        }
      }
    });

    reaction(() => this.value?.dose_response_calculation_object?.has_custom_minimum_activity, (has_custom_minimum_activity) => {
      const dose_response = this.value?.dose_response_calculation_object;
      if (dose_response) {
        if (has_custom_minimum_activity === false) {
          runInAction(() => {
            // when not using custom minimum activity, clear the value
            dose_response.minimum_inactivity_modifier = null;
            dose_response.inactivity_lower_bound = null;
            dose_response.inactivity_upper_bound = null;
          });
        }
      }
    });

    reaction(() => this.value?.dose_response_calculation_object?.minimum_inactivity_modifier, (minimum_inactivity_modifier) => {
      const dose_response = this.value?.dose_response_calculation_object;
      if (dose_response) {
        runInAction(() => {
          switch (minimum_inactivity_modifier) {
            case '<':
              dose_response.inactivity_lower_bound = null;
              break;

            case '>':
            case '=':
              dose_response.inactivity_upper_bound = null;
          }
        });
      }
    });

    reaction(() => this.value?.dose_response_calculation_object?.has_fit_parameter_constraints, (has_fit_parameter_constraints) => {
      const dose_response = this.value?.dose_response_calculation_object;
      if (dose_response) {
        if (has_fit_parameter_constraints === false) {
          runInAction(() => {
            // when constraints are disabled, clear them
            dose_response.constraints = {};
          });
        }
      }
    });
    this.inited = true;
  }

  async prepareForEditing() {
    if (Object.keys(this.mapHelpToContent).length === 0) {
      const helpTopics = [
        'protocol_conditions',
        'display_formatting',
        'percent_inhibition_calculation_details',
        'percent_of_control_calculation_details',
        'z_score_calculation_details',
        'custom_calculations',
      ];

      const promises: Array<Promise<unknown>> =
        helpTopics.map(topic =>
          readoutDefinitionService.getHelpTopic(topic).then(result => {
            this.mapHelpToContent[topic] = result.data;

            const additionalMappings = {
              'percent_inhibition_calculation_details': ['% inhibition or activation'],
              'percent_of_control_calculation_details': ['% positive control', '% negative control'],
              'z_score_calculation_details': ['z score'],
            };
            (additionalMappings[topic] ?? []).forEach(key => {
              this.mapHelpToContent[key] = result.data;
            });
          }));

      const result = Promise.all(promises);
      result.catch(displayUnhandledErrorDialog);
      return result;
    }
  }

  async reloadAutocomplete() {
    const result = readoutDefinitionService.getCustomCalculationAutoComplete();
    const autocomplete = await result;
    runInAction(() => {
      this.inCustomCalculationAutoComplete = autocomplete.data;
    });
    return result;
  }

  get isOpen() {
    return !!this.value;
  }

  get formState() {
    return {};
  }

  get nameOfCurrentReadout() {
    const v = this.valueBeforeEdits;
    if (v) {
      if (v.readout_definition_object) {
        return v.readout_definition_object.name;
      }
      if (v.dose_response_calculation_object) {
        return v.dose_response_calculation_object.sentenced_intercept_names;
      }
      if (v.custom_calculation_object) {
        const { rows } = this.protocolReadoutDefinitionsStore;
        const row = rows.find(row => row.calculation_id === v.custom_calculation_object.id);
        if (row) {
          return row.name;
        }
      }
    }

    return '';
  }

  get selectedYUnitReadout() {
    const unit_y_id = this.value?.dose_response_calculation_object?.response_readout_definition_id;

    if (unit_y_id) {
      return this.protocolReadoutDefinitionsStore.rows.find(row => (row.id === unit_y_id));
    }
    return null;
  }

  /**
   * Modify the auto complete options so that the current readout definition is not included
   */
  get customCalculationAutoComplete() {
    const autoCompleteOptions = this.inCustomCalculationAutoComplete;
    if (!autoCompleteOptions?.readoutDefinitions) {
      return {};
    }
    const calculationId = this.value?.custom_calculation_object?.id;
    if (calculationId) {
      const calculationIdFromReadout = (r: { id?: number }) => {
        const readout = this.protocolReadoutDefinitionsStore.rows.find(row => (row.id === r.id));
        if (readout) {
          return readout.calculation_id;
        }
        return null;
      };
      autoCompleteOptions.readoutDefinitions = (autoCompleteOptions.readoutDefinitions ?? [])
        .filter(row => calculationIdFromReadout(row) !== calculationId);
    }

    return autoCompleteOptions;
  }

  get availableNormalizedCalculations() {
    return this.newReadoutDefinitionTemplate?.available_normalized_calculations ?? [];
  }

  clearFormulaError() {
    if (this.serverValidationErrors) {
      this.serverValidationErrors = omit(this.serverValidationErrors, 'formula');
    }
  }

  updateReadoutNormalizationNames(oldName = '', newName = '') {
    if (this.serverValidationErrors) {
      this.serverValidationErrors.name = null;
    }
    const update = (calculations: ReadoutDefinitionEdit['available_normalized_calculations']) => {
      if (calculations) {
        for (const normalized_calculation of calculations) {
          const { label } = normalized_calculation;
          const formattedOldName = (oldName ? `${oldName} ` : '') + label;
          const formattedNewName = (newName ? `${newName} ` : '') + label;
          const name = normalized_calculation.name;

          if (!oldName && name === formattedOldName && normalized_calculation.selected) {
            // if this normalization is selected and its name doesn't include the readout definition name,
            // then don't update it as it was likely manually edited.
            continue;
          }

          // if the name of the calculation began with the previous name of the readout definition, update it with the new name
          if (!name || name === formattedOldName) {
            normalized_calculation.name = formattedNewName;
          }
        }
      }
    };

    update(this.value?.readout_definition_object?.available_normalized_calculations);
    update(this.value?.custom_calculation_object?.output_readout_definition_attributes?.available_normalized_calculations);
  }

  async loadNewTemplates() {
    const promises = [];

    if (!this.newReadoutDefinitionTemplate) {
      promises.push(readoutDefinitionService.getNewTemplateForReadoutDefinition().then(result => {
        runInAction(() => { this.newReadoutDefinitionTemplate = result.data; });
      }).catch(error => {
        this.handleError(error);
      }));
    }

    if (!this.newDoseResponseTemplate) {
      promises.push(readoutDefinitionService.getNewTemplateForDoseResponse().then(result => {
        runInAction(() => { this.newDoseResponseTemplate = result.data; });
      }).catch(error => {
        this.handleError(error);
      }));
    }

    if (!this.inCustomCalculationAutoComplete) {
      promises.push(readoutDefinitionService.getCustomCalculationAutoComplete().then(result => {
        runInAction(() => { this.inCustomCalculationAutoComplete = result.data; });
        runInAction(() => { this.newCalculatedReadoutTemplate = result.data as Partial<ReadoutDefinitionEdit>; });
      }).catch(error => {
        this.handleError(error);
      }));
    }
    return Promise.all(promises);
  }

  async handleCreateNewReadoutDefinition() {
    if (!readoutDefinitionService.isOnProtocolPage) {
      return;
    }

    await this.loadNewTemplates();
    await this.prepareForEditing();

    if (!this.newReadoutDefinitionTemplate || !this.newCalculatedReadoutTemplate || !this.newDoseResponseTemplate) {
      return;
    }

    runInAction(() => {
      this.valueBeforeEdits = null;
      this.serverValidationErrors = null;
      this.value = {
        readout_definition_object: deepClone(this.newReadoutDefinitionTemplate),
        custom_calculation_object: deepClone(this.newCalculatedReadoutTemplate),
        dose_response_calculation_object: deepClone(this.newDoseResponseTemplate),
      };
      this.value.readout_definition_object.data_type_name = '' as any;

      if (!this.value.dose_response_calculation_object?.intercept_readout_definitions_attributes) {
        this.value.dose_response_calculation_object.intercept_readout_definitions_attributes = [];
      }
      if (!this.value.dose_response_calculation_object.intercept_readout_definitions_attributes.length) {
        this.value.dose_response_calculation_object.intercept_readout_definitions_attributes.push({} as any);
      }
      this.updateReadoutNormalizationNames();
    });
  }

  async handleEditReadoutDefinition(readoutDefinition: ReadoutDefinitionObject) {
    await this.prepareForEditing();
    await this.loadNewTemplates();
    try {
      const { protocolReadoutDefinitionsStore } = this;

      runInAction(() => {
        this.serverValidationErrors = null;
      });

      const valueForEdit: EditValueType = {};
      switch (ReadoutDefinitionUtils.getType(readoutDefinition)) {
        case 'dose-response': {
          const dose_response_calculation_object = protocolReadoutDefinitionsStore.getDoseResponseForReadout(readoutDefinition);
          if (dose_response_calculation_object) {
            readoutDefinitionService.getDoseResponseForEditing(dose_response_calculation_object.id)
              .then(result => {
                valueForEdit.dose_response_calculation_object = result.data;
                runInAction(() => {
                  this.value = deepClone(valueForEdit);
                  this.valueBeforeEdits = deepClone(this.value);
                });
              });
          }
          break;
        }

        case 'calculated': {
          const custom_calculation_object = protocolReadoutDefinitionsStore.getCustomCalculationForReadout(readoutDefinition);
          if (custom_calculation_object) {
            readoutDefinitionService.getCustomCalculationForEditing(custom_calculation_object.id)
              .then(result => {
                valueForEdit.custom_calculation_object = result.data;
                runInAction(() => {
                  this.value = deepClone(valueForEdit);
                  this.valueBeforeEdits = deepClone(this.value);
                });
                this.updateReadoutNormalizationNames('', readoutDefinition.name);
              });
          }
          break;
        }

        default: // readout
          readoutDefinitionService.getReadoutDefinitionForEditing(readoutDefinition.id)
            .then(result => {
              valueForEdit.readout_definition_object = result.data;
              runInAction(() => {
                this.value = deepClone(valueForEdit);
                this.valueBeforeEdits = deepClone(this.value);
              });
              this.updateReadoutNormalizationNames('', readoutDefinition.name);
            });
          break;
      }
    } catch (error) {
      this.handleError(error);
    }
  }

  handleDeleteDataCalculation(index: number) {
    if (this.value?.dose_response_calculation_object) {
      runInAction(() => {
        const attributes = this.value?.dose_response_calculation_object?.intercept_readout_definitions_attributes;
        const attribute = attributes[index];
        if (attribute.id) {
          attribute._destroy = true;
        } else {
          attributes.splice(index, 1);
        }
        if (attributes.filter(v => !v._destroy).length === 0) {
          attributes.push({ name: null });
        }
      });
    }
  }

  handleAddDataCalculation() {
    if (this.value?.dose_response_calculation_object) {
      runInAction(() => {
        this.value?.dose_response_calculation_object.intercept_readout_definitions_attributes.push({} as any);
      });
    }
  }

  handleCancelEdit() {
    this.value = null;
    this.valueBeforeEdits = null;
    this.serverValidationErrors = null;
  }

  handleSubmit = async (type?: ReadoutDefinitionType) => {
    const parseCalculations = (inReadout: Partial<ReadoutDefinitionEdit> | Partial<CustomCalculationEdit>) => {
      const readout: ReadoutDefinitionEdit = (inReadout as CustomCalculationEdit).output_readout_definition_attributes
        ? (inReadout as CustomCalculationEdit).output_readout_definition_attributes
        : inReadout;

      const normalized_calculations_attributes = [];
      const scope = readout.normalization_scope;
      const calculations = readout.available_normalized_calculations ?? [];

      for (const calc of calculations) {
        const newCalc: Partial<NormalizedCalculationCreate> = {
          ...calc,
          scope: scope ?? '',
        };

        if (!calc.selected || !scope) {
          newCalc._destroy = true;
        } else {
          newCalc.selected = true;
        }

        if (readout.invert_normalized_value_selected && (calc.type !== 'ZScoreCalculation')) {
          newCalc.invert_normalized_value = true;
        } else {
          newCalc.invert_normalized_value = false;
        }

        if (newCalc.mean_type_options?.length && !newCalc.mean_type) {
          // default to first option if none selected
          newCalc.mean_type = newCalc.mean_type_options[0][1];
        }

        normalized_calculations_attributes.push(newCalc);
      }
      return normalized_calculations_attributes;
    };

    try {
      let value = this.value;
      type = type ?? ReadoutDefinitionUtils.getType(value);
      if (type) {
        switch (type) {
          case 'readout': {
            value = { readout_definition_object: deepClone(value.readout_definition_object) };
            if (value.readout_definition_object.id && this.valueBeforeEdits) {
              const previous_protocol_condition = this.valueBeforeEdits?.readout_definition_object.protocol_condition;
              const protocol_condition = value.readout_definition_object.protocol_condition;
              if (this.valueBeforeEdits.readout_definition_object.id && previous_protocol_condition !== protocol_condition) {
                const confirmationMessage = `Are you sure you want to ${protocol_condition ? 'mark' : 'unmark'} this ${term('readout_definition')} as ${term('protocol.with_indefinite_article')} condition and delete any custom calculations and saved searches that reference it?`;
                if (!await ModalUtils.showModal(confirmationMessage, { okLabel: 'Yes', cancelLabel: 'No' })) {
                  return;
                }
              }
            }

            value.readout_definition_object.normalized_calculations_attributes = parseCalculations(value.readout_definition_object);
            break;
          }

          case 'dose-response':
            value = { dose_response_calculation_object: value.dose_response_calculation_object };
            break;

          case 'calculated':
            value = { custom_calculation_object: value.custom_calculation_object };
            value.custom_calculation_object.output_readout_definition_attributes.normalized_calculations_attributes = parseCalculations(value.custom_calculation_object);
            break;
        }
      }

      // perhaps rethink this. The problem is that the service may call one of three different APIs
      // each returning different API results. So this makes typing a challenge. Instead we could
      // break this up into three separate methods, one for each readout type.
      const result = await readoutDefinitionService.updateReadoutDefinition(value);
      this.handleCancelEdit();
      await this.reloadAutocomplete();
      this.protocolReadoutDefinitionsStore.loadReadoutDefinitions();

      const callback = (result as any).data?.callback;
      if (callback) {
        // eslint-disable-next-line security/detect-eval-with-expression, no-eval
        eval(callback);
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  handleDelete = async () => {
    try {
      if (await ModalUtils.confirmDelete(`this ${term('readout')}`)) {
        const result = await readoutDefinitionService.deleteReadoutDefinition(this.value);
        const callback = (result as any).data?.callback;
        if (callback) {
          // eslint-disable-next-line security/detect-eval-with-expression, no-eval
          eval(callback);
        }

        this.handleCancelEdit();
        this.protocolReadoutDefinitionsStore.loadReadoutDefinitions();
      }
    } catch (error) {
      this.handleError(error);
    }
  };

  private handleError(error: ErrorEvent | AxiosError) {
    if (axios.isAxiosError(error)) {
      if (error.response?.status === 400) {
        cancelBroadcastError(error);

        // the errors handled directly by our UI (showing error text under the input field:)
        const handledErrorKeys = [
          'formula',
          'name',
        ];
        const hasUnhandledError = Object.keys(error.response.data).some(key => !handledErrorKeys.includes(key));

        const base = (error.response.data as any).base ?? error.response.data['output_readout_definition.base'] ??
          (hasUnhandledError ? ['An error occurred.', ...Object.values(error.response.data)] : null);

        if (base) {
          ModalUtils.showModal(base.join('\n'), {
            title: 'Error',
            noCancelOption: true,
          });
        } else {
          runInAction(() => {
            this.serverValidationErrors = error.response.data as Record<string, string[]>;
          });
        }
      } else {
        displayUnhandledErrorDialog(error);
      }
    } else {
      console.error(error);
      throw error;
    }
  }
}
