import { CDD } from '@/typedJS';
import capitalize from 'lodash/capitalize';
import stripTags from 'striptags';

/**
 * A collection of string utilities extracted from application.js, which directly adds these to
 * String.prototype. Extending native object prototypes is generally considered to be a bad practice
 * and this approach requires hidden dependencies (importing application.js into a story, for example).
 * Simple functions do the same job.
 */
export const titleize = (val: string) => {
  return val
    .split(' ')
    .map(function (term) {
      return capitalize(term);
    })
    .join(' ');
};

export const unescapeHTML = (val: string) => {
  return stripTags(val)
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&#39;/g, "'")
    .replace(/&amp;/g, '&');
};

export const escapeHTML = (val: string) => {
  return val.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
};

export const term = (key: string, makeTitle = false) => {
  const modifier = key.split('.')[1];
  if (modifier === 'with_indefinite_article') {
    key = key.replace('.with_indefinite_article', '');
  }
  const dictionary = CDD?.Terminology?.dictionary ?? {};
  let result = dictionary[key] ?? key.replace('.other', 's');
  if (makeTitle) {
    result = titleize(result);
  }
  if (modifier === 'with_indefinite_article') {
    result = `${/^[aeiou]/i.test(result) ? 'an' : 'a'} ${result}`;
  }
  return result;
};

const termInDictionary = (key: string) => {
  const dictionary = CDD?.Terminology?.dictionary ?? {};
  return key in dictionary;
};

// if key as lowercase is in dictionary then translate, otherwise do nothing to the string
export const translateCaseInsensitive = (key: string, makeTitle = false) => {
  const safeKey = key.toLowerCase();
  return termInDictionary(safeKey) ? term(safeKey, makeTitle) : key;
};

export const pluralize = (count: number, name: string) => {
  return `${count} ${name}${count === 1 ? '' : 's'}`;
};

/**
 *
 * https://apidock.com/rails/String/humanize
 * Capitalizes the first word, turns underscores into spaces, and (by default)strips a trailing ‘_id’ if present
 */

export const humanize = (val: string) => {
  val = val.toLowerCase();
  if (val.endsWith('_id')) {
    val = val.substring(0, val.length - 3);
  }
  return capitalize(val.replace(/_/g, ' '));
};

export const compareCaseInsensitive = (
  a: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  b: any, // eslint-disable-line @typescript-eslint/no-explicit-any
  sortDirection: 'asc' | 'desc' = 'asc',
) => {
  const toString = (value: any) => { // eslint-disable-line @typescript-eslint/no-explicit-any
    return (value ?? '').toString();
  };
  const comparisonResult = toString(a).localeCompare(toString(b), undefined, {
    sensitivity: 'base',
    numeric: true,
  });

  if (sortDirection === 'asc') {
    return comparisonResult;
  } else {
    return -comparisonResult;
  }
};

export const toSentence = (
  array: string[],
  options: {
    wordsConnector?: string;
    twoWordsConnector?: string;
    lastWordConnector?: string;
  } = {},
) => {
  const defaultOptions = {
    wordsConnector: ', ',
    twoWordsConnector: ' and ',
    lastWordConnector: ', and ',
  };

  // Merge default options with provided options
  options = Object.assign({}, defaultOptions, options);

  switch (array.length) {
    case 0:
      return '';
    case 1:
      return array[0].toString();
    case 2:
      return (
        array[0].toString() + options.twoWordsConnector + array[1].toString()
      );
    default: {
      let sentence = '';
      const lastElementIndex = array.length - 2;
      array.slice(0, -1).forEach((part, index) => {
        sentence += part.toString();
        sentence +=
          index === lastElementIndex
            ? options.lastWordConnector
            : options.wordsConnector;
      });
      sentence += array[array.length - 1].toString();
      return sentence;
    }
  }
};
export { stripTags };
