import React from 'react';
import { ButtonDef } from '../DDForm/types/buttonDef';
import { layoutBuilder } from '../DDForm/layoutBuilder';
import { Icon } from '@mui/material';
import { Img } from '@/shared/components/sanitizedTags.js';
import HelpIcon from 'ASSETS/images/cdd30/icons/help.png';

import './cddElements.sass';
import { TypographyDef } from '../DDForm/types/typographyDef';
import { DeleteDialogButton } from '@/components/Buttons/DialogButtons';

/**
 * A collection of functions that return JSON objects which can be rendered in DDForm
 */

const deleteIconButtonLayout = (
  option: Partial<ButtonDef> & Pick<ButtonDef, 'key' | 'label'>,
) => {
  return layoutBuilder.button({
    buttonType: 'icon',
    className: 'delete-icon-button',
    width: 'default',
    label: '⊝',
    tooltip: 'Remove item',
    ...option,
  });
};

const groupIconButtonLayout = (
  option?: Partial<ButtonDef>,
) => {
  return layoutBuilder.button({
    key: 'icon',
    buttonType: 'icon',
    width: 'default',
    label: '👥',
    ...option,
  });
};

const helpIconButtonLayout = (
  option: Partial<TypographyDef> & Pick<ButtonDef, 'label'>,
) => {
  return layoutBuilder.typography({
    label: <><Icon className='help-icon'><Img src={HelpIcon} /></Icon></>,
    width: '1rem',
    ...option,
  });
};

export const deleteButtonLayout = (props: Partial<ButtonDef> & Pick<ButtonDef, 'onClickButton'>) => {
  return layoutBuilder.custom({
    render: (field) => <DeleteDialogButton
      key={field.renderKey ?? field.key ?? field.id}
      id={props.id ?? props.key}
      disabled={props.disabled}
      hidden={props.visible === false}
      onClick={props.onClickButton}
      tooltip={props.tooltip}
      tooltipProps={props.tooltipProps}
    />,
  });
};

export const CDDElements = {
  deleteIconButton: deleteIconButtonLayout,
  groupIconButton: groupIconButtonLayout,
  helpIconButton: helpIconButtonLayout,
  deleteButton: deleteButtonLayout,
};
