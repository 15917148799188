import React from 'react'
import {
  Checkbox,
  Button,
} from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { errorTheme } from '@/shared/components/theme.ts'

export default class ConfirmDeleteButton extends React.Component {
  // Accepts confirmationMessage, buttonText and buttonProps
  constructor(props) {
    super(props)
    this.state = {
      confirmed: false,
      fired: false,
      buttonProps: this.buttonProps(),
    }
  }

  buttonProps = () => {
    return { variant: 'contained', color: 'primary', ...this.props.buttonProps }
  }

  toggleCheckbox = () => {
    this.setState((state, props) => ({ confirmed: !state.confirmed }))
  }

  handleButtonClick = (e) => {
    this.setState({ fired: true })
    this.props.buttonProps?.onClick?.(e)
    return false
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
        <ThemeProvider theme={errorTheme}>
          <div className='checkbox-and-button'>
            <span className='checkboxAndLabel'>
              <Checkbox
                color="primary"
                checked={this.state.confirmed}
                onChange={this.toggleCheckbox}
                disabled={this.state.fired}
                className='ConfirmDeleteCheckbox'
              />
              {this.props.confirmationMessage}
            </span>
            <Button
              {...this.state.buttonProps}
              disabled={!this.state.confirmed || this.state.fired}
              onClick={this.handleButtonClick}
              className='DeleteButton'
            >
              {this.props.buttonText}
            </Button>
          </div>
        </ThemeProvider>
      </React.Fragment>
    )
  }
}

ConfirmDeleteButton.defaultProps = {
  confirmationMessage: 'Really delete this?',
  buttonText: 'Delete',
}
