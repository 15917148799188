import React from 'react';
import { BioisosterFragment, BioisosterSettings, ReferenceStructure } from './BioisosterResultsTypes';
import { BioisosterView, ReferenceView } from './BioisosterView';
import { ExpandableGroup } from '../layout';

export const BioisosterResults = (props: {
  bioisosterFragments: Array<BioisosterFragment>;
  settings: BioisosterSettings
  reference: ReferenceStructure;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
}) => {
  const { bioisosterFragments, settings, reference, canRegisterMolecules, banExternalLinks } = props;
  return (
    <>{
      bioisosterFragments.map((bioisosterFragment, key) => {
        return (
          <BioisosterFragmentGrid
            reference={reference}
            bioisosterFragment={bioisosterFragment}
            settings={settings}
            banExternalLinks={banExternalLinks}
            canRegisterMolecules={canRegisterMolecules}
            key={key}
          />
        );
      })
    }</>
  );
};

const BioisosterFragmentGrid = (props: {
  reference: ReferenceStructure;
  bioisosterFragment: BioisosterFragment;
  settings: BioisosterSettings;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
}) => {
  const { reference, bioisosterFragment, settings } = props;
  const { atomIdx, fragment, bioisosters } = bioisosterFragment;
  const groupNode = (
    <ReferenceView
      reference={reference}
      highlightedAtomNumbers={atomIdx.map((atomIdx) => atomIdx + 1)}
      settings={settings}
    />
  );
  const group = bioisosters.map((bioisoster, key) => (
    <BioisosterView
      bioisoster={bioisoster}
      banExternalLinks={props.banExternalLinks}
      canRegisterMolecules={props.canRegisterMolecules}
      reference={reference}
      key={key}
      settings={settings}
    />
  ));

  return (
    <ExpandableGroup
      key={fragment}
      groupNode={groupNode}
      group={group}
    />
  );
};
