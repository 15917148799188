import { ChangePassword } from './components/ChangePassword/ChangePassword';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const w = window as unknown as { CDD: any };

const CDD = w.CDD ?? {};
w.CDD = CDD;

CDD.Component = {
  Class: {
    ChangePassword,
  },
  Instance: {},
};
