import { FieldIdType, FormDefinition, FormLayout, FormLayoutType, FormSection } from '@/Annotator/data/forms';
import { OntologyTemplate, TemplateGroup } from '@/Annotator/data/templates';
import { deepClone, keyPropGroup } from '@/Annotator/data/utils';
import { term } from '@/shared/utils/stringUtils';
import { blankFormTemplate } from '../components/editor/constants';
import { FieldDefinitionsMap, FormDefinitionForEditing, FormLayoutForEditing, FormSectionForEditing, ProtocolFormDetails, TemplateAssignmentLookup } from '../types';
import omit from 'lodash/omit';

import { v4 as uuidv4 } from 'uuid';

export class EditFormUtils {
  static readonly sidebarIdPrefix = 'sidebar-';
  static readonly dragLabelFromSidebarId = 'drag-from-sidebar-label';

  static getUniqueLayoutId(
    prefix: ('synthetic-' | 'sidebar-') = 'synthetic-') {
    return `${prefix}${uuidv4()}`;
  }

  static isSidebarItem(item: FormLayoutForEditing) {
    return item?.layoutID?.startsWith(this.sidebarIdPrefix);
  }

  static isLabel(item: FormLayout) {
    if (!item) {
      return false;
    }
    return item.layoutType === FormLayoutType.Cell &&
      (item.label !== null && item.label !== undefined) &&
      !this.isField(item);
  }

  static isField(item: FormLayout) {
    if (!item) {
      return false;
    }
    return item.layoutType === FormLayoutType.Cell && (!!item.fieldID || !!item.ontologyAssn);
  }

  static getTemplateAssignmentKey(layout: FormLayout) {
    if (layout.ontologyAssn) {
      return keyPropGroup(layout.ontologyAssn[0], layout.ontologyAssn.slice(1));
    }
    return null;
  }

  static iterateThroughForms(value: FormDefinitionForEditing, callback: (data: ProtocolFormDetails) => void) {
    // first ensure that all the forms are present
    ['protocol_form', 'readout_form', 'run_form']
      .forEach(key => {
        if (!value[key] || !value[key].sections?.length) {
          value[key] = { ...blankFormTemplate[key] };
        }
      });

    const formDetails: Array<ProtocolFormDetails> = [
      {
        formName: 'protocol_form',
        form: value.protocol_form,
        requiredFields: [{
          layoutType: FormLayoutType.Cell,
          fieldID: 'protocol_name',
          label: `${term('protocol', true)} Name`,
          isRequired: true,
        }],
      },
      // skipping for now:
      // {
      //   form: value.readout_form,
      //   fieldDefinitions: fieldDefinitionsMap.readout_form,
      // },
      {
        formName: 'run_form',
        form: value.run_form,
        requiredFields: [{
          layoutType: FormLayoutType.Cell,
          fieldID: 'run_date',
          label: `${term('run', true)} Date`,
          isRequired: true,
        }],
      },
    ];
    formDetails.forEach(formDetail => {
      callback(formDetail);
    });
  }

  static iterateThroughFormFields(value: FormDefinitionForEditing,
    callback: (layout: FormLayoutForEditing, data: ProtocolFormDetails) => 'delete' | void) {
    this.iterateThroughForms(value, (formDetail) => {
      const iterateThroughFields = (layout: (FormSectionForEditing | FormLayoutForEditing)) => {
        const layoutType = ('layoutType' in layout) ? layout.layoutType : null;
        if (layout.contents) {
          for (let i = 0; i < layout.contents.length; i++) {
            const itemResult = callback(layout.contents[i], formDetail);
            if (itemResult === 'delete') {
              layout.contents.splice(i, 1);
              --i;
            } else {
              if (iterateThroughFields(layout.contents[i]) === 'delete') {
                layout.contents.splice(i, 1);
                --i;
              }
            }
          }
          if (layout.contents.length === 0 && layoutType === FormLayoutType.Row) {
            return 'delete'; // empty rows should be deleted
          }
        }
      };

      formDetail.form.sections.forEach(section => {
        iterateThroughFields(section);
      });
    });
  }

  static buildFieldLookupMapFromTemplates(templates: Array<OntologyTemplate>) {
    const result: TemplateAssignmentLookup = {};

    const addAssignments = (template: OntologyTemplate, group: TemplateGroup, groups:Array<string> = []) => {
      group.assignments.forEach(assignment => {
        const key = keyPropGroup(assignment.propURI, groups);
        result[key] = { template, assignment };
      });
      (group.subGroups ?? []).forEach(group => addAssignments(template, group, [group.groupURI, ...groups]));
    };

    templates.forEach(template => {
      addAssignments(template, template.root, []);
    });
    return result;
  }

  static ensureIds(value: FormDefinition) {
    this.iterateThroughFormFields(value, layout => {
      if (!layout.layoutID) {
        if (layout.fieldID) {
          layout.layoutID = '' + layout.fieldID;
        } else if (layout.ontologyAssn?.length) {
          layout.layoutID = this.getTemplateAssignmentKey(layout);
        } else {
          layout.layoutID = this.getUniqueLayoutId();
        }
      }
    });
  }

  static collapseLabeledFields(value: FormDefinition) {
    this.iterateThroughFormFields(value, layout => {
      if (layout.layoutType === FormLayoutType.Row) {
        let i = 0;
        while (i < layout.contents.length - 1) {
          const item = layout.contents[i];
          if (this.isLabel(item)) {
          // if this is a label that is followed by a field with no label, then combine them
            const nextItem = layout.contents[i + 1];
            if (this.isField(nextItem) && !nextItem.label) {
              nextItem.label = item.label;
              layout.contents.splice(i, 1);
              continue;
            }
          }
          i++;
        }
      }
    });
  }

  static expandLabelsAndAdjustColSpans(value: FormDefinition) {
    this.iterateThroughFormFields(value, layout => {
      if (layout.layoutType === FormLayoutType.Row) {
        const contents = [] as Array<FormLayout>;
        layout.contents.forEach(item => {
          const span = 6 / layout.contents.length;

          if (item.label && EditFormUtils.isField(item)) {
            contents.push({
              layoutType: FormLayoutType.Cell,
              span: 1,
              label: item.label,
              isRequired: !!item.isRequired,
            });
            contents.push({
              ...(omit(item, ['label'])),
              span: span - 1,
            });
          } else {
            contents.push({
              ...item,
              span,
            });
          }
        });
        layout.contents = contents;
      }
    });
  }

  /**
   * Ensure that all the labels are up to date with the vault definitions.
   * Must be called AFTER labels and fields are collapsed
   */
  static updateLabelsAndRemoveOrphans(
    value: FormDefinition,
    fieldDefinitionsMap: FieldDefinitionsMap,
    assignmentLookup: TemplateAssignmentLookup,
  ) {
    this.iterateThroughFormFields(value, (layout, details) => {
      if (layout.ontologyAssn?.length > 0) {
        const key = keyPropGroup(layout.ontologyAssn[0], layout.ontologyAssn.slice(1));
        if (assignmentLookup[key]) {
          layout.label = assignmentLookup[key].assignment.name;
        } else {
          return 'delete';
        }
      } else {
        if (typeof layout.fieldID === 'number') {
          const fieldDef = fieldDefinitionsMap[details.formName].find(f => f.id === layout.fieldID);
          if (fieldDef) {
            layout.label = fieldDef.name;
          } else {
            return 'delete';
          }
        } else {
          switch (layout.fieldID) {
            case 'run_date':
              layout.label = `${term('run', true)} Date`;
              break;
            case 'protocol_name':
              layout.label = `${term('protocol', true)} Name`;
              break;
          }
        }
      }
    });
  }

  static updateSchemaPrefixes(value: FormDefinition, assignmentLookup: TemplateAssignmentLookup) {
    const formPrefixes = {
      protocol_form: null,
      readout_form: null,
      run_form: null,
    };

    this.iterateThroughFormFields(value, (layout, details) => {
      if (layout.ontologyAssn?.length > 0) {
        const key = keyPropGroup(layout.ontologyAssn[0], layout.ontologyAssn.slice(1));
        if (assignmentLookup[key]) {
          formPrefixes[details.formName] = assignmentLookup[key].template.schemaPrefix;
        }
      }
    });

    Object.keys(formPrefixes).forEach(key => {
      if (formPrefixes[key] === null) {
        delete value[key].schemaPrefix;
      } else {
        value[key].schemaPrefix = formPrefixes[key];
      }
    });
  }

  static ensureRequiredFields(value: FormDefinitionForEditing, fieldDefinitionsMap?: FieldDefinitionsMap) {
    this.iterateThroughForms(value, ({ form, requiredFields, formName }) => {
      // fix up any missing values
      if (!form.sections) {
        form.sections = [];
      }
      if (!form.sections.length) {
        form.sections = [{
          name: null,
          contents: [],
        }];
      }

      form.sections.forEach(section => {
        section.contents = section.contents ?? [];
        if (!section.contents.length) {
          section.contents = [{
            layoutType: FormLayoutType.Table,
            contents: [],
          }];
        }
      });

      (fieldDefinitionsMap?.[formName] ?? []).forEach(fieldDef => {
        if (fieldDef.required_group_number) {
          requiredFields.push({
            layoutType: FormLayoutType.Cell,
            fieldID: fieldDef.id,
            label: fieldDef.name,
            layoutID: '' + fieldDef.id,
          });
        }
      });
      const formContainsField = (fieldID: FieldIdType) => {
        const searchLayoutForField = (fieldID: FieldIdType, layout: FormLayout | FormSection): boolean => {
          if ((layout as FormLayout).fieldID === fieldID) {
            return true;
          }
          if (layout.contents) {
            for (const child of layout.contents) {
              if (searchLayoutForField(fieldID, child)) {
                return true;
              }
            }
          }
          return false;
        };

        for (const section of form.sections) {
          if (searchLayoutForField(fieldID, section)) {
            return true;
          }
        }
      };

      requiredFields && requiredFields.forEach(layout => {
        if (!formContainsField(layout.fieldID)) {
          const rows = form.sections[0]?.contents?.[0]?.contents ?? [];
          rows.push({
            layoutID: this.getUniqueLayoutId(),
            layoutType: FormLayoutType.Row,
            contents: [{
              layoutType: FormLayoutType.Cell,
              ...layout,
            }],
          });
        }
      });
    });
  }

  static processFormForEditing(
    value: FormDefinition,
    fieldDefinitionsMap: FieldDefinitionsMap,
    assignmentLookup: TemplateAssignmentLookup,
  ) {
    const result = deepClone(value) as FormDefinitionForEditing;
    this.collapseLabeledFields(result);
    this.updateLabelsAndRemoveOrphans(result, fieldDefinitionsMap, assignmentLookup);
    this.ensureRequiredFields(result, fieldDefinitionsMap);
    this.ensureIds(result);
    return result;
  }

  /**
   * Convert a form in editing mode to one that can be persisted and displayed.
   * This means expanding the label/field pairs into separate cells and removing the synthetic IDs
   */
  static processFormForSerialization(value: FormDefinitionForEditing) {
    if (value) {
      const result = deepClone(value);
      this.expandLabelsAndAdjustColSpans(result);
      this.iterateThroughFormFields(result, layout => {
        delete layout.layoutID;
      });

      return result;
    }
    return value;
  }
}
