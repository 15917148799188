import axios from 'axios';
import { pathFor } from '@/shared/utils/routes.js';
import camelCaseKeys from '@/shared/utils/camelCaseKeys.js';
import activeAjax from 'ASSETS/javascripts/activeAjax.js';
import { CDD } from '@/typedJS';
import { MarvinCML } from '@cdd/ui-kit/lib/molRendering/v2/MarvinCML';
import { ensureChemUtils } from '@/shared/utils/chemUtils';

const NAME_LOOKUP = {
  '13965-03-2': 'MFCD00009593', // https://pubchem.ncbi.nlm.nih.gov/compound/6102075
  '51364-51-3': 'MFCD00013310', // https://pubchem.ncbi.nlm.nih.gov/compound/9811564
  'tbab': 'MFCD00011633', // https://pubchem.ncbi.nlm.nih.gov/compound/74236
  'diphenylphosphorylazide': 'MFCD00001987', // https://pubchem.ncbi.nlm.nih.gov/compound/123414
};

const NAME_SMILES = {
  '26299-14-9': 'C1C=C[NH+]=CC=1.O=[Cr](=O)=O.[Cl-]',
  'pd(dppf)cl2': '[CH-]1C=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3.[CH-]1C=CC=C1P(C2=CC=CC=C2)C3=CC=CC=C3.Cl[Pd]Cl.[Fe+2]',
  '870987-63-6': 'CC(C)(C)C1=CC(=NC=C1)C2=NC=CC(=C2)C(C)(C)C.C1=CC(=NC=C1C(F)(F)F)C2=C(C=C(C=[C-]2)F)F.C1=CC(=NC=C1C(F)(F)F)C2=C(C=C(C=[C-]2)F)F.F[P-](F)(F)(F)(F)F.[Ir+3]',
};

async function getMoleculeMrvByName(name: string): Promise<string | null> {
  const smiles = NAME_SMILES[name.toLowerCase()];
  if (smiles) {
    await ensureChemUtils();
    const structure = window.chemUtils.smilesToMol(smiles);
    const mrv = MarvinCML.fromMolfile(structure);
    return mrv.serialize();
  }

  activeAjax.start();
  const url = `/${CDD.ActiveDataContext.toContextParam}/name_to_structure`;
  name = NAME_LOOKUP[name.toLowerCase().replace(/\s/g, '')] || name;
  const response = await axios.get(url, { params: { text: name } });
  activeAjax.stop();
  const structure = response.data?.structure;

  if (!structure) {
    return null;
  }
  const mrv = MarvinCML.fromMolfile(structure);
  return mrv.serialize();
}

// First try to find it by ID.  Then try to find it by name.
const getMoleculeMrv = (identifier) => {
  return getMoleculeMrvById(identifier).catch(() => getMoleculeMrvByName(identifier));
};

const getMoleculeMrvById = (id) => {
  return getMoleculeDataById(id).then(data => data.mrv);
};

const getMoleculeDataById = (id) => {
  return axios.get(
    `${pathFor({ type: 'molecule', id: id.trim() })}`,
    { params: { format: 'json' } },
  ).then(response => {
    return camelCaseKeys(response.data);
  });
};

const getMoleculeProperties = (mrv) => {
  return axios.post(
    CDD.StructureEditor.marvin4JSServices().detailws,
    {
      exportStructure: true,
      exportFormat: 'name',
      singleFragmentMode: false,
      structure: mrv,
    },
  ).then(response => {
    const data = response.data[0];
    const { formula, mass } = data;
    const { structure } = data.structure;
    return {
      formula,
      molecularWeight: mass,
      iupacName: structure,
    };
  });
};

export {
  getMoleculeMrv,
  getMoleculeDataById,
  getMoleculeMrvByName,
  getMoleculeProperties,
};
