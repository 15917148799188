/* eslint-disable @typescript-eslint/no-explicit-any */
import { CDD } from '@/typedJS';
import { MenuItem, Select, TextField, Autocomplete } from '@mui/material';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Wrapper components for MUI Select, Textfield (with select attribute) and Autocomplete that allow for testing by
 * displaying an interactive 'select' element that is (mostly) hidden from the user. The 'select' element is only shown
 * when running in test mode, and its selections are linked to the MUI component.
 *
 * You can use these components in place of the MUI components in your code, and they will work the same way.
 *
 * The only difference is that you can use standard capybara methods to interact with select and autocomplete fields.
 *
 * Implements TestableSelect, TestableTextFieldSelect, TestableAutocomplete
 *
 */

type SelectProps = React.ComponentProps<typeof Select> & { title?: string, placeholder?: string, applyClassToTag?: boolean };
type TextFieldProps = React.ComponentProps<typeof TextField> & { title?: string, placeholder?: string, applyClassToTag?: boolean };
type AutocompleteProps = React.ComponentProps<typeof Autocomplete> & { title?: string, placeholder?: string, applyClassToTag?: boolean };

export const showTestableField = () => CDD.testEnvironment || location.search.includes('showTestableField');

const normalizeValue = (value: any) => {
  return (!value || value === ' ') ? '' : value;
};

const normalizeLabel = (value: any) => {
  const extractTextFromReactElement = (value: any) => {
    if (!React.isValidElement(value)) {
      return typeof value === 'string' ? value : '';
    }

    // Extract the children of the React element
    const { children } = value.props as any;

    // If there are no children, return an empty string
    if (!children) {
      return '';
    }

    // If children is a string, return it
    if (typeof children === 'string') {
      return children;
    }

    // If children is an array, recursively extract text from each child
    if (Array.isArray(children)) {
      return children.map(extractTextFromReactElement).join('');
    }

    // If children is a React element, recursively extract its text
    return extractTextFromReactElement(children);
  };
  return extractTextFromReactElement(value);
};

const extractOptionsFromSelect = (props: SelectProps | TextFieldProps) => {
  return React.Children.map(props.children, (child) => {
    // Check if the child is a MenuItem
    if (React.isValidElement(child) && child.type === MenuItem) {
      return {
        value: normalizeValue(child.props.value),
        label: normalizeLabel(child.props.children),
      };
    }
    return null;
  }).filter(item => item !== null); // Filter out nulls
};

const renderTestablePair = (a: JSX.Element, b: JSX.Element) => {
  return <div className="testable-select-container" style={{ position: 'relative' }}>
    <div className='mui-control'>
      {a}
    </div>
    <div className='select-control'>
      {b}
    </div>
  </div>;
};

const renderMenu = (props: SelectProps | TextFieldProps, options: Array<{ value: string, label: string }>) => {
  const value = normalizeValue(props.value);
  const addBlankOption = (value === '') && !options.find(item => item.value === value);

  const label = props.label || props.placeholder || options[0]?.label;
  const id = props.id ?? uuidv4();

  const applyClassToTag = props.applyClassToTag && showTestableField();

  return <>
    {label && <label style={{ display: 'none' }} htmlFor={id}>{label}</label>}
    <select
      id={id}
      className={applyClassToTag ? props.className : undefined}
      disabled={props.disabled}
      name={props.name}
      title={props.title}
      value={value}
      multiple={Array.isArray(value)}
      onChange={e => {
        const value = e.target.value === '' ? ' ' : e.target.value;
        const synthEvent: any = { ...e, target: { ...e.target, value } }; // hacky partial event so we can pass the sendvalue
        props.onChange(synthEvent, null);
      }}>
      {options.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
      {addBlankOption && <option value=''></option>}
    </select>
  </>;
};

export const TestableSelect = (props: SelectProps) => {
  if (!showTestableField()) {
    return <Select {...props} />;
  }

  return renderTestablePair(
    <Select
      {...props}
      id={null}
      className={props.applyClassToTag ? undefined : props.className}
      name={null}
      data-ismultiple={props.multiple}
    />,
    renderMenu(props, extractOptionsFromSelect(props)),
  );
};

/**
 * A TextField with a select property
 * @param props
 * @returns
 */
export const TestableTextFieldSelect = (props: TextFieldProps) => {
  if (!showTestableField()) {
    return <TextField {...props} />;
  }

  return renderTestablePair(
    <TextField
      {...props}
      id={null}
      className={props.applyClassToTag ? undefined : props.className}
      name={null}
    />,
    renderMenu(props, extractOptionsFromSelect(props)),
  );
};

/**
 * A TextField with a select property
 * @param props
 * @returns
 */
export const TestableAutocomplete = (props: AutocompleteProps) => {
  if (!showTestableField()) {
    return <Autocomplete
      {...props}
    />;
  }

  const options = (props.options as Array<any>).map(option => {
    return {
      value: normalizeValue(option.id),
      label: props.getOptionLabel?.(option) ?? option.label ?? option.value,
    };
  });

  const value = normalizeValue((props.value as any)?.id);

  const addBlankOption = true;

  return renderTestablePair(
    <Autocomplete
      {...props}
      id={null}
      className={props.applyClassToTag ? undefined : props.className}
    />,
    <>
      <label style={{ display: 'none' }} htmlFor={props.id}>{props.placeholder}</label>
      <select
        id={props.id}
        disabled={props.disabled}
        title={props.title}
        className={props.applyClassToTag ? props.className : undefined}
        value={value}
        onChange={e => {
          props.onChange(e as any, props.options[e.currentTarget.selectedIndex], 'selectOption');
        }}>
        {options.map(item => <option key={item.value} value={item.value}>{item.label}</option>)}
        {addBlankOption && <option value=''></option>}
      </select>
    </>,
  );
};
