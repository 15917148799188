import React from 'react';
import { Box, Grid } from '@mui/material';
import { SimilarityResultView } from './SimilarityResultView';
import { ReferenceStructure, SimilarStructure, SimilaritySettings } from './SimilarityResultsTypes';
import { ChemistryImage } from '@/components';
import { CenterContent, ExpandableGroup } from '../layout';
import { filterByCollections } from './Collections';

type SimilarityResultsProps = {
  similarStructures: Array<SimilarStructure>;
  loading?: boolean;
  grouped: boolean;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
  reference: ReferenceStructure;
  settings: SimilaritySettings
};

const SimilarityResults = (props: SimilarityResultsProps) => {
  const { loading, similarStructures, grouped, banExternalLinks, settings } = props;

  if (loading) {
    return CenterContent(<div>Loading...</div>);
  }
  if (!similarStructures || !similarStructures.length) {
    return CenterContent(<div>No results found</div>);
  }
  if (settings.filters.length == 0) {
    return CenterContent(<div>Please select at least one collection</div>);
  }
  const filteredStructures = filterByCollections(similarStructures, settings.filters);

  if (grouped) {
    const groupedHits = groupBy(
      filteredStructures,
      (structure) => structure.scaffold,
    );
    return (
      <>
        {Array.from(groupedHits).map(([scaffold, group]) => {
          return (
            <ScaffoldSimilarityResultsGrid
              scaffold={scaffold}
              results={group}
              key={scaffold}
              banExternalLinks={banExternalLinks}
              canRegisterMolecules={props.canRegisterMolecules}
              reference={props.reference}
              settings={props.settings}
            />
          );
        })}
      </>
    );
  }

  return (
    <>
      <Grid item>
        <SimilarityResultsGrid
          results={filteredStructures}
          banExternalLinks={banExternalLinks}
          canRegisterMolecules={props.canRegisterMolecules}
          reference={props.reference}
          settings={props.settings}
        />
      </Grid>
    </>
  );
};

const SimilarityResultsGrid = (props: {
  results: Array<SimilarStructure>;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
  reference: ReferenceStructure;
  settings: SimilaritySettings;
}) => {
  return (
    <>
      <Box style={{ fontWeight: 'bold' }}>{`${props.results.length} hits`}</Box>
      <Box style={{ display: 'flex' }} sx={{ flexWrap: 'wrap' }}>
        {props.results.map((hit, key) => (
          <SimilarityResultView
            hit={hit}
            key={key}
            banExternalLinks={props.banExternalLinks}
            canRegisterMolecules={props.canRegisterMolecules}
            reference={props.reference}
            settings={props.settings}
          />
        ))}
      </Box>
    </>
  );
};

const ScaffoldSimilarityResultsGrid = (props: {
  scaffold: string;
  results: Array<SimilarStructure>;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
  reference: ReferenceStructure;
  settings: SimilaritySettings;
}) => {
  const { scaffold, results, banExternalLinks } = props;
  const scaffoldSize = 150;

  const groupNode = (
    <Box
      width={scaffoldSize}
      height={scaffoldSize}
      sx={{
        minWidth: `${scaffoldSize}px`,
        borderColor: 'rgba(0, 0, 0, 0.12)',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderRadius: '4px',
        padding: '3px',
      }}
      data-testid='similarity-scaffold'
    >
      <ChemistryImage
        src={scaffold}
        width={scaffoldSize}
        height={scaffoldSize}
        onlyRenderWhenVisible={false}
      />
    </Box>);
  const group = results.map((hit, key) => (
    <SimilarityResultView
      hit={hit}
      key={key}
      banExternalLinks={banExternalLinks}
      canRegisterMolecules={props.canRegisterMolecules}
      reference={props.reference}
      settings={props.settings}
    />
  ));
  const groupCaption = `${results.length} ${results.length == 1 ? 'hit' : 'hits'} with this scaffold`;

  return (
    <ExpandableGroup
      key={scaffold}
      groupNode={groupNode}
      group={group}
      groupCaption={groupCaption}
    />
  );
};

function groupBy<K, V>(
  list: Array<V>,
  keyGetter: (input: V) => K,
): Map<K, Array<V>> {
  const map = new Map<K, Array<V>>();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export { SimilarityResults };
