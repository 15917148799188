import { Record } from 'immutable'
import { LOCATION_CHANGE } from 'react-router-redux'
import isEqual from 'lodash/isEqual'

import {
  UPDATE_QUERY,
  ADD_QUERY_FILTER,
  UPDATE_QUERY_FILTER,
  REMOVE_QUERY_FILTER,
  SUBMIT_QUERY,
  UPDATE_SORT_COLUMN,
  UPDATE_DATA_SOURCES,
} from '../actions'
import {
  compressForParam,
  decompressFromParam,
} from '@/shared/utils/moleculeImageHelper.js'
import { Filter, Filters } from '@/Eln/Entries/models/Filter.js'

const DEFAULTS = {
  text: '',
  query_filters: [],
  mrv: '',
  ignore_sort_column: false,
}

const TAINTED_PARAMS = [
  'mrv',
  'text',
]

/*
 * Represent's the query we'll be requesting from rails
 * @class
 * @property {string}    [text]    - the search string. Currently supports a
 *  subset of elasticsearch's simple_query_string: https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-simple-query-string-query.html
 * @property {string}    [mrv] - a structure to search for
 */
export class Query extends Record(DEFAULTS) {
  /*
   * Create a new Query object
   * @param {object} [props]
   * @returns {Query}
   */
  constructor(props = {}) {
    let coercedProps = Object.assign({}, props)
    const { query_filters, text } = props
    coercedProps.query_filters = Filters(query_filters)
    coercedProps.ignore_sort_column = !_.isEmpty(text)

    super(coercedProps)
  }
  /*
   * Create a new Query from query params.
   * @param {object} [params] - coming from the query string
   * @returns {Query}
   */
  static fromParams(state = new Query(), params = {}) {
    const prevQueryFilters = state.query_filters
    let coercedParams = Object.assign({}, params)
    const { mrv } = params

    if (mrv) {
      coercedParams.mrv = decompressFromParam(mrv)
    }

    if (prevQueryFilters) {
      coercedParams.query_filters = prevQueryFilters.toJS()
    }

    return new Query(coercedParams)
  }

  static scopeFilters(prevQuery, elnFieldDefinitions) {
    const scopedSelectValues = elnFieldDefinitions.bySelectValue.keySeq().toArray()

    return prevQuery.update('query_filters', query_filters => query_filters.filter((filter) =>
      (scopedSelectValues.includes(filter.field_select_value))
    ))
  }

  /*
   * Set with naive type checking
   * @param {string} [attr] - attribute
   * @param {*} [value] - value
   * @return {Query}
   */
  set(attr, value) {
    if (typeof value === typeof this[attr]) {
      return super.set(attr, value)
    } else {
      return this
    }
  }

  /*
   * A helper to convert to query params while removing defaults
   * @return {object}
   */
  toJS() {
    return this.reduce((memo, value, key) => {
      if (value) {
        if (key === 'query_filters') {
          value = value.filter(query_filter => !!query_filter.field_select_value).toMap().toJS()
        }

        if (!isEqual(value, DEFAULTS[key])) {
          memo[key] = value
        }
      }

      return memo
    }, {})
  }

  /*
   * Sanitize query for query string
   * @return {object}
   */
  toParams() {
    let params = this.toJS()

    if (params.mrv) {
      params.mrv = compressForParam(params.mrv)
    }

    TAINTED_PARAMS
      .filter(key => !!params[key])
      .forEach(key => {
        params[key] = encodeURIComponent(params[key])
      })

    return params
  }
}

/*
 * The query reducer
 * @function
 * @param {Query}  state
 * @param {object} action
 * @return {Query} new state
 */
export const query = (state = new Query(), action) => {
  switch (action.type) {
    case UPDATE_QUERY: {
      return state.merge(action.query)
    }
    case ADD_QUERY_FILTER: {
      return state.update('query_filters', filters => filters.push(new Filter()))
    }
    case UPDATE_QUERY_FILTER: {
      return state.setIn(['query_filters', action.index], new Filter(action.filter))
    }
    case REMOVE_QUERY_FILTER: {
      return state.deleteIn(['query_filters', action.index])
    }
    case SUBMIT_QUERY: {
      return state.set('ignore_sort_column', !_.isEmpty(state.text))
    }
    case UPDATE_DATA_SOURCES:
    case UPDATE_SORT_COLUMN: {
      return state.set('ignore_sort_column', false)
    }
    /*
     * We have to decode the query params
     */
    case LOCATION_CHANGE: {
      if (_.isEmpty(action.payload.query)) {
        return state
      }
      return Query.fromParams(state, action.payload.query)
    }

    default:
      return state
  }
}
