import axios from 'axios'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import keyBy from 'lodash/keyBy'
import pick from 'lodash/pick'
import { makeAutoObservable } from 'mobx'
import camelCaseKeys, { snakeCaseKeys } from 'shared/utils/camelCaseKeys.js'

const headers = {
  'Accept': 'application/json',
  'Content-type': 'application/json',
}

class Store {
  elnWitnessingEnabled = false
  elnWitnessingNotificationPeriod = 14
  elnWitnessingAdminIds = []
  elnWitnesserIds = []
  users = []
  previous = {}
  loading = false
  errors = {
    // TODO: can I remove this initialization?
    eln_witnessing_notification_period: null,
  }
  vault_eln_witnessing_path = ''
  max_witnessing_period = 28

  constructor(props) {
    makeAutoObservable(this)

    Object.assign(this, camelCaseKeys(pick(props, [
      'eln_witnessing_enabled',
      'eln_witnessing_notification_period',
      'eln_witnessing_admin_ids',
      'eln_witnesser_ids',
      'users',
      'vault_eln_witnessing_path',
      'max_witnessing_period',
    ])))

    this.previous = this.current
  }

  get current() {
    return pick(this, [
      'elnWitnessingEnabled',
      'elnWitnessingNotificationPeriod',
      'elnWitnessingAdminIds',
      'elnWitnesserIds',
    ])
  }

  get dirty() {
    return !isEqual(this.current, this.previous)
  }

  get elnWitnessingAdminsById() {
    return keyBy(this.elnWitnessingAdminIds, id => id)
  }

  get elnWitnessersById() {
    return keyBy(this.elnWitnesserIds, id => id)
  }

  get usersById() {
    return keyBy(this.users, ({ id }) => id)
  }

  get elnWitnessers() {
    return this.elnWitnesserIds.map(id => this.usersById[id])
  }

  onChange = change => {
    Object.assign(this, change)
  }

  submit = () => {
    var formData = this.current
    const elnWitnessingAdminIds = this.elnWitnessingAdminIds.slice()
    const elnWitnesserIds = this.elnWitnesserIds.slice()
    formData = { ...formData, elnWitnessingAdminIds, elnWitnesserIds }
    const data = snakeCaseKeys(formData)

    this.loading = true
    axios.patch(this.vault_eln_witnessing_path, data, { headers })
      .then(this.handleResponse)
      .catch(this.handleResponse)
  }

  handleResponse = ({ response }) => {
    this.loading = false
    this.previous = this.current
    this.errors = camelCaseKeys(get(response, 'data.errors')) || {}
  }
}

export default Store
