import { CatchUnhandledErrors } from '@/ErrorReporting/CatchUnhandledErrors';
import EditInventoryDialog from '@/Inventory/components/inventory_dialogs/EditInventoryDialog';
import InventorySampleTables from '@/Samples/components/InventorySampleTables';
import { CDDLink } from '@/components/ui/CDDLink';
import { Img } from '@/shared/components/sanitizedTags';
import { term } from '@/shared/utils/stringUtils';
import { getRootStore } from '@/stores/rootStore';
import addIcon from 'ASSETS/images/cdd30/icons/add.png';
import { observer } from 'mobx-react';
import React from 'react';
import { Batch, InventoryEvent, Sample } from './types';

/**
 * "Create a new sample" link
*   - visible unless hide_sample_add_link (view prop) is true.
 *   - disabled if can_add_sample (props) is false OR the inventory sample API returned sample_limit_hit
 *   - tooltip is  "All possible samples have been created." if the limit was hit
 *   - tooltip is locking_slurp_text (view prop) if has_locking_chemistry_slurps (props) is true
 *
 * "Edit sample" link
 *   - visible unless hide_sample_edit_links (sample prop) is true.
 *   - disabled if can_modify (sample prop) is false
 *   - tooltip is locking_slurp_text (view prop) if has_locking_chemistry_slurps (props) is true
 *
 * "Create a new event" link
 *   - visible unless hide_event_add_link (view prop) is true.
 *   - disabled if can_add_event (view prop) is false.
 *   - tooltip is locking_slurp_text (view prop) if has_locking_chemistry_slurps (props) is true

Event editing is disabled if can_modify (sample prop) is false
 */

type Props = {
  can_add_sample: boolean;
  can_add_event: boolean;
  locking_slurp_text?: string;
  hide_sample_add_link?: boolean;
  hide_event_add_link?: boolean;
  has_locking_chemistry_slurps?: boolean;
  units: Array<string>;
  batches: Array<Batch>;
};

@observer
export default class SampleDataView extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.store.loadSamples(true);
  }

  get store() {
    return getRootStore().sampleDataStore;
  }

  get editStore() {
    return getRootStore().editInventoryStore;
  }

  handleCreateSample = () => {
    if (this.props.can_add_sample && !this.store.sampleLimitHit) {
      this.editStore.handleCreateSample();
    }
  };

  handleCreateEvent = (sample: Sample) => {
    if (this.props.can_add_event) {
      this.editStore.handleCreateEvent(sample);
    }
  };

  handleEditSample = (sample?: Sample) => {
    if (sample.can_modify && !sample.hide_sample_edit_links) {
      this.editStore.handleEditSample(sample);
    }
  };

  handleClickRow = (eventOrSample: InventoryEvent | Sample) => {
    let event: InventoryEvent;
    let sample: Sample;

    if ((eventOrSample as Sample).inventory_events) {
      sample = eventOrSample as Sample;
      event = sample.inventory_events?.[0];
    } else {
      event = eventOrSample as InventoryEvent;
      sample = this.store.samples.find(
        (sample) => sample.id === event.inventory_sample_id,
      );
    }

    if (sample && !sample.depleted) {
      if (sample.is_single_use) {
        this.editStore.handleEditSample(sample);
      } else if (sample.can_modify && !sample.hide_sample_edit_links) {
        this.editStore.handleEditEvent(event);
      }
    }
  };

  get createSampleDisabled() {
    return !this.props.can_add_sample || this.store.sampleLimitHit;
  }

  renderHeader() {
    const { samples, loading } = this.store;
    const { createSampleDisabled, handleCreateSample } = this;
    const createLabel = `Create a new ${term('sample')}`;

    const createSampleVisible = !this.props.hide_sample_add_link;

    const createSampleToolTip =
      (this.props.has_locking_chemistry_slurps &&
        this.props.locking_slurp_text) ||
      (this.store.sampleLimitHit &&
        'All possible samples have been created.') ||
      undefined;

    const sampleCount = (samples ?? []).filter(
      (sample) => !sample.depleted,
    ).length;

    // update DOM rendered by rails so that the Samples link shows the right number
    const sampleCountElement = document.querySelector(
      '#molecule-inventory_samplesLink .count',
    ) as HTMLElement;
    sampleCountElement &&
      (sampleCountElement.innerText = sampleCount.toString());

    return (
      <section className="section__header">
        <div className="editableData" id="molecule-newSample">
          <div className="show-panel" id="molecule-newSample-show">
            <div className="editableData-editLink">
              {createSampleVisible && (
                <CDDLink
                  onClick={handleCreateSample}
                  disabled={createSampleDisabled}
                  title={createSampleToolTip}
                >
                  <Img
                    width={16}
                    height={16}
                    className="icon-16"
                    alt={createLabel}
                    src={addIcon}
                  />
                  {createLabel}
                </CDDLink>
              )}
            </div>
          </div>
        </div>

        {sampleCount > 0 && (
          <h2>
            {sampleCount === 1
              ? `1 ${term('sample', true)}`
              : `${sampleCount} ${term('sample.other', true)}`}
          </h2>
        )}

        {sampleCount === 0 && !loading && (
          <div className="noDataMessage">No {term('sample.other', true)}</div>
        )}
      </section>
    );
  }

  render() {
    const {
      displayedColumns,
      samples,
      vaultId,
      singleUseColumns,
      inventory_event_field_definitions,
      inventory_sample_field_definitions,
      loading,
      sampleTableData,
      handleToggleDeplete,
    } = this.store;

    const {
      has_locking_chemistry_slurps,
      locking_slurp_text,
      hide_event_add_link,
      can_add_event,
    } = this.props;

    if (loading) {
      return null;
    }

    return (
      <div className="SampleDataView">
        {this.renderHeader()}
        <InventorySampleTables
          vaultId={vaultId}
          moleculeId={this.store.moleculeId}
          editSampleTooltip={
            has_locking_chemistry_slurps ? locking_slurp_text : undefined
          }
          addEventVisible={!hide_event_add_link}
          addEventDisabled={!can_add_event}
          sampleFieldDefinitions={inventory_sample_field_definitions}
          eventFieldDefinitions={inventory_event_field_definitions}
          columns={displayedColumns}
          singleUseColumns={singleUseColumns}
          samples={samples}
          tableData={sampleTableData}
          editSample={this.handleEditSample}
          addSampleEvent={this.handleCreateEvent}
          onClickRow={this.handleClickRow}
          onToggleSampleDepleted={handleToggleDeplete}
        />

        <EditInventoryDialog
          store={this.editStore}
          units={this.props.units}
          batches={this.props.batches}
          createSampleDisabled={
            this.createSampleDisabled ||
            this.editStore.currentlyEditingValue?.sample.is_single_use
          }
        />

        <CatchUnhandledErrors
          ignoreUrlErrors={[/\/vaults\/\d+\/files\/\d+\/external_data/]}
        />
      </div>
    );
  }
}
