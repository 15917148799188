import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import pick from 'lodash/pick'

import MultiSelect from 'shared/components/MultiSelect.jsx'

@inject(stores => ({
  ...pick(stores.store, [
    'elnWitnesserIds',
    'elnWitnessersById',
    'onChange',
    'users',
  ]),
  disabled: !stores.store.elnWitnessingEnabled,
}))
@observer
export default class WitnesserList extends Component {
  onChange = event => {
    this.props.onChange({ elnWitnesserIds: event.target.value })
  }

  onDelete = id => () => {
    const withDeleted = this.props.elnWitnesserIds
      .filter(_id => id !== _id)

    this.props.onChange({ elnWitnesserIds: withDeleted })
  }

  itemProps = ({ id, full_name }) => {
    const selected = !!this.props.elnWitnessersById[id]

    return ({
      id,
      selected,
      label: full_name,
      value: id,
    })
  }

  render() {
    const { elnWitnesserIds, users, disabled } = this.props

    return (
      <MultiSelect
        tagged
        className='eln-witnessing__list'
        disabled={disabled}
        value={elnWitnesserIds}
        onChange={this.onChange}
        onDelete={this.onDelete}
        inputProps={{ className: 'settings-page__multi-select' }}
        items={users}
        itemProps={this.itemProps}
      />
    )
  }
}
