import isPlainObject from 'lodash/isPlainObject'
import map from 'lodash/map'
import mapKeys from 'lodash/mapKeys'
import mapValues from 'lodash/mapValues'
import camelCase from 'lodash/camelCase'
import snakeCase from 'lodash/snakeCase'

function deepKeyMap(object, options, keyMapper) {
  const {
    deep,
    ignore,
    deepIgnore,
  } = {
    deep: false,
    ignore: [],
    deepIgnore: [],
    ...options,
  }

  if (isPlainObject(object)) {
    object = mapKeys(object, (value, key) => ignore.includes(key) ? key : keyMapper(key))
  }

  if (deep) {
    if (isPlainObject(object)) {
      object = mapValues(object, (value, key) => deepIgnore.includes(key) ? value : deepKeyMap(value, options, keyMapper))
    }
    if (Array.isArray(object)) {
      object = map(object, (value) => deepKeyMap(value, options, keyMapper))
    }
  }

  return object
}

export function camelCaseKeys(object, options) {
  return deepKeyMap(object, options, camelCase)
}

export function snakeCaseKeys(object, options) {
  return deepKeyMap(object, options, snakeCase)
}

export default camelCaseKeys
