// make importing various javascript objects into Typescript a little easier.

import type { StructureEditorDialog } from '@/components/StructureEditor/StructureEditorDialog';
import type {
  KetcherUtils,
  StructureOptions,
} from 'ASSETS/javascripts/structure_editors.js';
import type { AxiosStatic } from 'axios';
import activeAjax from '../assets/javascripts/activeAjax.js';
import type { CustomCalculationAutoComplete } from './ProtocolReadoutDefinitions/types.js';
import type { AnyObject, StringOrNumber } from './types';

export enum CardinalMixtureTypes {
  None = 'none',
  Name = 'name',
  Link = 'link',
  Batch = 'batch',
}

export type CDDType = {
  Terminology: {
    dictionary: { [key: string]: string };
    t: (term: string) => string;
  };
  DataSourceSelection: {
    updateCheckBoxState: (dataSource: string) => void;
    pageUpdateUrl: string;
    updateCallback: () => Promise<void>;
    onSelectionChanged?: () => void;
    selectionChanged: () => void;
  };
  ActiveDataContext: {
    toContextParam: string;
  };
  Axios: {
    initialize: () => void;
    invalidCSRFMessage: string;
    staticInstance: AxiosStatic;
  };
  Component: AnyObject;
  Export: {
    submit: (
      exportPath: string,
      exportProgressPath?: string,
      additionalParams?: AnyObject
    ) => void;
    enable: () => void;
    disable: () => void;
    downloadClicked: () => boolean;
    setElnDisableTo: (val: boolean) => void;
  };
  features: {
    enableAnnotations: boolean;
    enableForms: boolean;
    enableMixtures: boolean;
    cardinalMixtureType: CardinalMixtureTypes;
    enableDL: boolean;
    elnWitnessingEnabled: boolean;
    structureEditor: 'ketcher';
    isRegistrationSystem: boolean;
    banExternalLinks: boolean;
    skipExternalLinkWarning: boolean;
    canRegisterMolecules: boolean;
  };
  Form: {
    hideEditPanel: (domIdBase: string) => void;
    setupCalendarFields: () => void;
    setupReactFileUploader: (element: HTMLElement) => void;
  };
  insertGlobalMessage: (val: any) => void; //  eslint-disable-line @typescript-eslint/no-explicit-any
  Helpers: {
    rjsPostProcessing: () => void;
    updateTitleAndPageHeader: (name: string, nameForTitle?: string) => void;
  };
  Mapper: {
    createRunDialog: (
      runID: StringOrNumber,
      label: string,
      runGrouping: string,
      protocolId: StringOrNumber
    ) => void;
    hideCreateRunDialog: () => void;
    repositionCreateRunDialog: () => void;
  };
  Pollers: {
    start: (
      name: string,
      path: string,
      frequency: number,
      onSuccessCallback?: () => void
    ) => void;
  };
  StructureEditor: {
    openMarvin4JS: (
      structureElementId: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorContext: any,
      options: StructureOptions
    ) => boolean; //  eslint-disable-line @typescript-eslint/no-explicit-any
    openMarvin4JSWithPromise: (
      structureElementId: string,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      editorContext: any,
      options: StructureOptions
    ) => Promise<{ mrv: string; image: string }>; //  eslint-disable-line @typescript-eslint/no-explicit-any
    convertStructure: (
      structure: string,
      structureFormat: string,
      onSuccess: (result: string) => void,
      onError?: (jqXHR, response) => void
    ) => void;
    importStructure: (structure: string) => void;
    ketcherUtils: KetcherUtils;
    marvin4JSServices: () => {
      detailws: string;
    };
    launchStructureEditorDialog: (
      props: React.ComponentProps<typeof StructureEditorDialog>
    ) => void;

    //  Marvin-specific state
    ready: boolean;
    sketcherInstance: any; //  eslint-disable-line @typescript-eslint/no-explicit-any
  };
  ModalOverlay: {
    show: () => void;
    hide: () => void;
  };
  moleculeImageHelper: any; //  eslint-disable-line @typescript-eslint/no-explicit-any

  //  options that can be switched on for tests
  debug: {
    structureRenderingMnemonics: boolean;
  };

  testEnvironment: boolean;
};

type Window = {
  CDD: CDDType;
  AutoFormula: (
    element: HTMLTextAreaElement,
    update: HTMLElement,
    aggregation: HTMLElement,
    options: CustomCalculationAutoComplete
  ) => void;
};

const w: Window = window as any as Window; //  eslint-disable-line @typescript-eslint/no-explicit-any
w.CDD = w.CDD ?? ({} as CDDType);

const { CDD, AutoFormula } = w;

export { AutoFormula, CDD, activeAjax };
