import { StandaloneStructService } from 'ketcher-standalone/dist/binaryWasm';

export enum ChemicalMimeType {
  Mol = 'chemical/x-mdl-molfile',
  Rxn = 'chemical/x-mdl-rxnfile',
  DaylightSmiles = 'chemical/x-daylight-smiles',
  ExtendedSmiles = 'chemical/x-chemaxon-cxsmiles',
  DaylightSmarts = 'chemical/x-daylight-smarts',
  InChI = 'chemical/x-inchi',
  InChIAuxInfo = 'chemical/x-inchi-aux',
  CDXML = 'chemical/x-cdxml',
  CDX = 'chemical/x-cdx',
  CML = 'chemical/x-cml',
  KET = 'chemical/x-indigo-ket',
}

interface ConvertData {
  struct: string;
  output_format: ChemicalMimeType;
}

interface StructServiceOptions {
  [key: string]: string | number | boolean | undefined;
}

export interface ConvertResults {
  struct: string;
  format: ChemicalMimeType;
}

// Partial interface exposed by the IndigoService from ketcher-standalone

export interface Indigo {
  convert: (convertData: ConvertData) => Promise<ConvertResults>;
}

//
export class StandaloneIndigo {
  private readonly indigo: Indigo;
  private ongoingConversion: Promise<ConvertResults> | null;

  constructor(indigo: Indigo) {
    this.indigo = indigo;
  }

  // Indigo appears to not be threadsafe so we queue up the conversion requests and do one after the other
  async convert(
    data: string,
    options?: StructServiceOptions,
  ): Promise<ConvertResults> {
    if (this.ongoingConversion) {
      try {
        await this.ongoingConversion;
      } catch (_e) {
        // Ignore previous conversion errors
      }
    }

    const outputFormat =
      (options?.outputFormat as ChemicalMimeType) || ChemicalMimeType.KET;
    const conversion = this.indigo.convert({
      struct: data,
      output_format: outputFormat,
    });

    this.ongoingConversion = conversion.finally(() => {
      this.ongoingConversion = null;
    });

    return conversion;
  }
}

let standaloneIndigo: StandaloneIndigo = null;
export function obtainIndigo() {
  if (!standaloneIndigo) {
    standaloneIndigo = new StandaloneIndigo(
      new StandaloneStructService({}) as Indigo,
    );
  }

  return standaloneIndigo;
}

export function stubIndigo(indigo: Indigo) {
  standaloneIndigo = new StandaloneIndigo(indigo);
}

export function clearIndigo() {
  standaloneIndigo = null;
}
