import camelCase from 'lodash/camelCase'

import constants from 'javascripts/constants.js'
const { ELN_ATTACHED_STRUCTURE_ANCHOR_KEY } = constants

export const urlFor = ({ id, type }) => {
  const { protocol, host } = window.location
  const base = `${protocol}//${host}`
  const path = pathFor({ id, type })

  return path ? `${base}${path}` : undefined
}

const anchorTypes = [
  camelCase(ELN_ATTACHED_STRUCTURE_ANCHOR_KEY),
]

const anchors = new Proxy(anchorTypes, {
  get: (array, type) => id => {
    const { pathname } = window.location
    return array.includes(type) ? `${pathname}${id || ''}` : null
  },
})

const routeMap = {
  batch: 'batches',
  elnEntry: 'eln/entries',
  elnDebug: 'eln/debugs',
  molecule: 'molecules',
  run: 'runs',
  protocol: 'protocols',
  vaultMembers: 'vault_members',
}

const routes = new Proxy(routeMap, {
  get: (map, type) => id => {
    const base = '/' + CDD.ActiveDataContext.toContextParam
    return map[type] ? `${base}/${map[type]}/${id || ''}` : base
  },
})

export const pathFor = ({ id, type }) => {
  type = camelCase(type)
  return anchors[type](id) || routes[type](id)
}
