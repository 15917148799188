import { CustomNucleotidesSchema } from '@/Accounts/CustomNucleotides/types';
import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, reaction } from 'mobx';
import { ColumnSortDef } from '@/components';
import { EditCustomNucleotideStore } from './editCustomNucleotideStore';
import keyBy from 'lodash/keyBy';
import { accountsService } from '@/Accounts/accountsServices';

export class CustomNucleotidesStore {
  root: RootStore;
  editCustomNucleotideStore: EditCustomNucleotideStore;
  inited = false;
  loading = 0;
  disposers: Array<() => void> = [];
  customNucleotides: Array<CustomNucleotidesSchema> = [];
  sortBy: ColumnSortDef = { id: 'name', direction: 'asc' };
  filterText = '';

  lastLoadedCustomNucleotidesForAccount = 0;

  constructor(root: RootStore) {
    this.root = root;
    this.editCustomNucleotideStore = new EditCustomNucleotideStore(root);
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get customNucleotidesMap(): { [id: number]: CustomNucleotidesSchema } {
    return keyBy(this.customNucleotides, 'id');
  }

  init = () => {
    if (!this.inited) {
      this.inited = true;
      this.loading = 0;
      // react to url changes by updating model data and loading account data
      this.disposers.push(
        reaction(
          () => this.root.accountsStore.accountsUrlParams?.accountId,
          (accountId) => {
            if (accountId && accountId !== this.lastLoadedCustomNucleotidesForAccount) {
              this.loadCustomNucleotides();
            }
          },
          { fireImmediately: true },
        ),
      );
    }
    this.editCustomNucleotideStore.init();
  };

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
    this.editCustomNucleotideStore.cleanup();
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  setCustomNucleotides(val: Array<CustomNucleotidesSchema>) {
    this.customNucleotides = val;
  }

  setSortBy(value: ColumnSortDef) {
    this.sortBy = value;
  }

  setFilterText(value: string) {
    this.filterText = value;
  }

  get displayRows(): Array<CustomNucleotidesSchema> {
    const lcFilter = this.filterText.toLowerCase();

    return this.customNucleotides.filter((customNucleotide) => {
      return (
        !lcFilter ||
        customNucleotide.name
          .toLowerCase()
          .includes(lcFilter)
      );
    });
  }

  async loadCustomNucleotides() {
    try {
      this.lastLoadedCustomNucleotidesForAccount = this.root.accountsStore.accountId;
      this.incrementLoading();
      const response = await accountsService.getCustomNucleotides();
      this.setCustomNucleotides(response.data);
    } finally {
      this.decrementLoading();
    }
  }
}
