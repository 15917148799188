import debounce from 'lodash/debounce';

import basicMiddleware from './basicMiddleware.js';
import { GET_MOLECULE_SUGGESTIONS, suggestMolecules } from '@/shared/actions/moleculeSuggestions';
import { LINK_SUGGESTION_THROTTLE as WAIT } from '@/shared/constants.js';
import { getMoleculeMrvByName } from '@/shared/utils/moleculeSearch';

const __getMoleculeSuggestions = ({ dispatch, name }) => {
  getMoleculeMrvByName(name).then(mrv => {
    dispatch(suggestMolecules({ name, mrv }));
  }).catch(() => {
    dispatch(suggestMolecules({ name, mrv: null }));
  });
};

const _getMoleculeSuggestions = debounce(__getMoleculeSuggestions, WAIT);

const getMoleculeSuggestions = ({ name }) => (dispatch, getState) => {
  const { moleculeSuggestions } = getState();
  if (!name || name.length == 0 || moleculeSuggestions.has(name)) return;
  _getMoleculeSuggestions({ dispatch, name });
};

const operations = {
  [GET_MOLECULE_SUGGESTIONS]: getMoleculeSuggestions,
};

export default basicMiddleware({ operations });
