import { List, OrderedMap, Record } from 'immutable'
import camelCase from 'lodash/camelCase'

import { SUGGEST_LINKS } from '@/shared/actions/linkSuggestions.js'

import constants from 'javascripts/constants.js'
const { ELN_ATTACHED_STRUCTURE_ANCHOR_KEY } = constants
const ElnAttachedStructureAnchorKey = camelCase(ELN_ATTACHED_STRUCTURE_ANCHOR_KEY)

const DEFAULTS = {
  id: null,
  label: null,
}

class SuggestionHighlight extends Record(DEFAULTS) { }

const WITH_HIGHLIGHT = Object.assign({}, DEFAULTS, {
  url: null,
  vault: null,
  type: null,
  highlight: new SuggestionHighlight(),
  molecule_details: null,
})

class LinkSuggestion extends Record(WITH_HIGHLIGHT) {
  constructor({ type, highlight = {}, ...other } = {}) {
    type = camelCase(type)
    highlight = new SuggestionHighlight(highlight)

    super({ type, highlight, ...other })
  }

  get highlighted() {
    let { id, label } = this.highlight

    id = id || this.id
    label = label || this.label

    return { id, label }
  }

  get display() {
    const { type } = this
    const { id, label } = this.highlighted

    switch (type) {
      case ('batches'):
      case ('elnEntries'):
      case ('molecules'):
      case ('protocols'):
      case ('runs'):
      case (ElnAttachedStructureAnchorKey):
        return label
      default:
        return label ? `${id} · ${label}` : id.toString()
    }
  }
}

const LinkSuggestions = OrderedMap

// Reducer
const suggestLinks = (state, action) => {
  let { text, suggestions } = action.payload
  suggestions = suggestions.map(suggestion => new LinkSuggestion(suggestion))
  return state.set(text, List(suggestions)).takeLast(10)
}

const actionMap = {
  [SUGGEST_LINKS]: suggestLinks,
}

const linkSuggestions = (state = new LinkSuggestions(), action) => {
  const fn = actionMap[action.type]
  return fn ? fn(state, action) : state
}

export { LinkSuggestion, LinkSuggestions, linkSuggestions }
