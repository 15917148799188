import React, { Component } from 'react'
import { FormGroup, FormControl, FormHelperText, Input, Select, MenuItem } from '@mui/material'
import ArrowDropDown from '@mui/icons-material/ArrowDropDown'
import { observer, inject } from 'mobx-react'
import pick from 'lodash/pick'

const DATE_UNITS = ['days', 'weeks']

@inject(({ store }) => ({
  ...pick(store, [
    'elnWitnessingNotificationPeriod',
    'onChange',
    'maxWitnessingPeriod',
  ]),
  disabled: !store.elnWitnessingEnabled,
  errors: store.errors.elnWitnessingNotificationPeriod,
}))
@observer
class PeriodSelect extends Component {
  constructor(props) {
    super(props)

    const { elnWitnessingNotificationPeriod: period } = props
    let units = 'days'
    let value = period

    if (period % 7 == 0) {
      units = 'weeks'
      value = period / 7
    }

    this.state = {
      units,
      value,
    }
  }

  get unitConversion() {
    return this.state.units == 'weeks' ? 7 : 1
  }

  get max() {
    const { maxWitnessingPeriod } = this.props
    return maxWitnessingPeriod / this.unitConversion
  }

  onPeriodChange = event => {
    const { value } = event.target
    this.setState({ value }, this.onChange)
  }

  onUnitsChange = event => {
    const units = event.target.value
    this.setState({ units }, this.onChange)
  }

  onChange = () => {
    const elnWitnessingNotificationPeriod = this.state.value * this.unitConversion
    this.props.onChange({ elnWitnessingNotificationPeriod })
  }

  renderIcon = ({ className = '', ...props }) => {
    if (this.props.disabled) { className += ' icon--disabled' }

    return <ArrowDropDown {...props} className={className} />
  }

  render() {
    const { disabled, errors } = this.props
    const { value, units } = this.state

    return (
      <FormControl error={!!errors}>
        <FormGroup row>
          {/* XXX: there appears to be a bug in Input. If it's not
          wrapped in a FormControl, it errors out when value = "". */}
          <FormControl>
            <Input
              data-testid="value"
              disabled={disabled}
              className="eln-witnessing__period"
              type="number"
              onChange={this.onPeriodChange}
              value={value}
              inputProps={{
                min: 0,
                max: this.max,
              }}
            />
          </FormControl>
          <Select
            data-testid="units"
            disabled={disabled}
            className="eln-witnessing__period_units"
            onChange={this.onUnitsChange}
            value={units}
            IconComponent={this.renderIcon}
          >
            {DATE_UNITS.map((unit, idx) => (
              <MenuItem key={idx} value={unit}>
                {unit}
              </MenuItem>
            ))}
          </Select>
        </FormGroup>
        {!!errors && <FormHelperText>{errors}</FormHelperText>}
      </FormControl>
    )
  }
}

export { PeriodSelect }
export default PeriodSelect
