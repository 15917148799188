import { LocationNode, Sample } from '@/Samples/types';
import { RootStore } from '@/stores/rootStore';
import axios from 'axios';
import { makeAutoObservable, reaction, runInAction, toJS } from 'mobx';
import { LocationUtils } from '../components/location/locationUtils';
import { StringOrNumber } from '@/types';
import { deepClone } from '@/Annotator/data/utils';

export class LocationStore {
  disposers: Array<() => void> = [];
  inited = false;
  loading = 0;

  locations: Array<LocationNode> = [];
  currentlyEditingLocations?: Array<LocationNode> = null;

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    if (!this.inited) {
      reaction(() => this.root.sampleDataStore.inventory_event_field_definitions,
        () => {
          this.loadLocations();
        });
      this.inited = true;
    }
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    ++this.loading;
  }

  decrementLoading() {
    --this.loading;
  }

  handleEditLocations() {
    this.currentlyEditingLocations = [{
      id: -1,
      value: 'Locations',
      display_order: 0,
      children: deepClone(this.locations),
    }];
    LocationUtils.ensureParentsOnChildren(this.currentlyEditingLocations);
  }

  handleCancelEditLocations() {
    this.currentlyEditingLocations = null;
  }

  async handleSubmitEditLocations() {
    const { apiPrefix } = this;
    if (apiPrefix) {
      const url = `${apiPrefix}/inventory_locations/bulk_update.json`;
      try {
        this.incrementLoading();
        const data = LocationUtils.prepareToSave(
          toJS(this.currentlyEditingLocations[0].children),
        );
        const result = await axios.post(url, { inventory_locations: data }, null);
        LocationUtils.sortByDisplayOrder(result.data.inventory_locations);

        runInAction(() => {
          this.locations = result.data.inventory_locations;
        });
      } finally {
        this.decrementLoading();
      }
    }
    this.currentlyEditingLocations = null;
  }

  get currentlyEditingLocationsMap() {
    return LocationUtils.computeNodeMap(this.currentlyEditingLocations ?? []);
  }

  setCurrentlyEditingLocations(locations: Array<LocationNode>, draggedNodeId: StringOrNumber) {
    locations = toJS(locations) as Array<LocationNode>;
    LocationUtils.ensureParentsOnChildren(locations, true);
    this.currentlyEditingLocations = locations;
    this.currentlyEditingLocations.forEach(node => LocationUtils.ensureParentsOnChildren(node));

    const draggedNode = LocationUtils.findNodeById(this.currentlyEditingLocations, draggedNodeId);
    if (draggedNode) {
      draggedNode.value = LocationUtils.ensureDragNameUniqueness(draggedNode.value, draggedNode.parent, draggedNode.id);
    }
  }

  get apiPrefix() {
    const { routerStore, sampleDataStore: { inventory_event_field_definitions } } = this.root;
    const params = routerStore.extractFromPattern('/vaults/:vaultId');
    if (!params) {
      return null;
    }

    const { vaultId } = params as { vaultId?: number; };

    const locationId = inventory_event_field_definitions?.find((fieldDefinition) => fieldDefinition.name === 'Location')?.id;
    return locationId ? `/vaults/${vaultId}/inventory_event_field_definitions/${locationId}` : null;
  }

  async loadLocations() {
    const { apiPrefix } = this;
    if (apiPrefix) {
      const url = `${apiPrefix}/inventory_locations.json`;
      try {
        this.incrementLoading();
        const result = await axios.get<{ inventory_locations: Array<LocationNode>; }>(url);
        runInAction(() => {
          LocationUtils.sortByDisplayOrder(result.data.inventory_locations);
          this.locations = result.data.inventory_locations;
          if (this.currentlyEditingLocations) {
            this.handleEditLocations();
          }
        });
      } finally {
        this.decrementLoading();
        if (!this.inited) {
          runInAction(() => {
            this.inited = true;
          });
        }
      }
    }
  }

  fetchBoxContents = async (boxId: number): Promise<Array<Sample>> => {
    const {
      routerStore,
      sampleDataStore: { inventory_event_field_definitions },
      editInventoryStore: { sampleRelocation: { oldLocation, newLocation } },
    } = this.root;
    const { vaultId } = routerStore.extractFromPattern('/vaults/:vaultId') as {
      vaultId?: number;
    };

    const locationId = inventory_event_field_definitions.find((fieldDefinition) => fieldDefinition.name === 'Location')?.id;
    const url = `/vaults/${vaultId}/inventory_event_field_definitions/${locationId}/inventory_locations/${boxId}/contents.json`;

    try {
      this.incrementLoading();
      const contents = await axios.get<Array<Sample>>(url);
      let result = contents.data;
      // screen out any special allowed location, this is used when you add a debt event, change the location of the sample
      // and then elect to create a sample from the debit. The sample's previous location should be allowed.
      if (oldLocation) {
        const sampleWithOldLocation: Sample = result.find(sample => sample.location?.id === oldLocation?.id && sample.location?.position === oldLocation?.position);
        result = result.filter(sample => sample !== sampleWithOldLocation);
        if (newLocation && newLocation.id === boxId) {
          result.push({ ...sampleWithOldLocation, location: newLocation });
        }
      }
      return result;
    } catch (e) {
      // ignore it
    } finally {
      this.decrementLoading();
    }
    return [];
  };
}
