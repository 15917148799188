import { FieldDefinition } from '@/FieldDefinitions/types';
import {
  InventorySearchViewInitialLoadProps,
  ProcessedInventoryEntries,
} from '@/Inventory/components/types';
import { InventorySampleService } from '@/Samples/inventorySampleService';
import {
  EditSample,
  InventoryEvent,
  InventoryFieldValue,
  LocationValue,
  Sample,
} from '@/Samples/types';
import { ColumnDef } from '@/components';
import hasFeature from '@/shared/utils/features.js';
import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, reaction, runInAction } from 'mobx';

export const currentAmountForSample = (
  sample: Partial<Pick<Sample, 'current_amount'>>,
) => {
  return sample?.current_amount ?? 0;
};

export const locationForEvent = (
  event: Pick<
    InventoryEvent,
    'field_definitions' | 'inventory_event_fields'
  > | null,
) => {
  if (!event || !event.field_definitions) {
    return null;
  }

  for (const field of event.field_definitions) {
    if (field.data_type_name === 'Location') {
      return event.inventory_event_fields[field.id] as LocationValue;
    }
  }
};

export const setEventCreditDebit = (
  event: Pick<InventoryEvent, 'field_definitions' | 'inventory_event_fields'>,
  amount: number,
) => {
  let creditFieldId, debitFieldId;

  event.field_definitions.forEach((field) => {
    if (field.name === 'Credit') {
      creditFieldId = field.id;
    }
    if (field.name === 'Debit') {
      debitFieldId = field.id;
    }
  });
  runInAction(() => {
    if (amount > 0) {
      event.inventory_event_fields[debitFieldId] = 0;
      event.inventory_event_fields[creditFieldId] = amount;
    } else {
      event.inventory_event_fields[creditFieldId] = 0;
      event.inventory_event_fields[debitFieldId] = -amount;
    }
  });
};

export type SampleTableData = Array<{
  group: Sample;
  rows: Array<InventoryEvent | Sample>;
}>;

export class SampleDataStore {
  disposers: Array<() => void> = [];
  inited = false;
  loadingCount = 0;
  inventory_sample_field_definitions: FieldDefinition[] = [];
  inventory_event_field_definitions: FieldDefinition[] = [];

  search_inventory_entries_url: InventorySearchViewInitialLoadProps['links']['inventory_entries_url'];

  sampleLimitHit = false;
  samples: Array<Sample> = [];

  inventoryEntries: ProcessedInventoryEntries = [];

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  init() {
    reaction(
      () => {
        return this.shouldLoadInventoryFields;
      },
      async (shouldInit) => {
        if (shouldInit && !this.inited) {
          await this.loadInventoryFields();
          this.inited = true;
        }
      },
      { fireImmediately: true },
    );
  }

  get shouldLoadInventoryFields() {
    const whitelistPaths = [
      '/inventory_search',
      '/molecules',
      '/inventory_field_definitions',
    ];
    const pathname = this.root.routerStore.url.pathname;
    return (
      hasFeature('enableInventoryFields') &&
      whitelistPaths.some((path) => pathname.includes(path))
    );
  }

  get vaultId() {
    const { vaultId } = this.root.routerStore.extractFromPattern(
      '/vaults/:vaultId',
    ) ?? { vaultId: null };
    return vaultId as number;
  }

  get moleculeId() {
    const { routerStore } = this.root;
    const { moleculeId } = (routerStore.extractFromPattern(
      '/vaults/:vaultId/molecules/:moleculeId',
    ) as { vaultId?: number; moleculeId?: number }) ?? { moleculeId: null };
    return moleculeId;
  }

  get usesInventorySampleIdentifierSystem() {
    return this.inventory_sample_field_definitions.some(
      (field) => field.uses_inventory_sample_identifier_system,
    );
  }

  get nextSampleIdentifier() {
    if (this.usesInventorySampleIdentifierSystem) {
      for (const field of this.inventory_sample_field_definitions) {
        if (field.next_identifier) {
          return field.next_identifier;
        }
      }
    }
    return null;
  }

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoadingCount() {
    ++this.loadingCount;
  }

  decrementLoadingCount() {
    --this.loadingCount;
  }

  get loading() {
    return this.loadingCount > 0;
  }

  async loadInventoryFields() {
    const { routerStore } = this.root;
    const { vaultId } = (routerStore.extractFromPattern('/vaults/:vaultId') as {
      vaultId?: number;
    }) ?? { vaultId: null };

    if (vaultId === null) {
      return;
    }

    try {
      this.incrementLoadingCount();
      const result =
        await InventorySampleService.getInventoryFieldDefinitions();

      runInAction(() => {
        this.inventory_sample_field_definitions =
          result.data.inventory_sample_field_definitions;
        this.inventory_event_field_definitions =
          result.data.inventory_event_field_definitions;
        this.updateSamplesAndEvents(this.samples);
      });
    } finally {
      this.decrementLoadingCount();
    }
  }

  loadSamples(setLoading = false) {
    const promise = InventorySampleService.getSamples();
    if (promise) {
      setLoading && this.incrementLoadingCount();
      promise
        .then((result) => {
          this.setInventoryResults(
            result.data.inventory_samples,
            result.data.sample_limit_hit,
          );
        })
        .finally(() => {
          setLoading && this.decrementLoadingCount();
        });
    }
    return promise;
  }

  updateSamplesAndEvents(samples: Array<EditSample>) {
    const convertNameKeysToFieldIds = (
      fields: Record<string, InventoryFieldValue>,
      fieldDefinitions: FieldDefinition[],
    ) => {
      Object.keys(fields || {}).forEach((key) => {
        const fieldDefinition = fieldDefinitions.find(
          (field) => field.name === key,
        );
        if (fieldDefinition) {
          fields[fieldDefinition.id] = fields[key];
          delete fields[key];
        }
      });
    };
    if (
      this.inventory_sample_field_definitions &&
      this.inventory_event_field_definitions
    ) {
      samples.forEach((sample) => {
        sample.field_definitions = this.inventory_sample_field_definitions;
        sample.inventory_sample_fields_orig = {
          ...sample.inventory_sample_fields,
        };
        convertNameKeysToFieldIds(
          sample.inventory_sample_fields,
          this.inventory_sample_field_definitions,
        );
        sample.inventory_events?.forEach((event) => {
          event.field_definitions = this.inventory_event_field_definitions;

          event.inventory_event_fields_name_keyed = {
            ...event.inventory_event_fields,
          };
          convertNameKeysToFieldIds(
            event.inventory_event_fields,
            this.inventory_event_field_definitions,
          );
        });
      });
    }
    // if the event doesn't have a modified_by_user_full_name, set the modified_at to empty string
    // we only show the modified date if there's a user name
    for (const sample of samples) {
      for (const event of sample.inventory_events) {
        if (!event.modified_by_user_full_name) {
          event.modified_at = '';
        }
      }
    }
  }

  processInventorySearchEntries(samples: Sample[]): ProcessedInventoryEntries {
    const convertNameKeysToFieldIds = (
      fields: Record<string, unknown>,
      fieldDefinitions: FieldDefinition[],
    ) => {
      const relevantDefs = fieldDefinitions.filter(
        (fieldDefinition) => fields[fieldDefinition.name] !== undefined,
      );
      return Object.fromEntries(
        relevantDefs.map((def) => [def.id, fields[def.name]]),
      );
    };

    const newSamples = samples.map((sample) => {
      return {
        ...sample,
        field_definitions: this.inventory_sample_field_definitions,
        inventory_sample_fields_orig: sample.inventory_sample_fields,
        inventory_events: sample.inventory_events.map((event) => {
          return {
            ...event,
            inventory_event_fields_name_keyed: event.inventory_event_fields,
            inventory_event_fields: convertNameKeysToFieldIds(
              event,
              this.inventory_sample_field_definitions,
            ),
            // inventory_event_fields_name_keyed: event.inventory_event_fields,
            // if the event doesn't have a modified_by_user_full_name, set the modified_at to empty string
            // we only show the modified date if there's a user name
            modified_at: event.modified_by_user_full_name
              ? event.modified_at
              : '',
          };
        }),
      };
    });
    // TODO no any - better api-end typing
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return newSamples as any as ProcessedInventoryEntries;
  }

  handleToggleDeplete(sample: Sample) {
    sample.depleted = !sample.depleted;
    this.root.editInventoryStore.handleSubmitSample(sample);
  }

  setInventoryResults(samples: Array<Sample>, sampleLimitHit: boolean) {
    this.updateSamplesAndEvents(samples);
    this.samples = samples;
    this.sampleLimitHit = sampleLimitHit;
  }

  get displayedColumns() {
    const result = [
      {
        id: 'created_at',
        label: 'Created',
        hideIfEmpty: true,
      },
      {
        id: 'modified_at',
        label: 'Last Modified',
        hideIfEmpty: true,
      },
      {
        id: 'child_sample_name',
        label: 'Child Sample',
        hideIfEmpty: true,
      },
      {
        id: 'debit_credit',
        label: 'Debit/Credit',
      },
      {
        id: 'location',
        label: 'Location',
      },
      ...this.inventory_event_field_definitions
        .filter(
          (field) => !['Credit', 'Debit', 'Location'].includes(field.name),
        )
        .map((field) => ({
          id: `inventory_event_fields.${field.id}`,
          label: field.name,
          hideIfEmpty: true,
        })),
    ];

    return result;
  }

  get singleUseColumns() {
    const result: Array<ColumnDef> = [
      {
        id: 'created_at',
        label: 'Created',
        hideIfEmpty: true,
      },
      {
        id: 'modified_at',
        label: 'Last Modified',
        hideIfEmpty: true,
      },
      {
        id: 'current_amount',
        label: 'Amount',
      },
      ...this.inventory_sample_field_definitions.map((field) => ({
        id: `inventory_sample_fields.${field.id}`,
        label: field.name,
        hideIfEmpty: true,
      })),
      {
        id: 'location',
        label: 'Location',
      },
      {
        id: 'parent_sample_name',
        label: 'Parent Sample',
        hideIfEmpty: true,
      },
      ...this.inventory_event_field_definitions
        .filter(
          (field) => !['Credit', 'Debit', 'Location'].includes(field.name),
        )
        .map((field) => ({
          id: `inventory_events[0].inventory_event_fields.${field.id}`,
          label: field.name,
          hideIfEmpty: true,
        })),
      {
        id: 'deplete',
        width: 20,
        label: '',
        hideIfEmpty: false,
      },
    ];

    return result;
  }

  get sampleTableData(): SampleTableData {
    const { samples } = this;

    // first collect all non single use samples
    const result: Array<{
      group: Sample;
      rows: Array<InventoryEvent | Sample>;
    }> = samples
      .filter((sample) => !sample.is_single_use)
      .map((sample) => ({
        group: sample,
        rows: (
          sample.inventory_events?.slice() ?? ([] as InventoryEvent[])
        ).sort((a, b) => {
          return b.created_at.localeCompare(a.created_at);
        }),
      }));

    // now group single use samples by batch
    const batchToSamples: Record<number, Array<Sample>> = {};
    samples
      .filter((sample) => sample.is_single_use)
      .forEach((sample) => {
        batchToSamples[sample.batch.id] = [
          ...(batchToSamples[sample.batch.id] ?? []),
          sample,
        ];
      });

    // and add grouped single use samples to result
    Object.values(batchToSamples).forEach((samples) => {
      const group = { ...samples[0] };
      const { name, sample_identifier } = samples[0];

      // remove sample identifier from group name
      if (sample_identifier && name.endsWith(`-${sample_identifier}`)) {
        group.name = group.name.substring(
          0,
          group.name.length - sample_identifier.length - 1,
        );
      }
      result.push({
        group: {
          ...group,
          depleted: samples.every((sample) => sample.depleted),
        },
        rows: samples
          .sort((a, b) => {
            return b.created_at.localeCompare(a.created_at);
          })
          .sort((a, b) => {
            return (a.depleted ? 1 : 0) - (b.depleted ? 1 : 0);
          }),
      });
    });

    // sort by single use (first), then by batch name, then by depleted
    result
      .sort(
        (a, b) =>
          (b.group.is_single_use ? 1 : 0) - (a.group.is_single_use ? 1 : 0),
      )
      .sort((a, b) => {
        return a.group.batch.name.localeCompare(b.group.batch.name);
      })
      .sort((a, b) => {
        return (a.group.depleted ? 1 : 0) - (b.group.depleted ? 1 : 0);
      });

    return result;
  }
}
