/* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-explicit-any */

import React from 'react';

import { AnyObject } from '@/types';
import {
  Tooltip,
} from '@mui/material';
import omitBy from 'lodash/omitBy';
import isUndefined from 'lodash/isUndefined';
import pick from 'lodash/pick';
import { DDFormProps } from '../DDForm';
import {
  FormElementRendererMap,
  RenderElementFunction,
} from '../renderers/formRenderMap';
import { FieldValueType } from '../types';
import { ElementDef } from '../types/base/elementDef';
import { FieldDef } from '../types/base/fieldDef';
import { GroupDef } from '../types/base/groupDef';
import { RowDef } from '../types/rowColumnDef';
import { SelectCommonFields } from '../types/selectSingleDef';
import { StepperDef } from '../types/stepperDef';
import { TypographyDef } from '../types/typographyDef';
import { ddMuiRenderButton } from './ddMuiRenderers/ddMuiRenderButton';
import { ddMuiRenderCustom } from './ddMuiRenderers/ddMuiRenderCustom';
import { ddMuiRenderDate } from './ddMuiRenderers/ddMuiRenderDate';
import { ddMuiRenderNumericInput } from './ddMuiRenderers/ddMuiRenderNumericInput';
import { ddMuiRenderPage } from './ddMuiRenderers/ddMuiRenderPage';
import { ddMuiRenderRadioGroup } from './ddMuiRenderers/ddMuiRenderRadioGroup';
import { ddMuiRenderSelect } from './ddMuiRenderers/ddMuiRenderSelect';
import { ddMuiRenderSimpleGroup } from './ddMuiRenderers/ddMuiRenderSimpleGroup';
import { ddMuiRenderStepper } from './ddMuiRenderers/ddMuiRenderStepper';
import { ddMuiRenderTabs } from './ddMuiRenderers/ddMuiRenderTabs';
import { ddMuiRenderText } from './ddMuiRenderers/ddMuiRenderText';
import { ddMuiRenderToggle } from './ddMuiRenderers/ddMuiRenderToggle';
import { ddMuiRenderTree } from './ddMuiRenderers/ddMuiRenderTree';
import { ddMuiRenderTypography } from './ddMuiRenderers/ddMuiRenderTypography';
import { ddMuiRenderCheckbox } from './ddMuiRenderers/ddMuiRenderCheckbox';

export const getOuterElementAttributes = (field: ElementDef, props: DDFormProps) => {
  const result = {
    'data-key': field.key,
    'data-testid': field.id ?? field.key,
    'data-label': (field as any).label,
  };

  return omitBy(result, isUndefined);
};

export const getInnerElementAttributes = (field: ElementDef, props: DDFormProps) => {
  const result = {
    id: field.id ?? field.key,
  } as AnyObject;

  if (field.name) {
    result.name = field.name;
  } else if (props.renderNameAttribute ?? true) {
    result.name = field.key;
  } else {
    result['data-name'] = field.key;
  }

  Object.assign(result, {
    ...field.controlAttributes,
    name: field.name ?? field.key,
    id: field.id ?? field.key,
  });

  return omitBy(result, isUndefined);
};

export const getStyleElementAttributes = (field: ElementDef, props: DDFormProps) => {
  const result = {
  } as AnyObject;

  const style: React.CSSProperties = {
    textWrap: 'nowrap',
  };

  if (field.type === 'typography' && typeof (field as TypographyDef).label === 'string') {
    // center text vertically if a simple text label
    Object.assign(style, {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    });
  }

  if (field.type === 'row' && (field as RowDef).wrap) {
    result.useFlexGap = true;
    style.flexWrap = 'wrap';
  }

  if (field.width) {
    if (field.width === 'expand') {
      if (field.parent?.type === 'column') {
        style.width = '100%';
      } else {
        style.flexGrow = 1;
      }
    } else {
      if (field.width !== 'auto') {
        style.width = field.width;
      }
    }
  }

  if (field.minWidth) {
    style.minWidth = field.minWidth;
  }

  if (field.autoEllipsisMaxWidth) {
    style.maxWidth = field.autoEllipsisMaxWidth;
  }

  if (field.style) {
    Object.assign(style, field.style);
  }

  let elementType = field.type;
  if (elementType === 'stepper' && !(field as StepperDef).useStepper) {
    elementType = 'tabs';
  }

  const classes = [
    'mui-element',
    `element-${elementType}`,
    field.variant ? `element-${elementType}-${field.variant}` : '',
    (field.visible === false) ? 'hidden' : '',
    field.className,
  ];

  if (field.type === 'select' && (field as SelectCommonFields).typeahead) {
    classes.push('element-select-typeahead');
  }

  Object.assign(result, {
    style,
    className: classes.filter(v => !!v).join(' '),
  });

  return omitBy(result, isUndefined);
};

/**
 * Function to get additional attributes for the control
 * @param field
 * @param props
 * @returns
 */
export const getElementAttributes = (field: ElementDef, props: DDFormProps) => {
  return {
    ...getInnerElementAttributes(field, props),
    ...getOuterElementAttributes(field, props),
    ...getStyleElementAttributes(field, props),
  };
};

export const getInputProps = (field: ElementDef, overrides: AnyObject = {}) => {
  const result = {
    className: 'material-input',
    ...(pick(field, ['maxLength', 'min', 'max', 'step'])),
    ...overrides,
  };

  return omitBy(result, isUndefined);
};

export const renderElement: RenderElementFunction = (
  props: DDFormProps,
  element: ElementDef,
  getValue: (field: ElementDef) => FieldValueType,
  setValue: (field: ElementDef, value: FieldValueType) => void,
  formErrors: { [key: string]: string; } | null,
) => {
  if (element.visible === false && !element.renderHidden) {
    return <></>;
  }
  // render the item
  const item =
    muiRenderMap[element.type] &&
    muiRenderMap[element.type](
      props,
      element,
      getValue,
      setValue,
      formErrors,
    );

  const { tooltip } = element as FieldDef;
  const { legend } = element as GroupDef;

  let result = item;
  if (tooltip) {
    result = (
      <Tooltip title={tooltip} {...element.tooltipProps} key={element.key ?? element.id}>
        {item}
      </Tooltip>
    );
  }

  if (legend) {
    result = (
      <fieldset className='ddform-fieldset' key={element.key ?? element.id ?? legend}>
        <legend>{legend}</legend>
        {item}
      </fieldset>
    );
  }
  return result;
};

export const muiRenderMap: FormElementRendererMap = {
  custom: ddMuiRenderCustom,
  text: ddMuiRenderText,
  row: ddMuiRenderSimpleGroup,
  column: ddMuiRenderSimpleGroup,
  number: ddMuiRenderNumericInput,
  date: ddMuiRenderDate,
  select: ddMuiRenderSelect,
  multiselect: ddMuiRenderSelect,
  toggle: ddMuiRenderToggle,
  button: ddMuiRenderButton,
  page: ddMuiRenderPage,
  stepper: ddMuiRenderStepper,
  tabs: ddMuiRenderTabs,
  typography: ddMuiRenderTypography,
  checkbox: ddMuiRenderCheckbox,
  tree: ddMuiRenderTree,
  radioGroup: ddMuiRenderRadioGroup,
};

export const muiRenderElement = renderElement;
