/* eslint-disable react/forbid-dom-props */
import React from 'react';
import { Alert, Fade, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { A } from '@/shared/components/sanitizedTags';
import { ChemicalMimeType, obtainIndigo } from '@/shared/utils/indigo';
import { copyImage, copyText, downloadImage, downloadText, preparePNG, prepareSVGForExport, prepareSVGForURL } from './downloadUtils';
import { LIGHTBOX_SHOW_EVENT_CLICKED } from 'ASSETS/javascripts/light_box_handler';
import { Mixture } from 'chemical-mixtures/Mixture';
import { ArrangeMixture } from 'chemical-mixtures/ArrangeMixture';
import { ExportMInChI, MInChISegment } from 'chemical-mixtures/ExportMInChI';
import { MixfileComponent } from 'chemical-mixtures/Mixfile';
import { MetaVector } from 'webmolkit/gfx/MetaVector';
import { DrawMixture } from 'chemical-mixtures/DrawMixture';
import { Box } from 'webmolkit/util/Geom';
import { RenderPolicy } from 'webmolkit/gfx/Rendering';
import { OutlineMeasurement } from 'webmolkit/gfx/ArrangeMeasurement';
import { InChIDelegate } from 'chemical-mixtures/InChIDelegate';

type Props = {
  mixtureData: string; // the primary mixture which is associated with the molecule
  moleculeName: string;
};

enum SelectedTab {
  SVG = 'tab_svg',
  PNG = 'tab_png',
  Mixfile = 'tab_mixfile',
  MInChI = 'tab_minchi',
}

type State = {
  beenOpened: boolean;
  selectedTab: SelectedTab;
  inputWidth: string;
  inputHeight: string;
  minchi: string;
  minchiSegments?: MInChISegment[],
  minchiError?: string;
  copiedTypes: string[];
};

const PAYLOAD_W = 500, PAYLOAD_H = 500;
const SCALE_BASE = 30, SCALE_INTERIM = 300;
const MIN_SIZE = 50, MAX_SIZE = 2000;

export class DownloadMixtureImage extends React.Component<Props, State> {
  private mixture: Mixture;
  private fileRoot: string;
  private layout: ArrangeMixture;
  private baseWidth: number;
  private baseHeight: number;

  constructor(props: Props) {
    super(props);

    this.mixture = Mixture.deserialise(props.mixtureData);
    this.recursivelyFinagleComponent(this.mixture.mixfile);

    const chars: string[] = [];
    for (const ch of props.moleculeName) {
      const keep = /[A-Za-z0-9-]/.test(ch);
      chars.push(keep ? ch : '_');
    }
    this.fileRoot = chars.join('') || 'download';

    this.layout = this.layoutMixture();

    this.baseWidth = this.layout.width * SCALE_BASE / SCALE_INTERIM;
    this.baseHeight = this.layout.height * SCALE_BASE / SCALE_INTERIM;

    this.state = {
      beenOpened: false,
      selectedTab: SelectedTab.SVG,
      inputWidth: Math.ceil(this.baseWidth).toString(),
      inputHeight: Math.ceil(this.baseHeight).toString(),
      minchi: null,
      copiedTypes: [],
    };
  }

  private recursivelyFinagleComponent(comp: MixfileComponent): void {
    const batch = (comp.identifiers ?? {}).vaultBatch as string;
    if (batch) {
      comp.name = comp.name ? `${comp.name}-${batch}` : batch;
      delete comp.identifiers.vaultBatch;
    }
    for (const sub of (comp.contents ?? [])) {
      this.recursivelyFinagleComponent(sub);
    }
  }

  public render(): JSX.Element {
    if (!this.state.beenOpened) {
      return (
        <div
          className={LIGHTBOX_SHOW_EVENT_CLICKED}
          ref={(element) => this.observeActualElement(element)}>
          Loading...
        </div>
      );
    }

    const { selectedTab, inputWidth, inputHeight, minchi, minchiError, copiedTypes } = this.state;
    const hasSize = selectedTab == SelectedTab.SVG || selectedTab == SelectedTab.PNG;
    if (!this.layout) {
      this.layout = this.layoutMixture();
    }

    let width = parseInt(inputWidth), height = parseInt(inputHeight);
    if (!(width > 50)) width = 50;
    if (!(height > 50)) height = 50;

    const gfx = new MetaVector();
    const draw = new DrawMixture(this.layout, gfx);
    draw.draw();
    gfx.normalise();
    gfx.transformIntoBox(new Box(0, 0, width, height));
    gfx.setSize(width, height);

    let copyAvailable = true;
    if (selectedTab == SelectedTab.PNG) {
      copyAvailable = navigator.clipboard && typeof ClipboardItem != 'undefined';
    }

    if (selectedTab == SelectedTab.MInChI && !minchi && !minchiError) {
      setTimeout(() => this.buildMInChI().then(), 1);
    }

    const panelW = `calc(${PAYLOAD_W}px + 4em)`;
    const panelH = `calc(${PAYLOAD_H}px + 4em)`;

    let elementCopy = (
      <A onClick={() => { if (copyAvailable) this.clickCopy(gfx); }}>
        <span className="fa fa-files-o icon-10" style={{ paddingRight: '0.5em' }}></span>
        Copy
        {copiedTypes.length > 0 && (
          <Fade in={true} timeout={1000}>
            <Alert
              className="DownloadMoleculeImage-alert"
              severity="success"
              >
              Copied {copiedTypes[0]} to clipboard.
            </Alert>
          </Fade>
        )}
      </A>
    );
    if (!copyAvailable) {
      const tooltipCopy = (
        <Typography>
          Your browser does not support this clipboard copy operation.
        </Typography>
      );
      elementCopy = (
        <Tooltip
          key="tip-copy"
          title={tooltipCopy}
          arrow
          placement="right"
          >
          <div className="DownloadMoleculeImage-unclickableLink">
            <span className="fa fa-files-o icon-10" style={{ paddingRight: '0.5em' }}></span>
            Copy
          </div>
        </Tooltip>
      );
    }

    return (
      <>
        <TabContext value={this.state.selectedTab}>
          <div className="DownloadMoleculeImage-tabbar">
            <div>
              <Tabs value={selectedTab} onChange={this.handleChangeTab}>
                <Tab key={SelectedTab.SVG} label="SVG" value={SelectedTab.SVG}/>
                <Tab key={SelectedTab.PNG} label="PNG" value={SelectedTab.PNG}/>
                <Tab key={SelectedTab.Mixfile} label="Mixfile" value={SelectedTab.Mixfile}/>
                <Tab key={SelectedTab.MInChI} label="MInChI" value={SelectedTab.MInChI}/>
              </Tabs>
            </div>
          </div>
          <div className={`DownloadMoleculeImage-sizing ${hasSize ? '' : 'DownloadMoleculeImage-disabled'}`}>
            <div>Size</div>
            <input
              className="DownloadMoleculeImage-size-input"
              type="number"
              min={MIN_SIZE}
              max={MAX_SIZE}
              value={inputWidth}
              onChange={(event) => this.changeWidth(event.target.value)}
              disabled={!hasSize}
              autoFocus={true}
              />
            <div>{'\u{2715}'}</div>
            <input
              className="DownloadMoleculeImage-size-input"
              type="number"
              min={MIN_SIZE}
              max={MAX_SIZE}
              value={inputHeight}
              onChange={(event) => this.changeHeight(event.target.value)}
              disabled={!hasSize}
              />
          </div>
          <div style={{ width: panelW, height: panelH }}>
            <TabPanel value={SelectedTab.SVG}>
              {this.renderPreview(gfx, prepareSVGForURL(gfx))}
            </TabPanel>
            <TabPanel value={SelectedTab.PNG}>
              {this.renderPreview(gfx, preparePNG(gfx))}
            </TabPanel>
            <TabPanel value={SelectedTab.Mixfile}>
              {this.renderMixfile()}
            </TabPanel>
            <TabPanel value={SelectedTab.MInChI}>
              {this.renderMInChI()}
            </TabPanel>
          </div>
          <div className="DownloadMoleculeImage-downloadline">
            <A onClick={() => this.clickDownload(gfx)}>
              <span className="fa fa-download icon-10" style={{ paddingRight: '0.5em' }}></span>
              Download
            </A>
            {elementCopy}
          </div>
        </TabContext>
      </>
    );
  }

  private observeActualElement(element: HTMLElement): void {
    if (!element || this.state.beenOpened) return;

    const callbackBeenOpened = () => {
      if (this.state.beenOpened) return;
      this.setState({ beenOpened: true });
    };

    element.addEventListener(LIGHTBOX_SHOW_EVENT_CLICKED, callbackBeenOpened);
  }

  private renderPreview(gfx: MetaVector, src:string): JSX.Element {
    const scale = Math.min(1, Math.min(PAYLOAD_W / gfx.width, PAYLOAD_H / gfx.height));
    const width = Math.ceil(gfx.width * scale), height = Math.ceil(gfx.height * scale);

    const percentScale = Math.floor(100 * Math.min(PAYLOAD_W / gfx.width, PAYLOAD_H / gfx.height));
    const scalenote = percentScale < 100 && (
      <div key="note" className="DownloadMoleculeImage-scalenote">
        scale {percentScale}%
      </div>
    );

    return (
      <div
        className="DownloadMoleculeImage-center"
        style={{ width: `${PAYLOAD_W}px`, height: `${PAYLOAD_H}px` }}
        >
        <div key="preview" className="DownloadMoleculeImage-centrepoint">
          <div className="DownloadMoleculeImage-outline">
            <img
              src={src}
              style={{ display: 'block', width: `${width}px`, height: `${height}px` }}
              />
          </div>
          {scalenote}
        </div>
      </div>
    );
  }

  private layoutMixture(): ArrangeMixture {
    const { mixture } = this;

    const policy = RenderPolicy.defaultBlackOnWhite(SCALE_INTERIM);
    const measure = new OutlineMeasurement(0, 0, policy.data.pointScale);
    const layout = new ArrangeMixture(mixture, measure, policy);
    layout.arrange();

    return layout;
  }

  private renderMixfile(): JSX.Element {
    return (
      <textarea
        className="DownloadMoleculeImage-textraw"
        style={{ width: `${PAYLOAD_W}px`, height: `${PAYLOAD_H}px` }}
        value={this.mixture.serialise()}
        readOnly
        >
      </textarea>
    );
  }

  private renderMInChI(): JSX.Element {
    const { minchi, minchiSegments, minchiError } = this.state;
    if (minchiError) {
      return (
        <>{minchiError}</>
      );
    }
    if (!minchi) {
      return (
        <>
          Preparing MInChI notation...
        </>
      );
    }

    const characters: JSX.Element[] = [];
    const COLORS = {
      [MInChISegment.Header]: '#FF4040',
      [MInChISegment.Component]: '#4040FF',
      [MInChISegment.Hierarchy]: '#808000',
      [MInChISegment.Concentration]: '#008000',
    };
    for (let n = 0; n < minchi.length; n++) {
      const color = COLORS[minchiSegments[n]];
      characters.push((
        <div
          key={`ch${n}`}
          style={{ display: 'inline-block', color }}
          >
          {minchi[n]}
        </div>
      ));
      characters.push((<wbr key={`br${n}`}/>));
    }

    return (
      <>
        <pre className="DownloadMoleculeImage-minchi">
          {characters}
        </pre>
        <div className="DownloadMoleculeImage-minchiref">
          Mixtures InChI (<i>MInChI</i>) is a concise notation for mixtures layered on top of the International Chemical Identifier (InChI).
          For  more information, see <A href="https://github.com/IUPAC/MInChI" target="_blank">IUPAC Project Page</A>.
        </div>
      </>
    );
  }

  private changeWidth(strW: string) {
    const width = Math.min(parseInt(strW) >= MIN_SIZE ? parseInt(strW) : MIN_SIZE, MAX_SIZE);
    const height = this.baseHeight * (width / this.baseWidth);
    const strH = Math.ceil(height).toString();
    this.setState({ inputWidth: strW, inputHeight: strH });
  }

  private changeHeight(strH: string) {
    const height = Math.min(parseInt(strH) >= MIN_SIZE ? parseInt(strH) : MIN_SIZE, MAX_SIZE);
    const width = this.baseWidth * (height / this.baseHeight);
    const strW = Math.ceil(width).toString();
    this.setState({ inputWidth: strW, inputHeight: strH });
  }

  private handleChangeTab = (_event: React.ChangeEvent, val: string) => {
    this.setState({ selectedTab: val as SelectedTab });
  };

  private clickDownload(gfx: MetaVector): void {
    const { selectedTab } = this.state;
    if (selectedTab == SelectedTab.SVG) {
      const svg = prepareSVGForExport(gfx);
      downloadText(svg, `${this.fileRoot}.svg`, 'image/svg+xml');
    } else if (selectedTab == SelectedTab.PNG) {
      downloadImage(gfx, `${this.fileRoot}.png`);
    } else if (selectedTab == SelectedTab.Mixfile) {
      const json = this.mixture.serialise();
      downloadText(json, `${this.fileRoot}.mixfile`, 'application/json');
    } else if (selectedTab == SelectedTab.MInChI) {
      const { minchi } = this.state;
      if (!minchi) return;
      downloadText(minchi, `${this.fileRoot}.mixfile`, 'text/plain');
    }
  }

  private clickCopy(gfx: MetaVector): void {
    const { selectedTab } = this.state;
    if (selectedTab == SelectedTab.SVG) {
      const svg = prepareSVGForExport(gfx);
      copyText(svg);
      this.notifyClipboard('SVG');
    } else if (selectedTab == SelectedTab.PNG) {
      copyImage(gfx);
      this.notifyClipboard('PNG');
    } else if (selectedTab == SelectedTab.Mixfile) {
      const json = this.mixture.serialise();
      copyText(json);
      this.notifyClipboard('Mixfile');
    } else if (selectedTab == SelectedTab.MInChI) {
      const { minchi } = this.state;
      if (!minchi) return;
      copyText(minchi);
      this.notifyClipboard('MInChI');
    }
  }

  private notifyClipboard(copiedType: string): void {
    this.setState({ copiedTypes: [copiedType, ...this.state.copiedTypes] });
    setTimeout(() => {
      const { copiedTypes } = this.state;
      this.setState({ copiedTypes: copiedTypes.slice(0, copiedTypes.length - 1) });
    }, 10000);
  }

  private async buildMInChI(): Promise<void> {
    const indigo = obtainIndigo();
    if (!indigo) {
      this.setState({ minchiError: 'Unable to load Indigo library.' });
      return;
    }

    const mixture = this.mixture.clone();
    for (const comp of mixture.getComponents()) {
      if (!comp.molfile) continue;
      const result = await indigo.convert(comp.molfile, { outputFormat: ChemicalMimeType.InChI });
      comp.inchi = result.struct;
    }

    class InChI extends InChIDelegate {
      // empty definition, because we are precalculating the InChI codes and passing that in
    }

    const generator = new ExportMInChI(mixture.mixfile, new InChI());
    generator.formulate();
    this.setState({
      minchi: generator.getResult(),
      minchiSegments: generator.getSegment(),
    });
  }
}
