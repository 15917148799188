import { PropsOf } from '@emotion/react';
import { Tooltip } from '@mui/material';
import React from 'react';

interface Props extends Partial<PropsOf<typeof Tooltip>> {
  title: React.ReactNode;
  offset_x?: number;
  offset_y?: number;
}
export const ChildWithTooltip = (props: Props) => {
  let offsetProps = {};
  if (props.offset_x !== undefined || props.offset_y !== undefined) {
    offsetProps = {
      slotProps: {
        'popper':
        {
          'modifiers':
            [{
              'name': 'offset',
              'options': {
                'offset': [props.offset_x ?? 0, props.offset_y ?? 0],
              },
            }],
        },
      },
    };
  }

  return <Tooltip {...offsetProps} {...props} placement='bottom'>
    <span>{props.children}</span>
  </Tooltip>;
};
