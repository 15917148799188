/* eslint-disable multiline-ternary, no-nested-ternary */
import { A, Img } from '@/shared/components/sanitizedTags.js';
import addIcon from 'ASSETS/images/cdd30/icons/add.png';
import magnifierIcon from 'ASSETS/images/cdd30/icons/magnifier.png';
import { observer } from 'mobx-react';
import React from 'react';
import { getRootStore } from '@/stores/rootStore';
import { SearchBar, SearchResultsTable } from '@/Protocols/components';
import autobind from 'class-autobind';
import { term } from '@/shared/utils/stringUtils';
import { AdvancedSearchWidget } from './AdvancedSearchWidget';
import { OntologyTemplate } from '@/Annotator/data/templates';
import { PropertyGridWidget } from './PropertyGridWidget';
import { Alert, Fade, Popover, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { ColumnForDisplay, Protocol } from './types';
import { copyText, downloadText } from '@/components/DownloadMoleculeImage/downloadUtils';
import { PROTOCOL_ID_EXPLORE, PROTOCOL_ID_NAME, PROTOCOL_ID_ONTOLOGY } from './protocolsStore';
import { PropertyGridData } from './PropertyGridData';
import { RenderSVGResult, renderSVGGraphicsForGrid } from './PropertyGridRender';
import { CDD } from '@/typedJS';
import { AnyObject } from '@/types';

type Props = {
  canCreate: boolean,
  query: string,
  templateList: OntologyTemplate[],
};

enum SelectedTab {
  ResultsTable = 'tab_resultstable',
  PropertyGrid = 'tab_propertygrid',
}

type State = {
  selectedTab: SelectedTab;
  isExportOpen: boolean;
  activityNotes: string[];
};

@observer
export default class Protocols extends React.Component<Props, State> {
  private anchorExportEl: HTMLElement = null;

  constructor(props) {
    super(props);
    autobind(this);
    this.store.setQuery({ text: this.props.query });
    this.store.setOriginalTemplateList(props.templateList);
    this.state = {
      selectedTab: SelectedTab.ResultsTable,
      isExportOpen: false,
      activityNotes: [],
    };
    this.store.loadProtocols();
  }

  get store() {
    return getRootStore().protocolsStore;
  }

  handleGetRowHref(row: Protocol): string {
    return row.url;
  }

  render(): JSX.Element {
    const {
      displayedColumns,
      availableColumns,
      rows,
      query,
      loading,
      visibleColumnIds,
      enableSearch,
      newUrl,
      loadingBranches,
      setVisibleColumnIds,
      setQuery,
      handleClickColumn,
      handleClickSearch,
    } = this.store;
    const { isExportOpen, activityNotes } = this.state;

    if (!availableColumns || !rows) {
      return null;
    }

    const protocolsCount = <div id='protocols-count'>
      {!loading && rows.length >= 1 ? (
        <>
          Showing {rows.length}{' '}
          {rows.length === 1 ? term('protocol') : term('protocol.other')}
        </>
      ) : (
        <span>&nbsp;</span>
      )}
    </div>;

    const urlSearch = this.urlForExploreProtocols();
    let searchWithinResults = (
      <A href={urlSearch}>
        Explore {term('protocol', true)} Data
      </A>
    );
    if (urlSearch?.length > 2000) { // fnord
      const tooltip = (
        <Typography>
          Use a more specific filter to reduce the number of protocols to a smaller set in order to launch the explore page.
        </Typography>
      );
      searchWithinResults = (
        <Tooltip
          key="tip-noexplore"
          title={tooltip}
          arrow
          placement="left"
        >
          <div>
            Explore {term('protocol.other', true)}
          </div>
        </Tooltip>
      );
    }

    const exportTable = <div id="export-table">
      <A ref={(el) => this.anchorExportEl = el} href="#" onClick={this.handleToggleExport}>
        Export Results
      </A>
      <Popover
        open={isExportOpen}
        onClose={this.handleToggleExport}
        anchorEl={this.anchorExportEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <div className="AdvancedSearch-popover-outline">
          <div>Export Table</div>
          <ul className="AdvancedSearch-popover-listblock">
            <li><A href="#" onClick={this.handleExportClipboard}>Copy to clipboard</A></li>
            <li><A href="#" onClick={this.handleExportAsFile}>Save as file</A></li>
          </ul>
          <div>Export Property Grid</div>
          <ul className="AdvancedSearch-popover-listblock">
            <li>
              Copy as&nbsp;
              <A href="#" onClick={this.handleExportCopyGridSVG}>SVG</A>
              &nbsp;or&nbsp;
              <A href="#" onClick={this.handleExportCopyGridPNG}>PNG</A>
            </li>
            <li>
              Save as&nbsp;
              <A href="#" onClick={this.handleExportSaveGridSVG}>SVG</A>
              &nbsp;or&nbsp;
              <A href="#" onClick={this.handleExportSaveGridPNG}>PNG</A>
            </li>
          </ul>
        </div>
      </Popover>
    </div>;

    const searchResultsTable = rows.length > 0 && (
      <SearchResultsTable
        displayedColumns={displayedColumns}
        availableColumns={availableColumns}
        rows={rows}
        getRowHref={this.handleGetRowHref}
        visibleColumnIds={visibleColumnIds}
        onChangeVisibleColumnIds={setVisibleColumnIds}
        onClickColumn={handleClickColumn}
        renderCell={this.renderCell}
        loadingBranches={loadingBranches}
      />
    );
    const tabsIfApplicable = rows.length == 0 ? null : (
      <TabContext value={this.state.selectedTab}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ flexGrow: '1' }}>
            <Tabs value={this.state.selectedTab} onChange={this.handleChangeTab}>
              <Tab key={SelectedTab.ResultsTable} label="Table" value={SelectedTab.ResultsTable} />
              <Tab key={SelectedTab.PropertyGrid} label="Property Grid" value={SelectedTab.PropertyGrid} />
            </Tabs>
          </div>
          <div>
            {protocolsCount}
            <div className="AdvancedSearch-button-flexrow">
              <div id="export-table">{searchWithinResults}</div>
              {exportTable}
            </div>
          </div>
        </div>

        <TabPanel value={SelectedTab.ResultsTable}>
          {searchResultsTable}
        </TabPanel>
        <TabPanel value={SelectedTab.PropertyGrid}>
          <PropertyGridWidget store={this.store} />
        </TabPanel>
      </TabContext>
    );

    return (
      <div id='protocols-index' data-testid='protocols-index'>
        <div className='container'>
          <section className='section__header'>
            <div className='data-filters-flex'>
              <div style={{ marginBottom: '-1em', flexGrow: '0' }}>
                <SearchBar
                  query={query}
                  setQuery={setQuery}
                  enableSearch={enableSearch}
                  onClickSearch={handleClickSearch}
                />
              </div>
              {this.props.canCreate && (
                <div id='create-new-link' style={{ flexGrow: '0' }}>
                  <div className='item'>
                    <A href={newUrl}>
                      <Img
                        width={16}
                        height={16}
                        className='icon-16'
                        alt='Add'
                        src={addIcon}
                      />
                      Create a new {term('protocol')}
                    </A>
                  </div>
                </div>
              )}
            </div>
          </section>

          <AdvancedSearchWidget store={this.store} />

          {!loading && !rows.length && (
            <div id='entries-show' className='noDataMessage'>
              {`No ${term('protocol.other')} found.`}
            </div>
          )}

          {tabsIfApplicable}
        </div>

        {activityNotes.length > 0 && (
          <Fade in={true} timeout={1000}>
            <Alert
              className="DownloadMoleculeImage-alert"
              severity="success"
            >
              {activityNotes[0]}
            </Alert>
          </Fade>
        )}
      </div>
    );
  }

  renderCell(data: Protocol, column: ColumnForDisplay): JSX.Element {
    if (column.parentID == PROTOCOL_ID_ONTOLOGY) {
      const labelList = this.store.findOntologyLabel(data, column.id as string);
      return (<>{labelList.join(', ')}</>);
    }

    const val = data.protocol_fields.find((field) => field.protocol_field_definition_id === column.id);
    if (typeof val === 'object') {
      const { file_name, display_string, value, url } = val as AnyObject;
      if (url) {
        return <A href={url} target="_blank">{display_string ?? value ?? file_name}</A>;
      }
    }

    switch (column.id) {
      case PROTOCOL_ID_NAME:
        return (
          <b>
            <A href={data.url}>{data.name}</A>
          </b>
        );

      case PROTOCOL_ID_EXPLORE:
        return data.has_compounds
          ? (<A href={data.url + '/explore'}>
            <Img
              width={16}
              height={16}
              className='icon-16'
              alt='explore'
              src={magnifierIcon}
            />
            Explore
          </A>)
          : (<>No data</>);

      default:
        return (
          <A className='plain-text' href={data.url}>
            {data[column.id]}
          </A>
        );
    }
  }

  private notifyActivity(note: string): void {
    this.setState({ activityNotes: [note, ...this.state.activityNotes] });
    setTimeout(() => {
      const { activityNotes } = this.state;
      this.setState({ activityNotes: activityNotes.slice(0, activityNotes.length - 1) });
    }, 10000);
  }

  private urlForExploreProtocols = () => {
    const { rows } = this.store;
    if (rows.length == 0) return;

    const params: string[] = [];

    // note: this is the official style, but unfortunately it blags the 2048 character limit for URLs pretty quickly...
    /*
    for (let n = 0; n < rows.length; n++) {
      if (n > 0) {
        params.push(`${encodeURIComponent('protocol_junction[]')}=or`);
      }
      params.push(`${encodeURIComponent('protocol_criterion_type[]')}=${encodeURIComponent('data set or protocol')}`);
      params.push(`${encodeURIComponent('data_set_or_protocol[]')}=${rows[n].id}`);
    }
    */
    const idList = rows.map((row) => `${row.id}`).join(':');
    params.push(`protocol_id_list=${encodeURIComponent(idList)}`);

    return `/${CDD.ActiveDataContext.toContextParam}/searches/new?${params.join('&')}`;
  };

  private handleChangeTab = (event: React.ChangeEvent, val: string) => {
    this.setState({ selectedTab: val as SelectedTab });
  };

  private handleToggleExport = () => {
    this.setState({ isExportOpen: !this.state.isExportOpen });
  };

  private handleExportClipboard = () => {
    const text = this.store.convertVisibleTableToCSV();
    copyText(text);
    this.notifyActivity('Copied table to clipboard.');
    this.setState({ isExportOpen: false });
  };

  private handleExportAsFile = () => {
    const text = this.store.convertVisibleTableToCSV();
    downloadText(text, 'protocols.csv', 'text/plain');
    this.setState({ isExportOpen: false });
  };

  private async obtainSVG(): Promise<RenderSVGResult> {
    const { store } = this;
    const gridData = new PropertyGridData(
      store.eligibleProtocols,
      store.fieldDefinitions,
      store.currentTemplateSchema(),
      store.filterLayers,
    );
    return await renderSVGGraphicsForGrid(gridData);
  }

  private handleExportCopyGridSVG = () => {
    this.notifyActivity('Generating image');

    setTimeout(() => {
      (async () => {
        const { svg } = await this.obtainSVG();
        copyText(svg);
        this.notifyActivity('Copied grid to clipboard as SVG.');
        this.setState({ isExportOpen: false });
      })();
    }, 1);
  };

  private handleExportCopyGridPNG = () => {
    this.notifyActivity('Generating image');

    setTimeout(() => {
      (async () => {
        const { svg, width, height } = await this.obtainSVG();

        const img = new Image();
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const svgURL = window.URL.createObjectURL(blob);

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const density = Math.min(4, Math.min(2000 / width, 2000 / height));
          canvas.style.width = `${width}px`;
          canvas.style.height = `${height}px`;
          canvas.width = Math.ceil(width) * density;
          canvas.height = Math.ceil(height) * density;
          const ctx = canvas.getContext('2d');
          ctx.scale(density, density);
          ctx.drawImage(img, 0, 0);

          const fetchBlob = new Promise<Blob>(resolve => {
            canvas.toBlob((blob) => {
              resolve(blob);
            }, 'image/png', 1);
          });
          const item = new ClipboardItem({ 'image/png': fetchBlob });
          (async () => {
            await navigator.clipboard.write([item]);
            this.notifyActivity('Copied grid to clipboard as PNG.');
            this.setState({ isExportOpen: false });
          })();

          canvas.remove();
        };
        img.src = svgURL;
      })();
    }, 1);
  };

  private handleExportSaveGridSVG = () => {
    this.notifyActivity('Generating image');

    setTimeout(() => {
      (async () => {
        const { svg } = await this.obtainSVG();
        downloadText(svg, 'protocolgrid.svg', 'text/plain');
        this.notifyActivity('Image saved.');
        this.setState({ isExportOpen: false });
      })();
    }, 1);
  };

  private handleExportSaveGridPNG = () => {
    this.notifyActivity('Generating image');

    setTimeout(() => {
      (async () => {
        const { svg, width, height } = await this.obtainSVG();

        const img = new Image();
        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const svgURL = window.URL.createObjectURL(blob);

        img.onload = () => {
          const canvas = document.createElement('canvas');
          const density = Math.min(4, Math.min(2000 / width, 2000 / height));
          canvas.style.width = `${width}px`;
          canvas.style.height = `${height}px`;
          canvas.width = Math.ceil(width) * density;
          canvas.height = Math.ceil(height) * density;
          const ctx = canvas.getContext('2d');
          ctx.scale(density, density);
          ctx.drawImage(img, 0, 0);

          const pngURL = canvas.toDataURL('image/png');
          canvas.remove();

          const a = document.createElement('a');
          a.setAttribute('href', pngURL);
          a.setAttribute('download', 'protocolgrid.png');
          a.click();
          a.remove();
          this.notifyActivity('Image saved.');
          this.setState({ isExportOpen: false });
        };
        img.src = svgURL;
      })();
    }, 1);
  };
}
