import { IconButton, Tooltip } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import autobind from 'class-autobind';
import React, { FormEventHandler } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import FilterList from '@mui/icons-material/FilterList';

import { TSearchQuery } from '@/Samples/types';
import { term } from '@/shared/utils/stringUtils';
import { Handlers, InventorySearchViewAPIProps } from '../types';
import {
  FilterFields,
  FilterFieldsProps,
} from './Filter/FilterFields/FilterFields';
import { FilterItemProps } from './Filter/FilterFields/FilterItem';
import { SearchButton } from './SearchButton';
import { SubstructureInput } from './SubstructureInput';
import { searchField as styles } from './styles';

export type SearchFilterProps = {
  handlers: {
    submitQuery: () => void;
    handleFilterPanelClose: () => void;
    handleFilterPanelToggle: () => void;
    updateQueryText: (updatedVal: Pick<TSearchQuery, 'text'>) => void;
    updateQueryMRV: (updatedVal: Pick<TSearchQuery, 'mrv'>) => void;
  } & Handlers<FilterItemProps> &
  Handlers<FilterFieldsProps>;
  searchQuery: Omit<TSearchQuery, 'query_filters'> & {
    query_filters: (TSearchQuery['query_filters'][number] | null)[];
  };
  filterPanelVisible: boolean;
} & Pick<
  FilterFieldsProps,
  'availableFilters' | 'vaultNameMap' | 'id_list_fd_ids'
> &
  Pick<InventorySearchViewAPIProps, 'user_structure_masked'>;

export class SearchFilters extends React.Component<SearchFilterProps> {
  constructor(props) {
    super(props);
    autobind(this);
  }

  onSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    const {
      handlers: { submitQuery, handleFilterPanelClose },
    } = this.props;

    handleFilterPanelClose();
    submitQuery();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onQueryTextChange(event: { currentTarget: { value: any } }) {
    const {
      handlers: { updateQueryText: updateQuery },
    } = this.props;
    const { value } = event.currentTarget;

    updateQuery({ text: value });
  }

  renderFilterIcon = () => {
    const {
      filterPanelVisible,
      handlers: { handleFilterPanelToggle },
    } = this.props;

    return (
      <IconButton
        className="search-bar__filter"
        style={styles.filterButtonStyle}
        onClick={handleFilterPanelToggle}
      >
        {filterPanelVisible ? <ExpandMore /> : <FilterList />}
      </IconButton>
    );
  };

  renderFilterPanel = () => {
    return (
      <div>
        <FilterFields
          handlers={this.props.handlers}
          availableFilters={this.props.availableFilters}
          queryFilters={this.props.searchQuery.query_filters}
          vaultNameMap={this.props.vaultNameMap}
          id_list_fd_ids={this.props.id_list_fd_ids}
        />
      </div>
    );
  };

  render() {
    const { searchQuery, filterPanelVisible, handlers } = this.props;

    return (
      <form className="search-bar__form" onSubmit={this.onSubmit}>
        <Accordion expanded={filterPanelVisible}>
          <AccordionSummary className="search-bar__summary">
            <div className="search-bar__container">
              {filterPanelVisible
                ? (
                    this.renderFilterIcon()
                  )
                : (
                  <Tooltip title={`Filter ${term('entry.other', true)}`}>
                    {this.renderFilterIcon()}
                  </Tooltip>
                  )}
              <input
                className="search-bar__input"
                value={searchQuery.text || ''}
                onChange={this.onQueryTextChange}
                placeholder="Search (e.g. keyword*)"
              />
              {this.props.user_structure_masked
                ? (
                  <></>
                  )
                : (
                  <SubstructureInput
                    submitQuery={handlers.submitQuery}
                    updateQuery={handlers.updateQueryMRV}
                    mrv={searchQuery.mrv}
                  />
                  )}
              <SearchButton />
            </div>
          </AccordionSummary>

          <AccordionDetails className="search-bar__filters">
            <this.renderFilterPanel />
          </AccordionDetails>
        </Accordion>
      </form>
    );
  }
}
