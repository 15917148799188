import React from 'react';
import constants from 'javascripts/constants.js';
import isString from 'lodash/isString';
import { A } from './sanitizedTags';

const { ELN_INTERNAL_LINK_VALID_URL_SCHEMES } = constants as { ELN_INTERNAL_LINK_VALID_URL_SCHEMES: string[] };

const emptyTag: Record<string, React.JSX.Element> = {
  br: <br />,
};

const entities: Record<string, React.JSX.Element> = {
  deg: <>&deg;</>,
};

type RenderTagFunction = (text: string | React.JSX.Element) => React.JSX.Element;

const filledTag: Record<string, RenderTagFunction> = {
  b: (s) => <b>{s}</b>,
  p: (s) => <p>{s}</p>,
  i: (s) => <i>{s}</i>,
  em: (s) => <em>{s}</em>,
  span: (s) => <span>{s}</span>,
  sup: (s) => <sup>{s}</sup>,
  sub: (s) => <sub>{s}</sub>,
};

const tagRegex = new RegExp('</*(' + Object.keys(filledTag).join('|') + ')>', 'i');
const emptyTagRegex = new RegExp('<(' + Object.keys(emptyTag).join('|') + ') */?>', 'i');
const entityRegex = new RegExp('&(' + Object.keys(entities).join('|') + ');', 'i');

const endRegex = Object.keys(filledTag).reduce((p: Record<string, RegExp>, k: string) => {
  p[k] = new RegExp('</' + k + '>', 'i');

  return p;
}, {});

const schemes = `(${ELN_INTERNAL_LINK_VALID_URL_SCHEMES.join('|')})`;
const urlRegex = `${schemes}:\\/\\/[/\\w?.=#%&+-_~:;,$@]*`;
const linkRegex = new RegExp(urlRegex);
const markdownRegex = new RegExp(`\\[([\\w\\s\\d]*)\\]\\((${urlRegex})\\)`);

const splitAtTag = (text: string, tag: RegExpMatchArray) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  return [text.slice(0, Math.max(0, tag.index!)), text.slice(Math.max(0, tag.index! + tag[0].length))];
};

type SupportedTag = {
  regex: RegExp;
  render?: (textMatch: RegExpMatchArray) => React.JSX.Element;
};

const supported: Record<string, SupportedTag> = {
  tag: {
    regex: tagRegex,
  },
  emptyTag: {
    regex: emptyTagRegex,
    render: (textMatch: RegExpMatchArray) => emptyTag[textMatch[1]],
  },
  entity: {
    regex: entityRegex,
    render: (textMatch: RegExpMatchArray) => entities[textMatch[1]],
  },
  link: {
    regex: linkRegex,
    render: (textMatch: RegExpMatchArray) => (<A href={textMatch[0]}>{textMatch[0]}</A>),
  },
  markdownLink: {
    regex: markdownRegex,
    render: (textMatch: RegExpMatchArray) => (<A href={textMatch[2]}>{textMatch[1] || textMatch[2]}</A>),
  },
};

type TagMatch = {
  key: string;
  textMatch: RegExpMatchArray;
};

const processLine = (input_text: string | number, allowLinks: boolean): React.JSX.Element => {
  if (input_text !== 0 && !input_text) {
    return <></>;
  }

  let text: string;
  if (isString(input_text)) {
    text = input_text as string;
  } else {
    text = input_text.toString();
  }

  // find the next match if any
  let nextIndex = text.length;
  let nextMatch: TagMatch | undefined;

  Object.entries(supported).forEach(([key, { regex }]) => {
    if (['link', 'markdownLink'].includes(key) && !allowLinks) {
      return;
    }

    const textMatch = text.toString().match(regex);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    if (!textMatch || textMatch.index! > nextIndex) {
      return <></>;
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    nextIndex = textMatch.index!;
    nextMatch = { key, textMatch };
  });

  // No more supported tags - return unchanged
  if (!nextMatch) {
    return <>{text}</>;
  }

  const { key, textMatch } = nextMatch;
  const [frag1, frag2] = splitAtTag(text, textMatch);
  const supportedKey = supported[key];

  if (supportedKey.render !== undefined) {
    return (
      <>
        {frag1}
        {supportedKey.render(textMatch)}
        {processLine(frag2, allowLinks)}
      </>
    );
  }

  const tag = textMatch[1].toLowerCase();
  const wrapIt = filledTag[textMatch[1].toLowerCase()];
  const fullTag = textMatch[0];

  // The found tag is an end tag - assume that a start tag is missing at the beginning of the text
  if (fullTag.includes('/')) {
    return (
      <>
        {wrapIt(frag1)}
        {processLine(frag2, allowLinks)}
      </>
    );
  }

  const matchFound = frag2.match(endRegex[tag]);

  // Find no matching tag
  if (!matchFound) {
    return (
      <>
        {frag1}
        {wrapIt(processLine(frag2, allowLinks))}
      </>
    );
  }

  // Found matching tag
  const [frag21, frag22] = splitAtTag(frag2, matchFound);

  return (
    <>
      {frag1}
      {wrapIt(processLine(frag21, allowLinks))}
      {processLine(frag22, allowLinks)}
    </>
  );
};

const RenderSimpleHTML = ({ text, allowLinks = false }: { text: string; allowLinks?: boolean }) => (
  <>{processLine(text, allowLinks)}</>
);

export default RenderSimpleHTML;

export const internals = {
  linkRegex,
  markdownRegex,
};
