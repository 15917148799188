import React, { useEffect, useState } from 'react';
import { TextField } from '@mui/material';
import isEqual from 'lodash/isEqual';

// https://dev.to/kwirke/solving-caret-jumping-in-react-inputs-36ic
type Props = React.ComponentProps<typeof TextField>;

export const TextFieldCursorFix = (props: Props) => {
  const { value, onChange } = props;
  const [val, setVal] = useState(value);
  useEffect(() => {
    // when the props.value changes, update the local state
    if (!isEqual(value, val)) {
      setVal(value);
    }
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setVal(e.target.value);
    onChange(e);
  };
  return <TextField {...props} value={val} onChange={handleChange} />;
};
