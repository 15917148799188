/*
  Schema: the actualization of an ontology template, achieved by merging it with the underlying ontology data.
*/

/* eslint-disable multiline-ternary, no-nested-ternary, no-labels */

import { OntologyTemplate, TemplateAssignment, SpecificationType, TemplateGroup, TemplateValue, SuggestionType } from './templates';
import { OntologyTree, Branch } from './OntologyTree';
import { collapsePrefix } from './utils';
import { Vec } from 'webmolkit/util/Vec';

interface SchemaNode {
  parent: SchemaNode;
  uri: string;
  label: string;
  descr?: string;
  children: SchemaNode[];
  inSchema: boolean;
}

interface SchemaTree {
  root: SchemaNode;
  map: Record<string, SchemaNode>;
  sourceParentURI: string;
}

export interface SchemaBranch extends Branch {
  inSchema: boolean;
  treeKey: string;
}

interface CabinetBlock {
  lo: string;
  hi: string;
  nodes: SchemaNode[];
}

export class Schema {
  constructor(public template: OntologyTemplate) {
  }

  // looks up the template using propURI & groupNest; the groupNest must be defined for assignments that aren't in
  // the root; note that the priority is backwards (i.e. the groupURI directly attached to the root is at the end of
  // the array); returns null if it can't be found
  public findGroup(groupNest: string[]) {
    let group = this.template.root;
    for (let n = (groupNest ? groupNest.length : 0) - 1; n >= 0; n--) {
      group = group.subGroups.find((look) => look.groupURI == groupNest[n]);
      if (!group) return null;
    }
    return group;
  }

  public findAssignment(propURI: string, groupNest: string[]) {
    const group = this.findGroup(groupNest);
    if (group == null) return null;
    return group.assignments.find((look) => look.propURI == propURI);
  }

  // TODO: fuzzyFindAssignment ... like above, but tolerant of some degree of groupNest mismatch...

  // puts together a branch that corresponds to the content for a specific ontology, by splicing together instructions
  // in the template and the ontology tree; some fetches to the server may be required; for large trees, some content
  // may be as-yet-not loaded; the selected values in the list are guaranteed to be there
  public async composeBranch(assn: TemplateAssignment, selectedValues: string[] = []): Promise<SchemaBranch[]> {
    // this basically guarantees that the selected values are loaded, thus fully instantiated in branch
    for (const uri of selectedValues) await OntologyTree.values.getBranch(uri);
    const treeBranches: SchemaTree[] = [];

    // part 1: all affirmative inclusions cause the creation of a branch of nodes (overlap not considered)
    for (const value of assn.values) {
      if (![SpecificationType.Item, SpecificationType.WholeBranch, SpecificationType.Container].includes(value.spec)) continue;

      const branch = await OntologyTree.values.getBranch(value.uri, true, false, true);
      if (branch == null) {
        // consider this as a directive to add a new item)
        if (value.parentURI) {
          for (const tree of treeBranches) {
            const parent = tree.map[collapsePrefix(value.parentURI)];
            if (parent != null) {
              const node: SchemaNode = {
                parent,
                uri: collapsePrefix(value.uri),
                label: value.name,
                descr: value.descr || '',
                children: [],
                inSchema: true,
              };
              parent.children.push(node);
              tree.map[node.uri] = node;
            }
          }
        } else {
          // treat it as a root
          const node: SchemaNode = {
            parent: null,
            uri: collapsePrefix(value.uri),
            label: value.name,
            descr: value.descr || '',
            children: [],
            inSchema: true,
          };
          const tree: SchemaTree = {
            root: node,
            map: { [node.uri]: node },
            sourceParentURI: null,
          };
          treeBranches.push(tree);
        }
        continue;
      }

      // see if it's already in one of the tree hierarchies
      let already = false;
      for (const tree of treeBranches) {
        const node = tree.map[collapsePrefix(value.uri)];
        if (!node) continue;

        // replace details if applicable
        if (value.spec == SpecificationType.Item) {
          if (value.name) node.label = value.name;
          node.inSchema = true;
        }

        // if a parent is specified, move it to the new position
        const parentURI = collapsePrefix(value.parentURI);
        if (parentURI) {
          const parent = tree.map[parentURI];
          if (parent != null) {
            node.parent.children = node.parent.children.filter((look) => look !== node);
            parent.children.push(node);
            node.parent = parent;
          }
        }

        already = true;
      }
      if (already) continue;

      const map: Record<string, SchemaNode> = {};
      const node = this.fromBranch(null, branch, map);
      if (value.spec == SpecificationType.Item) {
        if (value.name) node.label = value.name;
      }

      if (value.spec == SpecificationType.Container) node.inSchema = false;

      const tree: SchemaTree = {
        root: node,
        map,
        sourceParentURI: null,
      };

      if (value.parentURI != null) tree.sourceParentURI = collapsePrefix(value.parentURI);
      else if (branch.parent != null) tree.sourceParentURI = branch.parent.uri;

      treeBranches.push(tree);
    }

    // part 2: apply deletions
    for (const value of assn.values) {
      if (![SpecificationType.Exclude, SpecificationType.ExcludeBranch].includes(value.spec)) continue;
      for (let n = 0; n < treeBranches.length; n++) {
        const tree = treeBranches[n];
        if (tree.root.uri == collapsePrefix(value.uri)) {
          // special case: destroy it
          treeBranches.splice(n, 1);
          n--;
          continue;
        }

        // normal case: snip out a section of it
        const node = tree.map[collapsePrefix(value.uri)];
        if (node != null) {
          if (node.parent != null) node.parent.children = node.parent.children.filter((look) => look !== node);
          this.pruneMap(node, tree.map);
        }
      }
    }

    if (treeBranches.length == 0) return null; // !! new SchemaTree(new SchemaTree.Node[0], assn);

    // part 3: any branch that has a root parent existing in another branch gets grafted onto that
    outer: for (let i = 0; i < treeBranches.length; i++) {
      const tree1 = treeBranches[i];
      if (tree1.sourceParentURI == null) continue;
      for (let j = 0; j < treeBranches.length; j++) {
        const tree2 = treeBranches[j];
        const parent = tree2.map[tree1.sourceParentURI];
        if (parent != null) {
          this.graftTree(tree2, parent, tree1.root);
          treeBranches.splice(i, 1);
          i--;
          continue outer;
        }
      }
    }

    // part 4: merge branches
    let tree = treeBranches[0];
    for (let n = 1; n < treeBranches.length; n++) {
      const other = treeBranches[n];

      // it may have become graftable since the previous section
      if (other.sourceParentURI != null) {
        const parent = tree.map[other.sourceParentURI];
        if (parent != null) {
          this.graftTree(tree, parent, other.root);
          continue;
        }
      }

      // otherwise, have to extend the trunks in some capacity
      tree = await this.mergeTrees(tree, other);
    }

    this.cabinetifyBranch(tree.root, [0]);

    // convert to array of "branch + extra" format, where each entry is considered to be a root
    if (tree.root.uri == null) {
      return tree.root.children.map((node) => this.convertToBranch(null, node));
    } else {
      return [this.convertToBranch(null, tree.root)];
    }
  }

  private fromBranch(parent: SchemaNode, branch: Branch, mapNode: Record<string, SchemaNode>): SchemaNode {
    const node = this.makeNode(branch);
    node.parent = parent;
    for (const child of branch.children) node.children.push(this.fromBranch(node, child, mapNode));
    mapNode[node.uri] = node;
    return node;
  }

  private makeNode(branch: Branch): SchemaNode {
    return {
      parent: null,
      uri: branch.uri,
      label: branch.label,
      children: [],
      inSchema: true,
    };
  }

  private pruneMap(node: SchemaNode, mapNode: Record<string, SchemaNode>): void {
    delete mapNode[node.uri];
    for (const child of node.children) this.pruneMap(child, mapNode);
  }

  private augmentMap(node: SchemaNode, mapNode: Record<string, SchemaNode>): void {
    mapNode[node.uri] = node;
    for (const child of node.children) this.augmentMap(child, mapNode);
  }

  // given that {node} has a parent URI that exists in {tree} as {parent}, copy over any missing constituents
  private graftTree(tree: SchemaTree, parent: SchemaNode, node: SchemaNode): void {
    // if the node's URI isn't present, add its whole branch as a child, and call it done
    const look = tree.map[node.uri];
    if (look == null) {
      node.parent = parent;
      parent.children.push(node);
      this.augmentMap(node, tree.map);
      return;
    }

    look.inSchema = look.inSchema || node.inSchema;

    // scan through all the children recursively
    for (const child of node.children) this.graftTree(tree, look, child);
  }

  // given two trees that do not "contain" each other, make them into a single tree by following the trunk backward, even if it leads to them
  // sharing a blank placeholder (i.e. they're both roots)
  private async mergeTrees(tree1: SchemaTree, tree2: SchemaTree): Promise<SchemaTree> {
    let trunk1: string[] = [], trunk2: string[] = [];
    for (let branch = await OntologyTree.values.getBranch(tree1.sourceParentURI); ; branch = branch.parent) {
      trunk1.push(branch ? branch.uri : null);
      if (branch == null) break;
    }
    for (let branch = await OntologyTree.values.getBranch(tree2.sourceParentURI); ; branch = branch.parent) {
      trunk2.push(branch ? branch.uri : null);
      if (branch == null) break;
    }

    let best1 = trunk1.length + trunk2.length, best2 = best1; // bignum
    for (let i = 0; i < trunk1.length; i++) {
      for (let j = 0; j < trunk2.length; j++) {
        if (trunk1[i] == trunk2[j] && i + j < best1 + best2) {
          best1 = i;
          best2 = j;
        }
      }
    }

    // if they both lead back to nothing in common, trim the trunks
    if (trunk1[best1] == null && trunk2[best2] == null) {
      tree1.root.parent = null;
      tree1.sourceParentURI = null;
      tree2.root.parent = null;
      tree2.sourceParentURI = null;
      trunk1 = [null];
      trunk2 = [null];
      best1 = best2 = 0;
    }

    for (let n = 0; n <= best1; n++) {
      if (trunk1[n] == tree1.root.uri) break;

      const uri = trunk1[n];
      let parent: SchemaNode;
      if (uri == null) {
        parent = { children: [] } as SchemaNode;
      } else {
        parent = this.makeNode(await OntologyTree.values.getBranch(uri));
      }
      parent.inSchema = false;
      parent.children.push(tree1.root);
      tree1.root.parent = parent;
      tree1.map[uri] = parent;
      tree1.root = parent;
    }
    for (let n = 0; n <= best2; n++) {
      if (trunk2[n] == tree2.root.uri) break;

      const uri = trunk2[n];
      let parent: SchemaNode;
      if (uri == null) {
        parent = { children: [] } as SchemaNode;
      } else {
        parent = this.makeNode(await OntologyTree.values.getBranch(uri));
      }
      parent.inSchema = false;
      parent.children.push(tree2.root);
      tree2.root.parent = parent;
      tree2.map[uri] = parent;
      tree2.root = parent;
    }

    if (tree2.root.children.length == 0) {
      tree1.root.children.push(tree2.root);
      tree2.root.parent = tree1.root;
    } else this.graftTree(tree1, tree1.root, tree2.root.children[0]);

    return tree1;
  }

  // converts the internal representation into the desired Branch structure
  private convertToBranch(parent: SchemaBranch, node: SchemaNode): SchemaBranch {
    const cached = OntologyTree.values.cachedBranch(node.uri);

    const sequence = [node.uri];
    for (let look: Branch = parent; look; look = look.parent) sequence.unshift(look.uri);

    const branch: SchemaBranch = {
      parent,
      uri: node.uri,
      label: node.label,
      children: null,
      description: node.descr ? node.descr : cached ? cached.description : null,
      altLabels: cached ? cached.altLabels : null,
      externalURLs: cached ? cached.externalURLs : null,
      group: cached ? cached.group : -1,
      inSchema: node.inSchema,
      treeKey: Schema.makeTreeKey(sequence),
    };
    branch.children = node.children.map((child) => this.convertToBranch(branch, child));
    return branch;
  }

  // turns a sequence of URIs of the form [rootURI, branchURI..., leafURI] into a hash key
  public static makeTreeKey(sequence: string[]): string {
    return sequence.join('::');
  }

  // looks to see if the template has a custom value with the given URI, or overrides an existing term with its own value; if neither,
  // returns null
  public labelForURI(propURI: string, groupNest: string[], valueURI: string): string {
    // look at the specific assignment first
    const assn = this.findAssignment(propURI, groupNest);
    for (const value of (assn?.values || [])) {
      if (value.uri == valueURI) return value.name;
    }

    // then consider the whole tree
    const assnList: TemplateAssignment[] = [];
    const scan = (group: TemplateGroup): void => {
      assnList.push(...group.assignments);
      for (const sub of group.subGroups) scan(sub);
    };
    scan(this.template.root);
    for (const assn of assnList) {
      for (const value of assn.values) {
        if (value.uri == valueURI) return value.name;
      }
    }

    return null;
  }

  // returns a unique new custom URI that's made up of the template's schema prefix; if the current URI is provided, and it is of
  // that form already, then return it
  public pickCustomURI(currentURI: string): string {
    let pfx = this.template.schemaPrefix;
    if (currentURI && currentURI.startsWith(pfx)) return currentURI;

    const nameBits = this.template.root.name.split(/\s+/);
    let postPfx = '';
    for (const bit of nameBits) {
      const ch = bit.charAt(0).toUpperCase();
      if (ch >= 'A' && ch <= 'Z') postPfx += ch; else postPfx += 'X';
    }
    if (postPfx) pfx += postPfx + '_';

    let highNum = 0;

    const processURI = (uri: string): void => {
      if (!uri || !uri.startsWith(pfx)) return;
      const sfx = uri.substring(pfx.length);
      if (!/^(0*)[0-9+]+$/.test(sfx)) return;
      highNum = Math.max(highNum, parseInt(sfx));
    };
    const examineGroup = (group: TemplateGroup): void => {
      processURI(group.groupURI);
      for (const assn of group.assignments) {
        processURI(assn.propURI);
        for (const value of assn.values) processURI(value.uri);
      }
      for (const sub of group.subGroups) examineGroup(sub);
    };

    examineGroup(this.template.root);

    const txt = (highNum + 1).toString();
    return pfx + '0'.repeat(Math.max(0, 7 - txt.length)) + txt;
  }

  // for the group indicated, paste an object into it: this can be either a group or an assignment; it will be created as a new child object,
  // if valid, otherwise an exception is thrown
  public pasteIntoGroup(groupNest: string[], entity: TemplateGroup | TemplateAssignment): void {
    const parent = this.findGroup(groupNest);
    if (!parent) throw new Error('Group not found.');

    const assn = entity as TemplateAssignment, group = entity as TemplateGroup;

    if (group.groupURI) {
      Schema.validateGroup(group);
      for (const look of parent.subGroups) if (look.groupURI == group.groupURI) throw new Error(`Group URI ${group.groupURI} already present in parent group.`);
      parent.subGroups.push(group);
    } else if (assn.propURI) {
      Schema.validateAssignment(assn);
      for (const look of parent.assignments) if (look.propURI == assn.propURI) throw new Error(`Prop URI ${assn.propURI} already present in parent group.`);
      parent.assignments.push(assn);
    } else {
      throw new Error('Can only paste groups or assignment.');
    }
  }

  // for the assignment indicated, paste an object (which is presumed to be a value); throws an exception if not valid
  public pasteIntoAssignment(propURI: string, groupNest: string[], value: TemplateValue): void {
    const parent = this.findAssignment(propURI, groupNest);
    if (!parent) throw new Error('Assignment not found.');

    Schema.validateValue(value);
    for (const look of parent.values) if (look.uri == value.uri) throw new Error(`Value URI ${value.uri} already present in parent assignment.`);
    parent.values.push(value);
  }

  // throw an exception if the group has any invalid content
  public static validateGroup(group: TemplateGroup): void {
    if (typeof group.name != 'string' || group.name == '') throw new Error('Group requires a name field.');
    if (typeof group.descr != 'string') delete group.descr;
    if (typeof group.groupURI != 'string' || group.groupURI == '') throw new Error('Group requires a URI field.');
    group.canDuplicate = !!group.canDuplicate;
    if (!Array.isArray(group.assignments)) group.assignments = [];
    for (const assn of group.assignments) this.validateAssignment(assn);
    if (!Array.isArray(group.subGroups)) group.subGroups = [];
    for (const sub of group.subGroups) this.validateGroup(sub);

    const ALLFIELDS = ['name', 'descr', 'groupURI', 'canDuplicate', 'assignments', 'subGroups'];
    for (const key of Object.keys(group)) if (!ALLFIELDS.includes(key)) delete group[key];
  }

  // throw an exception if the assignment has any invalid content
  public static validateAssignment(assn: TemplateAssignment): void {
    if (typeof assn.name != 'string' || assn.name == '') throw new Error('Assignment requires a name field.');
    if (typeof assn.descr != 'string') delete assn.descr;
    if (typeof assn.propURI != 'string' || assn.propURI == '') throw new Error('Assignment requires a URI field.');
    assn.suggestions = assn.suggestions || SuggestionType.Full;
    assn.mandatory = !!assn.mandatory;
    if (!Array.isArray(assn.values)) assn.values = [];
    for (const value of assn.values) this.validateValue(value);

    const ALLFIELDS = ['name', 'descr', 'propURI', 'suggestions', 'mandatory', 'values'];
    for (const key of Object.keys(assn)) if (!ALLFIELDS.includes(key)) delete assn[key];
  }

  // throw an exception if the value has any invalid content
  public static validateValue(value: TemplateValue): void {
    if (typeof value.uri != 'string' || value.uri == '') throw new Error('Value requires URI');
    if (typeof value.name != 'string' || value.name == '') throw new Error('Value requires name');
    if (!Array.isArray(value.altLabels)) delete value.altLabels;
    if (!Array.isArray(value.externalURLs)) delete value.externalURLs;
    if (!value.spec) value.spec = SpecificationType.Item;
    if (typeof value.parentURI != 'string') delete value.parentURI;

    const ALLFIELDS = ['uri', 'name', 'descr', 'altLabels', 'externalURLs', 'spec', 'parentURI'];
    for (const key of Object.keys(value)) if (!ALLFIELDS.includes(key)) delete value[key];
  }

  public debugNode(node: SchemaNode, depth = 0) {
    console.log('- '.repeat(depth) + '<' + node.uri + '> ' + node.label);
    for (const child of node.children) this.debugNode(child, depth + 1);
  }

  // if the given branch has a large number of children (as happens for certain flat ontologies, like cell line) this can make the UI performance
  // degrade, so we can make it better by dividing it up "cabinet style", e.g. picking categories like (A-E, F-N, O-Z) as necessary to balance
  private cabinetifyBranch(root: SchemaNode, watermark: [number]): void {
    for (const child of root.children) this.cabinetifyBranch(child, watermark);

    const CABINET_MAX_SIZE = 300; // up to this many, we just let it slide
    const BLOCK_IDEAL_SIZE = 100; // if we're splitting, try to make them about this big
    const MIN_BLOCK_COUNT = 6; // any fewer than this subdivisions files would look silly

    if (root.children.length < CABINET_MAX_SIZE) return;

    const blocks = this.splitCabinetBlock(root.children, 1, CABINET_MAX_SIZE, BLOCK_IDEAL_SIZE, MIN_BLOCK_COUNT);

    // rebuild the inheritance hierarchy
    root.children = [];
    for (const blk of blocks) {
      const label = (blk.lo == blk.hi ? `${blk.lo}` : `${blk.lo} ... ${blk.hi}`);
      const stub: SchemaNode = {
        parent: root,
        uri: `@stub${++watermark[0]}`,
        label,
        descr: null,
        children: blk.nodes,
        inSchema: false,
      };
      root.children.push(stub);
    }
  }

  private splitCabinetBlock(childNodes: SchemaNode[], minChars: number, cabinetMaxSize: number, blockIdealSize: number, minBlockCount: number): CabinetBlock[] {
    // try a series of leading character lengths, starting by subdividing by just the first letter, and dialing it up: want to try several
    // lengths to get the best options
    const candidates: CabinetBlock[][] = [];
    const biggestBlocks: number[] = [];

    for (let nchars = 1; nchars <= 10; nchars++) {
      const map = new Map<string, CabinetBlock>();
      for (const child of childNodes) {
        const tag = child.label.substring(0, Math.min(nchars, child.label.length)).toUpperCase();
        const blk = map.get(tag);
        if (blk) {
          blk.nodes.push(child);
        } else {
          map.set(tag, { lo: tag, hi: tag, nodes: [child] });
        }
      }
      const blocks = Array.from(map.values()).sort((b1, b2) => b1.lo.localeCompare(b2.lo));
      const biggest = Vec.max(blocks.map((blk) => blk.nodes.length));

      candidates.push(blocks);
      biggestBlocks.push(biggest);
      if (blocks.length >= minBlockCount && biggest <= blockIdealSize) break; // we like this one
    }

    // for longer blocks, if increasing the maximum length doesn't shrink down the longest one, back it down
    while (candidates.length > 3) {
      const max1 = biggestBlocks[biggestBlocks.length - 2], max2 = biggestBlocks[biggestBlocks.length - 1];
      if (max1 == max2) {
        candidates.pop();
      } else {
        break;
      }
    }

    const blocks = Vec.last(candidates);

    // join consecutive blocks together to try to balance them
    while (blocks.length > minBlockCount) {
      let bestIdx = -1, bestGsz = 0;
      for (let n = 0; n < blocks.length - 1; n++) {
        const gsz = blocks[n].nodes.length + blocks[n + 1].nodes.length;
        if (gsz < blockIdealSize && (bestIdx < 0 || gsz < bestGsz)) {
          [bestIdx, bestGsz] = [n, gsz];
        }
      }
      if (bestIdx < 0) break;
      blocks[bestIdx].hi = blocks[bestIdx + 1].hi;
      blocks[bestIdx].nodes = [...blocks[bestIdx].nodes, ...blocks[bestIdx + 1].nodes];
      blocks.splice(bestIdx + 1, 1);
    }

    // merge any conspicuously small blocks that might've been squeezed between bigger ones
    const TOO_SMALL_SIZE = 0.3 * blockIdealSize;
    while (blocks.length > minBlockCount) {
      let bestIdx = -1, bestGsz = 0;
      for (let n = 0; n < blocks.length - 1; n++) {
        const sz1 = blocks[n].nodes.length, sz2 = blocks[n + 1].nodes.length, gsz = sz1 + sz2;
        if (sz1 > blockIdealSize || sz2 > blockIdealSize) continue;
        if ((sz1 < TOO_SMALL_SIZE || sz2 < TOO_SMALL_SIZE) && (bestIdx < 0 || gsz < bestGsz)) {
          [bestIdx, bestGsz] = [n, gsz];
        }
      }
      if (bestIdx < 0) break;
      blocks[bestIdx].hi = blocks[bestIdx + 1].hi;
      blocks[bestIdx].nodes = [...blocks[bestIdx].nodes, ...blocks[bestIdx + 1].nodes];
      blocks.splice(bestIdx + 1, 1);
    }

    return blocks;
  }

  public static isPlaceholderURI(uri: string): boolean {
    return uri && uri.startsWith('@');
  }
}
