/* eslint-disable multiline-ternary, no-nested-ternary, react/forbid-dom-props */

import './Mixture.sass';
import { observer } from 'mobx-react';
import { MixtureParsedResult, MixtureParsedResultType, MixturesStore } from './mixturesStore';
import React from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import OpenLinkIcon from '@/shared/components/icons/OpenLinkIcon';
import { term } from '@/shared/utils/stringUtils';
import { Box } from 'webmolkit/util/Geom';
import { ArrangeMixture } from 'chemical-mixtures/ArrangeMixture';

type Props = {
  store: MixturesStore,
  parentBox: Box,
  width: number,
  height: number,
};
type State = {
  tick: number;
}

@observer
export class InputParsingText extends React.Component<Props, State> {
  private boxEntry: Box = null;
  private toScroll: HTMLElement = null;
  private inputElement: HTMLInputElement = null;

  constructor(props: Props) {
    super(props);

    this.state = {
      tick: 0,
    };
  }

  public render(): JSX.Element {
    const { store, parentBox } = this.props;
    const { parsing } = store;

    this.toScroll = null;

    const results = parsing.text.length > 0 && parsing.results.length > 0 && this.boxEntry && this.buildResults();

    if (!this.boxEntry) {
      setTimeout(() => this.setState({ tick: this.state.tick + 1 }), 1);
    }

    return (
      <>
        <div
          key="overlay-parsing"
          ref={(element) => { if (element) this.boxEntry = new Box(element.offsetLeft, element.offsetTop, element.offsetWidth, element.offsetHeight); }}
          className="MixtureEditor-selectedhalo"
          style={{
            position: 'absolute',
            left: `${parentBox.x}px`,
            top: `${parentBox.maxY()}px`,
          }}
          >
          <input
            className="input-text"
            style={{ width: '20em' }}
            type="text"
            value={parsing.text}
            onChange={(event) => this.props.store.changeParsingText(event.target.value)}
            onKeyDown={this.handleKeyParsing}
            onBlur={this.handleBlur}
            autoFocus={true}
            ref={(el) => this.inputElement = el}
            />
        </div>
        {results}
      </>
    );
  }

  componentDidUpdate(): void {
    if (this.toScroll) this.toScroll.scrollIntoView(false);
  }

  private handleKeyParsing = (event: React.KeyboardEvent<HTMLElement>): void => {
    const { store } = this.props;
    const { key, shiftKey, ctrlKey, altKey, metaKey } = event;
    const mods = (shiftKey ? 'S' : '') + (ctrlKey ? 'C' : '') + (altKey ? 'A' : '') + (metaKey ? 'M' : '');

    if (key == 'Enter' && mods == '') {
      this.selectCurrentResult();
    } else if (key == 'Escape' && mods == '') {
      store.clearParsingText();
    } else if (key == 'ArrowUp' && mods == '') {
      store.adjustParsingSelection(-1);
    } else if (key == 'ArrowDown' && mods == '') {
      store.adjustParsingSelection(1);
    } else if (key == 'Tab' && mods == '') {
      store.actionKeyTab();
    }

    event.stopPropagation();
  };

  private handleBlur = (event: React.FocusEvent<HTMLInputElement>): void => {
    const srctag = event.relatedTarget?.tagName;
    if (srctag == 'A' || srctag == 'BUTTON' || srctag == 'INPUT') return;
    if (srctag == 'DIV' && event.relatedTarget.classList.contains('MixtureEditor-panel-parent')) return;

    this.props.store.clearParsingText();
  };

  private buildResults(): JSX.Element {
    const { store, width, height } = this.props;
    const { parsing } = store;

    const szLeft = this.boxEntry.x, szRight = width - this.boxEntry.maxX();
    const PAD = 3, EXTRA_PAD = 10;
    const IDEAL_WIDTH = 400;
    const outerStyle: React.CSSProperties = {
      top: `${PAD}px`,
      height: `${height - 2 * PAD - EXTRA_PAD}px`,
    };
    if (szLeft > szRight) {
      const width = Math.min(IDEAL_WIDTH, szLeft - 2 * PAD);
      outerStyle.left = `${szLeft - PAD - width}px`;
      outerStyle.width = `${width}px`;
    } else {
      const width = Math.min(IDEAL_WIDTH, szRight - 2 * PAD);
      outerStyle.left = `${this.boxEntry.maxX() + PAD}px`;
      outerStyle.right = `${width}px`;
    }

    const midY = this.boxEntry.midY();
    const growAbove = midY / height, growBelow = 1 - midY / height;
    const innerStyle: React.CSSProperties = {
      maxHeight: `${height - 2 * PAD - EXTRA_PAD}px`,
    };

    return (
      <div
        className="MixtureEditor-parsingouter"
        style={outerStyle}
        >
        <div style={{ flexGrow: growAbove }}/>
        <div
          className="MixtureEditor-parsinginner"
          style={innerStyle}
          >
          {parsing.results.map((result, idx) => this.buildIndividualResult(result, idx))}
        </div>
        <div style={{ flexGrow: growBelow }}/>
      </div>
    );
  }

  private buildIndividualResult(result: MixtureParsedResult, idx: number): JSX.Element {
    let classRow = idx % 2 == 0 ? 'MixtureEditor-parsingresult-even' : 'MixtureEditor-parsingresult-odd';
    const isSelected = result.key == this.props.store.parsing.selectedResultKey;
    if (isSelected) classRow += ' MixtureEditor-parsingresult-selected';

    return (
      <div
        key={result.key}
        data-resulttype={`${result.type}`}
        className={`MixtureEditor-parsingresult ${classRow}`}
        ref={(element) => { if (isSelected) this.toScroll = element; }}
        onClick={(event) => this.handleClickResult(event, result)}
        >
        {result.type == MixtureParsedResultType.Quantity ? this.renderQuantity(result) : this.renderMixture(result)}
      </div>
    );
  }

  private renderMixture(result: MixtureParsedResult): JSX.Element {
    const svg = result.gfx && result.gfx.createSVG();

    const comp = result.comp;

    const lines: JSX.Element[] = [];

    const vaultURL = (comp.links ?? {})['vaultMolecule']; // eslint-disable-line dot-notation
    if (typeof vaultURL == 'string') {
      const tooltipLink = (
        <Typography>
          Click on link to see registered molecule.
        </Typography>
      );

      lines.push((
        <div
          key="link"
          className="MixtureEditor-openlink"
          onClick={(event) => this.handleOpenURL(event, `${vaultURL}`)}
          >
          <Tooltip
            title={tooltipLink}
            arrow
            disableFocusListener
            placement="top"
            >
            <div className="MixtureEditor-openicon">
              <OpenLinkIcon width="16" height="16" color="#0277CC"/>
            </div>
          </Tooltip>
        </div>
      ));
    }

    const synonyms = [...(result.vaultSynonyms ?? [])];
    if (result.vaultMolName && result.vaultMolName != comp.name && !synonyms.includes(result.vaultMolName)) {
      synonyms.unshift(result.vaultMolName);
    }

    if (synonyms.length > 0) {
      const selidx = synonyms.indexOf(result.selectedSynonym) + 1;
      lines.push((
        <div key="namesyn" className="MixtureEditor-flexkeyval">
          <div>Name</div>
          <div>
            <FormControl className="MixtureEditor-nowrap">
              <RadioGroup value={selidx}>
                {
                  [comp.name, ...synonyms].map((synonym, idx) => ((
                    <FormControlLabel
                      key={`syn${idx}`}
                      value={idx}
                      control={<Radio size="small"/>}
                      label={synonym || 'None'}
                      className="MixtureEditor-thinradio"
                      onClick={(event) => this.handleChangeSynonym(event, result, synonym)}/>
                  )))
                }
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      ));
    } else if (comp.name) {
      lines.push((
        <div key="name" className="MixtureEditor-lookupname">{comp.name}</div>
      ));
    }

    if (result.vaultBatches?.length > 0) {
      const selidx = result.vaultBatches.indexOf(result.selectedBatch) + 1;
      lines.push((
        <div key="batches" className="MixtureEditor-flexkeyval">
          <div>{term('batch', true)}</div>
          <div>
            <FormControl className="MixtureEditor-nowrap">
              <RadioGroup value={selidx}>
                {
                  [null, ...result.vaultBatches].map((batch, idx) => ((
                    <FormControlLabel
                      key={`syn${idx}`}
                      value={idx}
                      control={<Radio size="small"/>}
                      label={batch ?? 'None'}
                      className="MixtureEditor-thinradio"
                      onClick={(event) => this.handleChangeBatch(event, result, batch)}/>
                  )))
                }
              </RadioGroup>
            </FormControl>
          </div>
        </div>
      ));
    }

    if (result.type == MixtureParsedResultType.Predicted) {
      lines.push((
        <div key="predicted">
          <i>predicted from text</i>
        </div>
      ));
    }

    return (
      <div style={{ display: 'flex', gap: '1em' }}>
        {svg && <div style={{ flexGrow: 0 }}>
          <div dangerouslySetInnerHTML={{ __html: svg }}/>
        </div>}
        <div style={{ flexGrow: 1 }}>
          {lines}
        </div>
      </div>
    );
  }

  private renderQuantity(result: MixtureParsedResult): JSX.Element {
    const str = ArrangeMixture.formatQuantity(result.comp);
    return (<div>Quantity: <b>{str}</b></div>);
  }

  private selectCurrentResult(): void {
    const { store } = this.props;

    const result = store.parsing.results.find((result) => result.key == store.parsing.selectedResultKey);
    if (!result) return;

    store.mergeComponent(store.selectedOrigin, result);
    store.clearParsingText();
  }

  private handleClickResult(event: React.MouseEvent<HTMLElement>, result: MixtureParsedResult): void {
    const { store } = this.props;

    store.mergeComponent(store.selectedOrigin, result);
    store.clearParsingText();
    event.stopPropagation();
    event.preventDefault();
  }

  private handleOpenURL(event: React.MouseEvent<HTMLElement>, vaultURL: string): void { // eslint-disable-line @typescript-eslint/no-unused-vars
    window.open(vaultURL, '_blank');
    event.stopPropagation();
    event.preventDefault();
  }

  private handleChangeSynonym(event: React.MouseEvent<HTMLElement>, result: MixtureParsedResult, synonym: string): void {
    this.props.store.selectParsingSynonym(result.key, synonym);
    event.stopPropagation();
    event.preventDefault();
    if (this.inputElement) this.inputElement.focus();
  }

  private handleChangeBatch(event: React.MouseEvent<HTMLElement>, result: MixtureParsedResult, batch: string): void {
    this.props.store.selectParsingBatch(result.key, batch);
    event.stopPropagation();
    event.preventDefault();
    if (this.inputElement) this.inputElement.focus();
  }
}
