import React, { useEffect, useCallback, useMemo } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from '@hello-pangea/dnd';
import { Icon, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import { SimpleDataTable, SimpleTableCell } from '@/components';
import DragIcon from 'ASSETS/images/cdd30/layout/sort/drag.svg';
import DisabledDragIcon from 'ASSETS/images/layout/sort/disabled_drag.svg';
import { Img, A } from '@/shared/components/sanitizedTags';
import { CDD } from '@/typedJS';
import { AnyObject, StringOrNumber } from '@/types';

export type ReorderDataTableRow = AnyObject;

type Rows = Array<ReorderDataTableRow>;

type Props = SimpleDataTable['props'] & {
  rowIdPrefix?: string;
  numFixedRows?: number;
  showDelete?: boolean;
  showHeader?: boolean;
  style?: React.CSSProperties;

  getRowDisplayOptions?: (row: Rows[number]) => {
    disableDelete?: boolean;
    disableDeleteTooltip?: string;
    hideReorder?: boolean;
    hiddenColumns?: Array<StringOrNumber>;
  };
  onDeleteRow?: (row: Rows[number], index: number) => void;
  onChangeOrder: (rows: Rows) => void;
  renderCustomRow?: (row: Rows[number], index: number) => React.ReactNode | void;
}

const arrangeItemsAfterDragEndFromIndexToIndex = (rows: Rows, sourceIndex: number, destinationIndex: number) => {
  const newItems = [...rows];
  const movedItem = newItems[sourceIndex];
  newItems.splice(sourceIndex, 1);
  newItems.splice(destinationIndex, 0, movedItem);
  return newItems;
};

export const arrangeItemsAfterDragEnd = (rows: Rows, result: DropResult) => {
  if (!result.destination) {
    return null;
  }

  if (result.destination.index === result.source.index) {
    return null;
  }

  return arrangeItemsAfterDragEndFromIndexToIndex(rows, result.source.index, result.destination.index);
};

export const ReorderDataTable: React.FC<Props> = React.memo((props) => {
  const {
    rows, columns, showDelete,
    onChangeOrder, onClickRow, onDeleteRow,
    showHeader = true,
    style,
  } = props;

  useEffect(() => {
    // Scott: allow integration tests to call the reorder function without simulating drag and drop.
    // example: ReorderDataTable.reorder(0, 1);
    if (CDD.testEnvironment) {
      const w = window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
      if (!w.ReorderDataTable) {
        w.ReorderDataTable = {};
      }
      w.ReorderDataTable.reorder = (from: number, to: number) => {
        onChangeOrder(arrangeItemsAfterDragEndFromIndexToIndex(rows, from, to));
      };
    }

    return () => {
      if (CDD.testEnvironment) {
        const w = window as any; // eslint-disable-line @typescript-eslint/no-explicit-any
        w.ReorderDataTable = null;
      }
    };
  }, [onChangeOrder, rows]);

  const handleDeleteRow = useCallback((row: Rows[number], index: number) => {
    if (onDeleteRow) {
      onDeleteRow(row, index);
    } else {
      const newRows = rows.slice();
      newRows.splice(index, 1);
      onChangeOrder(newRows);
    }
  }, [onDeleteRow, onChangeOrder, rows]);

  const handleDragEnd = useCallback((result: DropResult) => {
    const newItems = arrangeItemsAfterDragEnd(rows, result);
    if (newItems) {
      onChangeOrder(newItems);
    }
  }, [rows, onChangeOrder]);

  const renderRow = useCallback((item: Rows[number], index: number) => {
    const { numFixedRows, getRowAttributes, getRowDisplayOptions } = props;
    const customRenderRow = props.renderCustomRow?.(rows[index], index);
    if (customRenderRow) {
      return customRenderRow;
    }
    const {
      disableDelete = false,
      disableDeleteTooltip = null,
      hideReorder = false,
      hiddenColumns = [],
    } = getRowDisplayOptions?.(rows[index]) ?? {};

    const isFixedRow = (index < (numFixedRows ?? 0)) || (hideReorder ?? false);
    const isRemovable = !(isFixedRow || disableDelete);
    const removeClassnames = isRemovable ? 'cancel' : 'cancel disabled';
    const { rowIdPrefix = '' } = props;

    if (isFixedRow) {
      return (
        <TableRow key={item.id} {...getRowAttributes?.(rows[index])} data-row-id={item.id} id={rowIdPrefix + item.id}>
          {columns.map((column) =>
            <SimpleTableCell
              key={column.id} {...props} row={rows[index]} id={column.id} className={column.className} />)
          }
          <TableCell align='right' className={'inner actions'}>
            {showDelete
              ? (<A
                className={removeClassnames}
                name="remove"
                disabled={!isRemovable}
                title={disableDeleteTooltip}
                onClick={() => { /**/ }}
                href="#"
              >
                Remove
              </A>)
              : null}

            <span className='disabled' draggable='false'>
              <Img src={DisabledDragIcon} />
            </span>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <Draggable
        key={`${item.id ?? index}`}
        draggableId={`${item.id}`}
        index={index}
      >
        {(draggableProvided, snapshot) => (
          <TableRow
            className='row'
            id={rowIdPrefix + item.id}
            onClick={() => {
              onClickRow?.(rows[index], index);
            }}
            ref={draggableProvided.innerRef}
            {...draggableProvided.draggableProps}
            {...getRowAttributes?.(rows[index])}
            style={{
              ...draggableProvided.draggableProps.style,
              background: snapshot.isDragging
                ? 'rgba(245,245,245, 0.75)'
                : 'none',
            }}
          >
            {columns.map((column, columnIndex) => (
              <SimpleTableCell
                key={column.id}
                {...props}
                width={columns[columnIndex].width}
                row={rows[index]}
                id={column.id}
                className={column.className}
                hideContents={hiddenColumns.includes(column.id)}
              />
            ))}

            <TableCell align='right' className={`inner actions${isFixedRow ? ' hidden' : ''}`}>
              {!!showDelete && !snapshot.isDragging && (
                <A
                  className={removeClassnames}
                  name="remove"
                  disabled={!isRemovable}
                  title={disableDeleteTooltip}
                  onClick={isRemovable ? () => { handleDeleteRow(rows[index], index); } : null}
                  href="#"
                >
                  Remove
                </A>
              )}

              <span {...draggableProvided.dragHandleProps} className='drag-handle' draggable='true'>
                {!snapshot.isDragging && (
                  <Icon>
                    <Img src={DragIcon} />
                  </Icon>
                )}
              </span>
            </TableCell>
          </TableRow>
        )}
      </Draggable>
    );
  }, [columns, handleDeleteRow, onClickRow, props, rows]);

  const colgroup = useMemo(() => (
    <colgroup>
      {columns.map((column) => (
        <col key={column.id} width={column.width} />
      ))}
      <col width="20" />
    </colgroup>
  ), [columns]);

  return (
    <TableContainer className='ReorderDataTable'>
      <Table className={props.className}>
        {/* This enforces column widths: */}
        {colgroup}
        {showHeader && (
          <TableHead className='table-head-row'>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} id={`${column.id}`} style={column.style ?? {}}>
                  {column.label}
                </TableCell>
              ))}
              <TableCell />
            </TableRow>
          </TableHead>
        )}

        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable' direction='vertical'>
            {(droppableProvided) => (
              <TableBody
                className='sortable'
                data-testid='reorder-data-table'
                style={style}
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {rows.map((item, index) => renderRow(item, index))}
                {droppableProvided.placeholder}
              </TableBody>
            )}
          </Droppable>
        </DragDropContext>
      </Table>
    </TableContainer>
  );
});

ReorderDataTable.displayName = 'ReorderDataTable';
