import React from 'react';

import { A } from '@/shared/components/sanitizedTags.js';
import Icon from '@/shared/components/Icon.jsx';

interface AddButtonProps {
  onClick: () => void
  text: string
}

export default class AddButton extends React.PureComponent<AddButtonProps> {
  handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onClick();
  };

  render() {
    const { text } = this.props;

    return (
      <A className="add-associated-model" href="#" onClick={this.handleClick}>
        <Icon icon="add" forceSize="16" />
        <span>{' ' + text}</span>
      </A>
    );
  }
}
