/* eslint-disable operator-linebreak, multiline-ternary, no-nested-ternary, react/forbid-dom-props */

import React from 'react';
import axios from 'axios';
import { Alert, Fade, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import constants from 'ASSETS/javascripts/constants.js';

import MoleculeBatchIdentifierPicker, { SelectedBatchOption } from '@/models/Batch/MoleculeBatchIdentifierPicker';
import { ID } from '@/Protocols/types';
import SubmitOrCancel from '@/shared/components/CancelOrSubmit.jsx';
import Icon from '@/shared/components/Icon.jsx';

import { A, Img } from '@/shared/components/sanitizedTags.js';
import hasFeature from '@/shared/utils/features.js';
import { term } from '@/shared/utils/stringUtils';
import { CDD } from '@/typedJS';
import { StringOrNumber } from '@/types';

import { Annotation, harmonizeTemplateAnnotations } from '../data/annotations';
import { ProtocolForm, FormSection, FormLayoutType, FormLayout, createImpliedForm, replaceFormTerms, FormLayoutEmphasis, FormDefinition, FormContextType } from '../data/forms';
import { OntologyTree } from '../data/OntologyTree';
import { ProtocolField } from '../data/protocol_fields';
import { Schema, SchemaBranch } from '../data/Schema';
import { SearchCache, SearchCacheRoot } from '../data/SearchCache';
import { collapsePrefixes, initializeOntologies, keyPropGroup, keyPropGroupValue, expandPrefix, expandPrefixes, cherryPickBranches, deepClone } from '../data/utils';
import { OntologyTemplate, TemplateGroup, TemplateAssignment, SuggestionType } from '../data/templates';

import { GlobalAnnotationSearch } from './GlobalAnnotationSearch';
import addIcon from 'ASSETS/images/cdd30/icons/add.png';
import { LabelDialog } from './LabelDialog';
import LegacyFileInput from './LegacyFileInput';
import { PickTermDialog } from './PickTermDialog';
import './Template.sass';
import { FieldDataType, FieldDefinitionValue } from '@/FieldDefinitions/types';
import SpecialCharacterSelect from '@/shared/components/SpecialCharacterSelect';
import { copyText } from '@/components/DownloadMoleculeImage/downloadUtils';
import { getRootStore } from '@/stores/rootStore';

const {
  DATE_DISPLAY_FORMAT_ALT,
  ML_DEFAULT_FINGERPRINTER_NAME,
  ML_ECFP_INFO_URL,
  ML_ECFP_PAPER_URL,
  ML_MODIFIED_BAYESIAN_MODEL_URL,
  ML_MODIFIED_BAYESIAN_PAPER_URL,
} = constants;

interface ClipboardJSON {
  schemaURI: string;
  fields: { label: string; value: FieldDefinitionValue, fieldDefnID: ID; }[];
  annotations: { propURI: string, groupNest: string[], valueURI?: string, valueLabel?: string; }[];
}

type Props = {
  cancelURL: string,
  context?: string,
  form?: ProtocolForm,
  formDefinitionList?: FormDefinition[],
  formURL: string,
  isEditable: boolean,
  isLocked: boolean,
  isEditing?: boolean,
  isNew: boolean,
  navigateOnCreation: boolean,
  ontologyAnnotations: Annotation[], // initial values
  projectOptions: [string, string][],
  protocolFields: ProtocolField[], // initial values
  protocolId: ID,
  resourceType: 'protocol' | 'run',
  runGrouping: string,
  schemaPrefix: string,
  showMachineLearningInfo?: boolean,
  templateList: OntologyTemplate[],
  sharedProjectIDs: string[],
};

type State = {
  isEditing: boolean,
  protocolFields: ProtocolField[],
  template: OntologyTemplate,
  selectedProject: string | null,
  selectedFormOrTemplate: StringOrNumber,
  ontologyAnnotations: Annotation[],
  ontologyAnnotsSansDefs: Annotation[], // as above w/out default values
  errorMessages: string[],
  disableSubmit: boolean,
  form: ProtocolForm,
  isPickerOpen: boolean,
  pickerAssn: TemplateAssignment,
  pickerNest: string[],
  pickerRoots: SchemaBranch[],
  pickerSelected: string[],
  pickerSearch: SearchCache,
  isLabelOpen: boolean,
  labelDataType: SuggestionType,
  labelAssn: TemplateAssignment,
  labelNest: string[],
  labelValue: string,
  popoverID: string,
  popoverURI: string,
  popoverDescr: string,
  globalSearchText: string,
  fadeCount: number,
  fadeMessage: string,
  bumpState: number,
};

export default class ProtocolAnnotator extends React.Component<Props, State> {
  private formRef: React.RefObject<HTMLFormElement>;
  private inputCount = 0;

  private blankRequiredFieldLabels: string[] = [];

  private strRunTitle = term('run', true);
  private strRun = term('run', false);
  private strProtocolTitle = term('protocol', true);
  private strProtocol = term('protocol', false);

  private unsetPosition = true;
  private uncachedValues = new Set<string>();

  private savedState: State;

  constructor(props: Props) {
    super(props);

    const {
      isEditing,
      isNew,
      ontologyAnnotations,
      projectOptions,
      protocolFields,
      schemaPrefix,
    } = props;

    this.formRef = React.createRef();

    this.state = {
      isEditing: (isEditing || isNew) ?? false,
      protocolFields: [...protocolFields],
      template: null,
      selectedProject: (projectOptions?.length === 1) ? projectOptions[0][1] : '',
      selectedFormOrTemplate: schemaPrefix || '',
      ontologyAnnotations: [...ontologyAnnotations],
      ontologyAnnotsSansDefs: [...ontologyAnnotations],
      errorMessages: [],
      disableSubmit: true,
      form: null,
      isPickerOpen: false,
      pickerAssn: null,
      pickerNest: null,
      pickerRoots: null,
      pickerSelected: null,
      pickerSearch: null,
      isLabelOpen: false,
      labelDataType: null,
      labelAssn: null,
      labelNest: null,
      labelValue: null,
      popoverID: null,
      popoverURI: null,
      popoverDescr: null,
      globalSearchText: '',
      fadeCount: 0,
      fadeMessage: null,
      bumpState: 0,
    };

    (async () => {
      await initializeOntologies();

      const { form, template } = this.findProtocolForm(schemaPrefix);

      if (template) {
        await this.cacheloadOntology(template, ontologyAnnotations);
      }

      this.updateFormState(form, ontologyAnnotations, ontologyAnnotations);
      this.setState({ template, disableSubmit: false });
    })();
  }

  saveState() {
    this.savedState = deepClone(this.state);
  }

  restoreState() {
    this.setState(this.savedState);
  }

  public render(): JSX.Element {
    const { navigateOnCreation } = this.props;
    const { form, isEditing } = this.state;
    this.blankRequiredFieldLabels = [];

    // Forcibly position the jQuery dialog once, after the first render
    // where any form has been specified.
    if (!navigateOnCreation && form && this.unsetPosition) {
      this.unsetPosition = false;
      setTimeout(CDD.Mapper.repositionCreateRunDialog);
    }

    const result = isEditing ? this.renderEditing() : this.renderSummary();

    if (this.uncachedValues.size > 0) {
      setTimeout(() => this.updateOntologyCache().then(), 0);
    }

    return result;
  }

  private renderSummary(): JSX.Element {
    const { form: propsForm, isEditable, isLocked, showMachineLearningInfo } = this.props;
    const { form, selectedFormOrTemplate, template } = this.state;
    const formName = this.findFormName(selectedFormOrTemplate);
    const showTemplateSelection = template && !propsForm && this.isProtocol && !showMachineLearningInfo;

    if (!form) {
      return (
        <>
          Loading...
        </>
      );
    }

    return (
      <>
        {(isEditable || isLocked) && (
          <A
            href="#"
            className={'editableData-editLink' + (isLocked ? ' disabled' : '')}
            onClick={isLocked ? Function.prototype : this.handleEnterEditMode}
          >
            <Icon icon="pencil" forceSize="16" alt="Annotations" />
            Edit {this.resourceName} definition
          </A>
        )}
        <h3>{this.resourceTitle} Definition</h3>

        {showTemplateSelection && (
          <table className="ProtocolAnnotator-table ProtocolAnnotator-table-autoscroll">
            <tbody>
              {formName && (
                <tr>
                  <th>Form</th>
                  <td>{formName}</td>
                </tr>
              )}
              {!formName && (
                <tr>
                  <th>Template</th>
                  <td>{template.root.name ?? '(None)'}</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        {form.sections.map((section, idx) => this.renderSection(section, false, template, [idx]))}

        {showMachineLearningInfo && (
          <table className="ProtocolAnnotator-table ProtocolAnnotator-table-autoscroll">
            <tbody>
              <tr>
                <th>Model</th>
                <td>
                  CDD’s open source <a href={ML_MODIFIED_BAYESIAN_MODEL_URL}>modified Bayesian model</a> described
                  in <a href={ML_MODIFIED_BAYESIAN_PAPER_URL}>Xia et al. 2004</a>.
                </td>
              </tr>
              <tr>
                <th>Descriptors</th>
                <td>
                  CDD’s open source <a href={ML_ECFP_INFO_URL}>{ML_DEFAULT_FINGERPRINTER_NAME} fingerprints</a> described
                  in <a href={ML_ECFP_PAPER_URL}>Rogers and Hahn 2010</a>.
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </>
    );
  }

  private renderEditing(): JSX.Element {
    const {
      form: propsForm,
      isNew,
      navigateOnCreation,
      projectOptions,
      resourceType,
      sharedProjectIDs,
      showMachineLearningInfo,
    } = this.props;

    const {
      disableSubmit,
      form,
      ontologyAnnotations,
      selectedFormOrTemplate,
      selectedProject,
      template,
      fadeCount,
      fadeMessage,
    } = this.state;

    if (!form) return null;

    const fullProjectOptions = [['(Select a project)', '']].concat(projectOptions);
    const formsEnabled = hasFeature('enableForms');

    this.inputCount = 0;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const templateAndFormOptions: any[] = this.buildTemplateAndFormOptions();
    const showTemplateSelector = !propsForm && this.isProtocol && !showMachineLearningInfo;
    const showProjectShareWarning = !propsForm && (this.isRun) && isNew && !sharedProjectIDs.includes(selectedProject);
    const projectShareWarning = `This project will also gain access to the ${this.strProtocol}`;
    const showBottomButtons = !propsForm;
    const showSelectionBlock = showTemplateSelector || isNew;
    const projectIdParam = this.isProtocol ? 'project_id' : 'run[project_id]';

    return (
      <div
        tabIndex={0}
        className="ProtocolAnnotator-keycapture"
        onKeyDown={this.handleKeyDown}
        onPaste={this.handlePaste}
      >
        {this.renderErrorMessages()}
        <form
          className="edit_protocol"
          id="protocol-definition-form-extended"
          acceptCharset="UTF-8"
          ref={this.formRef}
          method="post"
        >
          <input
            type="hidden"
            name={`${resourceType}[ontology_annotations_json]`}
            value=""
          />
          <input
            type="hidden"
            name={`${resourceType}[ontology_schema_prefix]`}
            value=""
          />
          {showSelectionBlock && (
            <table
              className="ProtocolAnnotator-table"
              key="tableannot"
            >
              <tbody>

                {isNew && (
                  <tr>
                    <th>Project</th>
                    <td>
                      <select
                        id="project_id"
                        className="pick-list"
                        disabled={isNew && !navigateOnCreation}
                        onChange={({ target: { value } }) => this.setState({ selectedProject: value })}
                        name={projectIdParam}
                        value={selectedProject}
                      >
                        {
                          fullProjectOptions.map(([name, value], idx) => (
                            <option
                              key={'project-opt-' + [idx].join(',')}
                              value={value}
                            >
                              {name}
                            </option>
                          ))
                        }
                      </select>
                      {showProjectShareWarning && (
                        <span className="warning" style={{ padding: 0, paddingLeft: 10 }}>{projectShareWarning}</span>
                      )}
                    </td>
                  </tr>
                )}

                {showTemplateSelector && (
                  <tr>
                    {formsEnabled && <th>Form</th>}
                    {!formsEnabled && <th>Template</th>}
                    <td>
                      <select
                        className="pick-list"
                        value={selectedFormOrTemplate}
                        onChange={(event) => this.handleChangeTemplateOrForm(event.target.value)}
                      >
                        {
                          templateAndFormOptions.map((option, idx) => (
                            <option
                              key={'template-opt-' + [idx].join(',')}
                              value={option.value}
                            >
                              {option.name}
                            </option>
                          ))
                        }
                      </select>
                    </td>
                    <td>
                      <GlobalAnnotationSearch
                        template={template}
                        ontologyAnnotations={ontologyAnnotations}
                        onSelectTerm={this.handleGlobalSearchSelect}
                      />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {form.sections.map((section, idx) => this.renderSection(section, true, template, [idx]))}

          {showBottomButtons && (
            <div className="ProtocolAnnotator-bottom-buttons">

              <SubmitOrCancel
                green
                small
                disabled={disableSubmit}
                onSubmit={disableSubmit ? Function.prototype() : this.handleSubmitEdit}
                onCancel={this.handleCancelEdit}
              >
                {isNew ? `Create ${this.resourceTitle}` : 'Save'}
              </SubmitOrCancel>
            </div>
          )}
        </form>

        <PickTermDialog
          isOpen={this.state.isPickerOpen}
          template={this.state.template}
          assn={this.state.pickerAssn}
          groupNest={this.state.pickerNest}
          roots={this.state.pickerRoots || []}
          selected={this.state.pickerSelected || []}
          search={this.state.pickerSearch}
          onDialogCancel={this.handlePickerDialogCancel}
          onDialogSubmit={this.handlePickerDialogSubmit}
        />
        <LabelDialog
          isOpen={this.state.isLabelOpen}
          template={this.state.template}
          assn={this.state.labelAssn}
          groupNest={this.state.labelNest}
          initValue={this.state.labelValue}
          onDialogCancel={this.handleLabelDialogCancel}
          onDialogSubmit={this.handleLabelDialogSubmit}
        />

        {fadeCount > 0 && (
          <Fade in={true} timeout={1000}>
            <Alert
              className="PlateBlockImporter-alert"
              severity="success"
            >
              {fadeMessage}
            </Alert>
          </Fade>
        )}
      </div>
    );
  }

  private renderErrorMessages(): JSX.Element {
    const { errorMessages } = this.state;

    if (!errorMessages || errorMessages.length === 0) {
      return null;
    }

    return (
      <div className="ProtocolAnnotator-errors">
        <ul>
          {errorMessages.map((msg, idx) => (
            <li key={`msg-${idx}`}>{msg}</li>
          ))}
        </ul>
      </div>
    );
  }

  private renderSection(section: FormSection, editable: boolean, template: OntologyTemplate, keyseq: number[]): JSX.Element {
    const useKey = 'section-' + keyseq.join(',');
    const blkTitle = section.name ? <h3 className="ProtocolAnnotator-section">{section.name}</h3> : null;
    return (
      <React.Fragment key={useKey}>
        {blkTitle}
        {(section.contents || []).map((layout, idx) => this.renderLayout(layout, editable, template, [...keyseq, idx]))}
      </React.Fragment>
    );
  }

  private renderLayout(layout: FormLayout, editable: boolean, template: OntologyTemplate, keyseq: number[], next?: FormLayout, prev?: FormLayout): JSX.Element {
    const useKey = 'layout-' + keyseq.join(',');

    if (layout.layoutType == FormLayoutType.Table) {
      return (
        <table className="ProtocolAnnotator-table ProtocolAnnotator-table-autoscroll" key={useKey}>
          <tbody>
            {(layout.contents || []).map((layout, idx) => this.renderLayout(layout, editable, template, [...keyseq, idx]))}
          </tbody>
        </table>
      );
    } else if (layout.layoutType == FormLayoutType.Row) {
      const rowContents = layout.contents || [];
      return (
        <tr key={useKey}>
          {rowContents.map((layout, idx) => this.renderLayout(layout, editable, template, [...keyseq, idx], rowContents[idx + 1], rowContents[idx - 1]))}
        </tr>
      );
    } else if (layout.layoutType == FormLayoutType.Cell) {
      if (layout.label) {
        return (
          <th
            key={useKey}
            colSpan={layout.span || 1}
          >
            {this.renderLabel(layout, next)}
          </th>
        );
      } else {
        let content: JSX.Element = null;
        if (layout.ontologyAssn) {
          content = this.renderOntologyAssignment(layout, editable, template, keyseq, prev);
        } else if (layout.fieldID != null) {
          content = this.renderProtocolField(layout, editable, keyseq, prev);
        }
        return (
          <td
            key={useKey}
            colSpan={layout.span || 1}
          >
            {content}
          </td>
        );
      }
    }
  }

  private renderOntologyAssignment(layout: FormLayout, editable: boolean, template: OntologyTemplate, keyseq: number[], prevLayout: FormLayout): JSX.Element {
    const { template: stateTemplate } = this.state;
    const useKey = 'ontoassn-' + keyseq.join(',');

    const assnSpec = layout.ontologyAssn, propURI = assnSpec[0], groupNest = assnSpec.slice(1);
    const annots = layout.ontologyAnnots ?? [];

    const isLocked = layout.defaultValue && layout.isLocked;

    if (annots.length == 0) this.validateRequiredOntologyTerm(layout, prevLayout);

    const mainDisplay = (
      <div className="ProtocolAnnotator-assignment-vbox" key={useKey}>
        {annots.map((annot, idx) => annot.valueURI ? this.renderOntologyAnnotation(propURI, groupNest, annot.valueURI, editable, isLocked, template, [...keyseq, idx]) : null)}
        {annots.map((annot, idx) => annot.valueLabel ? this.renderOntologyLabel(propURI, groupNest, annot.valueLabel, editable, isLocked, [...keyseq, idx]) : null)}
      </div>
    );
    if (!editable) return mainDisplay;

    const schema = new Schema(stateTemplate);
    const assn = schema.findAssignment(propURI, groupNest);

    const plusIcon = (<Img width={16} height={16} className='icon-16' alt='Add' src={addIcon} />);

    const linkTerm = assn.suggestions == SuggestionType.Full || assn.suggestions == SuggestionType.Disabled ? (
      <A
        href="#"
        onClick={() => this.handleAddAnnotation(layout, false)}
        className="ProtocolAnnotator-buttonadd"
      >
        {plusIcon}&nbsp;Annotation
      </A>
    ) : null;
    const linkLabel = (
      <A
        href="#"
        onClick={() => this.handleAddAnnotation(layout, true)}
        className="ProtocolAnnotator-buttonadd"
      >
        {plusIcon}&nbsp;Free&nbsp;Text
      </A>
    );

    return (
      <div className="ProtocolAnnotator-assignment-hbox">
        <div className="ProtocolAnnotator-flexgrow1">
          {mainDisplay}
        </div>
        <div className="ProtocolAnnotator-flexgrow0">
          {!isLocked ? (
            <>
              {linkTerm}
              {linkLabel}
            </>
          ) : (
            <i>locked</i>
          )}
        </div>
      </div>
    );
  }

  private renderOntologyAnnotation(propURI: string, groupNest: string[], valueURI: string, editable: boolean, isLocked: boolean, template: OntologyTemplate, keyseq: number[]): JSX.Element {
    const {
      popoverDescr,
      popoverID: statePopoverID,
      popoverURI,
    } = this.state;
    const useKey = 'ontoannot-' + keyseq.join(',');
    const schema = template ? new Schema(template) : null;
    const label = schema?.labelForURI(propURI, groupNest, valueURI) || OntologyTree.values.cachedLabel(valueURI);
    if (!label) {
      this.uncachedValues.add(valueURI);
    }

    const popoverID = 'term-' + keyPropGroupValue(propURI, groupNest, valueURI);

    const tooltip = popoverID == statePopoverID ? (
      <Typography className="ProtocolAnnotator-tooltip">
        <b>{popoverURI}</b>
        <br />
        {popoverDescr}
      </Typography>
    ) : '';

    return (
      <div key={useKey}>
        <Tooltip
          title={tooltip}
          arrow
          placement="right"
        >
          <div
            className="ProtocolAnnotator-annotation"
            onMouseEnter={() => this.handleValuePopoverOpen(popoverID, valueURI)}
          >
            {label || valueURI}
            {editable && (
              <>
                &nbsp;
                {!isLocked && (
                  <div className="ProtocolAnnotator-term-button" onClick={() => this.handleDeleteAnnotation(propURI, groupNest, valueURI)}>
                    {'\u{00D7}'}
                  </div>
                )}
              </>
            )}
          </div>
        </Tooltip>
        {isLocked && (<>&nbsp;&nbsp;<span className='fa fa-lock' /></>)}
      </div>
    );
  }

  private validateRequiredProtocolField(field: ProtocolField, layout: FormLayout, labelLayout: FormLayout): void {
    if (layout.isRequired && !field.definition?.required_group_number) {
      this.blankRequiredFieldLabels.push(labelLayout.label);
    }
  }

  private validateRequiredOntologyTerm(layout: FormLayout, labelLayout: FormLayout): void {
    if (layout.isRequired) {
      this.blankRequiredFieldLabels.push(labelLayout.label);
    }
  }

  private renderOntologyLabel(propURI: string, groupNest: string[], textLabel: string, editable: boolean, isLocked: boolean, keyseq: number[]): JSX.Element {
    const useKey = 'ontolabel-' + keyseq.join(',');
    if (editable) {
      return (
        <>
          <div key={useKey} className="ProtocolAnnotator-wideinput-wrap">
            <input
              className="input-text ProtocolAnnotator-wideinput-gift"
              type="text"
              defaultValue={textLabel}
              onChange={(event) => this.changedOntologyLabel(null, propURI, groupNest, textLabel, event.target.value)}
              autoFocus={this.inputCount++ == 0}
            />
            &nbsp;
            {!isLocked && (
              <div
                className="ProtocolAnnotator-term-button"
                style={{ paddingTop: '0.4em' }}
                onClick={() => this.handleDeleteTextLabel(propURI, groupNest, textLabel)}
              >
                {'\u{00D7}'}
              </div>
            )}
          </div>
          {isLocked && (<>&nbsp;&nbsp;<span className='fa fa-lock' /></>)}
        </>
      );
    }

    return (
      <div key={useKey}>
        {textLabel}
      </div>
    );
  }

  private renderProtocolField(layout: FormLayout, editable: boolean, keyseq: number[], prevLayout: FormLayout): JSX.Element {
    const { context, resourceType } = this.props;

    const [field, fldidx] = this.findProtocolField(layout.fieldID);
    if (!field) {
      return (<i>Deleted field</i>);
    }
    const formName = this.buildFormName(layout.fieldID, fldidx);
    const dataType = this.getFieldType(field);
    const showTextSpecialCharacterSelect = layout.fieldID === 'protocol_name';

    let widget: JSX.Element = null;
    let hidden: JSX.Element = null;

    if (editable) {
      if (dataType == FieldDataType.Text) {
        const value = field.value.text_value ?? layout.defaultValue ?? '';
        if (!value) this.validateRequiredProtocolField(field, layout, prevLayout);
        widget = (
          <>
            <div className="ProtocolAnnotator-wideinput-wrap">
              <input
                className="input-text ProtocolAnnotator-wideinput-gift"
                disabled={layout.isLocked}
                key={formName}
                name={formName}
                type="text"
                value={value}
                onChange={(event) => this.changedProtocolText(field, event.target.value)}
                autoFocus={this.inputCount++ == 0}
                required={layout.isRequired}
              />
            </div>
            {showTextSpecialCharacterSelect && (
              <>
                <br />
                <SpecialCharacterSelect onSelect={(char) => this.changedProtocolText(field, value + char)} name={`field_${layout.fieldID}`} />
              </>
            )}
          </>
        );
      } else if (dataType == FieldDataType.Date) {
        const value = field.value.date_value ?? layout.defaultValue ?? '';
        setTimeout(CDD.Form.setupCalendarFields);
        if (!value) this.validateRequiredProtocolField(field, layout, prevLayout);
        widget = (
          <input
            className="input-text ProtocolAnnotator-wideinput-gift"
            disabled={layout.isLocked}
            data-datepicker-format={DATE_DISPLAY_FORMAT_ALT}
            key={formName}
            name={formName}
            type="text"
            defaultValue={value}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onInput={(event) => this.changedProtocolText(field, (event.target as any).value)}
            autoFocus={this.inputCount++ == 0}
            required={layout.isRequired}
          />
        );
      } else if (dataType == FieldDataType.Number) {
        const value = field.value.float_value ?? layout.defaultValue ?? '';
        if (!value && value !== 0) this.validateRequiredProtocolField(field, layout, prevLayout);
        widget = (
          <input
            className="input-text numeric-field"
            disabled={layout.isLocked}
            key={formName}
            name={formName}
            type="text"
            defaultValue={value}
            onChange={(event) => this.changedProtocolText(field, event.target.value)}
            autoFocus={this.inputCount++ == 0}
            required={layout.isRequired}
          />
        );
      } else if (dataType == FieldDataType.LongText) {
        const value = field.value.text_value ?? layout.defaultValue ?? '';
        if (!value) this.validateRequiredProtocolField(field, layout, prevLayout);
        widget = (
          <>
            <div className="ProtocolAnnotator-wideinput-wrap">
              <textarea
                className="resizable ProtocolAnnotator-wideinput-gift"
                disabled={layout.isLocked}
                key={formName}
                name={formName}
                cols={40}
                rows={6}
                value={value}
                onChange={(event) => this.changedProtocolText(field, event.target.value)}
                autoFocus={this.inputCount++ == 0}
                required={layout.isRequired}
              />
            </div>
            <br />
            <SpecialCharacterSelect onSelect={(char) => this.changedProtocolText(field, value + char)} name={`field_${layout.fieldID}`} />
          </>
        );
      } else if (dataType == FieldDataType.File) {
        widget = (
          <LegacyFileInput
            context={context}
            fieldValue={field.value}
            name={formName}
            onFileChanged={(fileID, filename) => this.changedProtocolFile(field, fileID, filename)}
          />
        );
      } else if (dataType == FieldDataType.PickList) {
        let defval = '';

        for (const pick of field.definition.pick_list_values) if (pick.id == field.value.pick_list_value_id) defval = pick.value;
        if (defval === '' && layout.defaultValue) {
          for (const pick of field.definition.pick_list_values) if (pick.id == +layout.defaultValue) defval = pick.value;
        }
        if (!field.value.pick_list_value_id && !defval) this.validateRequiredProtocolField(field, layout, prevLayout);
        const allowedValues = layout.allowedValues ?
          field.definition.pick_list_values.filter(option => layout.allowedValues.indexOf(option.value) !== -1) :
          field.definition.pick_list_values;
        const options: any[] = [{ id: null, value: '', showValue: `(select ${field.label})` }, ...allowedValues]; // eslint-disable-line @typescript-eslint/no-explicit-any
        widget = (
          <select
            className="pick-list"
            disabled={layout.isLocked}
            key={formName}
            name={formName}
            defaultValue={defval}
            onChange={(event) => this.changedProtocolText(field, event.target.value)}
          >
            {
              options.map((pick, idx) => (
                <option
                  key={'protofld-opt-' + [...keyseq, idx].join(',')}
                  value={pick.value}
                >
                  {pick.showValue || pick.value}
                </option>
              ))
            }
          </select>
        );
      } else if (dataType == FieldDataType.BatchLink) {
        if (!field.value.batch_link_id) this.validateRequiredProtocolField(field, layout, prevLayout);
        // Disabling eslint because the withStyles HOC Prop type inference is wrong with our setup
        /* eslint-disable @typescript-eslint/no-explicit-any */
        widget = (
          <MoleculeBatchIdentifierPicker
            context={context as any}
            name={formName as any}
            onChange={((option: SelectedBatchOption) => this.changedProtocolBatchLink(field, option)) as any}
            defaultValue={field.value as any}
          />
        );
        /* eslint-enable @typescript-eslint/no-explicit-any */
      }

      // TODO: (Nick) Get "protocol" swapped in with project
      if (field.definition) {
        hidden = (
          <>
            <input
              type="hidden"
              value={`${field.definition.id || ''}`}
              name={`${resourceType}[editable_fields_including_blanks_attributes][${fldidx - 1}][${resourceType}_field_definition_id]`}
            />
            <input
              type="hidden"
              value={field.value ? `${field.value.id /* !!! WRONG */ || ''}` : ''}
              name={`${resourceType}[editable_fields_including_blanks_attributes][${fldidx - 1}][id]`}
            />
          </>
        );
      }
    } else {
      if (field.value == null) {
        // (empty)
      } else if (dataType == FieldDataType.Text) {
        widget = (
          <>
            {field.value.text_value}
          </>
        );
      } else if (dataType == FieldDataType.BatchLink) {
        widget = (
          <a
            href={`${context}/specified_batches/${field.value.batch_link_id}`}
            title={`${field.value.text_value}`}
          >
            {field.value.text_value}
          </a>
        );
      } else if (dataType == FieldDataType.Number) {
        widget = (
          <>
            {field.value.float_value}
          </>
        );
      } else if (dataType == FieldDataType.LongText) {
        const lines = field.value.text_value ? field.value.text_value.split('\n') : [];
        widget = (
          <>
            {lines.map((line, idx) => (
              <div key={'protofld-opt-' + [...keyseq, idx].join(',')}>
                {line}
              </div>))
            }
          </>
        );
      } else if (dataType == FieldDataType.Date) {
        widget = (
          <>
            {field.value.date_value}
          </>
        );
      } else if (dataType == FieldDataType.File) {
        widget = (
          <a
            href={`${context}/files/${field.value.uploaded_file_id}`}
            rel="noopener noreferrer"
            target="_blank"
            title={`${field.value.text_value}`}
          >
            {field.value.text_value}
          </a>
        );
      } else if (dataType == FieldDataType.PickList) {
        const picked = field.definition.pick_list_values.find((pickv) => pickv.id == field.value.pick_list_value_id);
        if (picked) {
          widget = (
            <>
              {picked.value}
            </>
          );
        }
      }
    }

    if (editable && layout.isLocked) {
      widget = <span className='locked-field-wrapper'>
        <span className='locked-widget'>
          {widget}
        </span>
        <span className='fa fa-lock' />
      </span>;
    }

    const useKey = 'protofld-' + keyseq.join(',');
    return (
      <React.Fragment key={useKey}>
        {widget}
        {hidden}
      </React.Fragment>
    );
  }

  private renderLabel(layout: FormLayout, next?: FormLayout): JSX.Element {
    const {
      template,
      popoverDescr,
      popoverID: statePopoverID,
      popoverURI,
    } = this.state;

    let isRequired = next?.isRequired;
    let { label } = layout;

    if (next?.fieldID) {
      const field = this.fieldDefinitionMap[next.fieldID];
      if (field) {
        label = field.label;
        if (field.definition.required_group_number) {
          isRequired = true;
        }
      }
    }

    const displayLabel = isRequired ? `${label} *` : label;

    if (layout.ontologyAssn) {
      const schema = new Schema(template);
      const propURI = layout.ontologyAssn[0], groupNest = layout.ontologyAssn.slice(1);
      const assn = schema.findAssignment(propURI, groupNest);
      const popoverID = 'prop-' + keyPropGroup(propURI, groupNest);

      const tooltip = popoverID == statePopoverID ? (
        <Typography className="ProtocolAnnotator-tooltip">
          <b>{popoverURI}</b>
          <br />
          {popoverDescr}
        </Typography>
      ) : '';
      // TODO: should show the groupNest embedding as well, in the same popup?

      return (
        <Tooltip
          title={tooltip}
          arrow
          placement="right"
        >
          <span
            className="ProtocolAnnotator-label"
            onMouseEnter={() => this.handlePropPopoverOpen(popoverID, assn)}
          >
            <b>{label}</b>
          </span>
        </Tooltip>
      );
    }

    const classList = ['ProtocolAnnotator-label'];
    if (layout.emphasis?.includes(FormLayoutEmphasis.Underline)) classList.push('ProtocolAnnotator-emphasis-underline');
    return (
      <span className={classList.join(' ')}>
        <b>{displayLabel}</b>
      </span>
    );
  }

  private get fieldDefinitionMap() {
    const result: Record<number, ProtocolField> = {};
    this.props.protocolFields.forEach((field) => {
      if (field.definition) {
        result[field.definition.id] = field;
      }
    });
    return result;
  }

  private get isProtocol() {
    const { resourceType } = this.props;
    return resourceType === 'protocol';
  }

  private get isRun() {
    const { resourceType } = this.props;
    return resourceType === 'run';
  }

  private get resourceName() {
    return this.isProtocol ? this.strProtocol : this.strRun;
  }

  private get resourceTitle() {
    return this.isProtocol ? this.strProtocolTitle : this.strRunTitle;
  }

  private handlePropPopoverOpen = (popoverID: string, assn: TemplateAssignment) => {
    let descr = assn.descr;
    if (!descr) {
      const branch = OntologyTree.props.cachedBranch(assn.propURI);
      if (branch) descr = branch.description;
    }
    if (descr != null) {
      this.setState({ popoverID, popoverURI: assn.propURI, popoverDescr: descr });
      return;
    }

    (async () => {
      const branch = await OntologyTree.props.getBranch(assn.propURI, true, true);
      this.setState({ popoverID, popoverURI: assn.propURI, popoverDescr: branch ? branch.description : null });
    })();
  };

  private handleValuePopoverOpen = (popoverID: string, uri: string) => {
    const branch = OntologyTree.values.cachedBranch(uri);
    if (branch && branch.description != null) {
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch.description });
      return;
    }

    (async () => {
      const branch = await OntologyTree.values.getBranch(uri, true, true);
      this.setState({ popoverID, popoverURI: uri, popoverDescr: branch ? branch.description : null });
    })();
  };

  private changedOntologyLabel = (key: string, propURI: string, groupNest: string[], oldValue: string, newValue: string) => {
    const { form } = this.state;
    let { ontologyAnnotations, ontologyAnnotsSansDefs } = this.state;

    if (key == 'Enter' || key == null) {
      if (oldValue == newValue) return;

      const key = keyPropGroup(propURI, groupNest);

      ontologyAnnotations = ontologyAnnotations.filter((annot) => key != keyPropGroup(annot.propURI, annot.groupNest) || oldValue != annot.valueLabel);
      ontologyAnnotations.push({ propURI, groupNest, valueLabel: newValue });

      ontologyAnnotsSansDefs = ontologyAnnotsSansDefs.filter((annot) => key != keyPropGroup(annot.propURI, annot.groupNest) || oldValue != annot.valueLabel);
      ontologyAnnotsSansDefs.push({ propURI, groupNest, valueLabel: newValue });

      this.updateFormState(form, ontologyAnnotations, ontologyAnnotsSansDefs);
    }
  };

  private changedProtocolBatchLink = (field: ProtocolField, option: SelectedBatchOption) => {
    const { protocolFields } = this.state;

    if ((option?.value ?? '') !== '') {
      field.value.text_value = option.label;
      field.value.batch_link_id = option.value as number;
    } else {
      field.value.text_value = '';
      field.value.batch_link_id = null;
    }
    this.setState({ protocolFields });
  };

  private changedProtocolFile = (field: ProtocolField, fileID: ID | null, filename: string) => {
    const { protocolFields } = this.state;

    field.value.text_value = filename;
    field.value.uploaded_file_id = fileID;

    this.setState({ protocolFields });
  };

  private changedProtocolText = (field: ProtocolField, value: string) => {
    const { protocolFields } = this.state;

    const dataType = this.getFieldType(field);
    if (dataType == FieldDataType.Text ||
      dataType == FieldDataType.LongText) {
      field.value.text_value = value;
    } else if (dataType == FieldDataType.Number) {
      const num = parseFloat(value);
      field.value.float_value = Number.isNaN(num) ? undefined : num;
    } else if (dataType == FieldDataType.Date) {
      field.value.date_value = value;
    } else if (dataType == FieldDataType.File) {
      // TODO
    } else if (dataType == FieldDataType.PickList) {
      const look = field.definition.pick_list_values.find((pick) => pick.value == value);
      if (look) {
        field.value.pick_list_value_id = look.id;
      }
    }
    this.setState({ protocolFields });
  };

  private handleEnterEditMode = () => {
    this.saveState();
    this.setState({
      isEditing: true,
    });

    setTimeout(CDD.Form.setupCalendarFields);
  };

  private handleChangeTemplateOrForm = (selectedFormOrTemplate: string | number) => {
    const { ontologyAnnotsSansDefs } = this.state;
    const { form, template } = this.findProtocolForm(selectedFormOrTemplate);

    const effectiveAnnotations = this.supplementWithDefaults(form, ontologyAnnotsSansDefs);
    this.updateFormState(form, effectiveAnnotations, ontologyAnnotsSansDefs);
    this.setState({ template, selectedFormOrTemplate });
  };

  private handleSubmitEdit = (event: React.KeyboardEvent | React.MouseEvent) => {
    const {
      formURL,
      isNew,
      navigateOnCreation,
      protocolId,
      resourceType,
      runGrouping,
    } = this.props;
    const {
      ontologyAnnotations,
      selectedFormOrTemplate,
    } = this.state;

    if ((event as React.KeyboardEvent<HTMLInputElement>).key == 'Enter') {
      return;
    }
    const form = this.formRef.current;
    if (this.isProtocol) {
      form.elements[`${resourceType}[ontology_schema_prefix]`].value = selectedFormOrTemplate;
    }
    form.elements[`${resourceType}[ontology_annotations_json]`].value = JSON.stringify(ontologyAnnotations);

    // If any required fields defined in the form are blank (excluding those belonging to requirement groups
    // outside of forms), then prevent submission because the backend does not yet validate form-only requirement.
    if (this.blankRequiredFieldLabels.length > 0) {
      this.setState({ errorMessages: this.blankRequiredFieldLabels.map((label) => `${label} is required`) });
      return;
    }

    // find all elements in the form that have the disabled attribute, remove the disabled attribute, and add a data-disabled attribute.
    // necessary because disabled elements are not submitted with the form.
    const disabledElements = form.querySelectorAll(':disabled');
    disabledElements.forEach((element) => {
      element.setAttribute('data-disabled', 'true');
      element.removeAttribute('disabled');
    });

    const formData = new FormData(form);

    // re-disable the elements that were disabled before
    disabledElements.forEach((element) => {
      element.setAttribute('disabled', 'true');
      element.removeAttribute('data-disabled');
    });

    this.setState({ disableSubmit: true });
    if (isNew) {
      axios.post(formURL, formData).then(({ data: { redirect_url, resource_id, resource_name } }) => {
        if (navigateOnCreation) {
          window.location = redirect_url;
        } else {
          CDD.Mapper.hideCreateRunDialog();
          CDD.Mapper.createRunDialog(resource_id, resource_name, runGrouping, protocolId);
        }
      }, (error) => {
        let errorMessages = error?.response?.data?.errors ?? [`Unable to create ${this.resourceTitle}. Please contact support.`];
        if (error.response.status == 403) {
          errorMessages = [CDD.Axios.invalidCSRFMessage];
        }
        this.setState({ disableSubmit: false, errorMessages });
      });
    } else {
      axios.put(formURL, formData).then(() => {
        this.setState({ disableSubmit: false, errorMessages: [], isEditing: false });
        if (this.isProtocol) {
          const name = formData.get('protocol[name]');
          if (name) {
            CDD.Helpers.updateTitleAndPageHeader(name.toString());
            getRootStore().protocolReadoutDefinitionsStore.editStore.reloadAutocomplete();
          }
        }
      }, (error) => {
        let errorMessages = error?.response?.data?.errors ?? ['Unable to save changes. Please contact support.'];
        if (error.response.status == 403) {
          errorMessages = [CDD.Axios.invalidCSRFMessage];
        }
        this.setState({ disableSubmit: false, errorMessages });
      });
    }
  };

  private handleCancelEdit = (event: React.KeyboardEvent | React.MouseEvent) => {
    const {
      cancelURL,
      isNew,
      navigateOnCreation,
    } = this.props;

    if ((event as React.KeyboardEvent<HTMLInputElement>).key == 'Escape') {
      return;
    }

    if (cancelURL) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.location = cancelURL as any;
    } else if (isNew && this.isRun) {
      if (navigateOnCreation) {
        CDD.Form.hideEditPanel('protocol-newRun');
      } else {
        CDD.Mapper.hideCreateRunDialog();
      }
    } else {
      this.restoreState();
    }
  };

  private handleAddAnnotation = (layout: FormLayout, asLabel: boolean) => {
    (async () => {
      const { template } = this.state;
      const schema = new Schema(template);
      const propURI = layout.ontologyAssn[0], groupNest = layout.ontologyAssn.slice(1);
      const assn = schema.findAssignment(propURI, groupNest);
      if (!assn) throw new Error('Assignment not found'); // should've been biffed into orphan collection instead

      if (!asLabel && (assn.suggestions == SuggestionType.Full || assn.suggestions == SuggestionType.Disabled)) { // URI types
        const selected = (layout.ontologyAnnots || []).map((annot) => annot.valueURI).filter((uri) => !!uri);
        let roots = await schema.composeBranch(assn, selected) ?? [];
        if (layout.allowedValues?.length > 0) {
          roots = cherryPickBranches(roots, layout.allowedValues as string[]);
        }

        const cacheRoots: SearchCacheRoot[] = roots.map((branch) => {
          return {
            assn,
            groupNest,
            branch,
          };
        });

        this.setState({
          isPickerOpen: true,
          pickerAssn: assn,
          pickerNest: groupNest,
          pickerRoots: roots,
          pickerSelected: collapsePrefixes(selected),
          pickerSearch: new SearchCache(schema, cacheRoots),
        });
      } else { // other types that are stored as labels
        this.setState({
          isLabelOpen: true,
          labelAssn: assn,
          labelNest: groupNest,
          labelValue: null,
        });
      }
    })();
  };

  private handlePickerDialogCancel = () => {
    this.setState({ isPickerOpen: false });
  };

  private handlePickerDialogSubmit = (assn: TemplateAssignment, annotation: Annotation) => {
    const { form, ontologyAnnotations, ontologyAnnotsSansDefs } = this.state;

    this.updateFormState(form, [...ontologyAnnotations, annotation], [...ontologyAnnotsSansDefs, annotation]);

    this.setState({ isPickerOpen: false });
  };

  private handleLabelDialogCancel = () => {
    this.setState({ isLabelOpen: false });
  };

  private handleLabelDialogSubmit = (assn: TemplateAssignment, annotation: Annotation) => {
    const { form, ontologyAnnotations, ontologyAnnotsSansDefs } = this.state;

    this.updateFormState(form, [...ontologyAnnotations, annotation], [...ontologyAnnotsSansDefs, annotation]);

    this.setState({ isLabelOpen: false });
  };

  private handleDeleteAnnotation = (propURI: string, groupNest: string[], valueURI: string) => {
    const { form } = this.state;
    let { ontologyAnnotations, ontologyAnnotsSansDefs } = this.state;

    const key = keyPropGroup(propURI, groupNest);
    ontologyAnnotations = ontologyAnnotations.filter((annot) => key != keyPropGroup(annot.propURI, annot.groupNest) || valueURI != annot.valueURI);
    ontologyAnnotsSansDefs = ontologyAnnotsSansDefs.filter((annot) => key != keyPropGroup(annot.propURI, annot.groupNest) || valueURI != annot.valueURI);

    this.updateFormState(form, ontologyAnnotations, ontologyAnnotsSansDefs);
  };

  private handleDeleteTextLabel = (propURI: string, groupNest: string[], textLabel: string) => {
    const { form } = this.state;
    let { ontologyAnnotations, ontologyAnnotsSansDefs } = this.state;

    const key = keyPropGroup(propURI, groupNest);
    ontologyAnnotations = ontologyAnnotations.filter((annot) => key != keyPropGroup(annot.propURI, annot.groupNest) || textLabel != annot.valueLabel);
    ontologyAnnotsSansDefs = ontologyAnnotsSansDefs.filter((annot) => key != keyPropGroup(annot.propURI, annot.groupNest) || textLabel != annot.valueLabel);

    this.updateFormState(form, ontologyAnnotations, ontologyAnnotsSansDefs);
  };

  private handleGlobalSearchSelect = (annotation: Annotation) => {
    const { form, ontologyAnnotations, ontologyAnnotsSansDefs } = this.state;

    this.updateFormState(form, [...ontologyAnnotations, annotation], [...ontologyAnnotsSansDefs, annotation]);
  };

  private buildFormName(fieldID: StringOrNumber, fieldIndex: number) {
    const { resourceType } = this.props;

    if (fieldID === 'protocol_name') {
      return 'protocol[name]';
    } else if (fieldID === 'run_date') {
      return 'run[run_date]';
    } else {
      return `${resourceType}[editable_fields_including_blanks_attributes][${fieldIndex - 1}][value]`;
    }
  }

  // make sure that template & term info is cached, so it can be accessed later without async calls
  private async cacheloadOntology(template: OntologyTemplate, annotations: Annotation[]): Promise<void> {
    const loadGroup = async (group: TemplateGroup): Promise<void> => {
      OntologyTree.props.getBranch(group.groupURI);
      for (const assn of group.assignments) await OntologyTree.props.getBranch(assn.propURI);
      for (const sgrp of group.subGroups) await loadGroup(sgrp);
    };
    await loadGroup(template.root);

    for (const annot of annotations) {
      await OntologyTree.props.getBranch(annot.propURI);
      if (annot.valueURI) await OntologyTree.values.getBranch(annot.valueURI);
      for (const uri of annot.groupNest) await OntologyTree.props.getBranch(uri);
    }
  }

  private findFormName(formIdOrPrefix: StringOrNumber): string | null {
    const {
      formDefinitionList,
    } = this.props;

    return formDefinitionList?.find((look) => look.id.toString() === formIdOrPrefix)?.name;
  }

  private findDefinedForm(formIdOrPrefix: StringOrNumber) {
    const {
      formDefinitionList,
      resourceType,
    } = this.props;
    const formDefinition = formDefinitionList?.find((look) => look.id.toString() === formIdOrPrefix);
    if (this.isProtocol) {
      return formDefinition?.protocol_form;
    } else if (this.isRun) {
      return formDefinition?.run_form;
    } else {
      console.error('Unknown ProtocolAnnotator resource type:', resourceType);
    }
  }

  private findProtocolForm(formIdOrPrefix: StringOrNumber): { form: ProtocolForm | null, template: OntologyTemplate | null; } {
    const {
      form: staticFormProp,
      templateList,
    } = this.props;
    const {
      ontologyAnnotations,
      protocolFields,
    } = this.state;

    // Priority is given to the form prop, which never changes and is only used in preview mode to always render itself.
    // Next, look for a form definition with the given ID and use its associated template, if any.
    // Next, look for a template with the given schema prefix and generate a form for it.
    // Finally, generate a basic form with no annotation template associated.
    const definedForm = staticFormProp || this.findDefinedForm(formIdOrPrefix);
    const schemaPrefix = definedForm?.schemaPrefix ?? formIdOrPrefix;
    const template = templateList.find((look) => look.schemaPrefix === schemaPrefix);
    const formContext = this.isProtocol
      ? FormContextType.Protocol
      : FormContextType.Run;

    harmonizeTemplateAnnotations(template, ontologyAnnotations);

    const form = definedForm || createImpliedForm(formContext, protocolFields, template, ontologyAnnotations);

    return { form, template };
  }

  private findProtocolField(id: StringOrNumber): [ProtocolField, number] {
    const { protocolFields } = this.state;
    for (let n = 0; n < protocolFields.length; n++) {
      const field = protocolFields[n];

      if (field?.definition?.id === id) {
        return [field, n];
      } else if (!(field?.definition)) {
        if (this.isProtocol && id === 'protocol_name') {
          return [field, n];
        } else if (this.isRun && id === 'run_date') {
          return [field, n];
        }
      }
    }
    return [null, null];
  }

  private getFieldType(field: ProtocolField) {
    if (field?.definition) {
      return field.definition.data_type_name;
    } else if (this.isProtocol) {
      return FieldDataType.Text;
    } else if (this.isRun) {
      return FieldDataType.Date;
    }
    return null;
  }

  private updateFormState(form: ProtocolForm, ontologyAnnotations: Annotation[], ontologyAnnotsSansDefs: Annotation[]): void {
    const formCopy = JSON.parse(JSON.stringify(form));
    const expandAnnot = (annot: Annotation): Annotation => {
      return {
        propURI: expandPrefix(annot.propURI),
        groupNest: expandPrefixes(annot.groupNest),
        valueURI: expandPrefix(annot.valueURI),
        valueLabel: annot.valueLabel,
      };
    };
    ontologyAnnotations = ontologyAnnotations.map(expandAnnot);
    ontologyAnnotsSansDefs = ontologyAnnotsSansDefs.map(expandAnnot);

    replaceFormTerms(formCopy, ontologyAnnotations);

    this.setState({
      form: formCopy,
      ontologyAnnotations,
      ontologyAnnotsSansDefs,
    });
  }

  private buildTemplateAndFormOptions() {
    const {
      formDefinitionList,
      templateList,
    } = this.props;

    if (hasFeature('enableForms')) {
      return ([
        { name: '(Select form)', value: '' },
        ...formDefinitionList?.map((formDef) => {
          return { name: formDef.name, value: formDef.id.toString() };
        }) ?? [],
        ...templateList.map((template) => {
          return { name: `[Template] ${template.root.name}`, value: template.schemaPrefix };
        }),
      ]);
    } else {
      return ([
        { name: '(select template)', value: '?' },
        ...templateList.map((template) => {
          return { name: template.root.name, value: template.schemaPrefix };
        }),
      ]);
    }
  }

  private copyToClipboard(): void {
    const data: ClipboardJSON = {
      schemaURI: this.state.template.schemaPrefix,
      fields: this.state.protocolFields.map((field) => {
        return {
          label: field.label,
          value: field.value,
          fieldDefnID: field.definition?.id,
        };
      }),
      annotations: this.state.ontologyAnnotations.map((annot) => {
        return {
          propURI: annot.propURI,
          groupNest: annot.groupNest,
          valueURI: annot.valueURI || undefined,
          valueLabel: annot.valueLabel || undefined,
        };
      }),
    };
    copyText(JSON.stringify(data, null, 2));

    this.setState({ fadeCount: this.state.fadeCount + 1, fadeMessage: 'Copied to clipboard.' });
    setTimeout(() => this.setState({ fadeCount: this.state.fadeCount - 1 }), 10000);
  }

  private clearEverything(): void {
    const protocolFields = this.state.protocolFields.map((field) => {
      return {
        ...field,
        value: {
          ...field.value,
          float_value: null,
          text_value: null,
          date_value: null,
          uploaded_file_id: null,
          pick_list_value_id: null,
          batch_link_id: null,
        },
      };
    });

    const { form } = this.state;

    this.setState({ protocolFields });
    this.updateFormState(form, this.supplementWithDefaults(form, []), []);
  }

  private handleKeyDown = (event: React.KeyboardEvent<HTMLElement>): void => {
    const { key, shiftKey, ctrlKey, altKey, metaKey } = event;
    const xmods = (ctrlKey || metaKey ? 'X' : '') + (shiftKey ? 'S' : '') + (altKey ? 'A' : ''); // for Mac vs. PC: ctrl/shift = X
    const targetTag = (event.target as unknown as { tagName: string; }).tagName;

    let stop = false;
    if (key == 'c' && xmods == 'X') {
      if (targetTag == 'INPUT' && window.getSelection().toString()) return;
      this.copyToClipboard();
      stop = true;
    } else if (key == 'x' && xmods == 'X') {
      if (targetTag == 'INPUT' && window.getSelection().toString()) return;
      this.copyToClipboard();
      this.clearEverything();
      stop = true;
    }

    if (stop) {
      event.preventDefault();
      event.stopPropagation();
    }
  };

  private handlePaste = (event: React.ClipboardEvent<HTMLElement>): void => {
    const text = event.clipboardData.getData('text/plain');

    let json: ClipboardJSON;
    try {
      json = JSON.parse(text);
    } catch (_) {
      return; // no JSON, no problem...
    }
    if (!json || (!Array.isArray(json.fields) && !Array.isArray(json.annotations))) return;

    event.preventDefault();
    event.stopPropagation();

    const protocolFields = [...this.state.protocolFields];
    const ontologyAnnotations = [...this.state.ontologyAnnotations];
    const ontologyAnnotsSansDefs = [...this.state.ontologyAnnotsSansDefs];

    for (const field of (json.fields || [])) {
      for (const look of protocolFields) {
        if (field.fieldDefnID == look.definition?.id || (!field.fieldDefnID && !look.definition?.id && field.label == look.label)) {
          const { float_value, text_value, date_value, uploaded_file_id, pick_list_value_id, batch_link_id } = field.value;
          look.value = {
            ...look.value,
            float_value,
            text_value,
            date_value,
            uploaded_file_id,
            pick_list_value_id,
            batch_link_id,
          };
          break;
        }
      }
    }

    const hashAlready = new Set<string>(), hashAlreadySans = new Set<string>();
    for (const annot of ontologyAnnotations) {
      hashAlready.add(keyPropGroupValue(annot.propURI, annot.groupNest, annot.valueURI ?? annot.valueLabel));
    }
    for (const annot of ontologyAnnotsSansDefs) {
      hashAlreadySans.add(keyPropGroupValue(annot.propURI, annot.groupNest, annot.valueURI ?? annot.valueLabel));
    }

    const hashInForm = new Set<string>();
    const scanLayout = (layout: FormLayout): void => {
      if (layout.ontologyAssn) {
        hashInForm.add(keyPropGroup(layout.ontologyAssn[0], layout.ontologyAssn.slice(1)));
      }
      for (const sub of (layout.contents ?? [])) {
        scanLayout(sub);
      }
    };
    for (const section of this.state.form.sections) {
      for (const layout of section.contents) {
        scanLayout(layout);
      }
    }

    (async () => {
      for (const annot of json.annotations) {
        if (!annot.propURI || (!annot.valueURI && !annot.valueLabel)) continue;
        if (!hashInForm.has(keyPropGroup(annot.propURI, annot.groupNest))) continue;
        const hash = keyPropGroupValue(annot.propURI, annot.groupNest, annot.valueURI ?? annot.valueLabel);
        const sanitizedAnnot = {
          propURI: annot.propURI,
          groupNest: annot.groupNest ?? [],
          valueURI: annot.valueURI ?? undefined,
          valueLabel: annot.valueURI ? undefined : annot.valueLabel,
        };

        if (!hashAlready.has(hash)) {
          hashAlready.add(hash);
          ontologyAnnotations.push(sanitizedAnnot);
        }
        if (!hashAlreadySans.has(hash)) {
          hashAlreadySans.add(hash);
          ontologyAnnotsSansDefs.push(sanitizedAnnot);
        }

        if (annot.valueURI) await OntologyTree.values.getBranch(annot.valueURI);
      }

      this.setState({ protocolFields });
      this.updateFormState(this.state.form, ontologyAnnotations, ontologyAnnotsSansDefs);
    })();
  };

  private async updateOntologyCache(): Promise<void> {
    let anything = false;
    for (const uri of this.uncachedValues) {
      if (await OntologyTree.values.getBranch(uri, true, false, false)) {
        anything = true;
      }
    }
    this.uncachedValues.clear();
    if (anything) {
      this.setState({ bumpState: this.state.bumpState + 1 });
    }
  }

  private supplementWithDefaults(form: ProtocolForm, ontologyAnnotations:Annotation[]):Annotation[] {
    if (!form || !ontologyAnnotations) return ontologyAnnotations;

    const seenAssnKeys = new Set<string>();
    for (const assn of ontologyAnnotations) {
      seenAssnKeys.add(keyPropGroup(assn.propURI, assn.groupNest));
    }

    const supplemented = [...ontologyAnnotations];

    const scanLayout = (layout: FormLayout): void => {
      if (layout.layoutType == FormLayoutType.Cell && layout.ontologyAssn && layout.defaultValue) {
        const assnSpec = layout.ontologyAssn, propURI = assnSpec[0], groupNest = assnSpec.slice(1);
        const key = keyPropGroup(propURI, groupNest);
        if (!seenAssnKeys.has(key)) {
          supplemented.push({ propURI, groupNest, valueURI: layout.defaultValue });
        }
      }
      (layout.contents ?? []).map(scanLayout);
    };
    for (const section of form.sections) {
      section.contents.map(scanLayout);
    }

    return supplemented;
  }
}
