import React, { ReactNode, useState } from 'react';
import { Bioisoster, BioisosterSettings, ReferenceStructure } from './BioisosterResultsTypes';
import { StructureCardView } from '../renderer';
import { HorizontalPropertyListView } from '../Properties/PropertyListView';
import { Overlay } from '../layout';
import { Box, Tooltip, Link } from '@mui/material';
import StarBorderIcon from '@mui/icons-material/Star';
import { allCollections, CollectionInformationView } from '../Similarity/Collections';
import { GotoCollectionButton } from '../Similarity/SimilarityResultsControls';
import { CDD } from '@/typedJS';
import { RouterStore } from '@/stores/routerStore';

const bioisosterResultViewHeader = (
  result: Bioisoster,
  banExternalLinks: boolean,
): ReactNode => {
  return result.identifiers?.map<React.ReactNode>((identifier) => (
    <GotoCollectionButton key={identifier.name} identifier={identifier} banExternalLinks={banExternalLinks} />
  ));
};

export const BioisosterView = (props: {
  bioisoster: Bioisoster;
  reference: ReferenceStructure;
  settings: BioisosterSettings;
  banExternalLinks: boolean;
  canRegisterMolecules: boolean;
}) => {
  const { bioisoster, settings, canRegisterMolecules } = props;
  const [selected, setSelected] = useState(bioisoster.selected);
  const [registerlink, setRegisterlink] = useState(false);

  const handleMouseClick = (): void => {
    bioisoster.selected = !bioisoster.selected;
    setSelected(bioisoster.selected);
  };
  const handleMouseEnter = () => setRegisterlink(true);
  const handleMouseLeave = () => setRegisterlink(false);

  const structureSize = 200;
  const header = bioisosterResultViewHeader(bioisoster, props.banExternalLinks);
  const footer =
    <Box sx={{ bottom: 0, left: 0, fontSize: '0.75em' }} >
      <Tooltip title={'Tanimoto similarity to reference structure'} arrow placement='bottom'>
        <span>{bioisoster.tanimoto.toFixed(3)}</span>
      </Tooltip>
      {settings.showCollectionInformation && <CollectionInformationView filters={allCollections} identifiers={bioisoster.identifiers} />}
      <HorizontalPropertyListView
        properties={bioisoster.properties}
        reference={props.reference.properties}
        displayProperties={props.settings.properties}
        separator=','
      />
    </Box>;
  return (
    <Box
      onClick={handleMouseClick}
      sx={{ position: 'relative', margin: '0.01em' }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StructureCardView
        structure={bioisoster.structure}
        structureSize={structureSize}
        header={header}
        headerSx={{ minHeight: '2em', paddingRight: '2em', alignItems: 'center' }}
        highlightedAtomNumbers={bioisoster.atomIdx && bioisoster.atomIdx.map((atomIdx) => atomIdx + 1)}
        highlightedStructures={[bioisoster.fragment]}
        dataTestid={'bioisoster-structure'}
        selected={selected}
        footer={footer}
        contentSx={{ zIndex: 1, position: 'relative' }}
      />
      {canRegisterMolecules && registerlink
        ? <Overlay zIndex={10}><RegisterLink bioisoster={bioisoster} /></Overlay>
        : null}
      {bioisoster.selected
        ? <Overlay zIndex={5}><StarBorderIcon style={{ float: 'right' }} color='info' /></Overlay>
        : null}
    </Box>
  );
};

export const RegisterLink = (props: {
  bioisoster: Bioisoster
}) => {
  const handleClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    const url = `/${CDD.ActiveDataContext.toContextParam}/molecules/new`;
    await RouterStore.openInNewTabWithPost(url, { smiles: props.bioisoster.structure });
  };
  return (
    <Link href='#' target='_blank' rel='noreferrer' onClick={handleClick}>
      Register
    </Link>
  );
};

export const ReferenceView = (props: {
  reference: ReferenceStructure;
  settings: BioisosterSettings;
  highlightedAtomNumbers: number[];
}) => {
  const { reference, highlightedAtomNumbers } = props;
  const structureSize = 150;
  const footer = <HorizontalPropertyListView
    properties={reference.properties}
    displayProperties={props.settings.properties}
    separator=','
  />;
  return (
    <StructureCardView
      structure={reference.structure}
      structureSize={structureSize}
      highlightedAtomNumbers={highlightedAtomNumbers}
      dataTestid={'bioisoster-reference'}
      footer={footer}
    />
  );
};
