import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, runInAction } from 'mobx';
import { readoutDefinitionService } from '../readoutDefinitionService';
import { CustomCalculationObject, DoseResponseCalculationObject, ReadoutDefinitionObject } from '../types';
import { EditProtocolReadoutDefinitionsStore } from './editProtocolReadoutDefinitionsStore';

export class ProtocolReadoutDefinitionsStore {
  inited = false;
  loading = 0;

  editStore: EditProtocolReadoutDefinitionsStore = null;

  rows: Array<ReadoutDefinitionObject> = [];
  doseResponseCalculations: Array<DoseResponseCalculationObject> = null;
  customCalculations: Array<CustomCalculationObject> = [];

  disposers: Array<() => void> = [];

  constructor(public readonly root: RootStore) {
    makeAutoObservable(this, undefined, { autoBind: true });
    this.editStore = new EditProtocolReadoutDefinitionsStore(this.root, this);
  }

  init() {
    if (!this.inited) {
      this.inited = true;
    }
    this.editStore.init();
  }

  get displayRows() {
    return this.rows.filter(row => row.sortable);
  }

  loadReadoutDefinitions(createOnEmpty = false) {
    this.incrementLoading();
    Promise.all([
      readoutDefinitionService.getReadoutDefinitions(),
      readoutDefinitionService.getDoseResponseCalculations(),
      readoutDefinitionService.getCustomCalculations(),
    ])
      .then((values) => {
        runInAction(() => {
          this.rows = values[0].data;
          this.doseResponseCalculations = values[1].data;
          this.customCalculations = values[2].data;
        });

        if (createOnEmpty && this.displayRows.length === 0) {
          this.editStore.handleCreateNewReadoutDefinition();
        }
      })
      .finally(() => { this.decrementLoading(); });
  }

  getDoseResponseForReadout(readout: ReadoutDefinitionObject) {
    for (const drc of this.doseResponseCalculations) {
      if (drc.id === readout.calculation_id) {
        return drc;
      }
    }
    return null;
  }

  getCustomCalculationForReadout(readout: ReadoutDefinitionObject) {
    return this.customCalculations.find((customCalculation) => customCalculation.id === readout.calculation_id);
  }

  handleChangeOrder = (rows: Array<ReadoutDefinitionObject>) => {
    readoutDefinitionService.reorderReadoutDefinitions(rows.map(row => row.id));
    runInAction(() => {
      this.rows = rows;
    });
  };

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }
}
