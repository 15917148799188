import { LabelAndValue } from '@/models/Salt/SaltPicker';
import { CondensedTheme } from '@/shared/components/MuiTheme';
import RailsHiddenFields from '@/shared/components/RailsHiddenFields.jsx';
import { TestableAutocomplete } from '@/shared/components/TestableMuiComponents/TestableSelect';
import { Grid, Stack, TextField } from '@mui/material';
import uniqBy from 'lodash/uniqBy';
import React from 'react';

interface Vault {
  id: number;
  name: string;
  label?: string;
  value?: number;
  settings: {
    account: {
      name: string;
    };
  };
}

interface VaultSelectProps {
  vault_id: number;
  vaults: Vault[];
  fieldName?: string;
}

interface VaultSelectState {
  vaultId: number | null;
  vault: Vault | null;
  account: { label: string; value: string } | null;
}

export default class VaultSelect extends React.Component<VaultSelectProps, VaultSelectState> {
  vaultList: Vault[];
  accountList: { label: string; value: string }[];

  constructor(props: VaultSelectProps) {
    super(props);
    const vaultId = this.props.vault_id;
    this.vaultList = this.initializeVaultList(this.props.vaults);
    this.accountList = this.initializeAccountList(this.props.vaults);
    const vault = this.selectedVaultForId(vaultId);
    let account = null;
    if (vault) {
      account = this.initializeAccountList([vault])[0];
    }
    this.state = { vaultId, vault, account };
  }

  selectedVaultForId(id: number | null): Vault | null {
    if (!id) {
      return null;
    }
    const idInteger = 1 * id;
    const selectedVault = this.vaultList.find(entry => entry.value === idInteger);
    return selectedVault || null;
  }

  initializeVaultList = (rawList: Vault[]) => {
    return rawList.map(entry => ({ ...entry, label: entry.name, value: entry.id }));
  };

  initializeAccountList = (rawList: Vault[]): { label: string; value: string }[] => {
    const list = rawList.map(entry => ({ label: entry.settings.account.name, value: entry.settings.account.name }));
    return uniqBy(list, 'label');
  };

  handleChangeVault = (_e, selection: LabelAndValue) => {
    const vaultId = parseInt(selection.value.toString(), 10);
    const vault = this.selectedVaultForId(vaultId);
    const change: Partial<VaultSelectState> = { vaultId, vault };
    if (vault && !this.state.account) {
      change.account = this.initializeAccountList([vault])[0];
    }
    this.setState(change as VaultSelectState);
  };

  handleChangeAccount = (_e, value: LabelAndValue) => {
    const accountValue = value.value;
    const account = this.accountList.find(acc => acc.value === accountValue) || null;
    const change: Partial<VaultSelectState> = { vaultId: null, vault: null, account };
    this.setState(change as VaultSelectState);
  };

  // Scott - I hate this. The global event handler in buttonySubmitHandler wants to handle the Enter key and the
  // only way I can see to prevent this from firing when the menus are open is to disable the form. Yuck.
  formWasDisabled = false;

  handleOpen = () => {
    const button = document.querySelector("button[type='submit']");
    this.formWasDisabled = button?.hasAttribute('disabled') || false;
    button?.setAttribute('disabled', 'disabled');
  };

  handleClose = () => {
    if (!this.formWasDisabled) {
      const button = document.querySelector("button[type='submit']");
      button?.removeAttribute('disabled');
    }
  };

  render() {
    let filteredVaultList = this.vaultList;
    const value = this.state.vault ?? null;
    if (this.state.account) {
      filteredVaultList = filteredVaultList.filter(vault => vault.settings.account.name === this.state.account?.value);
    }
    return (
      <div>
      <CondensedTheme>
        <Stack spacing={1} direction='column' sx={{ marginTop: '.5rem', marginBottom: '.5rem', marginLeft: '-4px' }}>
        <Grid container spacing={1} id='account-select-container'>
          <Grid item xs={12}>
            <TestableAutocomplete
              className="account-select__control"
              id='account-select__control'
              value={this.state.account || ''}
              onChange={this.handleChangeAccount}
              options={this.accountList}
              onOpen={this.handleOpen}
              onClose={this.handleClose}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id='account-select-input'
                  variant="outlined"
                  placeholder="Select Account"
                  />
              )}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} id='vault-select-container'>
          <Grid item xs={12}>
          <TestableAutocomplete
              className='vault-select__control'
              id='vault-select__control'
              value={value ?? ''}
              onChange={this.handleChangeVault}
              options={filteredVaultList}
              onOpen={this.handleOpen}
              onClose={this.handleClose}
              renderInput={(params) => (
                <TextField
                  {...params}
                  id='vault-select-input'
                  placeholder="Select Vault"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        </Grid>
        </Stack>
        <RailsHiddenFields
          name={this.props.fieldName ?? 'user[vault_id]'}
          value={this.state.vaultId}
        />
      </CondensedTheme>
      </div>
    );
  }
}
