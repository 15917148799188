import React, { ChangeEvent, ClipboardEvent, Component, KeyboardEvent } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';

import Item from './Item';
import SubmitOrCancel from '@/shared/components/SubmitOrCancel';
import { FieldPickValue } from '@/FieldDefinitions/types';
import { deepClone } from '@/Annotator/data/utils';
import { StringOrNumber } from '@/types';

const messages = {
  duplicates: () => 'There are duplicate items in the pick list that require correction',
  present: () => 'This item is already present in the pick list',
  removed: value => `Removed ${value}`,
  addedMany: (count, failureCount) => {
    const itemCountStr = `${count} item${count == 1 ? '' : 's'}`;
    const failureCountStr = `${failureCount} item${failureCount == 1 ? '' : 's'}`;
    return `Added ${itemCountStr} [failed to add ${failureCountStr}]`;
  },
};

type State = {
  open: boolean;
  input: string;
  notification: string;
  values: FieldPickValue[];
  invalidIds?: Set<StringOrNumber>;
  unmodifiableSingleUseItems: Set<StringOrNumber>;
}

type Props = {
  open: boolean;
  values: FieldPickValue[];
  showSingleUseOption?: boolean;
  dialogId?: string;

  onSubmit: (values: FieldPickValue[]) => void;
  onCancel: () => void;
}

const instructions = 'Type in a value and hit enter or paste in a list of values, one per line';

export default class Modal extends Component<Props, State> {
  private inputRef = React.createRef<HTMLInputElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      open: props.open,
      input: '',
      notification: '',
      values: deepClone(props.values) ?? [],
      unmodifiableSingleUseItems: null,
    };
  }

  static getDerivedStateFromProps(props: Props, state: State): State {
    if (props.open !== state?.open) {
      const unmodifiableSingleUseItems = new Set<StringOrNumber>();
      (props.values ?? []).forEach(row => {
        if (!row.deletable && row.is_single_use) {
          unmodifiableSingleUseItems.add(row.id);
        }
      });

      return {
        open: props.open,
        input: '',
        notification: '',
        values: deepClone(props.values) ?? [],
        invalidIds: undefined,
        unmodifiableSingleUseItems,
      };
    }
    return state;
  }

  get displayValues() {
    return (this.state.values ?? []).filter(v => !v._destroy);
  }

  validate() {
    const { displayValues } = this;
    const invalidIds = new Set<StringOrNumber>();

    displayValues.forEach((row, index) => {
      if (displayValues.some((otherRow, otherIndex) => otherIndex !== index && otherRow.value.toLowerCase() === row.value.toLowerCase())) {
        invalidIds.add(row.id);
      }
    });

    this.setState({
      notification: invalidIds.size > 0 ? messages.duplicates() : 'The pick list is valid',
      invalidIds,
    });
  }

  updateInput = (value: string) => {
    this.setState({
      input: value,
      notification: '',
    });
  };

  addItem = (value: string, values: FieldPickValue[]) => {
    const existingIds = new Set(values.map(row => row.id));
    // Generate a unique id for the new item
    let id = 'FAKE-ID-' + Date.now();
    let index = 0;
    while (existingIds.has(id)) {
      id = 'FAKE-ID-' + Date.now() + ++index;
    }

    value = value.trim();
    if (!value) {
      return false;
    }
    if (this.displayValues.some(row => row.value.toLowerCase() === value.toLowerCase())) {
      this.setState({ notification: messages.present() });
      return false;
    }
    const newEntry: FieldPickValue = {
      id,
      value,
      deletable: true,
      hidden: false,
      created_at: null,
      updated_at: null,
      fields_count: 0,
    };
    values.push(newEntry);
    return true;
  };

  add = (value: string) => {
    value = value.trim();
    const values = this.state.values.slice();
    if (this.addItem(value, values)) {
      this.setState({
        values,
        notification: '',
        input: '',
      });
    }
  };

  addMany = (inValues: string[]) => {
    // remove any duplicate values using a case insensitive comparison, but preserving the case of the first occurrence
    const existingValues = new Set<string>(this.state.values.map(value => value.value.toLowerCase()));
    const values: string[] = [];

    // remove any empty values
    inValues = inValues.filter(value => value.trim().length > 0);

    // remove any duplicate values using a case insensitive comparison, but preserving the case of the first occurrence
    let failureCount = inValues.length;
    inValues.forEach(value => {
      if (!existingValues.has(value.toLowerCase())) {
        existingValues.add(value.toLowerCase());
        values.push(value);
      }
    });
    failureCount -= values.length;

    let addedCount = 0;

    const newValues = this.state.values.slice();
    values.forEach(value => {
      if (this.addItem(value, newValues)) {
        ++addedCount;
      } else {
        ++failureCount;
      }
    });

    this.setState({
      values: newValues,
      notification: messages.addedMany(addedCount, failureCount),
      input: '',
    });
  };

  remove = (id: StringOrNumber) => {
    const { values } = this.state;
    this.update(id, { _destroy: true });
    const removedRow = values.find(row => row.id === id);
    this.setState({
      notification: messages.removed(removedRow.value),
    });
  };

  update = (id: StringOrNumber, value: Partial<FieldPickValue>) => {
    const row = this.state.values.find(row => row.id === id);
    if (row) {
      Object.assign(row, value);
      this.validate();
      this.setState({ values: [...this.state.values] });
    }
  };

  handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    this.updateInput(event.target.value);
  };

  handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && this.inputRef.current.value) {
      event.nativeEvent.stopImmediatePropagation();
      this.add(this.inputRef.current.value);
    }
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state.values as FieldPickValue[]);
    this.setState({
      input: '',
      notification: '',
      values: [],
      invalidIds: undefined,
    });
  };

  handleCancel = () => {
    this.props.onCancel();
    this.setState({
      input: '',
      notification: '',
      values: [],
      invalidIds: undefined,
    });
  };

  handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const data = event.clipboardData.getData('Text');
    if (data) {
      event.stopPropagation();
      event.preventDefault();
      this.addMany(data.split('\n'));
    }
  };

  componentDidMount() {
    if (this.inputRef.current) {
      this.inputRef.current.focus();
    }
  }

  render() {
    const { showSingleUseOption, open, dialogId, onCancel } = this.props;
    const { input, notification, invalidIds, unmodifiableSingleUseItems } = this.state;
    const { displayValues: values } = this;
    const disabled = invalidIds?.size > 0;

    return (
      <Dialog
        className='pickListDefinitionDialog'
        id={dialogId}
        open={open}
        onClose={onCancel}
        maxWidth='sm'
        fullWidth
        PaperProps={{ classes: { root: 'pick_list__container' } }}
      >
        <DialogTitle className="pickListTitle">
          Pick List
        </DialogTitle>
        <DialogContent id="pick-list-editor" className="batch-field-definitions">
          <div className="pickListEntryWrapper">
            <input
              autoFocus
              className="pickListItemTextBox"
              onChange={this.handleInput}
              onKeyDown={this.handleKeyDown}
              onPaste={this.handlePaste}
              value={input}
              ref={this.inputRef}
              placeholder={instructions}
            />
            <div className="pickListNotification">
              {notification}
            </div>
          </div>
          <div className='pickListItems'>
            {values.map(pickListValue => (
              <Item
                key={pickListValue.id}
                pickListValue={pickListValue}
                showSingleUseOption={showSingleUseOption}
                unmodifiableSingleUseItems={unmodifiableSingleUseItems}
                update={this.update}
                remove={this.remove}
                invalid={invalidIds?.has(pickListValue.id) ?? false}
                onSelect={() => { this.inputRef.current?.focus(); }}
              />
            ))}
          </div>
        </DialogContent>
        <DialogActions className="pickListActionsContainer">
          <div className="pickListActions">
            <SubmitOrCancel
              disabled={disabled}
              onSubmit={this.handleSubmit}
              onCancel={this.handleCancel}
              green={false}
            >
              Update Pick List
            </SubmitOrCancel>
          </div>
        </DialogActions>
      </Dialog>
    );
  }
}
