import pick from 'lodash/pick'

export const CACHE_ATTACHED_STRUCTURE = 'eln/entry/attachedStructure/cache'
export const LINK_KEY_AND_ATTACHED_STRUCTURE_ID = 'eln/entry/attachedStructure/link'
export const UNLINK_KEY_AND_ATTACHED_STRUCTURE_ID = 'eln/entry/attachedStructure/unlink'

export const linkKeyAndAttachedStructureId = ({ id, nodeKey }) => ({
  type: LINK_KEY_AND_ATTACHED_STRUCTURE_ID,
  payload: { id, nodeKey },
})

export const unlinkKeyAndAttachedStructureId = ({ id, nodeKey }) => ({
  type: UNLINK_KEY_AND_ATTACHED_STRUCTURE_ID,
  payload: { id, nodeKey },
})

export const cacheAttachedStructure = attrs => {
  const payload = pick(attrs, [
    'id', 'batchId', 'moleculeId', 'mrv', 'image', 'moleculeNames',
    'fileId', 'moleculeRegistrationPath', 'registered', 'nodeKey',
    'stoichiometryTable', 'rowId', 'salt',
  ])

  return {
    type: CACHE_ATTACHED_STRUCTURE,
    payload,
  }
}
