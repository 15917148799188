import { CustomAminoAcidsSchema } from '@/Accounts/CustomAminoAcids/types';
import { RootStore } from '@/stores/rootStore';
import { makeAutoObservable, reaction } from 'mobx';
import { ColumnSortDef } from '@/components';
import { EditCustomAminoAcidStore } from './editCustomAminoAcidStore';
import keyBy from 'lodash/keyBy';
import { accountsService } from '@/Accounts/accountsServices';

export class CustomAminoAcidsStore {
  root: RootStore;
  editCustomAminoAcidStore: EditCustomAminoAcidStore;
  inited = false;
  loading = 0;
  disposers: Array<() => void> = [];
  customAminoAcids: Array<CustomAminoAcidsSchema> = [];
  sortBy: ColumnSortDef = { id: 'name', direction: 'asc' };
  filterText = '';

  lastLoadedCustomAminoAcidsForAccount = 0;

  constructor(root: RootStore) {
    this.root = root;
    this.editCustomAminoAcidStore = new EditCustomAminoAcidStore(root);
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  get customAminoAcidsMap(): { [id: number]: CustomAminoAcidsSchema } {
    return keyBy(this.customAminoAcids, 'id');
  }

  init = () => {
    if (!this.inited) {
      this.inited = true;
      this.loading = 0;
      // react to url changes by updating model data and loading account data
      this.disposers.push(
        reaction(
          () => this.root.accountsStore.accountsUrlParams?.accountId,
          (accountId) => {
            if (accountId && accountId !== this.lastLoadedCustomAminoAcidsForAccount) {
              this.loadCustomAminoAcids();
            }
          },
          { fireImmediately: true },
        ),
      );
    }
    this.editCustomAminoAcidStore.init();
  };

  cleanup() {
    this.inited = false;
    this.disposers.forEach((disposer) => disposer());
    this.editCustomAminoAcidStore.cleanup();
  }

  incrementLoading() {
    ++this.loading;
    this.root.incrementLoading();
  }

  decrementLoading() {
    --this.loading;
    this.root.decrementLoading();
  }

  setCustomAminoAcids(val: Array<CustomAminoAcidsSchema>) {
    this.customAminoAcids = val;
  }

  setSortBy(value: ColumnSortDef) {
    this.sortBy = value;
  }

  setFilterText(value: string) {
    this.filterText = value;
  }

  get displayRows(): Array<CustomAminoAcidsSchema> {
    const lcFilter = this.filterText.toLowerCase();

    return this.customAminoAcids.filter((customAminoAcid) => {
      return (
        !lcFilter ||
        customAminoAcid.name
          .toLowerCase()
          .includes(lcFilter)
      );
    });
  }

  async loadCustomAminoAcids() {
    try {
      this.lastLoadedCustomAminoAcidsForAccount = this.root.accountsStore.accountId;
      this.incrementLoading();
      const response = await accountsService.getCustomAminoAcids();
      this.setCustomAminoAcids(response.data);
    } finally {
      this.decrementLoading();
    }
  }
}
