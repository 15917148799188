/* eslint-disable @typescript-eslint/no-explicit-any, no-jquery/no-browser */

import React from 'react';
import pick from 'lodash/pick';
import omitDeep from 'omit-deep-lodash';
import { AxiosError } from 'axios';
import { Box, DialogContentText, Typography } from '@mui/material';
import askForConfirmation from '@/shared/components/ConfirmationDialog';
import { AnyObject } from '@/types';
import CopyToClipboardButton from '@/shared/components/Buttons/CopyToClipboardButton';
import { A } from '@/shared/components/sanitizedTags.js';
import { CDD } from '@/typedJS';

const stringify = (object: object) => JSON.stringify(object).replace(/,"/g, ', "');

// properties in the diagnostic object we don't want to show:
const suppressDisplayDiagnosticProperties = ['X-CSRF-Token'];
class AdditionalDetails extends React.Component<{ details: AnyObject, errorList: Array<string> }> {
  render() {
    const { details, errorList } = this.props;
    try {
      return <>
        {<DialogContentText component={'span'}>
          {(errorList.length > 0) && <>
            <Typography>Details:</Typography>
            <ul>
              {errorList.map(error =>
                <li key={error}>{error}</li>,
              )}
            </ul>
          </>}

          <Typography>Please copy the information below and email it
            along with a description of this problem to <A href='mailto:support@collaborativedrug.com'> support@collaborativedrug.com </A>:</Typography>

          {(Object.keys(details).length > 0) && <div className='diagnostic-details'>
            {stringify(details)}
          </div>}
        </DialogContentText>}

        {(Object.keys(details).length > 0) &&
          <Box textAlign='center'>
            <CopyToClipboardButton value={stringify(details)} />
          </Box>}
      </>;
    } catch (e) {
    }
  }
}

export const displayUnhandledErrorDialog = (errorEvent: ErrorEvent | AxiosError) => {
  let details: AnyObject = {};
  let errorList: Array<string> = [];

  if ('config' in errorEvent) {
    details.url = errorEvent.config?.url;
    const responseData = errorEvent.response?.data;
    const responseErrors = { ...(responseData as any)?.errors };
    if (responseData) {
      const deeperErrors = Object.keys(responseErrors);
      Object.entries(responseData).forEach(([key, value]) => {
        if (deeperErrors.includes(key)) {
          if (Array.isArray(value)) {
            value.forEach((item) => {
              Object.entries((item as any).errors).forEach(([key_sub, value]) => {
                responseErrors[key + ' ' + key_sub] = value as any;
              });
            });
          }
        }
      });
      details = {
        currentUrl: location.href,
        features: CDD.features,
        ...pick(errorEvent.config ?? {}, ['url', 'method', 'headers', 'data']),
      };
    }
    errorList = Object.values(responseErrors).flat() as Array<string>;
  } else {
    const { error, filename, message, lineno, colno } = (errorEvent as any);
    const { name, stack } = error || {};
    const browser = $.browser;

    details = {
      currentUrl: location.href,
      filename,
      lineno,
      colno,
      name,
      message,
      stack,
      browser,
      features: CDD.features,
    };
  }

  details = omitDeep(details, ...suppressDisplayDiagnosticProperties);

  askForConfirmation({
    title: 'Error',
    message: 'We\'re sorry, an error occurred during the last operation.',
    noCancelOption: true,
    additionalElements: <AdditionalDetails details={details} errorList={errorList} />,
  });
};
