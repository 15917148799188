// JEST-TODO - is this really used?
/* eslint-disable no-jquery/no-prop, no-jquery/no-jquery-constructor, no-jquery/no-val */

import React from 'react';
import autobind from 'class-autobind';
import $ from 'jquery';
import axios from 'axios';

import Icon from '@/shared/components/Icon.jsx';
import { A } from '@/shared/components/sanitizedTags.js';
import FileSingleAttachment from '@/shared/components/FileSelectors/SingleAttachment';
import { FileObject } from '@/shared/components/FileSelectors/types';

type Props = FileSingleAttachment['props'] & {
  dataTextboxSelector: string,
  data?: string,
};

type State = {
  retryable: boolean,
  alert: boolean,
  data?: string,
  file?: FileObject,
};

export default class MoleculeExternalDataFile extends React.Component<Props, State> {
  constructor(props) {
    super(props);

    const { dataTextboxSelector, data, file } = this.props;
    // console.log([data, dataTextboxSelector, $(dataTextboxSelector).val()])
    const retryable = !!data && $(dataTextboxSelector).val() != data;
    this.state = { alert: true, retryable, data, file };

    autobind(this);
  }

  private setSequence(file?: FileObject): Promise<void> | void {
    const { additionalActions, filesPath } = this.props;
    typeof additionalActions == 'function' && additionalActions(file);

    const id = file?.id;
    if (id > 0) {
      this.setState({ retryable: false });
      // we use the promise in the specs
      return axios.get(`${filesPath}/${id}/external_data`, { params: { format: 'json' } })
        .then(response => {
          let data: string;
          switch (response.data.type) {
            case 'nucleotide_sequence':
              data = response.data.sequence;
          }

          this.updateDataTextBox({ data, file });
        })
        .catch(() => {
          this.setState({ alert: true, retryable: true, data: null, file });
        });
    } else {
      this.updateDataTextBox({ data: null, file: null });
    }
  }

  private retrySetSequence(): void {
    const { data, file } = this.state;

    if (data) {
      this.updateDataTextBox({ data, file });
    } else {
      this.setSequence(file);
    }
  }

  private dismissAlert(): void {
    this.setState({ alert: false });
  }

  private updateDataTextBox({ data, file }: { data: string; file: FileObject; }): void {
    const { dataTextboxSelector } = this.props;
    if (data) {
      $(dataTextboxSelector).val(data);
      $(dataTextboxSelector).prop('readonly', true);
      this.setState({ retryable: false, data, file });
    } else {
      $(dataTextboxSelector).prop('readonly', false);
      this.setState({ retryable: false, data, file });
    }
  }

  private renderRetry(): JSX.Element {
    return (
      <span className='external-data-file-retry'>
        <A href='#' title='Retry data autofill' onClick={this.retrySetSequence}>
          <span>
            <Icon icon='arrow_rotate_anticlockwise' forceSize='16' />
          </span>
          <span>
            Retry data<br /> autofill
          </span>
        </A>
      </span>
    );
  }

  private renderAlertRetryIfNeeded(): JSX.Element {
    const { alert, retryable } = this.state;
    if (alert && retryable) {
      return (
        <span className='external-data-file-retry-alert'>
          <span className='external-data-file-retry-actions'>
            {this.renderRetry()}
            <span className='external-data-file-retry-alert-cancel'>
              <A href='#' className='cancel' title='Dismiss alert' onClick={this.dismissAlert}>
                <Icon icon='delete' forceSize='16' />
                Dismiss alert
              </A>
            </span>
          </span>
        </span >
      );
    }
  }

  private renderRetryIfNeeded(): JSX.Element {
    const { retryable } = this.state;

    if (retryable) {
      return this.renderRetry();
    }
  }

  render(): JSX.Element {
    const { children } = this.props;

    return (
      <div className='external-data-file'>
        <FileSingleAttachment {...this.props} additionalActions={this.setSequence}>
          {children as JSX.Element}
          {this.renderRetryIfNeeded()}
        </FileSingleAttachment>
        {this.renderAlertRetryIfNeeded()}
      </div>
    );
  }
}
