import { AccountsStore } from '@/Accounts/accountsStore';
import { ProtocolsStore } from '@/Protocols/protocolsStore';
import { activeAjax, CDD } from '@/typedJS';
import { makeAutoObservable } from 'mobx';
import React, { createContext, ReactNode, useContext } from 'react';
import { EnvironmentStore } from './environmentStore';
import { RouterStore } from './routerStore';
import { LocalPersistenceStore } from '@/stores/localPersistenceStore';
import { FormDefinitionsStore } from '@/FormDefinitions/stores/formDefinitionsStore';
import { ProtocolSearchCriteriaStore } from '@/ExploreData/stores/protocolSearchCriteriaStore';
import { RunDataStore } from '@/RunData/runDataStore';
import { SampleDataStore } from '@/Samples/stores/sampleDataStore';
import { MixturesStore } from '@/Mixtures/mixturesStore';
import { LocationStore } from '@/Samples/stores/locationStore';
import { ProtocolReadoutDefinitionsStore } from '@/ProtocolReadoutDefinitions/stores/protocolReadoutDefinitionsStore';
import { InventoryStore } from '@/Inventory/stores/inventoryStore';
import { VaultPreferencesStore } from './vaultPreferencesStore';
import { EditInventoryStore } from '@/Samples/stores/editInventoryStore';

interface BaseStore {
  // eslint-disable-next-line no-use-before-define
  readonly root: RootStore;
  init?();
  cleanup?();
}

// https://dev.to/ivandotv/mobx-root-store-pattern-with-react-hooks-318d
export class RootStore {
  routerStore: RouterStore;
  environmentStore: EnvironmentStore;
  protocolsStore: ProtocolsStore;
  accountsStore: AccountsStore;
  localPersistenceStore: LocalPersistenceStore;
  protocolSearchCriteriaStore: ProtocolSearchCriteriaStore;
  runDataStore: RunDataStore;
  formDefinitionsStore: FormDefinitionsStore;
  sampleDataStore: SampleDataStore;
  inventoryStore: InventoryStore;
  editInventoryStore: EditInventoryStore;

  mixturesStore: MixturesStore;
  locationStore: LocationStore;
  protocolReadoutDefinitionsStore: ProtocolReadoutDefinitionsStore;
  vaultPreferencesStore: VaultPreferencesStore;
  loading = 0;
  saving = 0;
  inited = false;

  private stores: Array<BaseStore> = [];

  constructor() {
    this.createStores();
    makeAutoObservable(this, undefined, { autoBind: true });
  }

  private createStores() {
    this.stores.push(
      this.routerStore = new RouterStore(this),
      this.vaultPreferencesStore = new VaultPreferencesStore(this),
      this.protocolsStore = new ProtocolsStore(this),
      this.accountsStore = new AccountsStore(this),
      this.localPersistenceStore = new LocalPersistenceStore(this),
      this.protocolSearchCriteriaStore = new ProtocolSearchCriteriaStore(this),
      this.environmentStore = new EnvironmentStore(this),
      this.runDataStore = new RunDataStore(this),
      this.sampleDataStore = new SampleDataStore(this),
      this.inventoryStore = new InventoryStore(this),
      this.mixturesStore = new MixturesStore(this),
      this.locationStore = new LocationStore(this),
      this.protocolReadoutDefinitionsStore = new ProtocolReadoutDefinitionsStore(this),
      this.editInventoryStore = new EditInventoryStore(this),
    );
    this.stores.push(this.formDefinitionsStore = new FormDefinitionsStore(this));
  }

  /**
   * Iterate through all the stores, removing any that aren't in the filter function.
   * Used in unit tests to exclude certain stores aside from the ones being tested.
   */
  filterOutStores(filterFunction: (root: RootStore, store: BaseStore) => boolean) {
    this.stores.filter(store => !filterFunction(this, store)).forEach(store => {
      // remove this store

      // first cleanup
      store.cleanup?.();

      // then find the property on this object containing the store and set it to null
      let property: keyof typeof this;
      for (property in this) {
        if (this[property] === store) {
          this[property] = null;
        }
      }

      // finally remove it from the array of stores
      this.stores = this.stores.filter(testStore => (testStore !== store));
    });
  }

  init() {
    if (this.inited) {
      return;
    }
    this.inited = true;
    this.loading = this.saving = 0;

    // call init *after* all stores are instantiated
    this.stores.forEach(store => store.init?.());
  }

  cleanup() {
    this.stores.forEach(store => store.cleanup?.());
    this.stores = [];
    this.inited = false;
    this.createStores();
    this.init();
  }

  incrementLoading() {
    ++this.loading;
    CDD && activeAjax.start();
  }

  decrementLoading() {
    --this.loading;
    CDD && activeAjax.stop();
  }

  incrementSaving() {
    ++this.saving;
    CDD && activeAjax.start();
  }

  decrementSaving() {
    --this.saving;
    CDD && activeAjax.stop();
  }
}

let store: RootStore;

// get the singleton rootStore object, creating if necessary.
export function getRootStore(unitTestOptions?: {
  forceInit?: boolean,
  mockUrl?: string,
  preInit?: (rootStore: RootStore) => void,
  filterStoresFunction?: (rootStore: RootStore, store: BaseStore) => boolean
}) {
  if (unitTestOptions?.forceInit) {
    store?.cleanup();
    store = null;
  }

  let doInit = false;
  if (!store) {
    store = new RootStore();
    if (unitTestOptions?.mockUrl) {
      store.routerStore.setUrl(unitTestOptions?.mockUrl);
    }
    doInit = true;
  }

  if (unitTestOptions?.filterStoresFunction) {
    store.filterOutStores(unitTestOptions.filterStoresFunction);
  }

  if (unitTestOptions?.preInit) {
    unitTestOptions?.preInit(store);
  }

  if (unitTestOptions?.mockUrl && store.routerStore) {
    store.routerStore.setUrl(unitTestOptions.mockUrl);
  }

  doInit && store.init();

  return store;
}

// create the context
const StoreContext = createContext(undefined);

// create the provider component
export function RootStoreProvider({ children }: { children: ReactNode }) {
  // only create the store once ( store is a singleton)
  const root = store || new RootStore();

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}

// create the hook
export function useRootStore() {
  const context = useContext(StoreContext);
  if (context === undefined) {
    throw new Error('useRootStore must be used within RootStoreProvider');
  }

  return context;
}
