import { ProtocolSearchCriteriaView } from '@/ExploreData/components/ProtocolSearchCriteriaView';
import { InventorySearchView } from '@/Inventory/InventorySearchView';
import launchMixtureEditorDialog from '@/Mixtures';
import { LaunchBioisosterResultsDialog } from '@/Molecule/DeepLearning/Bioisoster/LaunchDLBioisoster';
import { LaunchSimilarityResultsDialog } from '@/Molecule/DeepLearning/Similarity/LaunchDLSimilaritySearch';
import ProtocolReadoutDefinitionsView from '@/ProtocolReadoutDefinitions/ProtocolReadoutDefinitionsView';
import RunDataView from '@/RunData/RunDataView';
import SampleDataView from '@/Samples/SampleDataView';
import { LocationPickerFormField } from '@/Samples/components/location/LocationPickerFormField';
import {
  ChemistryImage,
  DownloadMoleculeImage,
  LaunchStructureEditor,
  RenderIfVisible,
} from '@/components';
import { DownloadMixtureImage } from '@/components/DownloadMoleculeImage/DownloadMixtureImage';
import { ImportParser } from '@/components/ImportParser/ImportParser';
import { ParserManager } from '@/components/ImportParser/ParserManager';
import { MixtureComponentBreakout } from '@/components/MixtureComponentBreakout/MixtureComponentBreakout';
import { PickListItemsEditorInForm } from '@/components/PickListItemsEditor/PickListItemsEditorInForm';
import { launchStructureEditorDialog } from '@/components/StructureEditor/StructureEditorDialog';
import { CDD } from '@/typedJS';
import merge from 'lodash/merge';
import AccountsView from './Accounts/AccountsView';
import { ProjectsPage } from './Accounts/Projects/ProjectsPage';
import AdminVaultSelect from './Admin/VaultSelect';
import ProtocolAnnotator from './Annotator/Templates/ProtocolAnnotator';
import TemplateManager from './Annotator/Templates/TemplateManager';
import ApiKeyCopyButton from './ApiKeyCopyButton/index.jsx';
import ChemicalPropertiesSearch from './ChemicalPropertiesSearch';
import ElnEntries from './Eln/Entries/index.jsx';
import { ElnEntryRenderer as ElnEntry } from './Eln/Entry/index.jsx';
import ElnWitnessing from './ElnWitnessing/index.js';
import {
  BatchFieldDefinitions,
  ElnFieldDefinitions,
  MoleculeFieldDefinitions,
  RunFieldDefinitions,
  ProtocolFieldDefinitions,
} from './FieldDefinitions';
import { InventoryFieldDefinitions } from './FieldDefinitions/InventoryFieldDefinitions';
import { FormDefinitionsPage } from './FormDefinitions';
import MixtureEditorDialog from './Mixtures/MixtureEditorDialog';
import MoleculeExternalDataFile from './Molecule/ExternalDataFile';
import { HazardCodeIconsView } from './Molecule/Safety/HazardCodeIconsView';
import MoleculeKeywordSearch from './MoleculeKeywordSearch/index.jsx';
import MoleculeSearchBuilder from './MoleculeSearchBuilder/index.jsx';
import Protocols from './Protocols/Protocols';
import { ChangePassword } from './components/ChangePassword/ChangePassword';
import { Delete as DeleteAbstractResource } from './models/AbstractResource/Delete.jsx';
import MoleculeBatchIdentifierPicker from './models/Batch/MoleculeBatchIdentifierPicker';
import BatchSaltAndSolventEditor from './models/Batch/SaltAndSolventEditor';
import { StripOrSalt as BatchStripOrSalt } from './models/Batch/StripOrSalt';
import ConfirmDeleteButton from './shared/components/ConfirmDeleteButton.jsx';
import EditLink from './shared/components/EditLink';
import WopiView from './shared/components/WopiView.jsx';
import initChemUtils from '@cdd/ui-kit/lib/chemUtils';

// Needs the browser or mocks which makes jest cranky at the moment. Easier to not have it in @/components/index.tsx
import DoseResponsePlotPreview from '@/components/DoseResponsePlotPreview';
import DoseResponseApp from '@/components/DoseResponseApp';
import { VisionFile, VisionVault } from '@/components/VisualizationApp';
import { ChildWithTooltip } from '@/components/ChildWithTooltip/ChildWithTooltip';

// temporary until we transition eln to transpiling
window.initChemUtils = initChemUtils;

if (CDD?.StructureEditor) {
  CDD.StructureEditor.launchStructureEditorDialog = launchStructureEditorDialog;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
(require as any).context('../assets/images/structureless/');

merge(CDD, {
  Component: {
    Class: {
      AccountsView,
      AdminVaultSelect,
      ApiKeyCopyButton,
      BatchFieldDefinitions,
      BatchSaltAndSolventEditor,
      BatchStripOrSalt,
      ChangePassword,
      ChemicalPropertiesSearch,
      ChemistryImage,
      ConfirmDeleteButton,
      DeleteAbstractResource,
      DoseResponseApp,
      DoseResponsePlotPreview,
      DownloadMixtureImage,
      DownloadMoleculeImage,
      EditLink,
      ElnEntries,
      ElnEntry,
      ElnFieldDefinitions,
      ElnWitnessing,
      FormDefinitionsPage,
      HazardCodeIconsView,
      SampleDataView,
      ImportParser,
      InventoryFieldDefinitions,
      InventorySearchView,
      LaunchBioisosterResultsDialog,
      launchMixtureEditorDialog,
      LaunchSimilarityResultsDialog,
      LaunchStructureEditor,
      LocationPickerFormField,
      MixtureComponentBreakout,
      MixtureEditorDialog,
      MoleculeBatchIdentifierPicker,
      MoleculeExternalDataFile,
      MoleculeFieldDefinitions,
      MoleculeKeywordSearch,
      MoleculeSearchBuilder,
      ParserManager,
      PickListItemsEditorInForm,
      ProjectsPage,
      ProtocolAnnotator,
      ProtocolFieldDefinitions,
      Protocols,
      ProtocolReadoutDefinitionsView,
      ProtocolSearchCriteriaView,
      RenderIfVisible,
      RunDataView,
      RunFieldDefinitions,
      TemplateManager,
      VisionFile,
      VisionVault,
      WopiView,
      ChildWithTooltip,
    },
    Instance: {},
  },
});
