import React from 'react';

import Icon from '@/shared/components/Icon';
import { A } from '@/shared/components/sanitizedTags';
import { Tooltip } from '@mui/material';

type Props = {
  id?: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
  hidden?: boolean;
  children?: React.ReactNode;
  disabledMessage?: string;
}

export default function EditLink(props: Props) {
  const { onClick, disabled, hidden, id, disabledMessage } = props;

  if (hidden) {
    return null;
  } else if (disabled) {
    const contents = <A href="#" className="disabled" id={id}>
      <Icon forceSize="16" icon="pencil" />
      {props.children}
      &nbsp;
      &#x26A0;
    </A>; // add WARNING SYMBOL

    return <Tooltip title={disabledMessage} className="editableData-editLink">
      <span>{contents}</span>
    </Tooltip>;
  } else {
    return (
      <A href="#" className="editableData-editLink" id={id} onClick={onClick}>
        <Icon forceSize="16" icon="pencil" />
        {props.children}
      </A>
    );
  }
}
