import React, { useState } from 'react';
import { Link, Tooltip } from '@mui/material';
import { Img } from '@/shared/components/sanitizedTags.js';
import { BioisosterResultsDialog } from './BioisosterResultsDialog';
import { DLBioisosterResponse, DLError } from './BioisosterResultsTypes';
import { processBioisostereResults } from './BioisosterResultsUtilities';
import magnifierIcon from 'ASSETS/images/cdd30/icons/magnifier.png';
import { CDD } from '@/typedJS';

export const fetchDLBioisoster = async (
  smiles: string,
  count: number,
): Promise<DLBioisosterResponse | DLError> => {
  // remove CXSMILES information if present
  const structure = smiles.trim().split(/(\s+)/)[0]; // eslint-disable-line @typescript-eslint/no-unused-vars
  try {
    const response = await fetch('/models/bioisosters', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ structure, number_suggestions: count, return_known_identifiers: true }),
    });
    const json = await response.json();
    return processBioisostereResults(json);
  } catch (e) {
    return { error: e };
  }
};

type LaunchBioisosterResultsDialogProps = {
  smiles: string;
  ban_external_links?: boolean;
  skip_external_link_warning?: boolean;
  user_can_register_molecules?: boolean;
  fetchDLBioisoster?: (
    smiles: string,
    count: number,
  ) => Promise<DLBioisosterResponse | DLError>;
  title?: string;
  showIcon?: boolean;
  linkClassName?: string;
};

export const LaunchBioisosterResultsDialog = (
  props: LaunchBioisosterResultsDialogProps,
) => {
  const { showIcon = true, title, linkClassName } = props;
  const [open, setOpen] = useState(false);

  const onClick = (e: React.MouseEvent) => {
    setOpen(true);
    e.preventDefault();
  };

  const heavy = props.smiles.split(' ')[0].replace(/[Ha-z@()[\]0-9=]/g, '');
  if (heavy.length > 50) {
    return (
      <Tooltip title='Feature not available for structures with more than 50 heavy atoms. Contact support@collaborativedrug.com with any questions.'>
        <span><BioisostereText showIcon={showIcon} title={title} /></span>
      </Tooltip>
    );
  }

  const onClose = () => setOpen(false);

  const skipExternalLinkWarning = props.skip_external_link_warning ?? CDD.features.skipExternalLinkWarning;
  const banExternalLinks = props.ban_external_links ?? CDD.features.banExternalLinks;
  const canRegisterMolecules = props.user_can_register_molecules ?? CDD.features.canRegisterMolecules;
  return (
    <>
      <Link className={linkClassName} href={'#'} onClick={onClick} style={{ textDecoration: 'none' }}>
        <BioisostereText showIcon={showIcon} title={title} />
      </Link>
      <BioisosterResultsDialog
        open={open}
        fetchDLBioisoster={props.fetchDLBioisoster || fetchDLBioisoster}
        smiles={props.smiles}
        banExternalLinks={banExternalLinks}
        skipExternalLinkWarning={skipExternalLinkWarning}
        canRegisterMolecules={canRegisterMolecules}
        onClose={onClose}
      />
    </>
  );
};

type BioisostereTextProps = {
  title?: string;
  showIcon?: boolean;
};

export const BioisostereText = (props: BioisostereTextProps) => {
  const { showIcon = true, title } = props;
  return (
    <>
      {showIcon && <Img
        width={16}
        height={16}
        className='icon-16'
        alt='explore'
        src={magnifierIcon}
      />}
      {title || 'Suggest bioisosteres using deep learning similarity'}
    </>
  );
};
