import svgToMiniDataURI from 'mini-svg-data-uri';
import { MetaVector } from 'webmolkit/gfx/MetaVector';

export function prepareSVGForURL(gfx: MetaVector): string {
  // note: the second parameter is for Xlinks, which cause problems for Firefox/Safari, but lacking
  // them breaks Inkscape, so you just can't win
  const svg = gfx.createSVG(false, false);

  return svgToMiniDataURI(svg);
}

export function prepareSVGForExport(gfx: MetaVector): string {
  return gfx.createSVG(true, true);
}

export function preparePNG(gfx: MetaVector): string {
  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  canvas.width = gfx.width;
  canvas.height = gfx.height;
  const ctx = canvas.getContext('2d');
  gfx.renderContext(ctx);
  return canvas.toDataURL('image/png');
}

export function copyText(text: string): void {
  if (navigator.clipboard) {
    (async () => {
      await navigator.clipboard.writeText(text);
    })();
  } else {
    document.execCommand('copy', true, text);
  }
}

export function copyImage(gfx: MetaVector): void {
  if (!navigator.clipboard || typeof ClipboardItem == 'undefined') {
    alert('Operation not supported.');
    return;
  }

  const canvas = document.createElement('canvas') as HTMLCanvasElement;
  canvas.width = gfx.width;
  canvas.height = gfx.height;
  const ctx = canvas.getContext('2d');
  gfx.renderContext(ctx);

  const fetchBlob = new Promise<Blob>(resolve => {
    canvas.toBlob((blob) => {
      resolve(blob);
    }, 'image/png', 1);
  });
  const item = new ClipboardItem({ 'image/png': fetchBlob });
  (async () => {
    await navigator.clipboard.write([item]);
  })();
}

export function downloadText(text: string, filename: string, mimeType: string): void {
  const a = document.createElement('a');
  const blob = new Blob([text], { type: mimeType });
  const url = URL.createObjectURL(blob);
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
  a.remove();
}

export function downloadImage(gfx: MetaVector, filename: string): void {
  const a = document.createElement('a');
  const url = preparePNG(gfx);
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  a.click();
  a.remove();
}
