import {
  ColumnDef,
} from '@/components';
import EditFieldDefinitionView from '@/FieldDefinitions/shared/EditFieldDefinitionView';
import { EditFieldDefinitionViewProps, FieldDefinitionUtils } from '@/FieldDefinitions/shared/fieldDefinitionUtils';
import React from 'react';
import { FieldDefinition } from '../types';
import { deepClone } from '@/Annotator/data/utils';

type Props = EditFieldDefinitionViewProps & {
  allows_multiple_molecules_per_structure: boolean;
};

type State = Props;

export class ProtocolFieldDefinitions extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = FieldDefinitionUtils.stripEntitiesFromProps(deepClone(props), 'protocol') as Props;
  }

  dataTypeOptions = [
    'Text',
    'LongText',
    'PickList',
    'File',
    'Number',
    'BatchLink',
  ];

  get columns(): Array<ColumnDef> {
    const columns: Array<ColumnDef & { id: keyof FieldDefinition; }> = [
      {
        label: 'Name',
        id: 'name',
        width: 140,
      },
      {
        label: 'Data Type',
        id: 'data_type_name',
        type: 'pickList',
        width: 120,
      },
      {
        label: 'This Field',
        id: 'required_group_number',
        name: 'requirement',
        className: 'requirement',
        width: 230,
      },
    ];
    return columns as Array<ColumnDef>;
  }

  get fixedRows(): Array<FieldDefinition> {
    return [
      {
        id: 'protocol-name',
        name: 'Name',
        unique_value: true,
        required: true,
        _required_group_label: 'is required',
      },
    ];
  }

  render() {
    return <EditFieldDefinitionView
      {...this.state}
      columns={this.columns}
      fixedRows={this.fixedRows}
      dataType={'protocol'}
      dataTypeOptions={this.dataTypeOptions}
    />;
  }
}
