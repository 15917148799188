import React from 'react';
import omit from 'lodash/omit';
import { A } from '@/shared/components/sanitizedTags.js';
import { Tooltip } from '@mui/material';

// a wrapper around A that adds a disabled class when disabled is true
type Props = React.HTMLProps<HTMLAnchorElement>;

export const CDDLink = (props: Props) => {
  let { className } = props;
  if (props.disabled) {
    className = (className ?? '') + ' disabled';
  }
  const link = <A href="#" {...omit(props, 'title')} className={className}>{props.children}</A>;

  if (props.title) {
    return <Tooltip title={props.title}>{link}</Tooltip>;
  }
  return link;
};
